import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  ActivityIndicator
} from "react-native";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import NavBar from "src/components/navBar";
import Style from "src/globalStyles";
import Rex from "src/globalState";

const screenName = "My Stats";

const StatsCard = ({ title, checkIns, vert, isTotalCard }) => (
  <>
    {checkIns || vert || isTotalCard ? (
      <View
        style={[
          Style.get("card"),
          {
            alignItems: "center",
            paddingBottom: 20,
            width: "100%",
            backgroundColor: title === "Total" ? "#e6f2ff" : "white"
          }
        ]}
      >
        <Text
          style={{
            fontSize: 24,
            fontWeight: "bold",
            color: title === "Total" ? "#0c9bad" : "#55b9c6"
          }}
        >
          {title}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%"
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 18, marginTop: 20 }}>Trips</Text>
            <Text
              style={{
                fontSize: 48,
                fontWeight: "bold",
                color: Rex.getConfig()?.colors?.text
              }}
            >
              {checkIns || 0}
            </Text>
          </View>
          {/* <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 18, marginTop: 20 }}>Laps</Text>
            <Text
              style={{
                fontSize: 48,
                fontWeight: "bold",
                color: Rex.getConfig()?.colors?.text
              }}
            >
              {laps || 0}
            </Text>
          </View> */}
        </View>
        <Text style={{ fontSize: 18, marginTop: 20 }}>
          Vertical Elevation Gained
        </Text>
        <Text
          style={{
            fontSize: 48,
            fontWeight: "bold",
            color: Rex.getConfig()?.colors?.text
          }}
        >
          {vert || 0} <Text style={{ fontSize: 24 }}>feet</Text>
        </Text>
      </View>
    ) : null}
  </>
);

export default function MyStatsUNE({ navigation }) {
  const [allPlaces, setAllPlaces] = useState(null);
  const [allRoutes, setAllRoutes] = useState(null);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    // Fetch all resort locations
    const placeData = await Util.fetchGoogleSheetData(
      "1kxZZQZM-DNEbQf9c4K0UeHvKOdyeYeU9fmdquO85fpk",
      "All Locations"
    );
    // Fetch all routes (slopes)
    const routeData = await Util.fetchGoogleSheetData(
      "1kxZZQZM-DNEbQf9c4K0UeHvKOdyeYeU9fmdquO85fpk",
      "All Routes"
    );
    const userCheckInData = await Util.fetchGoogleSheetData(
      "1Pgk7IPuLy4WmUNfSvm98G1Di3X8AKj_Cg0lZbYvrXIg",
      "User Check-ins by Location"
    );
    const userVertData = await Util.fetchGoogleSheetData(
      "1Pgk7IPuLy4WmUNfSvm98G1Di3X8AKj_Cg0lZbYvrXIg",
      "User Vert by Location"
    );
    // const userLapsData = await Util.fetchGoogleSheetData(
    //   "1Pgk7IPuLy4WmUNfSvm98G1Di3X8AKj_Cg0lZbYvrXIg",
    //   "User Laps by Location"
    // );
    const places = {};
    placeData.forEach((place) => {
      places[place.ID] = place;
    });
    const routes = {};
    routeData.forEach((route) => {
      routes[route.ID] = route;
    });
    let userCheckInRow = {};
    userCheckInData.forEach((row) => {
      if (row.UserID === Firebase.getUserID()) userCheckInRow = row;
    });
    let userVertRow = {};
    userVertData.forEach((row) => {
      if (row.UserID === Firebase.getUserID()) userVertRow = row;
    });
    // let userLapsRow = {};
    // userLapsData.forEach((row) => {
    //   if (row.UserID === Firebase.getUserID()) userLapsRow = row;
    // });
    const userStats = {
      total: {
        // laps: userLapsRow?.total || 0,
        vert: userVertRow?.total || 0,
        checkIns: userCheckInRow?.total || 0
      },
      byPlace: {},
      byRoute: {}
    };
    Object.keys(places).forEach((placeID) => {
      userStats.byPlace[placeID] = {
        checkIns: userCheckInRow[placeID] || 0,
        // laps: userLapsRow[placeID] || 0,
        vert: userVertRow[placeID] || 0
      };
    });
    setAllPlaces(places);
    setAllRoutes(routes);
    setStats(userStats);
    setLoading(false);
  };

  useEffect(() => {
    Analytics.logEvent("view_myStatsUNE");
    loadData();
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={screenName} />
      <ScrollView
        style={{
          paddingHorizontal: 15,
          backgroundColor: "white",
          flex: 1
        }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        {loading ? (
          <ActivityIndicator size="large" style={{ marginTop: 20 }} />
        ) : (
          <>
            <StatsCard
              title="Total"
              checkIns={stats?.total?.checkIns}
              // laps={stats?.total?.laps}
              vert={stats?.total?.vert}
              isTotalCard
            />
            {!!allPlaces &&
              !!stats &&
              Object.entries(allPlaces).map(([placeID, place]) => (
                <StatsCard
                  key={placeID}
                  title={place.Name}
                  checkIns={stats?.byPlace?.[placeID]?.checkIns}
                  // laps={stats?.byPlace?.[placeID]?.laps}
                  vert={stats?.byPlace?.[placeID]?.vert}
                />
              ))}
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  }
});
