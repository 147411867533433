/* global window */
import React from "react";
import { TouchableOpacity, Platform } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import Glob from "src/globalConstants";

const { width } = Glob.get("dimensions");

const DEFAULT_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

export default function HelpButton({
  title = "Help",
  message = "",
  color = "white",
  size = DEFAULT_SIZE,
  navigation,
  videoUrl,
  style = {}
}) {
  const onPress = () => {
    if (Platform.OS === "web" && videoUrl) {
      window.open(videoUrl, "_blank");
      return;
    }
    const buttons = [
      {
        text: "Done",
        onPress: () => {
          Analytics.logEvent("touch_HelpButton", {
            title,
            message,
            action: "Done"
          });
        }
      }
    ];
    if (videoUrl && navigation)
      buttons.push({
        text: "Learn More",
        onPress: () => {
          const { push } = navigation;
          Analytics.logEvent("touch_HelpButton", {
            title,
            message,
            action: "LearnMore"
          });
          push("webNav", { title, url: videoUrl });
        }
      });
    Util.alert(title, message, buttons);
  };
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onPress={onPress}
      style={{ marginRight: 10, ...style }}
    >
      <FontAwesome5 name="question-circle" size={size} color={color} />
    </TouchableOpacity>
  );
}
