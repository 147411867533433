import React, { Component } from "react";
import { Platform, Text } from "react-native";
import * as Linking from "expo-linking";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Rex from "src/globalState";

export default class TouchableLink extends Component {
  _handlePress = () => {
    const {
      link,
      type,
      navigate = () => {},
      navigation,
      openExternally,
      onPress = () => {}
    } = this.props;
    switch (type) {
      case "phone":
        Linking.openURL(`tel:${link}`);
        break;
      case "email":
        Linking.openURL(`mailto:${link}`);
        break;
      case "web":
        if (Platform.OS === "web" || openExternally) Util.openURL(link);
        else if (navigation)
          navigation.push("webNav", {
            title: this.formatWebLinkTitle(link),
            url: link
          });
        else navigate(this.formatWebLinkTitle(link), link);
        break;
      case "button":
        onPress();
        break;
      default:
        break;
    }
  };

  formatWebLinkTitle = (link) => {
    return link
      .replace("https", "")
      .replace("http", "")
      .replace("://", "")
      .replace("www.", "");
  };

  render() {
    const { text, textStyle = {} } = this.props;
    return (
      <Text
        style={{
          color: Rex.getConfig()?.colors?.button || Glob.get("onespotColor"),
          ...textStyle
        }}
        onPress={this._handlePress}
      >
        {(text || "").truncate(80)}
      </Text>
    );
  }
}
