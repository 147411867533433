// Web version
import React from "react";
import { View } from "react-native";
import Glob from "src/globalConstants";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const { width } = Glob.get("dimensions");

export default function CircularProgress({ value, color, small }) {
  const sizeMultiplier = small ? 0.3 : 1;
  return (
    <View style={{ width: 0.3 * width * sizeMultiplier }}>
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        background
        styles={{
          path: { stroke: color },
          text: {
            fill: color,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: 24
          },
          trail: { stroke: "#eee" },
          background: { fill: "#ffffff" }
        }}
      />
    </View>
  );
}
