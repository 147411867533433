// QR codes need to be formatted as:
//    `{"type": "dynamic", "navName": "ReferFriend"}`
//    `{"type": "dynamicForm", "navName": "WeekendOffCampus"}`
//    exp://192.168.50.213:19000/--/screen?type=webNav&navName=web_Calendar

// You can generate QR codes like this:
//    Linking.makeUrl("screen", {type: "webNav", navName: "web_Calendar"});

import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Alert } from "react-native";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as Linking from "expo-linking";
import { Ionicons } from "@expo/vector-icons";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";

const pageName = "QR Scanner";

export default function QRScanner({ navigation }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_qrscanner");
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      const granted = status === "granted";
      if (!granted)
        Analytics.logEvent("error_qrscanner_cameraPermissionsNotEnabled");
      setHasPermission(granted);
    })();
  }, []);

  const onBarCodeScanned = ({ data }) => {
    Analytics.logEvent("action_qrscanner_qrCodeScanned", { data });
    setScanned(true);

    let portalType;
    let navName;
    if (data.slice(0, 8) === '{"type":') {
      const parsedData = JSON.parse(data);
      portalType = parsedData.type;
      navName = parsedData.navName;
    } else if (data.includes("--/screen?")) {
      const { queryParams } = Linking.parse(data);
      portalType = queryParams.type;
      navName = queryParams.navName;
    } else {
      Analytics.logEvent("error_qrscanner_qrCodeInvalid");
      Alert.alert("Sorry! Invalid QR code.", "", [
        { text: "OK", onPress: () => setScanned(false) }
      ]);
      return;
    }
    if (portalType === "native") {
      navigation.push(navName, {
        portalType,
        navName,
        txtName: `${portalType}: ${navName}`
      }); // e.g. 'food'
    } else {
      navigation.push(portalType, {
        portalType,
        navName,
        txtName: `${portalType}: ${navName}`
      }); // e.g. 'webNav'
    }
  };

  if (hasPermission === null) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={pageName} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Requesting camera permission...
          </Text>
        </View>
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={pageName} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Please enable camera permission in order to use this feature.
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={pageName} />
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
        style={{ flex: 1 }}
      >
        <View style={styles.qrIcon}>
          <Ionicons name="scan" size={300} color="white" />
          <Text style={styles.qrHelperText}>
            Scan a posted QR code to interact with it.
          </Text>
        </View>
      </BarCodeScanner>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  qrIcon: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center"
  },
  qrHelperText: {
    fontSize: 24,
    color: "white",
    width: "60%",
    textAlign: "center"
  },
  providePermissionsText: {
    fontSize: 24,
    width: "60%",
    textAlign: "center"
  }
});
