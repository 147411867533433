import React, { useState } from "react";
import { View, ScrollView, TouchableOpacity, Platform } from "react-native";
import tinycolor from "tinycolor2";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import TextItem from "src/components/dynamicContent/TextItem";
import ButtonItem from "src/components/dynamicContent/ButtonItem";

const { height, width } = Glob.get("dimensions");

export default function BannerMessage({
  editable = false,
  navigation,
  onPressClose = () => {},
  setKeyboardToolbarComponents = () => {},
  setBannerMessage = () => {},
  // All props below are stored in the database (plus "active")
  text,
  type,
  backgroundColor,
  textItem,
  buttonItem,
  showButton
}) {
  const [isEditingTextItem, setIsEditingTextItem] = useState(false);
  const bannerColors = Rex.getConfig()?.colors?.bannerMessage || {};

  let closeButtonColor;
  if (backgroundColor)
    closeButtonColor = tinycolor(backgroundColor).isLight() ? "black" : "white";
  else closeButtonColor = type === "urgent" ? "white" : "black";

  const legacyTextItem = {
    type: "text",
    text,
    style: {
      textAlign: "center",
      color: closeButtonColor
    }
  };
  const shadowSection =
    Platform.OS === "web" ? { boxShadow: "0px 5px 5px rgba(0,0,0,0.2)" } : {};
  let textItemMaxHeight = showButton ? 80 : 120;
  if (isEditingTextItem && Platform.OS !== "ios")
    textItemMaxHeight = 0.7 * height;
  return (
    <View
      style={{
        backgroundColor: backgroundColor || bannerColors[type],
        width: "100%",
        alignItems: "center",
        shadowOpacity: 0.15,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 8 },
        elevation: 7,
        ...shadowSection
      }}
    >
      <ScrollView
        style={{
          paddingHorizontal: 15,
          width,
          maxHeight: textItemMaxHeight
        }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <TextItem
          item={textItem || legacyTextItem}
          allowChangeType={false}
          editable={editable}
          setKeyboardToolbarComponents={setKeyboardToolbarComponents}
          navigate={navigation.push}
          setIsEditingAnItem={setIsEditingTextItem}
          onChangeItem={(newItem) => {
            setBannerMessage((prev) => ({
              ...prev,
              text: newItem.text, // handle legacy implementation
              textItem: newItem
            }));
          }}
        />
      </ScrollView>
      {!!showButton && !!buttonItem && (
        <View style={{ width: "100%" }}>
          <ButtonItem
            editable={editable}
            navigation={navigation}
            navigate={navigation.push}
            setIsEditingAnItem={() => {}}
            item={{ ...buttonItem, flat: true }}
            onChangeItem={(newItem) => {
              setBannerMessage((prev) => ({
                ...prev,
                buttonItem: newItem
              }));
            }}
          />
        </View>
      )}

      {!editable && (
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 7,
            justifyContent: "center",
            alignItems: "center"
          }}
          activeOpacity={0.6}
          onPress={onPressClose}
        >
          <MaterialCommunityIcons
            name="window-close"
            size={24}
            color={closeButtonColor}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}
