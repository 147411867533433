import React, { useRef } from "react";
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";
import Analytics from "src/backend/analytics";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Firebase from "src/backend/firebase";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import InputBox from "src/components/InputBox";
import HelpText from "src/components/HelpText";
import AuthScreenHeader from "src/components/auth/AuthScreenHeader";

const { height } = Glob.get("dimensions");

export default function AuthWidget({
  navigation,
  title = "Log in",
  subtitle = "Welcome back again!",
  buttonText = "Log in",
  email,
  password,
  setEmail,
  setPassword,
  inputFieldOptions,
  isLoading,
  onEmailAuth = () => {},
  showForgotPassword,
  showLoggedInMessage,
  userInvitation,
  userIsAlreadyLoggedIn = false
}) {
  const {
    nameFieldIcon,
    name,
    setName,
    year,
    setYear,
    classYearRequired,
    customFields,
    customFieldsArray,
    setCustomInputFieldsValues,
    customInputFieldsValues
  } = inputFieldOptions || {};
  const phoneNumberRequired = !!customFields?.phoneNumber?.required;
  const phoneNumber = customInputFieldsValues?.phoneNumber;

  const RefName = useRef(null);
  const RefYear = useRef(null);
  const RefEmail = useRef(null);
  const RefPassword = useRef(null);

  const emailIsValid = email?.isEmail();
  const passwordIsValid = password?.length >= 6;
  const passwordIsBad = passwordIsValid && password?.length < 10;

  const nameIsValid = !!name;
  const currentYear = new Date().getFullYear();
  const enteredYear = parseInt(year, 10);
  const yearIsOutOfRange =
    enteredYear < currentYear - 90 || enteredYear > currentYear + 20; // example: in 2020, class year must be between 1930-2040
  const yearIsValid = !!year && !yearIsOutOfRange;

  const phoneNumberIsValid = (phoneNumber || "").isPhoneNumber();

  const allCustomFieldsAreValid =
    (customFieldsArray || []).filter((field) => {
      return field.required && !customInputFieldsValues[field.key];
    }).length < 1;

  const everythingIsValid =
    emailIsValid &&
    passwordIsValid &&
    (!inputFieldOptions ||
      (nameIsValid &&
        ((!classYearRequired && !year) || yearIsValid) &&
        ((!phoneNumberRequired && !phoneNumber) || phoneNumberIsValid) &&
        allCustomFieldsAreValid));

  const canSubmit = everythingIsValid && !isLoading;

  const showCustomFields = customFieldsArray?.length > 0;

  return (
    <View>
      <AuthScreenHeader title={title} subtitle={subtitle} />
      {!!inputFieldOptions && (
        <>
          <InputBox
            inputRef={RefName}
            key="name"
            placeholder="Your Full Name"
            icon={nameFieldIcon}
            autoCapitalize="words"
            onChangeText={setName}
            value={name}
            isValid={nameIsValid}
            onSubmitEditing={() => {
              if (!showCustomFields && RefEmail?.current)
                RefEmail.current.focus();
            }}
            returnKeyType={showCustomFields ? "done" : "next"}
            style={{ marginTop: 20 }}
          />
          {customFieldsArray.map((field) => {
            if (field.key === "phoneNumber")
              return (
                <InputBox
                  placeholder={`${field.title}${
                    field.required ? "" : " (optional)"
                  }`}
                  icon={field.icon || "eacd053e-5dca-4e7d-beff-a65bcab765c6"} // phone
                  value={phoneNumber}
                  onChangeText={(text) =>
                    setCustomInputFieldsValues({
                      ...customInputFieldsValues,
                      [field.key]: text
                    })
                  }
                  isInvalid={phoneNumber && !phoneNumberIsValid}
                  isValid={phoneNumber && phoneNumberIsValid}
                  keyboardType="phone-pad"
                  // returnKeyType="done" // note: for some reason this occasionally caused the iOS app to freeze/crash
                  style={{ marginTop: 20 }}
                />
              );
            if (field.key === "year")
              return (
                <InputBox
                  ref={RefYear}
                  placeholder={`${field.title}${
                    classYearRequired ? "" : " (optional)"
                  }`}
                  icon={field.icon || "d60f5da6-a459-4916-b5d2-f9fb2e4190a2"} // graduation cap
                  value={year}
                  onChangeText={setYear}
                  maxLength={4}
                  isInvalid={year && !yearIsValid}
                  isValid={year && yearIsValid}
                  keyboardType="numeric"
                  returnKeyType="next"
                  onSubmitEditing={() => {
                    if (RefEmail?.current) RefEmail.current.focus();
                  }}
                  style={{ marginTop: 20 }}
                />
              );
            return (
              <InputBox
                placeholder={field.title}
                icon={field.icon || "09f73aa3-64c9-4ff9-b504-53e04997ed6e"} // circle arrow
                onChangeText={(text) =>
                  setCustomInputFieldsValues({
                    ...customInputFieldsValues,
                    [field.key]: text
                  })
                }
                value={customInputFieldsValues[field.key]}
                isValid={!!customInputFieldsValues[field.key]}
                style={{ marginTop: 20 }}
              />
            );
          })}
        </>
      )}

      {!userIsAlreadyLoggedIn && (
        <>
          <InputBox
            inputRef={RefEmail}
            key="email"
            placeholder="Email Address"
            icon="email"
            isInvalid={email && !emailIsValid}
            isValid={email && emailIsValid}
            keyboardType="email-address"
            onChangeText={setEmail}
            value={userInvitation?.email ? `${email} (from invitation)` : email}
            onSubmitEditing={() => {
              if (RefPassword?.current) RefPassword.current.focus();
            }}
            returnKeyType="next"
            style={{ marginTop: 20 }}
            editable={!userInvitation?.email}
          />
          <InputBox
            inputRef={RefPassword}
            key="pw"
            placeholder="Password"
            icon="combokeeper"
            isInvalid={password && !passwordIsValid}
            isValid={password && passwordIsValid}
            isWarning={passwordIsBad}
            secure
            keyboardType="password"
            onChangeText={setPassword}
            value={password}
            onSubmitEditing={() => {
              if (canSubmit) onEmailAuth();
            }}
            returnKeyType="go"
            style={{ marginTop: 20 }}
          />
          {showForgotPassword && (
            <TouchableOpacity
              activeOpacity={0.7}
              style={{ marginTop: 20, alignItems: "flex-end" }}
              onPress={() => {
                Analytics.logEvent("touch_auth_forgotPassword");
                navigation.push("forgotPassword", {
                  prefilledEmail: email
                });
              }}
            >
              <Text style={{ color: "#868686" }}>Forgot password?</Text>
            </TouchableOpacity>
          )}
        </>
      )}

      {showLoggedInMessage && (
        <View style={{ marginTop: 0.04 * height }}>
          <HelpText
            text={[
              `You're signed in with your "${
                Firebase.getUser()?.email
              }" account. You can use that one account across all apps in `,
              <Text style={{ textDecorationLine: "underline" }}>
                <TouchableLink
                  type="web"
                  link="https://www.1spot.app"
                  text="the Onespot ecosystem"
                  openExternally
                />
              </Text>,
              "."
            ]}
          />
        </View>
      )}

      <View
        style={{
          marginTop: 0.08 * height
        }}
      >
        {isLoading ? (
          <ActivityIndicator size="large" />
        ) : (
          <Button
            text={buttonText}
            onPress={onEmailAuth}
            disabled={!canSubmit}
            style={{
              backgroundColor:
                Rex.getConfig()?.colors?.button || Glob.get("onespotColor"),
              opacity: canSubmit ? 1 : 0.3
            }}
            textStyle={{ fontWeight: "bold" }}
          />
        )}
      </View>
    </View>
  );
}
