/* global window */

import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { update } from "firebase/database";
import School from "school/school";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Util from "src/utility";
import Dropdown from "src/components/Dropdown";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";

const { height, width } = Glob.get("dimensions");
const DELETE_ACCOUNT_FORM_ID = "DeleteOnespotAccount";
const BUY_MONTESSORI_APP_FORM_ID = "PurchaseMontessoriSchoolMobileApp";
const BUY_MONTESSORI_APP_EMAIL_FIELD_ID = 351035121;
const BUY_MONTESSORI_APP_STUDENTS_FIELD_ID = 329705149;

const globalDynamicForm = (formID) => {
  const globalDynamicForms = {
    SuggestionBox: {
      title: "Suggestions",
      description:
        "Thanks for your suggestions! We love feedback and we're always working hard to make your app better.",
      id: "1FAIpQLSfaaB5TNg89APTcjoTmjqKxNW54pBoBjrg7YI_M6Spj4i_YGg",
      confirmationMessage:
        "Thanks so much for your feedback! We really appreciate it 🙂",
      questions: [
        {
          text: "First Name",
          autoPopulate: "firstName",
          required: true,
          hidden: true,
          fieldID: 1466138696
        },
        {
          text: "Last Name",
          autoPopulate: "lastName",
          required: true,
          hidden: true,
          fieldID: 1745667155
        },
        {
          text: "Email",
          autoPopulate: "email",
          required: true,
          hidden: true,
          fieldID: 989087614
        },
        {
          text: "Organization",
          autoPopulate: Rex.getConfig()?.names?.full || "Unknown",
          required: true,
          hidden: true,
          fieldID: 619667861
        },
        {
          text: "App Database ID",
          autoPopulate: School.getDatabaseAppID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 124919576
        },
        {
          text: "User ID",
          autoPopulate: Firebase.getUserID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 800416766
        },
        {
          text: "Suggestions",
          description: "or ideas, comments, feedback, or anything!",
          multiline: true,
          required: true,
          fieldID: 181925796
        }
      ]
    },
    RequestCustomPortal: {
      title: "Custom Portal",
      description:
        "Tell us what you're looking for! We'll reach out and work with you to make your vision a reality.",
      id: "1FAIpQLSfe6nxJqc9XdD_Gw1z1A_0-TVc4VWVaTQOGEpEZLYHzoHp8nQ",
      confirmationMessage:
        "Thanks! We will follow up with you with more information as soon as possible.",
      questions: [
        {
          text: "First Name",
          autoPopulate: "firstName",
          required: true,
          hidden: true,
          fieldID: 1466138696
        },
        {
          text: "Last Name",
          autoPopulate: "lastName",
          required: true,
          hidden: true,
          fieldID: 1745667155
        },
        {
          text: "Email",
          autoPopulate: "email",
          required: true,
          hidden: true,
          fieldID: 989087614
        },
        {
          text: "Organization",
          autoPopulate: Rex.getConfig()?.names?.full || "Unknown Organization",
          required: true,
          hidden: true,
          fieldID: 619667861
        },
        {
          text: "App Database ID",
          autoPopulate: School.getDatabaseAppID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 1767673864
        },
        {
          text: "User ID",
          autoPopulate: Firebase.getUserID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 313810388
        },
        {
          text: "Describe the portal you want",
          multiline: true,
          required: true,
          fieldID: 181925796
        }
      ]
    },
    DeleteOnespotAccount: {
      title: "Delete Account",
      description:
        "By submitting this form, you confirm that you want us to PERMANENTLY DELETE your account & data on all our systems. This is permanent and cannot be undone. If you would rather deactivate your account, feel free to go back and sign out, or just delete this app off your phone. If you submit this form, we will delete your account within 1 week.",
      id: "1FAIpQLSclQNcFbtGpIOngMpz3S7vyDAE9o8u7WOW0KNv3STtqi2reUw",
      confirmationMessage:
        "Signing you out now. We will delete your account & data within 7 days 👍",
      questions: [
        {
          text: "First Name",
          autoPopulate: "firstName",
          required: true,
          hidden: true,
          fieldID: 1466138696
        },
        {
          text: "Last Name",
          autoPopulate: "lastName",
          required: true,
          hidden: true,
          fieldID: 1745667155
        },
        {
          text: "Email",
          autoPopulate: "email",
          required: true,
          hidden: true,
          fieldID: 989087614
        },
        {
          text: "Organization",
          autoPopulate: Rex.getConfig()?.names?.full || "Unknown Organization",
          required: true,
          hidden: true,
          fieldID: 619667861
        },
        {
          text: "App Database ID",
          autoPopulate: School.getDatabaseAppID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 1767673864
        },
        {
          text: "User ID",
          autoPopulate: Firebase.getUserID() || "Unknown",
          required: true,
          hidden: true,
          fieldID: 313810388
        },
        {
          text: "Type your account's email address to confirm.",
          multiline: true,
          required: true,
          fieldID: 181925796
        },
        {
          text: "Any feedback you want to share with us?",
          description: "(Optional)",
          multiline: true,
          required: false,
          fieldID: 1652890535
        }
      ]
    }
  };
  return globalDynamicForms[formID];
};

export default function DynamicForm({ route, navigation }) {
  // pass isGlobalForm if this form isn't school-specific
  const { navName: formID, isGlobalForm, userIsCreator } = route?.params || {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [form, setForm] = useState(null);
  const [fields, setFields] = useState([]);
  const [questionIndexToFetchedInfo, setQuestionIndexToFetchedInfo] = useState(
    {}
  );
  const [userID, setUserID] = useState(null);

  const setField = (idx, val) => {
    const newFields = [...fields];
    newFields[idx] = val;
    setFields(newFields);
  };

  useEffect(() => {
    Analytics.logEvent("view_dynamicForm", { formID });
    setUserID(Firebase.getUserID());
    if (Rex.getLoginStatus()) {
      Database.listenUserFirstName((first) => {
        Database.listenUserLastName((last) => {
          Database.listenUserEmail((userEmail) => {
            Database.listenUserSchoolID((userSchoolID) => {
              Database.listenUserType((userType) => {
                const dForm = isGlobalForm
                  ? globalDynamicForm(formID)
                  : School.dynamicForm(formID);
                setForm(dForm);
                const dFields = dForm.questions.map((q) => {
                  switch (q.autoPopulate) {
                    case "firstName":
                      return first;
                    case "lastName":
                      return last;
                    case "email":
                      return userEmail;
                    case "userType":
                      return userType;
                    case "schoolID":
                      return userSchoolID;
                    case "userID":
                      return Firebase.getUserID();
                    default:
                      return q.autoPopulate || null;
                  }
                });
                setFields(dFields);
              });
            });
          });
        });
      });
    } else {
      // If user is not logged in (e.g. if accessed as a web page)
      const dForm = isGlobalForm
        ? globalDynamicForm(formID)
        : School.dynamicForm(formID);
      setForm(dForm);
      setFields(dForm.questions.map(() => null));
    }
  }, []);

  if (!form) return null;

  const readyForSubmission = form.questions
    .map((q, idx) => {
      return (
        !q.required ||
        (fields[idx] !== null &&
          fields[idx] !== "" &&
          fields[idx] !== "~OTHER~")
      );
    })
    .every((v) => v);

  const onSubmitResponse = () => {
    const responseFormattedForAnalytics = {};
    form.questions.forEach((q, idx) => {
      const answer = fields[idx];
      responseFormattedForAnalytics[q.text] = answer;
      if (q.options && q.options.dbSource) {
        getQuestionInfoFromDatabase(q.options.dbSource, false).then(
          (fetchedInfo) => {
            const indexOfChoice = fetchedInfo.options.findIndex(
              (o) => o.value === answer
            );
            addUserToDatabaseOption(q.options.dbSource, indexOfChoice);
          }
        );
      }
    });
    Util.fillDynamicForm(form, fields);
    setIsSubmitted(true);
    Analytics.logEvent("touch_dynamicForm_submit", {
      ...responseFormattedForAnalytics,
      formID
    });
    if (formID === BUY_MONTESSORI_APP_FORM_ID) {
      const indexOfEmailField = form.questions.findIndex(
        (q) => q.fieldID === BUY_MONTESSORI_APP_EMAIL_FIELD_ID
      );
      const indexOfNumberOfStudentsField = form.questions.findIndex(
        (q) => q.fieldID === BUY_MONTESSORI_APP_STUDENTS_FIELD_ID
      );
      const email = fields[indexOfEmailField];
      const students = fields[indexOfNumberOfStudentsField];
      Database.createStripeCheckoutSessionUnauthenticated({
        type: "school",
        students,
        email
      }).then((data) => {
        console.log("data");
        console.log(data);
        if (data?.success && data?.checkoutSessionURL) {
          window.open(data.checkoutSessionURL, "_self");
        } else if (data?.message) {
          Util.alert(data.message);
        }
      });
    } else if (formID === DELETE_ACCOUNT_FORM_ID) {
      if (userIsCreator) Database.deleteAllScheduledPushNotifications();
      Database.setNotificationPreferences({
        receivePushNotifications: false,
        receiveEmailForAll: false,
        receiveTextMessages: false,
        receivePhoneCalls: false
      });
      setTimeout(() => {
        Rex.setLoginStatus(false);
        Database.logoutUser()
          .then(() => Util.reloadApp())
          .catch((error) => Util.alert(`Error: ${error}`));
      }, 3000);
    }
  };

  // TODO: Handle this better, such as in database.js
  const addUserToDatabaseOption = (dbSource, optionIndex) => {
    const middlePath = `dynamicForms/${formID}/${dbSource}/${optionIndex}`;
    Database.getPortalContent(`${middlePath}/users`, (currentUsersString) => {
      const currentUsers = JSON.parse(currentUsersString) || [];
      const newUsers = [...currentUsers, userID];
      update(Firebase.getDbRef(`/content/${middlePath}`), {
        users: JSON.stringify(newUsers)
      });
    });
  };

  const getQuestionInfoFromDatabase = (dbSource, removeFullOptions = true) => {
    return new Promise((resolve, reject) => {
      const path = `dynamicForms/${formID}/${dbSource}`;
      Database.getPortalContent(path, (allData) => {
        const options = [];
        let currentAnswer = null;
        allData.forEach((option) => {
          const users = JSON.parse(option.users || "null");
          const numUsers = (!!users && users.length) || 0;
          const spotsLeft = option.maxUsers
            ? option.maxUsers - numUsers
            : 100000;
          const text = option.maxUsers
            ? `${option.name} (${spotsLeft} spots left)`
            : option.name;
          if (spotsLeft > 0 || !removeFullOptions)
            options.push({ value: option.name, text });
          if (!!users && users.includes(userID)) currentAnswer = option.name;
        });
        resolve({ options, currentAnswer });
      });
    });
  };

  if (isSubmitted)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={form.title} />
        <View style={{ flex: 1, alignItems: "center", margin: 30 }}>
          <Text style={[Style.get("headerText"), { fontSize: 22 }]}>
            {form.confirmationMessage}
          </Text>
        </View>
      </View>
    );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text={form.title} />
      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        showsVerticalScrollIndicator={false}
      >
        {!!form.description && (
          <Text style={styles.formDescription}>{form.description}</Text>
        )}

        {form.questions.map((q, idx) => {
          const field = fields[idx];
          if (q.hidden) return null;

          if (q.options) {
            // dropdown with options info stored in Firebase
            if (q.options.dbSource) {
              // if we haven't yet fetched the data
              if (!Object.keys(questionIndexToFetchedInfo).includes(`${idx}`)) {
                getQuestionInfoFromDatabase(q.options.dbSource).then(
                  (fetchedInfo) => {
                    setQuestionIndexToFetchedInfo({
                      ...questionIndexToFetchedInfo,
                      [idx]: fetchedInfo
                    });
                  }
                );
                return (
                  <View style={{ marginTop: 0.03 * height }} key={`${idx}`}>
                    <Text style={Style.get("headerText")}>{q.text}</Text>
                    <Text style={styles.questionDescription}>Loading...</Text>
                  </View>
                );
              }
              const fetchedInfo = questionIndexToFetchedInfo[idx];
              return (
                <View style={{ marginTop: 0.03 * height }} key={`${idx}`}>
                  <Text style={Style.get("headerText")}>{q.text}</Text>
                  {!!q.description && (
                    <Text style={styles.questionDescription}>
                      {q.description}
                    </Text>
                  )}
                  <Dropdown
                    value={fields[idx]}
                    allowOther={q.allowOther}
                    items={fetchedInfo.options}
                    onSelect={(v) => setField(idx, v)}
                  />
                  {fetchedInfo.currentAnswer &&
                    q.options.previouslySelectedText && (
                      <Text style={styles.questionDescription}>
                        {q.options.previouslySelectedText}:{" "}
                        {fetchedInfo.currentAnswer}
                      </Text>
                    )}
                </View>
              );
            }

            // simple dropdown
            return (
              <View style={{ marginTop: 0.03 * height }} key={`${idx}`}>
                <Text style={Style.get("headerText")}>{q.text}</Text>
                {!!q.description && (
                  <Text style={styles.questionDescription}>
                    {q.description}
                  </Text>
                )}
                <Dropdown
                  value={fields[idx]}
                  allowOther={q.allowOther}
                  items={q.options.map((o) => {
                    return { value: o, text: o };
                  })}
                  onSelect={(v) => setField(idx, v)}
                />
              </View>
            );
          }

          // regular text-input field
          return (
            <InputBox
              key={`${idx}`}
              header={q.text}
              description={q.description}
              onChangeText={(text) => setField(idx, text)}
              value={field}
              multiline={q.multiline}
            />
          );
        })}

        <Button
          text="Submit"
          style={{ opacity: readyForSubmission ? 1 : 0.3 }}
          disabled={!readyForSubmission}
          onPress={onSubmitResponse}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  questionDescription: {
    fontSize: 0.021 * height,
    color: "gray"
  },
  formDescription: {
    marginVertical: 20
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    minHeight: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});
