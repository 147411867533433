import React from "react";
import { StyleSheet, Text, View, Dimensions } from "react-native";
import Rex from "src/globalState";
import Icon from "src/components/Icon";

const { height } = Dimensions.get("window");

export default function TileStatic({
  tileStyle,
  icon,
  iconURL,
  tileTextSection,
  textStyle,
  text
}) {
  return (
    <View>
      <View style={tileStyle}>
        <View style={styles.icon}>
          <Icon
            icon={icon}
            iconURL={iconURL}
            color={Rex.getConfig()?.colors?.button}
            size={64}
          />
        </View>
      </View>
      <View style={tileTextSection}>
        <Text style={textStyle}>{text.toUpperCase().truncate(30)}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for the icons on the main buttons */
  icon: {
    top: 0.015 * height,
    width: 0.112 * height,
    height: 0.112 * height,
    opacity: 1,
    justifyContent: "center",
    alignItems: "center"
  },

  /* Style for the main label holder */
  textHolder: {
    marginTop: 0,
    backgroundColor: "transparent"
  }
});
