import React, { useState, useEffect } from "react";
import { View, FlatList, Platform, Text } from "react-native";
import ExpandableListItem from "src/components/dynamicContent/ExpandableListItem";
import Button from "src/components/Button";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import HelpText from "src/components/HelpText";

const TEMPLATE_ROW = {
  body: [
    {
      text: null,
      type: "text"
    }
  ],
  header: [
    {
      style: {
        textAlign: "left"
      },
      text: null,
      type: "text_header"
    }
  ]
};
const { width } = Glob.get("dimensions");

export default function ExpandableList({
  isEditingAnItem,
  item,
  editable,
  setIsEditingAnItem,
  onChangeItem,
  generateContentComponentsList,
  setKeyboardToolbarComponents,
  dataSourceRow
}) {
  const [indexExpanded, setIndexExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [flatListKey, setFlatListKey] = useState(1);

  useEffect(() => {
    if (item?.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
    }
  }, []);

  const toggleEditing = () => {
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  // For now, ensure we only edit items with a header and text
  const contentIsValidForEditing = item?.content?.every((itm) => {
    const headerIsValid =
      itm?.header?.length === 1 && itm.header[0]?.type === "text_header";
    const bodyIsValid = itm?.body?.length === 1 && itm.body[0]?.type === "text";
    return headerIsValid && bodyIsValid;
  });

  const onChangeBodyItem = (index, newBody) => {
    const newContent = [...item?.content];
    newContent[index] = { ...newContent[index], body: [newBody] };
    onChangeItem({ ...item, content: newContent });
  };

  const onChangeHeaderItem = (index, value) => {
    const newContent = [...item?.content];
    newContent[index] = { ...newContent[index], header: [value] };
    onChangeItem({ ...item, content: newContent });
  };

  const onAddRow = () => {
    onChangeItem({ ...item, content: [...item?.content, TEMPLATE_ROW] });
  };

  const onDeleteRow = (index) => {
    const newContent = [...item?.content];
    newContent.splice(index, 1);
    onChangeItem({ ...item, content: newContent });
    setFlatListKey((prev) => prev + 1);
  };

  const renderableItemsList = item.content.map((listItem) => ({
    header: generateContentComponentsList(listItem.header, dataSourceRow),
    body: generateContentComponentsList(listItem.body, dataSourceRow)
  }));

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5,
    marginBottom: 80
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  return (
    <View style={isEditing ? editingStyle : {}}>
      {isEditing && (
        <View>
          <Button
            key="doneButton"
            text="✓ Done"
            onPress={toggleEditing}
            style={{ backgroundColor: "#555" }}
            small
          />
          {contentIsValidForEditing && (
            <View style={{ padding: 20, marginBottom: 20 }}>
              <HelpText text="This item is an Accordion List. When a user touches a header, the text below it will expand or collapse." />
            </View>
          )}
        </View>
      )}
      {isEditing && !contentIsValidForEditing ? (
        <View style={{ margin: 20 }}>
          <Text style={Style.get("headerText")}>Uh oh...</Text>
          <Text style={Style.get("subheaderText")}>
            This content has custom formatting, so you're not able to edit it,
            sorry! Please reach out to us and we'll make changes for you:
            team@seabirdapps.com
          </Text>
        </View>
      ) : (
        <FlatList
          key={`flat-list-${flatListKey}`}
          data={renderableItemsList || []}
          keyExtractor={(_, index) => `${index}`}
          onEndReached={() => {}} // this override somehow fixes a glitch
          renderItem={({ item: renderableItem, index }) => {
            return (
              <ExpandableListItem
                key={`${index}`}
                renderableItem={renderableItem}
                headerItem={
                  contentIsValidForEditing && item?.content[index]?.header[0]
                }
                bodyItem={
                  contentIsValidForEditing && item?.content[index]?.body[0]
                }
                onChangeBodyItem={(value) => onChangeBodyItem(index, value)}
                onChangeHeaderItem={(value) => onChangeHeaderItem(index, value)}
                setKeyboardToolbarComponents={setKeyboardToolbarComponents}
                dataSourceRow={dataSourceRow}
                disabled={isEditingAnItem}
                shouldBeExpanded={indexExpanded === index}
                isEditing={isEditing}
                onAddRow={onAddRow}
                onDeleteRow={() => onDeleteRow(index)}
                canDelete={renderableItemsList?.length > 1}
                onPress={() => {
                  if (!editable) {
                    const newIndexExpanded =
                      indexExpanded === index ? null : index;
                    setIndexExpanded(newIndexExpanded);
                  } else if (editable && !isEditing) toggleEditing();
                }}
              />
            );
          }}
        />
      )}
      {isEditing && contentIsValidForEditing && (
        <View style={{ marginVertical: 10 }}>
          <Button
            text="Add an Expandable Item"
            icon="plus"
            onPress={onAddRow}
            style={{
              backgroundColor: "#555",
              width: 0.8 * width
            }}
            small
          />
        </View>
      )}
    </View>
  );
}
