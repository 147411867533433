// Initialize Firebase & Firebase Auth on web
// This is how we're doing it: https://firebase.google.com/docs/web/modular-upgrade

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Constants from "expo-constants";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;

firebase.initializeApp(FIREBASE_CONFIG);
const auth = firebase.auth();

export default class FirebaseAuth {
  static getAuth() {
    return auth;
  }

  static getUser() {
    return auth.currentUser;
  }

  static getUserID() {
    return auth?.currentUser?.uid;
  }

  static onAuthStateChanged() {
    return new Promise((resolve) =>
      auth.onAuthStateChanged((user) => resolve(!!user))
    );
  }

  static getUserCredential(password) {
    return firebase.auth.EmailAuthProvider.credential(
      this.getUser().email,
      password
    );
  }

  static logoutUser() {
    return auth.signOut();
  }

  static sendPasswordResetEmail(email = null) {
    const userEmail = email || auth.currentUser.email;
    return auth.sendPasswordResetEmail(userEmail);
  }
}
