// Web version
import React from "react";
import { View, TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Glob from "src/globalConstants";

const { height } = Glob.get("dimensions");

export default function BottomSheetModal({
  onClose,
  content,
  url,
  fullStyle,
  backgroundColor
}) {
  const isFullStyle = fullStyle || !!url;
  const snapPoints = isFullStyle
    ? ({ maxHeight }) => [0.9 * maxHeight]
    : ({ minHeight, maxHeight }) => [
        minHeight,
        0.35 * maxHeight,
        0.7 * maxHeight
      ];

  return (
    <BottomSheet
      open
      blocking={!!isFullStyle}
      scrollLocking={false}
      snapPoints={snapPoints}
      onDismiss={onClose}
    >
      <View
        style={{
          flex: 1,
          paddingBottom: 20,
          alignItems: "center",
          paddingTop: isFullStyle ? 0 : 20,
          backgroundColor: backgroundColor || "#ffffff"
        }}
      >
        <TouchableOpacity
          style={{
            position: "absolute",
            padding: 10,
            top: -15,
            right: 0
          }}
          onPress={onClose}
        >
          <AntDesign name="close" size={24} color="gray" />
        </TouchableOpacity>
        {url ? (
          <View style={{ width: "100%", height: height * 0.9 }}>
            <iframe
              src={url}
              title={url}
              style={{ height: "100%", width: "100%", border: 0 }}
            />
          </View>
        ) : (
          content
        )}
      </View>
    </BottomSheet>
  );
}
