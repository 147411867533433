/* This is REX */

/*
 * This class stores basically replaces index.ios.js functionality
 * To access the variable from elsewhere in the app:
 *  1. import Rex from 'src/globalState';   <-- put that towards the top
 *  2. Rex.[functionName([function_parameters])]
 *
 * Note that this is NOT where any constants are stored. There should be NO hardcoded variables in
 *  this file; everything herein is for an individual user's data stored for that particular app session.
 *  ALL constants (variables that are not user-dependent or user-set) should be stored in globalConstants.
 *
 * Why is it called "Rex", you ask?
 *  1. It's a fun, one syllable, 3-letter name
 *  2. This class involves dynamically changing things; Dynamic --> Dyno --> Dino --> T-Rex --> Rex
 *  3. Rex is also an shortened form of "redux" (REduX), which is kind of what this class is
 *
 */

import Database from "src/backend/database";
import moment from "moment";

// GLOBAL STATE:
// let animationOver = false;
// let checkedFirebase = false;
let loggedIn = false;
let userType = "";
let HOME_PORTALS = [];
let ALL_HOME_PORTALS = [];
const appVersion = [];
const portalInfo = [];

// attributes of whatever portal the user is currently in
const currentPortal = {
  name: null,
  title: null
};

const DEFAULT_GLOBAL_CONFIG = {
  colors: {
    primary: "#5b59d6", // Onespot color
    navbar: "#5b59d6" // Onespot color
  },
  names: {
    full: "",
    nickname: ""
  }
};

let globalConfig = DEFAULT_GLOBAL_CONFIG;
let metaApp = null;

// This can be used to store any key-value pair for any duration
const sessionMemory = {};

export default class Rex {
  // // inputs: 1) variable name to change
  // static setState() {
  //   globState["test"] = 2;
  //   return globState["test"];
  // }

  static updateHome(newOrder) {
    const newHomeOrder = newOrder;
    HOME_PORTALS = newHomeOrder;
  }

  static getHomePortals() {
    return HOME_PORTALS;
  }

  static orderChanged(newOrder) {
    /* Changes the home page order, used in Customize */
    HOME_PORTALS = newOrder;
    Database.setUserPortals(newOrder);
  }

  static getAllPortals() {
    return ALL_HOME_PORTALS;
  }

  static setAllPortals(allPortals) {
    ALL_HOME_PORTALS = allPortals;
  }

  static getUserType() {
    return userType;
  }

  static setUserType(type) {
    userType = type;
  }

  static setLoginStatus(bool) {
    loggedIn = bool;
  }

  static getLoginStatus() {
    return loggedIn;
  }

  static setPortalInfo(portalName, portalDetails) {
    portalInfo[portalName] = portalDetails;
  }

  static setMetaApp(metaAppData) {
    metaApp = metaAppData;
  }

  static getMetaApp() {
    return metaApp;
  }

  static setConfig(globalConfigData) {
    globalConfig = globalConfigData;
  }

  static resetConfig() {
    globalConfig = DEFAULT_GLOBAL_CONFIG;
  }

  static getConfig() {
    return globalConfig;
  }

  // duration is in seconds
  static setSessionMemory(key, value, duration = null) {
    let timeout = null;
    if (duration) timeout = moment().add(duration, "seconds");
    sessionMemory[key] = { value, timeout };
  }

  static getSessionMemory(key) {
    const storedItem = sessionMemory[key];
    if (!storedItem) return null;
    if (storedItem.timeout && storedItem.timeout < moment()) {
      delete sessionMemory[key];
      return null;
    }
    return storedItem.value;
  }
}
