/**
 * This is used to call all of our analytics functions.
 * Includes event logging, setting user properties, etc.
 */

import {
  init,
  setUserId,
  track,
  identify,
  Identify
} from "@amplitude/analytics-react-native";
import SmartlookMobileOnly from "src/backend/Smartlook";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import School from "school/school";

const API_KEY = School.get("amplitudeAPIKey");

export default class Analytics {
  // done in App.js
  static initialize() {
    init(API_KEY);
  }

  static initializeSmartlook(email, name) {
    // TODO: ENABLING SMARTLOOK BREAKS THE BUILD
    if (SmartlookMobileOnly) {
      const userID = Firebase.getUserID();
      SmartlookMobileOnly.instance.preferences.setProjectKey(
        Glob.get("smartlookProjectKey")
      );
      SmartlookMobileOnly.instance.start();
      SmartlookMobileOnly.instance.user.setIdentifier(userID);
      if (email) SmartlookMobileOnly.instance.user.setEmail(email);
      if (name) SmartlookMobileOnly.instance.user.setName(name);
    }
  }

  // this should only be used in App.js, login.js, signup.js, and root.js
  static setUserID() {
    const userID = Firebase.getUserID();
    setUserId(userID);
  }

  // obj is a JSON dictionary object, same as in logEvent
  static setUserProperties(obj) {
    const identifyObj = new Identify();
    Object.entries(obj).forEach(([key, value]) => {
      identifyObj.set(key, value);
    });
    identify(identifyObj);
  }

  // Example: Analytics.logEvent('view_food', {'place': 'foco'});
  static logEvent(name, props = null) {
    if (props) track(name, props);
    else track(name);
  }
}
