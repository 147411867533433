/*
 * This class holds all school-specific stuff
 * Feeds into Glob.
 * There's no formatting stuff, just variables and images (but only images that are in the 'school' directory)
 */

import Constants from "expo-constants";
import Util from "src/utility";

let { databaseAppID } = Constants.expoConfig.extra;

const AMPLITUDE_API_KEY_ONESPOT_DEVELOPMENT =
  "73bd309ca988233fa371541420a9d84c";
const AMPLITUDE_API_KEY_ONESPOT_PRODUCTION = "538219f361f41ba1d049910118472032";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;
const IS_DEVELOPMENT_MODE = FIREBASE_CONFIG.projectId !== "seabirdmain";
const AMPLITUDE_API_KEY_ONESPOT = IS_DEVELOPMENT_MODE
  ? AMPLITUDE_API_KEY_ONESPOT_DEVELOPMENT
  : AMPLITUDE_API_KEY_ONESPOT_PRODUCTION;

const variables = {
  // todo: these app links are here for backwards compatibility
  "app link ios":
    "https://apps.apple.com/us/app/onespot-apps-for-communities/id1589752313",
  "app link android":
    "https://play.google.com/store/apps/details?id=com.seabirdapps.onespot",
  amplitudeAPIKey: AMPLITUDE_API_KEY_ONESPOT, // used for analytics
  // googleAnalyticsTrackingID: "UA-53704158-10", // (DEPRECATED) used for analytics
  // "background image": require("school/images/background.png"),
  "school icon image": require("school/images/lone_pine.png"),
  "schedule image": require("school/images/general_schedule.png"),
  // "buildings url":
  //   "https://spreadsheets.google.com/feeds/list/1u0lsXSZs3q8VbfYA56z7M7fyaLFtzsGVQlj9xBZ1lZo/1/public/values",
  // "events sheet id": "1Qyw_az2D69vYdjFCL65qYzmSRqTLCco8zfMJaxqNC2o", // make sure to first publish the school's Events sheet to the web
  // anonymousAuthentication: false, // (DEPRECATED) if this is true then users will not need to add an email/password when getting started, and name and class year info will also be optional
  "home screen is scrollview": false, // if this is true then the iOS home screen is a vertical ScrollView rather than a paged view
  // "root title image enabled": false, // (DEPRECATED) if this is true, an image will be displayed on the home screen rather than the school's name
  // "root title image": require("school/images/demo_header_image.png"), // (DEPRECATED) the image that would be displayed on the home screen rather than the school's name
  "covid19 approved ids sheet id":
    "1qFWHsEJDVoNVQeoM4i8GyDAuGpqB-eHdHgOtjKZLHOA", // the Google Sheet ID of MD5 encrypted approved student/employee IDs who have submitted their COVID-19 forms
  "covid19 vaccinations sheet id":
    "1PSIK_j7SXOeLK6DqNkRRJNvVH_uMupthP0IYnmEAGxY", // the Google Sheet ID of MD5 encrypted student/employee IDs of people who are vaccinated, along with their expiration dates
  "homescreen banner sheet id": "14jN8C0Ac1O-_dlAbAU8qNQf27ao_-HecExJp0R3eGBM" // the Google Sheet ID of the homescreen banner message
};

// Get field IDs for each form here: https://support.google.com/a/users/answer/9308781?hl=en
// FOR FIVE TOWNS COLLEGE:
const staticGoogleForms = {
  "covid-19": {
    id: "1FAIpQLScugB0hLxrZ69YOhJuC2E7VJJFKrQrmB-Vv1nt37_23ZFPbEQ",
    fieldIDs: [
      1012579512,
      2096439040,
      630919600,
      373070823,
      1738990565,
      1898699202,
      409202324
    ]
  },
  "room check-in": {
    id: "1FAIpQLScPFNZa6G5_wDm1hNxxIaWtPo77msfzsUd3KMynmSs_sq3kTg",
    fieldIDs: [630919600, 373070823, 566299269, 1898699202]
  },
  uphillNewEnglandCheckIn: {
    id: "1FAIpQLSeYOZ4z_OXqcaVr4_3vdFtKmwNQb1egRDZxAL3gAyXGNxh6tg",
    fieldIDs: [
      290030945,
      815380149,
      200361061,
      413521225,
      862124318,
      256265987,
      1814541422,
      1303487512
    ]
  },
  uphillNewEnglandVertLog: {
    id: "1FAIpQLSfieUyHDvjOHotfcJ8QdXfUoMkfWNLW2gm2GN-QRmrdGxx-Ng",
    fieldIDs: [
      862124318,
      290030945,
      815380149,
      413521225,
      256265987,
      2018468445,
      1119778930,
      1950325598,
      1769487574,
      200361061,
      692658614,
      111774433
    ]
  }
};
// THESE IDs ARE FOR DEVELOPMENT:
// const googleForms = {
//   "covid-19": {
//     id: "1FAIpQLSeHScxqIII9Dof7qgGbdl90nTTe2x_a_rzVSl3E1DxLdbQnJg",
//     fieldIDs: [
//       1872441273,
//       498805164,
//       630919600,
//       373070823,
//       1738990565,
//       1898699202,
//       409202324
//     ]
//   },
//   "room check-in": {
//     id: "1FAIpQLSdEesUpTjDkru6VwqiaYNWcqbdLJJVrLyWrQyeiqEjFkOPh3w",
//     fieldIDs: [630919600, 373070823, 566299269, 1898699202]
//   }
// };

// HomeOrder format: {\"txtName\":\"Weekend Off-Campus Survey\",\"navName\":\"WeekendOffCampus\",\"imgName\":\"lightbulb\",\"portalType\":\"dynamicForm\"}
const dynamicForm = {
  AdminPushNotification: {
    title: "Push Notification",
    description: "Submit this form to instantly send a message.",
    id: "1FAIpQLSepnVf6C5i6BIp0WlEZdZr3uwpjY-J5AgD4vcQNJPMHJPsugA", // For aa-dev-app. But probably this should be a general form for all
    confirmationMessage: "Your message is now being sent!",
    questions: [
      {
        text: "Secure Key",
        required: true,
        fieldID: 1724353726,
        autoPopulate: "sba",
        hidden: true
      },
      {
        text: "Message Title",
        required: true,
        fieldID: 1494750662
      },
      {
        text: "Message Body",
        required: true,
        fieldID: 371253942
      },
      {
        // TODO: Allow selecting multiple
        text: "Account Types",
        options: ["student", "alum", "parent", "staff"],
        fieldID: 1498216592
      },
      {
        // TODO: Allow selecting multiple
        text: "Year",
        options: ["2024", "2023", "2022", "2021"],
        allowOther: true,
        fieldID: 1335919101
      }
    ]
  },
  // This is live for Five Towns College
  COVIDTestAppointment: {
    title: "COVID-19 Test",
    description:
      "Submit this to schedule a COVID-19 test appointment at the Upbeat on March 13.",
    id: "1FAIpQLSdGtY1dWXuvQcy1KnibeAEuq2nsClW3rOSUSkhT26vCYRSaAw",
    confirmationMessage:
      "Thanks for scheduling your COVID-19 test! Don't forget to bring your medical insurance card with you.",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        required: true,
        fieldID: 507858806
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        required: true,
        fieldID: 421934447
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 1834475091
      },
      {
        text: "Account Type",
        autoPopulate: "userType",
        hidden: true,
        fieldID: 221381953
      },
      {
        text: "Time Slot",
        description: "Choose a time slot for your March 13 test",
        options: {
          dbSource: "timeSlotOptions",
          previouslySelectedText: "You're already scheduled for"
        },
        required: true,
        fieldID: 1874518410
      }
    ]
  },
  // This is live for Five Towns College
  StudentAccessOfficeSignIn: {
    title: "Student Access Office",
    description:
      "Please fill this out whenever signing into the student access office.",
    id: "1FAIpQLSfcgWctk2vsTQBIsGwL3SiRkyj8PvKyT_9GzCgOJ6vWLT2WkQ",
    confirmationMessage:
      "Thank you for checking in, if you have any specific questions you can email us at student.access@ftc.edu. We are here to SUPPORT YOU!!!",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        required: true,
        fieldID: 630919600
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        required: true,
        fieldID: 1733894937
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 373070823
      },
      {
        text: "Account Type",
        autoPopulate: "userType",
        hidden: true,
        fieldID: 1102297853
      },
      {
        text: "Service Required",
        options: [
          "Just checking in / Everything is okay",
          "Set up an appointment for help",
          "Zoom Meeting",
          "Concerns: Testing, Professor issue, Assignment issue"
        ],
        allowOther: true,
        required: true,
        fieldID: 566299269
      }
    ]
  },
  // This is live for Five Towns College
  WeekendOffCampus: {
    title: "Off-Campus Survey",
    description:
      "For the safety of our community, please fill out this form every time you plan to leave campus for a period of time.",
    id: "1FAIpQLSe8pd9gZeJxUvIDnIhT8UZlvLFjC9ErSKKNxQLJVc-5rA7iZA",
    confirmationMessage:
      "Thank you for your response! Have an enjoyable and safe trip off-campus 🙂",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        required: true,
        fieldID: 630919600
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        required: true,
        fieldID: 1733894937
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 373070823
      },
      {
        text: "Account Type",
        autoPopulate: "userType",
        hidden: true,
        fieldID: 1102297853
      },
      {
        text: "Your Residence Hall",
        description: "Symphony, Harmony, Melody, or Rhapsody",
        options: ["Symphony", "Harmony", "Melody", "Rhapsody"],
        required: true,
        fieldID: 566299269
      },
      {
        text: "Your Room Number",
        description: "Where you live",
        required: true,
        fieldID: 1898699202
      },
      {
        text: "Please provide the address where you’ll be going.",
        description: 'E.g. "305 N Service Rd. Dix Hills, NY 11746"',
        required: true,
        fieldID: 1475453169
      },
      {
        text: "Please enter the date and time of exit.",
        description: 'E.g. "9/23/2020 at 7pm"',
        required: true,
        fieldID: 1303159713
      },
      {
        text: "Please enter the date and time of expected return.",
        description: 'E.g. "9/25/2020 at 9am"',
        required: true,
        fieldID: 114755632
      }
    ]
  },
  // This is live
  PostToMonteithParkForum: {
    title: "Post to Community Forum",
    description:
      "Post a message to the Monteith Park community forum. Then, exit and reopen the forum to see your posted message.",
    id: "1FAIpQLSesdUNpa7_EEcYKGA-WF74-gGxfakpz75If2iitc6HJcGM2BA",
    confirmationMessage: "Your message has been posted!",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        hidden: true,
        fieldID: 1803599727
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        hidden: true,
        fieldID: 1312147843
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 351035121
      },
      {
        text: "User ID",
        autoPopulate: "userID",
        hidden: true,
        fieldID: 329705149
      },
      {
        text: "Your Message",
        required: true,
        fieldID: 2030977753
      }
    ]
  },
  // This is live
  PostToArringtonForum: {
    title: "Post to Community Forum",
    description:
      "Post a message to the Arrington community forum. Then, exit and reopen the forum to see your posted message.",
    id: "1FAIpQLSfgaaXLXXpb4peYgkhKF5p5QhNLkJzMqovkPmZNYyovUs9b7w",
    confirmationMessage: "Your message has been posted!",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        hidden: true,
        fieldID: 1803599727
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        hidden: true,
        fieldID: 1312147843
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 351035121
      },
      {
        text: "User ID",
        autoPopulate: "userID",
        hidden: true,
        fieldID: 329705149
      },
      {
        text: "Your Message",
        required: true,
        fieldID: 2030977753
      }
    ]
  },
  // This is live
  PostToLakeErieLadies2022Forum: {
    title: "Post to Forum",
    description:
      "Post a message to the community forum. Then, exit and reopen the forum to see your posted message.",
    id: "1FAIpQLSdiEY2ZbN_92mAEkvgXcYyzXCzMmvBRg5T0pPc9j7EaBb5Beg",
    confirmationMessage: "Your message has been posted!",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        hidden: true,
        fieldID: 1803599727
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        hidden: true,
        fieldID: 1312147843
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 351035121
      },
      {
        text: "User ID",
        autoPopulate: "userID",
        hidden: true,
        fieldID: 329705149
      },
      {
        text: "Your Message",
        required: true,
        fieldID: 2030977753
      }
    ]
  },
  // This is live
  PostToMontessoriFoundationForum: {
    title: "Post to Community Forum",
    description:
      "Post a message to the Montessori community forum. Then, exit and reopen the forum to see your posted message.",
    id: "1FAIpQLSd17GA0zizk5wJ9pZdQeiHNyxt-xDp4Uiu5ot4-Pkld4LxDQg",
    confirmationMessage: "Your message has been posted!",
    questions: [
      {
        text: "First Name",
        autoPopulate: "firstName",
        hidden: true,
        fieldID: 1803599727
      },
      {
        text: "Last Name",
        autoPopulate: "lastName",
        hidden: true,
        fieldID: 1312147843
      },
      {
        text: "Email",
        autoPopulate: "email",
        hidden: true,
        fieldID: 351035121
      },
      {
        text: "User ID",
        autoPopulate: "userID",
        hidden: true,
        fieldID: 329705149
      },
      {
        text: "Your Message",
        required: true,
        fieldID: 2030977753
      }
    ]
  },
  // This is OLD, but live in the Onespot app (App ID: -N9c3gfF3YFVaM2FIstS)
  PurchaseMontessoriSchoolMobileApp: {
    title: "Get a Montessori App",
    description:
      "Fill out this info to get a custom app for your Montessori school.",
    id: "1FAIpQLSfYvyhv_n0XIYCIfoO9MVCJdzQJoo0mjXTCTQUQLs0Leu8q9Q",
    confirmationMessage:
      "Calculating your all-inclusive price. Please wait a minute...",
    questions: [
      {
        text: "Your name",
        required: true,
        fieldID: 1803599727
      },
      {
        text: "Your email",
        autoPopulate: "email",
        required: true,
        fieldID: 351035121
      },
      {
        text: "Your phone number",
        description: "(optional)",
        fieldID: 894222234
      },
      {
        text: "Your school's name",
        required: true,
        fieldID: 1312147843
      },
      {
        text: "Your school's website",
        description: "(optional)",
        fieldID: 605456234
      },
      {
        text: "How many students are enrolled in your school?",
        required: true,
        fieldID: 329705149
      }
      // {
      //   text: "How do you want to pay?",
      //   required: true,
      //   options: [
      //     "Monthly ($1/month per student)",
      //     "Yearly ($10/year per student)"
      //   ],
      //   fieldID: XXXXXXX
      // }
    ]
  }
};

// FOOD PORTAL INFO...
// Determine the current swipe info
// I know this is janky but since this whole portal is temporary it isn't worth additional time now
// var d = new Date();
const hour = new Date().getHours();
let swipeText = "late night";
if (hour >= 21) {
  swipeText = "late night: $5.25";
} else if (hour >= 16) {
  swipeText = "dinner: $10.00";
} else if (hour >= 11) {
  swipeText = "lunch: $7.75";
} else if (hour >= 7) {
  swipeText = "breakfast: $5.25";
} else if (hour >= 2) {
  swipeText = "none";
}

// TODO: It's possible these google sheets are now broken
const food = {
  "food sheet id": "12cYH8vNOcIla4MNboj-MPinTEERDjJM9dEWWoBnsNtk",
  "food hours sheet id": "195NlJIvBB3nGcRWYDDGuBUVaBqzoa9UafXBSRNJC8z4",
  // old
  "food url":
    "https://spreadsheets.google.com/feeds/list/12cYH8vNOcIla4MNboj-MPinTEERDjJM9dEWWoBnsNtk/1/public/values",
  "food hours url":
    "https://spreadsheets.google.com/feeds/list/195NlJIvBB3nGcRWYDDGuBUVaBqzoa9UafXBSRNJC8z4/1/public/values",
  "long button active": true,
  "long button text": "Order Online",
  "long button navigate": "root",
  "meal active": true,
  "meal text 1": "Current meal swipe:",
  "meal text 2": swipeText
};

export default class School {
  static getDatabaseAppID() {
    return databaseAppID;
  }

  static setDatabaseAppID(newID) {
    databaseAppID = newID;
  }

  static appWasBuiltOnThisDeviceAsync() {
    return new Promise((resolve, reject) => {
      Util.localStorageGetItemAsync("createdAppIDs").then((ids) => {
        if (!ids) return resolve(false);
        const createdAppIDs = JSON.parse(ids);
        return resolve(
          createdAppIDs?.length > 0 &&
            createdAppIDs.includes(this.getDatabaseAppID())
        );
      });
    });
  }

  static get(x) {
    return variables[x];
  }

  static food(x) {
    return food[x];
  }

  static dynamicForm(x) {
    return dynamicForm[x];
  }

  static staticGoogleForm(x) {
    return staticGoogleForms[x] || null;
  }
}
