import React, { useEffect, useState } from "react";
import {
  Platform,
  View,
  ScrollView,
  Text,
  ActivityIndicator,
  Dimensions
} from "react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import School from "school/school";
import Analytics from "src/backend/analytics";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Button from "src/components/Button";
import BottomSheetModal from "src/components/BottomSheetModal";
import AlertModal from "src/components/AlertModal";
import WebView from "react-native-webview";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import Icon from "src/components/Icon";
import OnespotProBadge from "src/components/OnespotProBadge";
import PrivacyAndTerms from "./PrivacyAndTerms";

const APP_VERSION_SUPPORTS_IAP = Database.compareVersions(
  Glob.get("appVersion"),
  Glob.get("revenueCatMinimumAppVersion")
);
const PURCHASES_SUPPORTED =
  !Glob.get("isExpoGo") && Glob.get("appIsOnespot") && APP_VERSION_SUPPORTS_IAP;

// Note: Implementation is based on https://docs.expo.dev/bare/using-expo-client
// And also skip if version number is not <= 17.3.0
const Purchases = {};
// const Purchases = PURCHASES_SUPPORTED
//   ? require("react-native-purchases").default
//   : {};

const ENTITLEMENT_ID_BASIC = "basic";
const ENTITLEMENT_ID_PRO = "pro";
const { height, width } = Dimensions.get("window");

const SUBSCRIPTION_INFO = {
  basic: {
    title: "Onespot Basic",
    description: "Make an app and publish it within Onespot",
    recommended: false,
    benefits: [
      {
        name: "All Basic Features",
        description:
          "Everything you need to engage your community, with unlimited content and notifications."
      },
      {
        name: "Publish in Onespot",
        description:
          "Publish your app within the Onespot mobile app and Onespot Web."
      }
    ]
  },
  pro: {
    title: "Onespot Pro",
    description: "Advanced features & priority support",
    recommended: true,
    benefits: [
      {
        description: "Everything in Onespot Basic"
      },
      {
        name: "All Advanced Features",
        description:
          "Analytics, data feeds, granular permissions & security, and more."
      },
      {
        name: "Priority Support & Onboarding",
        description:
          "Once you subscribe, we'll reach out to help you get your app set up and to make sure you love it. And we'll be around to answer any questions you have."
      },
      {
        name: "Publish on App Stores (+$99)",
        description:
          "Optionally, for a one-time service fee, we will list your custom-branded mobile app on the iOS & Android app stores with its own name, icon, screenshots, and description. When your app is ready, we'll handle the whole process for you—app store submissions, compliance, software updates, communication with Apple & Google, etc. Seriously."
      }
    ]
  }
};

// identifier = "$rc_monthly", "Basic_Annual", "Pro_Annual", or "Pro_Semiannual"
const getSubscriptionLevelFromIdentifier = (identifier) => {
  if (identifier.includes("Pro")) return "pro";
  return "basic";
};
const getBillingCadenceStringFromIdentifier = (identifier) => {
  let billingCadence = "per month";
  if (identifier.includes("Semiannual")) billingCadence = "every 6 months";
  else if (identifier.includes("Annual")) billingCadence = "per year";
  else if (identifier.includes("Weekly")) billingCadence = "per week";
  return billingCadence;
};

const Benefit = ({ name, description }) => (
  <View style={{ flexDirection: "row", paddingVertical: 4 }}>
    <Icon
      icon="1ea59c43-1fab-437c-b06b-076116244b04"
      color="#A9A9A9"
      size={16}
    />
    <Text
      style={{
        color: "#1A1A1A",
        marginLeft: 6,
        paddingRight: 6 + 16
      }}
    >
      <>{!!name && <Text style={{ fontWeight: "bold" }}>{name}: </Text>}</>
      {description}
    </Text>
  </View>
);

const PackageCard = ({ productPackage, onSubscribe = () => {} }) => {
  const { identifier = "", product = {} } = productPackage || {};
  const billingCadence = getBillingCadenceStringFromIdentifier(identifier);
  const subscriptionLevel = getSubscriptionLevelFromIdentifier(identifier);
  const { title = "", description = "", recommended = false, benefits = [] } =
    SUBSCRIPTION_INFO[subscriptionLevel] || {};
  return (
    <View style={Style.get("card")}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={{ fontWeight: "bold", fontSize: 24 }}>{title}</Text>
        {recommended && (
          <View>
            <OnespotProBadge text="BEST VALUE" />
          </View>
        )}
      </View>
      <Text style={{ color: "#868686", marginTop: 4 }}>{description}</Text>
      <View
        style={{
          marginTop: 8,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Text style={{ fontWeight: "bold", fontSize: 36 }}>
          {product?.priceString || "Unknown Price"}
          <Text
            style={{
              fontWeight: "normal",
              fontSize: 16,
              color: "#1A1A1A"
            }}
          >
            {" "}
            {billingCadence}
          </Text>
        </Text>
      </View>
      <Button
        text="Start Free Trial"
        onPress={() => onSubscribe(productPackage)}
        textStyle={{ fontWeight: "bold" }}
        style={{
          backgroundColor: Glob.get("onespotColor"),
          marginTop: 20,
          width: "100%"
        }}
      />
      <Text style={{ color: "#868686", marginTop: 14 }}>This includes:</Text>
      {benefits.map((benefit) => (
        <Benefit name={benefit.name} description={benefit.description} />
      ))}
    </View>
  );
};

export default function InAppSubscriptionModal({
  navigation,
  onClose,
  shouldPublish
}) {
  const [packages, setPackages] = useState(null);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [justPurchased, setJustPurchased] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [alert, setAlert] = useState(null);
  const [
    customerIsAlreadySubscribed,
    setCustomerIsAlreadySubscribed
  ] = useState(false);

  const config = Rex.getConfig();

  useEffect(() => {
    Analytics.logEvent("view_subscriptionModal");
    if (!PURCHASES_SUPPORTED)
      setAlert({
        title: "Not Supported",
        message:
          "In-app subscriptions (react-native-purchases) is not supported in the Expo Go app, in a non-Onespot app, or in an app version lower than 17.3.0."
      });
    else setUpRevenueCat();
    // This is just used for testing
    // setPackages([
    //   { identifier: "Basic_Weekly", product: { priceString: "$6.99" } },
    //   { identifier: "Pro_Weekly", product: { priceString: "$14.99" } }
    // ]);
    Database.fetchAllUserData().then((data) => {
      setUserFirstName(data?.firstName || "");
      setUserFullName(`${data?.firstName} ${data?.lastName}` || "");
      setUserEmail(data?.email || "");
    });
  }, []);

  const setUpRevenueCat = async () => {
    Purchases.setLogLevel(Purchases.LOG_LEVEL.VERBOSE);
    const appUserID = Firebase.getUserID();
    if (Platform.OS !== "web" && appUserID) {
      await Purchases.configure({
        apiKey: Glob.get("revenueCatAPIKey"),
        appUserID
      });
      if (Platform.OS === "ios")
        Purchases.enableAdServicesAttributionTokenCollection();
    }

    const user = await Database.fetchAllUserData();
    const fullName = `${user?.firstName} ${user?.lastName}`;
    const email = user?.email || "";
    const phoneNumber = user?.phoneNumber || "";
    const appID = School.getDatabaseAppID() || "";
    await Purchases.setAttributes({
      appID,
      $displayName: fullName,
      $email: email,
      $phoneNumber: phoneNumber,
      $amplitudeUserId: appUserID
    });

    // TODO: We should allow for an individual customer to subscribe to multiple apps. They need to be separate products though.
    const customerInfo = await Purchases.getCustomerInfo();

    if (
      typeof customerInfo?.entitlements?.active[ENTITLEMENT_ID_BASIC] !==
        "undefined" ||
      typeof customerInfo?.entitlements?.active[ENTITLEMENT_ID_PRO] !==
        "undefined"
    ) {
      setCustomerIsAlreadySubscribed(true);
      Analytics.logEvent("action_subscriptionModal_alreadySubscribed");
    }

    if (customerInfo)
      Analytics.logEvent("action_subscriptionModal_customerInfoFound", {
        customerUserID: customerInfo.originalAppUserId
      });

    try {
      const offerings = await Purchases.getOfferings();
      const { current } = offerings || {};
      // const { offering0: current } = offerings?.all || {}; // todo: this is just for testing
      if (current?.availablePackages?.length > 0 && current?.identifier) {
        Analytics.logEvent("action_subscriptionModal_offeringShown", {
          offeringIdentifier: current.identifier
        });
        setPackages(current.availablePackages || []);
      }
    } catch (error) {
      Analytics.logEvent("error_subscriptionModal_errorGettingPackage", {
        error
      });
    }
  };

  const onSubscribe = async (productPackage) => {
    setIsPurchasing(true);
    const { identifier, product } = productPackage || {};
    if (!identifier || !product) {
      Analytics.logEvent("touch_subscriptionModal_pressSubscribeButton", {
        error: "No identifier and/or product"
      });
      setIsPurchasing(false);
      return;
    }
    const subscriptionLevel = getSubscriptionLevelFromIdentifier(identifier);
    const { title = "", description = "" } =
      SUBSCRIPTION_INFO[subscriptionLevel] || {};
    Analytics.logEvent("touch_subscriptionModal_pressSubscribeButton", {
      productIdentifier: product?.identifier,
      subscriptionLevel,
      subscriptionTitle: title,
      subscriptionDescription: description
    });
    try {
      const { customerInfo } = await Purchases.purchasePackage(productPackage);
      if (
        typeof customerInfo.entitlements.active[ENTITLEMENT_ID_BASIC] !==
          "undefined" ||
        typeof customerInfo.entitlements.active[ENTITLEMENT_ID_PRO] !==
          "undefined"
      ) {
        const response = await Database.purchaseSubscriptionRevenueCat(
          product,
          customerInfo,
          shouldPublish
        );
        Analytics.logEvent("action_subscriptionModal_purchasedSubscription", {
          productIdentifier: product?.identifier,
          success: response?.success
        });
        // Note: This is technically unnecessary because the app is reloaded anyways
        Rex.setConfig({ ...Rex.getConfig(), showSubscriptionPaywall: false });
        if (subscriptionLevel === "pro") {
          Database.updateGlobalConfig({
            advancedComponentsEnabled: true,
            analyticsEnabled: true,
            granularSecurityEnabled: true
          });
        }
        // Remove any previously scheduled notifications
        await Database.deleteAllScheduledPushNotifications();
        // Schedule 4 notifications to send to the customer over the course of the week
        // Day 0: Welcome!
        const proBasicWord = subscriptionLevel === "pro" ? "Pro" : "Basic";
        await Database.schedulePushNotification(
          {
            title: "Congrats on subscribing 🎉",
            body: `Hey ${userFirstName ||
              "there"}, congrats on starting your free trial of Onespot ${proBasicWord}!

All our Onespot ${proBasicWord} features are now available for you to try out. Your free trial lasts for 7 days, and we'll remind you before it ends.

I'm Sean, one of the creators of Onespot. If you have any questions or want help with anything, reply to this message and I'd be happy to help 🙂`,
            chatEnabled: true,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date().toISOString(), // asap
          Glob.get("seanCannUserID")
        );
        // Day 1: Check in + onboarding session
        await Database.schedulePushNotification(
          {
            title: `Want help finishing your app?`,
            body: `Hey ${userFirstName ||
              "there"}! If you want some extra help finishing your app for ${
              config?.names?.full
            }, feel free to schedule a complimentary onboarding session with one of our founders at this link.`,
            url: `https://calendly.com/seancann/onespot-app-onboarding-session?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=5b59d6&name=${userFullName ||
              ""}&email=${userEmail || ""}&a1=${config?.names?.full || ""}`,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // 24 hours from now
          Glob.get("seanCannUserID")
        );
        // Day 3: Check in. If Pro, maybe offer the app store submission form?
        if (subscriptionLevel === "pro") {
          await Database.schedulePushNotification(
            {
              title: `Want to publish ${config?.names?.full} on the app stores?`,
              body: `With Onespot Pro, you have the option to list your mobile app on the iOS and Android app stores for a one-time fee of $99. We handle everything for you. Fill out this form if you'd like us to submit your app for publication with its own logo, name, screenshots, description, etc.`,
              url: "https://forms.gle/UGLpYnQubnY6CXFj9",
              audience: { users: [Firebase.getUserID()] }
            },
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(), // 3 days from now
            Glob.get("seanCannUserID")
          );
        } else {
          await Database.schedulePushNotification(
            {
              title: `Checking in on your app`,
              body: `Just a friendly reminder—you're nearly halfway through your free trial of Onespot Basic. Let me know if you have questions or would like help with anything!`,
              chatEnabled: true,
              audience: { users: [Firebase.getUserID()] }
            },
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(), // 3 days from now
            Glob.get("seanCannUserID")
          );
        }
        // Day 6: Note that trial will be converting soon. Link to cancel subscription
        await Database.schedulePushNotification(
          {
            title: `Your free trial is ending soon`,
            body: `Hey ${userFirstName ||
              "there"}, your free trial of Onespot ${proBasicWord} is ending tomorrow. You can manage your billing or cancel your subscription from ${
              Platform.OS === "ios" ? "the App Store" : "Google Play"
            }.`,
            chatEnabled: true,
            url:
              Platform.OS === "ios"
                ? Glob.get("iosSubscriptionManagementURL")
                : Glob.get("androidSubscriptionManagementURL"),
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 6 * 24 * 60 * 60 * 1000).toISOString(), // 6 days from now
          Glob.get("seanCannUserID")
        );
        setAlert({
          title: "Congrats! 🎉",
          message: `You're subscribed to ${"Onespot"}${
            shouldPublish ? " and your app has been published" : ""
          }!\n\nSchedule a complimentary onboarding session with us to ${
            subscriptionLevel === "pro"
              ? "get your app listed on the iOS and Android app stores"
              : "help you get started"
          }:`,
          customView: (
            <View style={{ height: 0.6 * height, width: 0.8 * width }}>
              <WebView
                style={{ flex: 1 }}
                onLoadStart={() => {}}
                onLoadEnd={() => {}}
                source={{
                  uri: `https://calendly.com/seancann/onespot-app-onboarding-session?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=5b59d6&name=${userFullName ||
                    ""}&email=${userEmail || ""}&a1=${config?.names?.full ||
                    ""}`
                }}
                userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
              />
            </View>
          ),
          confirm: {
            text: "Finish & Reload App",
            onPress: () => Util.reloadApp()
          },
          disableOutsideTouch: true
        });
        setJustPurchased(true);
      }
    } catch (e) {
      if (!e.userCancelled) {
        Analytics.logEvent("error_subscriptionModal_errorPurchasing", {
          message: e.message
        });
      } else {
        Analytics.logEvent("action_subscriptionModal_userCancelled");
      }
    } finally {
      Analytics.logEvent("action_subscriptionModal_purchaseFinished"); // may or may not have purchased
      setIsPurchasing(false);
    }
  };

  const isLoading = !packages || packages.length < 1 || isPurchasing;

  return (
    <>
      <BottomSheetModal
        fullStyle
        onClose={onClose}
        backgroundColor={Glob.get("onespotColor")}
        content={
          <>
            {customerIsAlreadySubscribed ? (
              <View style={{ marginVertical: 40, paddingHorizontal: 20 }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 36,
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  Subscribe Again
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    color: "white",
                    textAlign: "center",
                    marginVertical: 20
                  }}
                >
                  It looks like you signed up for a Onespot subscription
                  previously, but we're having trouble subscribing you again.
                  {"\n\n"}Please log into the web app and try from there:
                </Text>
                <ButtonItem
                  navigate={navigation.push}
                  item={{
                    type: "button_web",
                    url: `https://www.1spot.app?app=${School.getDatabaseAppID()}`,
                    title: "www.1spot.app",
                    openExternally: true,
                    color: "white"
                  }}
                />
              </View>
            ) : (
              <View style={{ width: "100%" }}>
                <View style={{ paddingHorizontal: 20 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      color: "white",
                      marginTop: 10,
                      textAlign: "center"
                    }}
                  >
                    {packages?.length !== 1
                      ? "Choose Your Subscription"
                      : "Start Your Trial"}
                  </Text>
                  <Text
                    style={{
                      color: "white",
                      marginVertical: 10,
                      textAlign: "center"
                    }}
                  >
                    You can cancel within{" "}
                    <Text style={{ fontWeight: "bold" }}>7 days</Text> without
                    being billed.
                  </Text>
                </View>
                {isLoading ? (
                  <>
                    <ActivityIndicator
                      size="large"
                      color="white"
                      style={{ marginTop: 30 }}
                    />
                    {isPurchasing && (
                      <Text
                        style={{
                          color: "white",
                          textAlign: "center",
                          marginTop: 10
                        }}
                      >
                        Loading your new superpowers...
                      </Text>
                    )}
                  </>
                ) : (
                  <ScrollView
                    style={{
                      paddingHorizontal: 20,
                      width: "100%",
                      height: "100%"
                    }}
                    scrollIndicatorInsets={{ right: 1 }}
                  >
                    {(packages || []).map((productPackage) => (
                      <PackageCard
                        productPackage={productPackage}
                        onSubscribe={onSubscribe}
                      />
                    ))}
                    <Button
                      text="Compare Plans"
                      onPress={() => {
                        navigation.push("webNav", {
                          title: "Compare Plans",
                          url: "https://onespotapps.com/pricing#compare"
                        });
                      }}
                      outline
                      color="white"
                      textStyle={{ fontWeight: "bold" }}
                      style={{ width: "80%" }}
                    />
                    <View style={{ marginTop: 30, marginBottom: height * 0.2 }}>
                      <PrivacyAndTerms
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          color: "white"
                        }}
                        navigation={navigation}
                        message="By subscribing, you agree to our"
                      />
                    </View>
                  </ScrollView>
                )}
              </View>
            )}
          </>
        }
      />
      {justPurchased && (
        <ConfettiCannon
          count={200}
          origin={{ x: width / 2, y: height }}
          autoStart
          fadeOut
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </>
  );
}
