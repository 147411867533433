import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Analytics from "src/backend/analytics";
import UserList from "src/components/UserList";

export default function AppAnalyticsViewUsers({ navigation, route }) {
  const { filteredUserIDs, filteredMessageSuffix } = route?.params || {};

  useEffect(() => {
    Analytics.logEvent("view_analyticsViewUsers");
  }, []);

  return (
    <View style={styles.pageContent}>
      <UserList
        navBarTitle="App Users"
        navigation={navigation}
        filteredUserIDs={filteredUserIDs}
        filteredMessageSuffix={filteredMessageSuffix}
        shouldShowShareOptions={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
