import React from "react";
import {
  TouchableHighlight,
  StyleSheet,
  Dimensions,
  Text,
  View
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Glob from "src/globalConstants";

const { width } = Dimensions.get("window");
const ICON_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

export default function BackButton({
  navigation,
  type,
  onPress,
  color,
  showAsLoginButton
}) {
  const navigatePop = () => {
    if (onPress) onPress();
    navigation.goBack();
  };

  if (type === "down") {
    return (
      <TouchableHighlight
        style={styles.touchableButton}
        underlayColor="transparent"
        onPress={() => navigatePop()}
      >
        <Ionicons
          name="chevron-down"
          size={ICON_SIZE}
          color={color || "white"}
        />
      </TouchableHighlight>
    );
  }
  return (
    <TouchableHighlight
      style={styles.touchableButton}
      underlayColor="transparent"
      onPress={() => navigatePop()}
    >
      {showAsLoginButton ? (
        <View
          style={{ flexDirection: "row", height: "100%", alignItems: "center" }}
        >
          <Ionicons name="person" size={ICON_SIZE} color={color || "white"} />
          {Glob.deviceHasTabletDimensions() && (
            <Text
              style={{
                color: color || "white",
                fontSize: ICON_SIZE * 0.6,
                marginLeft: 10
              }}
            >
              Login
            </Text>
          )}
        </View>
      ) : (
        <Ionicons
          name="chevron-back"
          size={ICON_SIZE}
          color={color || "white"}
        />
      )}
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  touchableButton: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 10
  }
});
