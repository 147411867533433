/*
 * This portal displays the content of a website, title, back button, and back/forward buttons.
 * Use the WebStatic screen if you want a portal that can't navigate back and forth within a site.
 * Content for this portal is pulled from Firebase, from portal "web_PORTALNAME" (specified in index.ios.js)
 */

import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  Platform,
  Linking
} from "react-native";
import NavWebView from "src/components/navWebView";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";

const { height, width } = Dimensions.get("window");

export default class WebNavWithButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portalContent: null
    };
  }

  componentDidMount() {
    const portalName = this.props?.route?.params?.navName;
    /* Get the content (url, title, etc) for this portal from the database */
    Database.getPortalContent(portalName, (value) => {
      this.setState({ portalContent: value });
      Analytics.logEvent("view_webNavWithButton", {
        key: portalName,
        url: value.url
      });
    });
  }

  render() {
    const { navigation } = this.props;
    const { portalContent } = this.state;
    if (portalContent == null) {
      /* Still waiting for response from the database */
      return null;
    }
    /* Once the database has given us the contents of this portal, display the content */
    const { url } = portalContent;
    const navbarText = portalContent.title;
    console.log(portalContent);
    const buttonUrl =
      Platform.OS === "android"
        ? portalContent.button.androidUrl
        : portalContent.button.iosUrl;
    const buttonText = portalContent.button.title;
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <NavWebView URL={url} title={navbarText} navigation={navigation} />
        <View style={styles.buttonView}>
          <TouchableHighlight
            onPress={() => Linking.openURL(buttonUrl)}
            style={Style.get("button")}
          >
            <Text style={Style.get("normalButtonText")}>{buttonText}</Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Holds the button TouchableHighlight */
  buttonView: {
    position: "absolute",
    alignItems: "center",
    width: width * 0.5,
    marginTop: height * 0.9,
    marginLeft: width * 0.25
  }
});
