import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import tinycolor from "tinycolor2";
import * as Haptics from "expo-haptics";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Dropdown from "src/components/Dropdown";
import ButtonSwitches from "src/components/buttonSwitches";
import Checkbox from "src/components/Checkbox";
import InputBox from "src/components/InputBox";
import HelpButton from "src/components/HelpButton";
import Button from "src/components/Button";
import HelpText from "src/components/HelpText";
import StatusMessage from "src/components/StatusMessage";
import KeyboardToolbar from "src/components/KeyboardToolbar";
import BannerMessage from "src/components/BannerMessage";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const { height, width } = Glob.get("dimensions");

const DEFAULT_TEXT_ITEM = {
  type: "text",
  text: "",
  style: { textAlign: "center" }
};
const DEFAULT_BUTTON_ITEM = { type: "button_web", title: "Open Link", url: "" };
const BUTTON_COLORS = {
  fun: "green",
  informational: "#dea300",
  urgent: "darkred",
  translucent: "white"
};
const TRANSLUCENT_COLOR = "rgba(0,0,0,0.3)";

export default function SetHomeScreenBanner({ route, navigation }) {
  const [isSaved, setIsSaved] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bannerMessage, setBannerMessage] = useState(null);
  const [bannerMessageKey, setBannerMessageKey] = useState("textItem");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [allowedToSendNotifications, setAllowedToSendNotifications] = useState(
    false
  );
  const [keyboardToolbarComponents, setKeyboardToolbarComponents] = useState(
    null
  );
  const { checkRefresh } = route?.params || {};

  // This should always just be { fun: "lightgreen", informational: "lightyellow", urgent: "red", translucent: TRANSLUCENT_COLOR }
  const bannerColors = Rex.getConfig()?.colors?.bannerMessage || {};

  useEffect(() => {
    Analytics.logEvent("view_setBannerMessage");
    // Only if user is allowed to send notifications to EVERYONE
    Database.fetchUserPrivileges(false).then((privileges) =>
      setAllowedToSendNotifications(privileges?.PushNotifications === true)
    );
    Database.getHomescreenBanner((newBannerMessage) => {
      setLoading(false);
      const backgroundColor =
        newBannerMessage?.backgroundColor ||
        bannerColors[newBannerMessage.type];
      const textItem = newBannerMessage?.textItem || {
        ...DEFAULT_TEXT_ITEM,
        text: newBannerMessage.text
      };
      const buttonItem = newBannerMessage?.buttonItem || DEFAULT_BUTTON_ITEM;
      setBannerMessage({
        ...newBannerMessage,
        backgroundColor,
        textItem,
        buttonItem
      });
    });
  }, []);

  if (loading)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Banner Message" />
        <View style={styles.errorContainer}>
          <Text style={styles.errorTextLarge}>Loading...</Text>
        </View>
      </View>
    );

  // This should only happen if the /content/bannerMessage node is missing in the database
  if (!bannerMessage)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Banner Message" />
        <View style={styles.errorContainer}>
          <Text style={{ fontSize: 100, marginTop: 50 }}>🤔</Text>
          <Text style={styles.errorTextLarge}>Hmmm...</Text>
          <Text style={{ color: "gray" }}>
            It looks like you don't yet have Banner Messages set up. Reach out
            to someone at Seabird Apps for help! You can find contact info in
            the "Help Me" superpower.
          </Text>
        </View>
      </View>
    );

  const onSave = () => {
    if (Platform.OS !== "web")
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    Analytics.logEvent("touch_setBannerMessage_set", bannerMessage);
    Database.setHomescreenBanner(bannerMessage);
    Database.addTask("setBannerMessage", "done");
    setIsSaved(true);
    checkRefresh();
  };

  const onSendNotification = () => {
    if (Platform.OS !== "web")
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    Analytics.logEvent("touch_setBannerMessage_sendNotification", {
      title: bannerMessage.text
    });
    Database.addTask("sendPushNotification", "done");
    Database.sendPushNotification({ title: bannerMessage.text });
    setIsSent(true);
  };

  const setBannerColor = (
    backgroundColor,
    bannerType = bannerMessage.type,
    buttonColor
  ) => {
    const textColor = tinycolor(backgroundColor).isLight() ? "black" : "white";
    const newTextColor =
      textColor !== bannerMessage.textItem?.style?.color ? textColor : false;
    const newTextItem = newTextColor
      ? {
          ...bannerMessage.textItem,
          style: {
            ...(bannerMessage.textItem?.style || {}),
            color: textColor
          }
        }
      : bannerMessage.textItem;
    const newButtonItem = buttonColor
      ? {
          ...bannerMessage.buttonItem,
          color: buttonColor
        }
      : bannerMessage.buttonItem;
    setBannerMessage({
      ...bannerMessage,
      backgroundColor,
      textItem: newTextItem,
      buttonItem: newButtonItem,
      type: bannerType // || bannerMessage.type
    });
    // force rerender, but only if the text color should change
    if (newTextColor) setBannerMessageKey(Math.random());
  };

  const bannerIsActive = bannerMessage.active === "yes";

  if (isSent)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Banner Message" />
        <StatusMessage
          type="success"
          message="Over the next few minutes, your notification will be sent to all users."
        />
        <View style={{ padding: 30, width }}>
          <Text
            style={{ fontWeight: "bold", fontSize: 16, textAlign: "center" }}
          >
            "{bannerMessage.text}"
          </Text>
        </View>
      </View>
    );

  if (isSaved)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Banner Message" />
        <StatusMessage
          type="success"
          message={`The banner message has been ${
            bannerIsActive ? "enabled." : "turned off."
          }`}
        />
        {bannerIsActive && !!allowedToSendNotifications && (
          <View style={{ width }}>
            <View style={{ padding: 30, width: "100%" }}>
              <Text style={{ fontSize: 16 }}>
                Want to also send a notification to all your users?
              </Text>
              <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                "{bannerMessage.text}"
              </Text>
            </View>
            <Button
              icon="send"
              outline
              text="Send Notification"
              onPress={onSendNotification}
            />
            <View style={{ padding: 20, width: "100%" }}>
              <HelpText text="This is recommended. Otherwise people will only see your message if they open the app." />
            </View>
          </View>
        )}
      </View>
    );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text="Banner Message"
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Banner Message"
              message="Here, you can set a banner message that shows up at the top of everyone's home screen."
              navigation={navigation}
              videoUrl="https://youtu.be/3OBRvbAYZ88"
            />
          </View>
        }
      />

      <ScrollView
        style={{ width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <View style={{ marginTop: 20, marginHorizontal: 15 }}>
          <HelpText text="When turned on, this banner is displayed on the home screen of every user." />
        </View>

        <View
          style={{
            marginBottom: bannerIsActive ? 0 : 50,
            marginHorizontal: 15
          }}
          key="active"
        >
          <ButtonSwitches
            initialButtonState={
              { no: "left", yes: "right" }[bannerMessage.active]
            }
            leftOption="Off"
            rightOption="On"
            leftAction={() =>
              setBannerMessage({ ...bannerMessage, active: "no" })
            }
            rightAction={() =>
              setBannerMessage({ ...bannerMessage, active: "yes" })
            }
          />
        </View>

        {bannerIsActive && (
          <>
            <View style={{ marginHorizontal: 15 }}>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Banner Message
              </Text>
              <Text style={Style.get("subheaderText")}>
                Touch the text{bannerMessage.showButton ? " or button" : ""}{" "}
                below to edit
              </Text>
            </View>
            <BannerMessage
              {...bannerMessage}
              key={bannerMessageKey}
              editable
              setBannerMessage={setBannerMessage}
              setKeyboardToolbarComponents={setKeyboardToolbarComponents}
              navigation={navigation}
            />

            <View style={{ marginHorizontal: 15 }}>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Button
              </Text>
              <Checkbox
                checked={!!bannerMessage.showButton}
                text="Include an action button"
                onChange={(checked) =>
                  setBannerMessage({
                    ...bannerMessage,
                    showButton: checked
                  })
                }
              />

              {!showAdvancedSettings && (
                <View key="type">
                  <Dropdown
                    header="Banner Color"
                    value={bannerMessage.type}
                    items={[
                      "informational",
                      "urgent",
                      "fun",
                      "translucent"
                    ].map((o) => {
                      return { value: o, text: o.capitalize() };
                    })}
                    onSelect={(type) => {
                      setBannerColor(
                        type === "translucent"
                          ? TRANSLUCENT_COLOR
                          : bannerColors[type],
                        type,
                        BUTTON_COLORS[type]
                      );
                    }}
                  />
                </View>
              )}

              {!showAdvancedSettings && (
                <TouchableLink
                  text="Show more colors"
                  type="button"
                  onPress={() => setShowAdvancedSettings(true)}
                  textStyle={{ marginTop: 10 }}
                />
              )}

              {showAdvancedSettings && (
                <>
                  <InputBox
                    header="Banner Color"
                    onChangeText={setBannerColor}
                    value={bannerMessage.backgroundColor}
                    colorPicker
                  />
                </>
              )}
            </View>
          </>
        )}

        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Button text="Save" onPress={onSave} />
        </View>
      </ScrollView>
      <KeyboardToolbar components={keyboardToolbarComponents} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  errorContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20
  },
  errorTextLarge: {
    fontSize: 30,
    marginVertical: 20,
    color: "gray"
  },
  questionDescription: {
    fontSize: 0.021 * height,
    color: "gray"
  },
  formDescription: {
    marginVertical: 20
  }
});
