import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import Moment from "moment";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import PostSkeleton from "src/components/activityFeeds/PostSkeleton";

const { width } = Glob.get("dimensions");

export default function ScheduledNotifications({ navigation }) {
  const [loaded, setLoaded] = useState(false);
  const [scheduledNotifications, setScheduledNotifications] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [allAccountTypes, setAllAccountTypes] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [isDeletingEvent, setIsDeletingEvent] = useState(null);
  const [alert, setAlert] = useState(null);

  const loadedRef = useRef();
  useEffect(() => {
    loadedRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    Analytics.logEvent("view_scheduledNotifications");
    Database.subscribeToScheduledEvents((data) => {
      // Convert to array and sort by timestamp
      const sortedNotifications = Object.entries(data || {})
        .map(([id, event]) => ({ id, ...event }))
        .filter((event) => event.type === "notification")
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      setScheduledNotifications(sortedNotifications);
      setLoaded(true);
    });
    // groups
    Database.getAllUserGroups().then((groups) => setAllGroups(groups || {}));
    // account types
    Database.fetchAllAccountTypeDetails().then((types) =>
      setAllAccountTypes(types || {})
    );
    // users
    Database.fetchAllUsers().then((users) => {
      // convert array to object
      const usersObj = users.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
      }, {});
      setAllUsers(usersObj);
    });

    return () => {
      Database.unsubscribeFromScheduledEvents();
    };
  }, []);

  const renderNotificationCard = (eventID, event) => {
    const { notification, timestamp, type } = event;
    if (type !== "notification") return null;

    if (isDeletingEvent === eventID) return <PostSkeleton />;

    const { title, body, audience, url } = notification;
    const scheduledDate = Moment(timestamp).format("MMM D, YYYY [at] h:mm A");
    const isScheduledForFuture = Moment(timestamp).isAfter(Moment());

    const displayAudience = () => {
      Analytics.logEvent("touch_scheduledNotifications_viewRecipients", {
        eventID,
        notification,
        timestamp
      });
      const groupNames = Array.isArray(audience?.groups)
        ? audience.groups.map((group) => {
            return allGroups && allGroups[group]?.name
              ? allGroups[group].name
              : "Unknown Group";
          })
        : [];
      const accountTypeNames = Array.isArray(audience?.accountTypes)
        ? audience.accountTypes.map((accountType) => {
            return allAccountTypes && allAccountTypes[accountType]?.title
              ? allAccountTypes[accountType].title
              : "Unknown Account Type";
          })
        : [];
      const userNames = (audience?.users || []).map((user) => {
        return allUsers &&
          (allUsers[user]?.firstName || allUsers[user]?.lastName)
          ? `${allUsers[user]?.firstName} ${allUsers[user]?.lastName}`.trim()
          : "Unknown User";
      });
      let invitationStatusPhrase = null;
      if (audience?.invitationStatus && audience?.invitationStatus !== "all") {
        if (audience?.invitationStatus === "invited")
          invitationStatusPhrase =
            "Only invited members who haven't yet joined";
        else if (audience?.invitationStatus === "current")
          invitationStatusPhrase =
            "Only current members who have joined this app";
      }

      setAlert({
        title: "Notification Recipients",
        message: (
          <View style={{ padding: 10 }}>
            <Text style={{ marginBottom: 10 }}>
              This notification will be sent to members who meet these criteria:
            </Text>
            {userNames.length > 0 ? (
              <Text style={{ marginTop: 5 }}>
                <Text style={{ fontWeight: "bold" }}>
                  Specific User{userNames.length !== 1 ? "s" : ""}:{" "}
                </Text>
                {userNames.join(", ")}
              </Text>
            ) : (
              <>
                <Text style={{ marginTop: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Account Type{accountTypeNames.length !== 1 ? "s" : ""}:{" "}
                  </Text>
                  {accountTypeNames.length > 0
                    ? accountTypeNames.join(", ")
                    : "Any account type"}
                </Text>
                <Text style={{ marginTop: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Group{groupNames.length !== 1 ? "s" : ""}:{" "}
                  </Text>
                  {groupNames.length > 0 ? groupNames.join(", ") : "Any group"}
                </Text>
                {!!invitationStatusPhrase && (
                  <Text style={{ marginTop: 5 }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Invitation Status:{" "}
                    </Text>
                    {invitationStatusPhrase}
                  </Text>
                )}
              </>
            )}
          </View>
        )
      });
    };

    return (
      <View key={eventID} style={styles.notificationCard}>
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 3,
            top: 3,
            borderRadius: 100,
            backgroundColor: "white",
            padding: 8,
            zIndex: 1
          }}
          onPress={() => {
            Analytics.logEvent(
              "touch_scheduledNotifications_deleteNotification",
              { eventID, notification, timestamp }
            );
            setAlert({
              title: "Delete Notification?",
              message:
                "Are you sure you want to delete this scheduled notification?",
              confirm: { text: "Cancel" },
              cancel: {
                text: "💥 Delete",
                onPress: () => {
                  setIsDeletingEvent(eventID);
                  Database.deleteScheduledEvent(eventID).then(() =>
                    Analytics.logEvent(
                      "action_scheduledNotifications_deletedNotification"
                    )
                  );
                }
              }
            });
          }}
        >
          <MaterialIcons
            name="delete"
            size={20}
            color={Glob.get("dangerRed")}
          />
        </TouchableOpacity>
        <View style={styles.notificationHeader}>
          <Text style={styles.scheduledDate}>
            {isScheduledForFuture ? "Scheduled for " : "Scheduled at "}
            {scheduledDate}
          </Text>
        </View>
        <Text style={styles.notificationTitle}>{title}</Text>
        {body && <Text style={styles.notificationBody}>{body}</Text>}
        {!!url && (
          <View style={styles.urlContainer}>
            <MaterialIcons name="link" size={14} color="#666" />
            <TouchableLink
              type="web"
              link={url}
              text={url}
              textStyle={styles.urlText}
              navigation={navigation}
            />
          </View>
        )}
        <View style={styles.cardFooter}>
          <TouchableOpacity
            style={styles.recipientInfo}
            onPress={displayAudience}
          >
            <MaterialIcons name="people" size={16} color="#666" />
            <Text style={styles.recipientText}>View Recipients</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.editButton,
              { borderColor: Rex.getConfig()?.colors?.button }
            ]}
            onPress={() => {
              Analytics.logEvent(
                "touch_scheduledNotifications_editNotification",
                { eventID, notification, timestamp }
              );
              navigation.navigate("sendPushNotification", {
                originalNotification: notification,
                scheduledTime: timestamp ? new Date(timestamp) : null,
                scheduledEventID: eventID
              });
            }}
          >
            <MaterialIcons
              name="edit"
              size={16}
              color={Rex.getConfig()?.colors?.button}
            />
            <Text
              style={[
                styles.editButtonText,
                { color: Rex.getConfig()?.colors?.button }
              ]}
            >
              Edit
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Scheduled Notifications" />
      <ScrollView
        contentContainerStyle={{ width, alignItems: "center" }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <View style={{ width: width * 0.9 }}>
          {!loaded ? (
            <ActivityIndicator size="large" style={{ marginTop: 80 }} />
          ) : (
            <>
              {(scheduledNotifications || []).length > 0 ? (
                <>
                  <HelpText
                    text={`There are ${scheduledNotifications.length} ${
                      scheduledNotifications.length === 1
                        ? "notification"
                        : "notifications"
                    } that are scheduled to be sent. You can view, edit, or delete them.`}
                    noteStyle
                    style={{ marginBottom: 10 }}
                  />
                  {scheduledNotifications.map((event) =>
                    renderNotificationCard(event.id, event)
                  )}
                </>
              ) : (
                <View style={styles.emptyState}>
                  <Ionicons name="calendar-outline" size={48} color="#666" />
                  <Text style={styles.emptyStateText}>
                    No scheduled notifications
                  </Text>
                  <Text style={styles.emptyStateSubtext}>
                    When you schedule messages to be sent later, they'll appear
                    here.
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  },
  notificationCard: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    borderWidth: 1,
    borderColor: "#eee"
  },
  notificationHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12
  },
  scheduledDate: {
    fontSize: 14,
    color: "#666",
    fontStyle: "italic"
  },
  notificationTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 8,
    color: "#333"
  },
  notificationBody: {
    fontSize: 16,
    color: "#666",
    marginBottom: 12,
    lineHeight: 22
  },
  recipientInfo: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: "#f6f6f6"
  },
  recipientText: {
    fontSize: 14,
    color: "#666",
    marginLeft: 6,
    fontWeight: "bold"
  },
  emptyState: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 60
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#666",
    marginTop: 16
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: "#999",
    textAlign: "center",
    marginTop: 8,
    paddingHorizontal: 20
  },
  cardFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 12
  },
  editButton: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 12,
    padding: 8,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: "#f6f6f6"
  },
  editButtonText: {
    fontSize: 14,
    color: "#666",
    marginLeft: 4,
    fontWeight: "bold"
  },
  urlContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12
  },
  urlText: {
    fontSize: 13,
    color: "#666",
    marginLeft: 4,
    textDecorationLine: "underline"
  }
});
