import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, ScrollView, Image } from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";

const { height, width } = Glob.get("dimensions");

export default function SubmitToAppStores({ navigation }) {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    Analytics.logEvent("view_submitToAppStores");
    Database.fetchAllUserData().then((user) => {
      setEmail(user?.email || "");
      setPhoneNumber(user?.phoneNumber || "");
    });
  }, []);

  const appName = Rex.getConfig()?.names?.full || "your app";
  const appDescription = Rex.getConfig()?.descriptionLong || "";

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Submit to App Stores" />
      <ScrollView style={{ paddingHorizontal: 20, width }}>
        <Text
          style={{
            fontWeight: "bold",
            marginVertical: 15,
            fontSize: 24,
            textAlign: "center"
          }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          Want your app listed on the iOS and Android app stores?
        </Text>
        <View style={{ height: height * 0.3, alignItems: "center" }}>
          <Image
            source={Glob.get("demoOnAppStores")}
            style={{
              flex: 1,
              resizeMode: "contain"
            }}
          />
        </View>
        <Text style={styles.bodyText}>
          Currently, you can invite anyone to join {appName} from within Onespot
          and Onespot Web.
        </Text>
        <Text style={styles.bodyText}>
          <Text style={{ fontWeight: "bold" }}>
            If you want custom branding, we can also list {appName} on the iOS
            and Android app stores
          </Text>{" "}
          as a standalone mobile app with its own name, app icon, screenshots,
          and description.
        </Text>
        <Text style={styles.bodyText}>
          This would make {appName} discoverable by the billions app store users
          worldwide.
        </Text>
        <Text style={styles.bodyText}>
          Normally, submitting an app to the app stores on your own is really
          complicated.{" "}
          <Text style={{ fontWeight: "bold" }}>
            We handle everything for you
          </Text>
          —app store submissions, compliance, software updates, communication
          with Apple & Google, etc.
        </Text>
        <Text style={[styles.bodyText, { marginBottom: 15 }]}>
          This all-inclusive add-on service costs:
        </Text>
        <Text
          style={{
            fontSize: 60,
            fontWeight: "bold",
            textAlign: "center",
            color: Glob.get("onespotColor")
          }}
        >
          $325<Text style={{ fontSize: 20 }}>/year</Text>
        </Text>
        <Text style={styles.bodyText}>
          If you're interested, please take a few minutes to fill out our app
          store submission form:
        </Text>
        <Button
          text="Submission Form 🚀"
          style={{ marginTop: 15 }}
          color={Glob.get("onespotColor")}
          onPress={() => {
            navigation.push("webNav", {
              title: "App Store Submission Form",
              url: `https://docs.google.com/forms/d/e/1FAIpQLSeYYLFzOG7cpEZw-Gt3o_qkiYFs9iFB1AuGVPtdgGqy3YvlGg/viewform?usp=pp_url&entry.2143430784=${appName}&entry.677955133=${appDescription}&entry.2112319221=${email}&entry.1288083875=${phoneNumber}`
            });
          }}
        />
        <Text style={[styles.bodyText, { marginBottom: 50 }]}>
          Once you've submitted that form, we will reach out to you within a
          couple of days about next steps, including a link for payment.
        </Text>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  }
});
