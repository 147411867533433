import React, { useState, useEffect } from "react";
import { Text, View, Platform } from "react-native";
import * as Haptics from "expo-haptics";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Multiselect from "src/components/Multiselect";
// import Checkbox from "src/components/Checkbox";

export default function DataSourceItem({
  item,
  isEditingAnItem,
  setIsEditingAnItem,
  onChangeItem,
  data,
  navigation,
  reformatContent
}) {
  const [isEditing, setIsEditing] = useState(false);
  // const [showDynamicFilter, setShowDynamicFilter] = useState(
  //   item?.dynamicFilterColumn && item?.dynamicFilterValue
  // );

  let columnNames = [];
  if (data && data.length > 0) columnNames = Object.keys(data[0]);

  useEffect(() => {
    if (item.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
    }
  }, []);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;

  const toggleEditing = () => {
    if (Platform.OS !== "web") Haptics.selectionAsync();
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  if (!item.isStart)
    return (
      <View
        style={{
          margin: 5,
          padding: 5,
          backgroundColor: "lightgray",
          flexDirection: "row"
        }}
      >
        <View style={{ marginHorizontal: 5 }}>
          <MaterialCommunityIcons
            name="arrow-up-bold-outline"
            size={24}
            color="black"
          />
        </View>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text style={{ textAlign: "center", fontWeight: "bold" }}>
            Everything in this section above will repeat for every row in the
            Google Sheet
          </Text>
        </View>
        <View style={{ marginHorizontal: 5 }}>
          <MaterialCommunityIcons
            name="arrow-up-bold-outline"
            size={24}
            color="black"
          />
        </View>
      </View>
    );

  return (
    <View
      style={
        isEditing
          ? editingStyle
          : {
              margin: 5,
              padding: 5,
              backgroundColor: "lightgray"
            }
      }
    >
      {!isEditing ? (
        <>
          <Button
            key="editDataSourceButton"
            text="Edit Data Source"
            onPress={toggleEditing}
            color="#555"
            small
            disabled={isEditingADifferentItem}
          />
          {!!data && data.length > 0 ? (
            <>
              <Text style={{ textAlign: "center" }}>
                Google Sheet connected! 🙌 It has {data.length} rows.
              </Text>
              <Text style={{ textAlign: "center", marginTop: 10 }}>
                <Text style={{ fontWeight: "bold" }}>Available Columns:</Text>
                {"\n"}
                {columnNames.map((col) => `{{${col}}}`).join(", ")}
              </Text>
              <Text style={{ textAlign: "center" }}>
                <Text style={{ fontWeight: "bold" }}>Example Row:</Text>
                {"\n"}
                {columnNames.map((col) => `"${data[0][col]}"`).join(", ")}
              </Text>
            </>
          ) : (
            <Text style={{ textAlign: "center" }}>
              We're having trouble connecting to your Google Sheet 😕 Try
              editing the data source.
            </Text>
          )}
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ marginHorizontal: 5, justifyContent: "center" }}>
              <MaterialCommunityIcons
                name="arrow-down-bold-outline"
                size={24}
                color="black"
              />
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text style={{ textAlign: "center", fontWeight: "bold" }}>
                Everything in this section below will repeat for every row in
                the Google Sheet
              </Text>
            </View>
            <View style={{ marginHorizontal: 5, justifyContent: "center" }}>
              <MaterialCommunityIcons
                name="arrow-down-bold-outline"
                size={24}
                color="black"
              />
            </View>
          </View>
        </>
      ) : (
        <View
          style={{
            width: "90%",
            alignSelf: "center",
            padding: 5,
            paddingBottom: 10,
            marginBottom: 10
          }}
        >
          <InputBox
            key="googleSheetID"
            header="Google Spreadsheet ID"
            value={item.googleSheetID}
            onChangeText={(text) =>
              onChangeItem({ ...item, googleSheetID: text })
            }
          />
          {item.googleSheetID === Glob.get("exampleGoogleSheetID") ? (
            <Text>
              <Text style={{ fontWeight: "bold" }}>
                This is an example Spreadsheet.
              </Text>
              <TouchableLink
                type="web"
                link={`https://docs.google.com/spreadsheets/d/${Glob.get(
                  "exampleGoogleSheetID"
                )}`}
                text=" You can explore it here. "
                navigation={navigation}
              />
              To connect this to your own data, create a Google Spreadsheet,
              make it public, and then paste its ID and name here.
            </Text>
          ) : (
            <Text>
              To edit the data,{" "}
              <Text style={{ textDecorationLine: "underline" }}>
                <TouchableLink
                  type="web"
                  link={`https://docs.google.com/spreadsheets/d/${item.googleSheetID}`}
                  text="open this Spreadsheet"
                  navigation={navigation}
                />
              </Text>{" "}
              and navigate to the "{item.googleSheetName}" tab.
            </Text>
          )}
          <InputBox
            key="googleSheetName"
            header="Google Sheet Tab Name"
            value={item.googleSheetName}
            onChangeText={(text) =>
              onChangeItem({ ...item, googleSheetName: text })
            }
          />
          <Text>
            This must be{" "}
            <Text style={{ fontWeight: "bold" }}>the exact name</Text> of the
            Sheet tab you want to pull data from (a.k.a. the name of the tab
            within your Spreadsheet)
          </Text>
          {!!data && data.length > 0 && (
            <>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Filters (optional)
              </Text>
              <Text>
                Your users will be able to filter by any column you enable.
              </Text>
              <Multiselect
                items={columnNames.map((c) => ({
                  key: c,
                  value: item?.filters && item?.filters[c],
                  text: c
                }))}
                onToggle={(columnName, active) => {
                  const filters = item.filters || {};
                  if (active) filters[columnName] = true;
                  else if (columnName in filters) delete filters[columnName];
                  onChangeItem({ ...item, filters });
                }}
              />
            </>
          )}
          {/* {!!data && data.length > 0 && (
            <>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Dynamic Filter (optional)
              </Text>
              <Text>
                Advanced: You can specify a filter that will be applied to the
                data before it is displayed.
              </Text>
              {!showDynamicFilter ? (
                <Button
                  key="showDynamicFilterButton"
                  text="Show Options"
                  onPress={() => setShowDynamicFilter(true)}
                  color="#555"
                  align="left"
                  outline
                  small
                  flat
                />
              ) : (
                <>
                  <Text style={{ fontWeight: "bold", marginTop: 20 }}>
                    Column to filter by
                  </Text>
                  <Checkbox
                    radio
                    checked={!item?.dynamicFilterColumn}
                    text="(None)"
                    onChange={() =>
                      onChangeItem({ ...item, dynamicFilterColumn: null })
                    }
                  />
                  <Multiselect
                    radio
                    items={columnNames.map((c) => ({
                      key: c,
                      value:
                        item?.dynamicFilterColumn &&
                        item?.dynamicFilterColumn === c,
                      text: c
                    }))}
                    onToggle={(columnName) => {
                      onChangeItem({
                        ...item,
                        dynamicFilterColumn: columnName
                      });
                    }}
                  />
                  <Text style={{ fontWeight: "bold", marginTop: 20 }}>
                    Value to filter to
                  </Text>
                  <Text>
                    {`Specify a value, or use variables like "{{Email}}" or "{{UserID}}".`}
                  </Text>
                  <InputBox
                    key="dynamicFilterValue"
                    value={item.dynamicFilterValue}
                    onChangeText={(text) =>
                      onChangeItem({ ...item, dynamicFilterValue: text })
                    }
                  />
                </>
              )}
            </>
          )} */}
          <View style={{ marginTop: 15 }}>
            <Button
              key="doneButton"
              text="✓ Done"
              onPress={() => {
                reformatContent();
                toggleEditing();
              }}
              color="#555"
              small
            />
          </View>
        </View>
      )}
    </View>
  );
}
