import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native";
import { signInWithEmailAndPassword } from "firebase/auth/react-native";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import AuthWidget from "src/components/auth/AuthWidget";

const { width } = Glob.get("dimensions");

export default function OnespotLogin({ navigation, route }) {
  const { setLoggedInEmail } = route?.params || {};
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_onespotLogin");
  }, []);

  const logUserIn = () => {
    Rex.setLoginStatus(true);
    Analytics.setUserID();
    setLoggedInEmail(email);
    navigation.replace("onespotJoin");
  };

  const login = async () => {
    Analytics.logEvent("touch_onespotLogin_loginButton", { email });
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(Firebase.getAuth(), email, password);
      logUserIn();
    } catch (e) {
      let errorMessage = {
        title: "Login failed 😕",
        message: "Double-check your email and password and then try again."
      };
      if (e.code === "auth/invalid-email")
        errorMessage = {
          title: "Hmm...",
          message: `It looks like "${email}" is not a real email. Double check that and try again.`
        };
      else if (e.code === "auth/wrong-password")
        errorMessage = {
          title: "Wrong password",
          message: `Sorry! You've created an account with "${email}" previously, but that was the wrong password for it 😕`
        };
      else if (e.code === "auth/user-not-found")
        errorMessage = {
          title: "No account with that email",
          message: `Sorry! We don't yet have an account with "${email}" 😕  To create an account, go back and join or make a spot.`
        };
      Analytics.logEvent("error_onespotLogin_loginFailed", {
        email,
        ...errorMessage
      });
      setAlert({
        ...errorMessage,
        confirm: {
          text: "OK",
          color: Glob.get("onespotColor")
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <NavBar
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Glob.get("onespotColor")}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <AuthWidget
          navigation={navigation}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          isLoading={isLoading}
          userIsAlreadyLoggedIn={false}
          onEmailAuth={login}
          showForgotPassword
        />

        <TouchableOpacity
          activeOpacity={0.7}
          style={{ marginTop: 40, alignItems: "center" }}
          onPress={() => {
            Analytics.logEvent("touch_onespotLogin_dontHaveAccountSignUp");
            navigation.goBack();
          }}
        >
          <Text style={{ color: "#868686", textAlign: "center" }}>
            Don't have an account yet?{"\n"}
            <Text
              style={{
                color: Glob.get("onespotColor"),
                fontWeight: "bold"
              }}
            >
              Go back
            </Text>{" "}
            and join a spot or make a spot.
          </Text>
        </TouchableOpacity>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
