import React from "react";
import { TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as FileSaver from "file-saver";
import Analytics from "src/backend/analytics";
import Glob from "src/globalConstants";
import moment from "moment";

const { width } = Glob.get("dimensions");
const ICON_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

export default function ExportOrdersButton({
  title = "All Orders",
  orders = []
}) {
  const exportOrdersAsCSV = () => {
    Analytics.logEvent("touch_commerceOrderHistory_exportAsCSV");

    const columns = [
      "Order Date",
      "Customer Name",
      "Customer Email",
      "Total Price",
      "Products",
      "Custom Questions & Responses"
    ];

    const csvContent = [
      columns.join(","),
      ...orders.map((order) =>
        [
          moment(order.timestamp).format("MM/DD/YYYY h:mm A"),
          (order.customerDetails?.name || "").replace(/,/g, ""),
          (order.customerDetails?.email || "").replace(/,/g, ""),
          (order.price / 100).toFixed(2),
          order.products
            .map(
              (product) =>
                `${product.name.replace(/,/g, "")} (${(
                  product.amount / 100
                ).toFixed(2)})`
            )
            .join("; "),
          order.metadata?.questionResponses
            ? Object.entries(JSON.parse(order.metadata.questionResponses))
                .map(([question, response]) => `${question}: ${response}`)
                .join("; ")
            : ""
        ].join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;"
    });
    FileSaver.saveAs(blob, `${title}.csv`);
  };

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight: 10
      }}
      activeOpacity={0.6}
      onPress={exportOrdersAsCSV}
    >
      <MaterialCommunityIcons
        name="file-download-outline"
        size={ICON_SIZE}
        color="white"
      />
    </TouchableOpacity>
  );
}
