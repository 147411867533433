/* global window */

import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  TouchableOpacity,
  Platform
} from "react-native";
import LottieView from "src/components/Lottie";
import Constants from "expo-constants";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Rex from "src/globalState";

const PUZZLE_PEOPLE_ANIMATION = require("resources/animations/puzzlePeople.json");
const POTTED_PLANT_ANIMATION = require("resources/animations/pottedPlant.json");
const ARROW_DOWN_YELLOW_ANIMATION = require("resources/animations/arrowDownYellow.json");
const ARROW_DOWN_BLUE_ANIMATION = require("resources/animations/arrowDownBlue.json");

const { height, width } = Glob.get("dimensions");
const IS_ONESPOT = Constants.expoConfig.slug === "onespot";
const IS_ONESPOT_CAMPUS = Constants.expoConfig.slug === "onespotcampus";
const IS_ONESPOT_K12 = Constants.expoConfig.slug === "onespotk12";
const IS_MONTESSORI_APP = Constants.expoConfig.slug === "montessori";
const IS_ONESPOT_OR_CAMPUS = IS_ONESPOT || IS_ONESPOT_CAMPUS || IS_ONESPOT_K12;

const BackgroundAnimations = () => {
  const [touchedPuzzleAnimation, setTouchedPuzzleAnimation] = useState(false);
  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        disabled={touchedPuzzleAnimation}
        style={{
          position: "absolute",
          bottom: -0.044 * height, // aligns the animation perfectly to the bottom
          height: height / 2,
          width,
          alignItems: "center"
        }}
        onPress={() => setTouchedPuzzleAnimation(true)}
      >
        <LottieView
          autoPlay
          loop
          style={{
            width: "100%",
            height: "100%"
          }}
          source={PUZZLE_PEOPLE_ANIMATION}
        />
      </TouchableOpacity>
      {touchedPuzzleAnimation && (
        <>
          <View
            style={{
              position: "absolute",
              bottom: -0.038 * height, // aligns the animation perfectly to the bottom
              left: 0.48 * width, // aligns the animation perfectly to the bottom
              height: height / 6,
              width: width / 2,
              alignItems: "center"
            }}
          >
            <LottieView
              autoPlay
              loop={false}
              style={{
                width: "100%",
                height: "100%"
              }}
              source={POTTED_PLANT_ANIMATION}
            />
          </View>
          <View
            style={{
              position: "absolute",
              bottom: -0.045 * height, // aligns the animation perfectly to the bottom
              right: 0.45 * width, // aligns the animation perfectly to the bottom
              height: height / 5,
              width: width / 2,
              alignItems: "center"
            }}
          >
            <LottieView
              autoPlay
              loop={false}
              style={{
                width: "100%",
                height: "100%"
              }}
              source={POTTED_PLANT_ANIMATION}
            />
          </View>
        </>
      )}
    </>
  );
};

const Logo = () => {
  if (IS_ONESPOT_OR_CAMPUS) {
    let imageSize = 80;
    if (IS_ONESPOT_K12) imageSize = 100;
    else if (IS_ONESPOT_CAMPUS) imageSize = 120;
    let imageName = "onespotLogoImage";
    if (IS_ONESPOT_CAMPUS) imageName = "onespotCampusLogoImage";
    else if (IS_ONESPOT_K12) imageName = "onespotK12LogoImage";
    return (
      <View style={{ height: imageSize, width: "80%" }}>
        <Image source={Glob.get(imageName)} style={styles.logo} />
      </View>
    );
  }
  if (Rex.getMetaApp())
    return (
      <View
        style={{
          width,
          justifyContent: "flex-end",
          alignItems: "center",
          height: 0.5 * height
        }}
      >
        <View
          style={{
            width: "80%",
            height: "80%",
            resizeMode: "contain"
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "contain"
            }}
            source={{ uri: Rex.getMetaApp().logo }}
          />
        </View>
        {!!Rex.getMetaApp().title && (
          <Text
            adjustsFontSizeToFit
            style={{
              color: Glob.get("primaryColor"),
              fontWeight: "500",
              fontSize: 36,
              textAlign: "center",
              marginHorizontal: 20
            }}
          >
            {Rex.getMetaApp().title}
          </Text>
        )}
        {!!Rex.getMetaApp().shortDescription && (
          <Text
            adjustsFontSizeToFit
            style={{
              color: Glob.get("primaryColor"),
              fontSize: 18,
              textAlign: "center",
              marginHorizontal: 20
            }}
          >
            {Rex.getMetaApp().shortDescription}
          </Text>
        )}
      </View>
    );
  return null;
};

const Tagline = () => {
  if (IS_ONESPOT_OR_CAMPUS) {
    let taglineText = "mobile-first, member-centered";
    if (IS_ONESPOT_CAMPUS)
      taglineText =
        "An actually-useful campus app, built & maintained by your fellow students.";
    else if (IS_ONESPOT_K12)
      taglineText = "All your school's information.\nAll in one spot.";
    return (
      <>
        <Text
          style={{
            fontSize: IS_ONESPOT_K12 ? 18 : 16,
            marginTop: IS_ONESPOT_CAMPUS || IS_ONESPOT_K12 ? 20 : 0,
            marginBottom: IS_ONESPOT_K12 ? 5 : 0,
            marginHorizontal: 40,
            fontWeight: IS_ONESPOT_K12 ? "normal" : "bold",
            color: Glob.get("onespotColor"),
            textAlign: IS_ONESPOT_K12 ? "center" : "left"
          }}
        >
          {taglineText}
        </Text>
      </>
    );
  }
  return null;
};

const ArrowAnimation = () => {
  const animationRef = useRef(null);
  useEffect(() => {
    setTimeout(() => animationRef.current?.play(), 1000);
  }, []);
  return (
    <View style={{ height: height / 8, width }}>
      <LottieView
        animationRef={animationRef}
        loop={false}
        style={{ width: "100%", height: "100%" }}
        source={
          Platform.OS === "web"
            ? ARROW_DOWN_YELLOW_ANIMATION
            : ARROW_DOWN_BLUE_ANIMATION
        }
        colorFilters={[{ keypath: "surface11095", color: "#FFE845" }]}
      />
    </View>
  );
};

const JoinButton = ({ navigation }) => {
  let joinButtonText = "Join";
  if (IS_ONESPOT_CAMPUS) joinButtonText = "Find my campus";
  else if (IS_ONESPOT_K12) joinButtonText = "Find my school";
  else if (!Rex.getMetaApp()) joinButtonText = "join an app";
  return (
    <>
      <Button
        text={joinButtonText}
        onPress={() => navigation.push("onespotJoin")}
        textStyle={styles.buttonText}
        style={styles.joinButton}
      />
      {IS_ONESPOT_CAMPUS && (
        <Text
          style={{
            fontSize: 16,
            color: Glob.get("onespotColor")
          }}
        >
          Join 5,000+ students (and counting)
        </Text>
      )}
    </>
  );
};

const MakeButton = ({ navigation }) => {
  // Only show for Onespot
  if (!Rex.getMetaApp() && !IS_ONESPOT_CAMPUS)
    return (
      <>
        <Button
          text="make an app"
          onPress={() => navigation.push("onespotCreateType")}
          textStyle={[styles.buttonText, { color: Glob.get("onespotColor") }]}
          style={styles.makeButton}
        />
      </>
    );
  return null;
};

export default function OnespotWelcome({ navigation }) {
  const [loggedInEmail, setLoggedInEmail] = useState(false);
  useEffect(() => {
    Analytics.setUserProperties({
      standaloneAppSlug: Constants.expoConfig.slug,
      appVersion: Glob.get("appVersionFull")
    });
    Analytics.logEvent("view_onespotWelcome");
  }, []);

  return (
    <View style={styles.mainView}>
      {IS_ONESPOT_OR_CAMPUS && <BackgroundAnimations />}
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: IS_ONESPOT_OR_CAMPUS ? "flex-end" : "flex-start"
        }}
      >
        <Logo />
        <Tagline />
        {IS_ONESPOT_K12 && <ArrowAnimation />}
      </View>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: Rex.getMetaApp() ? "space-around" : "flex-start",
          marginBottom: IS_ONESPOT_OR_CAMPUS ? 0.4 * height : 0
        }}
      >
        <JoinButton navigation={navigation} />
        <MakeButton navigation={navigation} />
        {IS_ONESPOT && (
          <View style={{ marginTop: 15 }}>
            {loggedInEmail ? (
              <Text style={{ color: "#aaa" }}>
                You're logged in as {loggedInEmail}.
              </Text>
            ) : (
              <TouchableLink
                type="button"
                onPress={() =>
                  navigation.push("onespotLogin", { setLoggedInEmail })
                }
                text="Log into your account."
                navigation={navigation}
                textStyle={{ fontWeight: "bold" }}
              />
            )}
          </View>
        )}
        {!IS_ONESPOT_OR_CAMPUS && (
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: Glob.get("primaryColor"),
              textAlign: "center",
              marginTop: 0.1 * height,
              marginBottom: 30,
              opacity: 0.7
            }}
          >
            {`Powered by Onespot\n`}
            <Text style={{ fontWeight: "normal" }}>
              {IS_MONTESSORI_APP ? "MontessoriMobileApps.com" : "www.1spot.app"}
            </Text>
          </Text>
        )}
      </View>
      {Platform.OS === "web" && !Rex.getMetaApp() && !IS_ONESPOT_CAMPUS && (
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => window.open("https://www.onespotapps.com", "_blank")}
          style={{
            position: "absolute",
            top: 15,
            left: 15
          }}
        >
          <Text style={{ color: Glob.get("gray"), fontWeight: "bold" }}>
            Learn more:
          </Text>
          <Text style={{ color: Glob.get("primaryColor") }}>
            onespotapps.com
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

const joinButton = {
  marginTop: IS_ONESPOT_K12 ? 10 : 40,
  backgroundColor: IS_ONESPOT_OR_CAMPUS
    ? Glob.get("onespotColor")
    : Glob.get("primaryColor"),
  height: 80,
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 10,
  elevation: 10
};
const makeButton = {
  marginTop: 20,
  backgroundColor: "white",
  height: 80,
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 10,
  elevation: 10
};

if (Platform.OS === "web") {
  joinButton.boxShadow = "0px 0px 10px rgba(0,0,0,0.2)";
  makeButton.boxShadow = "0px 0px 10px rgba(0,0,0,0.2)";
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-around",
    backgroundColor: "white"
  },

  logo: {
    flex: 1,
    resizeMode: "contain",
    tintColor: Glob.get("onespotColor")
  },

  joinButton,

  makeButton,

  buttonText: {
    color: "white",
    fontSize: 30,
    fontWeight: "500"
  }
});
