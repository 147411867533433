import React, { useState, useEffect } from "react";
import { View, Text, Image, Platform } from "react-native";
import Onboarding from "react-native-onboarding-swiper";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Rex from "src/globalState";
import Util from "src/utility";
import Style from "src/globalStyles";
import Button from "src/components/Button";
import InAppSubscriptionModal from "src/components/InAppSubscriptionModal";

const IMAGE_RATIO_APP_STORE_LISTINGS = 1080 / 1637; // height / width
const IMAGE_RATIO_APP_STORE_ICONS = 985 / 1545;
const IMAGE_RATIO_MOON_PHONES_MOCKUP = 1024 / 1024;
const IMAGE_RATIO_MEDITATION_WOMAN = 610 / 610;

const { width } = Glob.get("dimensions");

export default function CreatorOnboarding({ navigation, route }) {
  const { shouldPublish, title } = route?.params || {};
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_creatorOnboarding");
  }, []);

  const page1Title =
    title ||
    `${shouldPublish ? "Publish" : "Finish your app for"} ${
      Rex.getConfig()?.names?.nickname
    }`;

  const pages = [
    {
      title: page1Title,
      subtitle: title
        ? `Start your free trial now to finish your app for ${
            Rex.getConfig()?.names?.nickname
          } with custom branding and professional design.`
        : `Professional designs and custom branding for your ${(
            Rex.getConfig()?.appType?.name || "app"
          ).toLowerCase()}.`,
      backgroundColor: "white",
      image: (
        <View
          style={{
            height: width * IMAGE_RATIO_APP_STORE_LISTINGS,
            alignItems: "center"
          }}
        >
          <Image
            source={Glob.get("appStoreListings")}
            style={{
              flex: 1,
              resizeMode: "contain"
            }}
          />
        </View>
      )
    },
    {
      title: "Best app builder in the galaxy",
      subtitle:
        "Add unlimited content, notifications, chat, and more. Edit anything in seconds from your phone or computer.",
      backgroundColor: "white",
      image: (
        <View
          style={{
            height: width * IMAGE_RATIO_MOON_PHONES_MOCKUP,
            alignItems: "center"
          }}
        >
          <Image
            source={Glob.get("moonPhonesMockup")}
            style={{
              flex: 1,
              resizeMode: "contain"
            }}
          />
        </View>
      )
    },
    {
      title: "Loved by thousands",
      subtitle:
        "Onespot is loved by 7,000+ organizations, businesses, and communities worldwide.",
      backgroundColor: "white",
      image: (
        <View
          style={{
            height: width * IMAGE_RATIO_APP_STORE_ICONS,
            alignItems: "center"
          }}
        >
          <Image
            source={Glob.get("appStoreIcons")}
            style={{
              flex: 1,
              resizeMode: "contain"
            }}
          />
        </View>
      )
    },
    {
      title: "Let's get started!",
      subtitle: (
        <View style={{ paddingHorizontal: 20, alignItems: "center" }}>
          <Text style={{ fontSize: 15, marginBottom: 30, color: "#555" }}>
            Try everything completely free for 7 days.
          </Text>
          <Button
            text="View Pricing"
            onPress={() => {
              Analytics.logEvent("touch_creatorOnboarding_startTrial");
              setShowProducts(true);
            }}
            textStyle={{ fontWeight: "bold" }}
            style={{ backgroundColor: Glob.get("onespotColor") }}
          />
          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "center"
            }}
          >
            <Button
              text="💬 Chat with Us"
              flat
              onPress={() => {
                Analytics.logEvent("touch_creatorOnboarding_chatWithUs");
                Util.startChatWithSuperAdmin(navigation, true);
              }}
              textStyle={{
                fontWeight: "bold",
                color: Glob.get("onespotColor")
              }}
              style={{ backgroundColor: "#e1e0ff", width: "45%" }}
            />
            <Button
              text="Maybe Later"
              flat
              onPress={() => {
                Analytics.logEvent("touch_creatorOnboarding_maybeLater");
                navigation.goBack();
              }}
              textStyle={{
                fontWeight: "bold",
                color: Glob.get("onespotColor")
              }}
              style={{ backgroundColor: "#e1e0ff", width: "45%" }}
            />
          </View>
        </View>
      ),
      backgroundColor: "white",
      image: (
        <View
          style={{
            height: width * IMAGE_RATIO_MEDITATION_WOMAN * 0.8,
            alignItems: "center"
          }}
        >
          <Image
            source={Glob.get("meditationWoman")}
            style={{
              flex: 1,
              resizeMode: "contain"
            }}
          />
        </View>
      )
    }
  ];

  if (Platform.OS === "web")
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: Glob.get("onespotColor")
        }}
      >
        <View style={{ width }}>
          {pages.map((page) => (
            <View style={Style.get("card")}>
              {page.image}
              <Text
                style={{
                  fontSize: 32,
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                {page.title}
              </Text>
              <Text style={{ fontSize: 18, textAlign: "center" }}>
                {page.subtitle}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );

  return (
    <>
      <Onboarding
        showDone={false}
        skipLabel="Cancel"
        onSkip={() => {
          Analytics.logEvent("touch_creatorOnboarding_cancel");
          navigation.goBack();
        }}
        bottomBarHighlight={false}
        pages={pages}
        pageIndexCallback={(screenIndex) => {
          Analytics.logEvent("touch_creatorOnboarding_swipe", {
            screenIndex,
            title: pages[screenIndex]?.title,
            subtitle: pages[screenIndex]?.subtitle
          });
        }}
        titleStyles={{
          color: Glob.get("onespotColor"),
          fontWeight: "bold",
          marginHorizontal: 10
        }}
        subTitleStyles={{ color: "#555", marginHorizontal: 10 }}
      />
      {showProducts && (
        <InAppSubscriptionModal
          navigation={navigation}
          shouldPublish={shouldPublish}
          onClose={() => {
            Analytics.logEvent("touch_subscriptionModal_closeModal");
            setShowProducts(false);
          }}
        />
      )}
    </>
  );
}
