import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  TextInput
} from "react-native";
import moment from "moment";
import * as Crypto from "expo-crypto";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import ButtonSwitches from "../components/buttonSwitches";

const { height, width } = Dimensions.get("window");
const pageName = "COVID Vaccine Pass";

export default function Covid19Form({ navigation }) {
  const [accountType, setAccountType] = useState(null); // "student" or "employee"
  const [schoolID, setSchoolID] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("pending"); // "pending", "passed" or "failed"
  const [allApprovedIDs, setAllApprovedIDs] = useState(DEFAULT_APPROVED_IDS);
  const [didFetchIDs, setDidFetchIDs] = useState(false);
  const [
    initialAccountTypeButtonState,
    setInitialAccountTypeButtonState
  ] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_covid19form");
    if (Rex.getSessionMemory("covid19VaccineStatus") === "passed")
      setSubmissionStatus("passed");
    Database.listenUserFirstName((first) => {
      setFirstName(first);
    });
    Database.listenUserLastName((last) => {
      setLastName(last);
    });
    Database.listenUserSchoolID((userSchoolID) => {
      setSchoolID(userSchoolID);
    });
    Database.listenUserType((userType) => {
      switch (userType) {
        case "student":
          setAccountType("student");
          setInitialAccountTypeButtonState("left");
          break;
        case "faculty":
          setAccountType("employee");
          setInitialAccountTypeButtonState("right");
          break;
        case "staff":
          setAccountType("employee");
          setInitialAccountTypeButtonState("right");
          break;
        default:
          setAccountType("student");
          setInitialAccountTypeButtonState("left");
          break;
      }
    });
    Database.getCOVID19VaccinatedIDsEncrypted((idToDate) => {
      // Note: Leaving this code here in case we want to implement these cached approved IDs
      // const diff = new Set([...allIDs].filter(x => !allApprovedIDs.has(x)))
      if (idToDate) {
        // const union = new Set([...allApprovedIDs, ...idToDate]);
        // setAllApprovedIDs(union);
        setDidFetchIDs(true);
        setAllApprovedIDs(idToDate);
      }
    });
  }, []);

  useEffect(() => {
    if (didFetchIDs) {
      isIDApproved().then((isApproved) => {
        if (isApproved) onSubmitResponse();
      });
    }
  }, [allApprovedIDs]);

  const readyForSubmission =
    !!accountType &&
    !!schoolID &&
    schoolID.length > 0 &&
    !!firstName &&
    firstName.length > 0 &&
    !!lastName &&
    lastName.length > 0;

  const fullSchoolID = () => {
    return `${accountType === "employee" ? 10000 : ""}${schoolID}`;
  };

  const isIDApproved = async () => {
    const today = new Date();
    if (!accountType) return Promise.resolve(false);
    const id = fullSchoolID(); // employee IDs are 10000 + <last 4 of SSN>
    const md5 = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.MD5,
      `${id}`
    );
    const expirationDate = allApprovedIDs[md5];
    const vaccinationHasExpired = expirationDate < today;
    return Promise.resolve(
      !!allApprovedIDs && !!expirationDate && !vaccinationHasExpired
    );
  };

  const onSubmitResponse = () => {
    isIDApproved().then((isApproved) => {
      const covid19VaccineStatus = isApproved ? "passed" : "failed";
      setSubmissionStatus(covid19VaccineStatus);
      Rex.setSessionMemory("covid19VaccineStatus", covid19VaccineStatus, 3600); // cache result for 1 hour
      if (schoolID) Database.setUserSchoolID(schoolID);
      Analytics.logEvent("touch_covid19vaccinepassport_submit");
    });
  };

  if (submissionStatus === "passed")
    return (
      <View style={[styles.pageContent, { backgroundColor: "#3DA5D9" }]}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            marginHorizontal: 30
          }}
        >
          <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
            👍
          </Text>
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              {moment().format("M/D")}
            </Text>
            <Text style={{ fontSize: 42, color: "white", fontWeight: "bold" }}>
              {moment().format("YYYY")}
            </Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <Text
              style={{ fontSize: 26, color: "#18618C", fontWeight: "bold" }}
            >
              {firstName} {lastName}
            </Text>
            <Text
              style={{ fontSize: 16, color: "#18618C", fontWeight: "bold" }}
            >
              {accountType && accountType.capitalize()} ID: {fullSchoolID()}
            </Text>
          </View>
          <Text style={{ fontSize: 13, color: "white", fontWeight: "600" }}>
            Based on our records, you are fully vaccinated for COVID-19. If
            asked, please show this screen to a staff member in order to enter
            campus. If your information above is not correct, please edit it:
          </Text>
          <TouchableOpacity
            activeOpacity={0.6}
            style={Style.get("button")}
            onPress={() => setSubmissionStatus("pending")}
            disabled={!readyForSubmission}
          >
            <Text
              adjustsFontSizeToFit
              numberOfLines={1}
              style={Style.get("normalButtonText")}
            >
              Edit My Info
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );

  if (submissionStatus === "failed")
    return (
      <View style={[styles.pageContent, { backgroundColor: "#FF7477" }]}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            marginHorizontal: 30
          }}
        >
          <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
            📋
          </Text>
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              {moment().format("M/D")}
            </Text>
            <Text style={{ fontSize: 40, color: "white", fontWeight: "bold" }}>
              {moment().format("YYYY")}
            </Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <Text
              style={{ fontSize: 26, color: "#A30003", fontWeight: "bold" }}
            >
              {firstName} {lastName}
            </Text>
            <Text
              style={{ fontSize: 16, color: "#A30003", fontWeight: "bold" }}
            >
              {accountType && accountType.capitalize()} ID: {fullSchoolID()}
            </Text>
          </View>
          <Text style={{ fontSize: 16, color: "white", fontWeight: "600" }}>
            Based on our records, you are not yet vaccinated for COVID-19.
            Please screen yourself before entering campus:
          </Text>
          <TouchableOpacity
            activeOpacity={0.6}
            style={Style.get("button")}
            onPress={() => navigation.push("covid19form")}
            disabled={!readyForSubmission}
          >
            <Text
              adjustsFontSizeToFit
              numberOfLines={1}
              style={Style.get("normalButtonText")}
            >
              COVID-19 Survey
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={pageName} />
      <ScrollView
        style={{ marginHorizontal: 15 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 0.03 * height }}>
          <Text>
            If we have a record of your COVID-19 vaccination, you can submit
            this form to access a Vaccination Pass that you can show to enter
            campus safely and efficiently.
          </Text>
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>I am a...</Text>
          {!!initialAccountTypeButtonState && (
            <ButtonSwitches
              initialButtonState={initialAccountTypeButtonState || null}
              leftOption="Student"
              rightOption="Employee"
              leftAction={() => setAccountType("student")}
              rightAction={() => setAccountType("employee")}
            />
          )}
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            {accountType === "student"
              ? "Student ID Number"
              : "Last 4 Digits of Your Social Security Number"}
          </Text>
          <Text style={{ fontSize: 11 }}>
            {accountType === "student"
              ? "This can be found on your Student ID card"
              : "This will be used to check whether you have submitted your COVID-19 test results yet"}
            .
          </Text>
          <TextInput
            style={styles.textInput}
            value={schoolID}
            onChangeText={(text) => setSchoolID(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>First Name</Text>
          <TextInput
            style={styles.textInput}
            value={firstName}
            onChangeText={(text) => setFirstName(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>Last Name</Text>
          <TextInput
            style={styles.textInput}
            value={lastName}
            onChangeText={(text) => setLastName(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height, marginBottom: 0.03 * height }}>
          <TouchableOpacity
            activeOpacity={0.6}
            style={[
              Style.get("button"),
              { opacity: readyForSubmission ? 1 : 0.3 }
            ]}
            onPress={onSubmitResponse}
            disabled={!readyForSubmission}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                adjustsFontSizeToFit
                numberOfLines={1}
                style={Style.get("normalButtonText")}
              >
                Submit
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});

const DEFAULT_APPROVED_IDS = new Set(["exampleapprovedencodedidhere"]);
