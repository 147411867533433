// TODO: This portal should be eliminated
/*
 * This portal displays just the content of a website, title, and back button.
 * Use the WebNav screen if you want a portal to navigate back and forth within a site.
 * Content for this portal is pulled from Firebase, from portal "web_PORTALNAME" (specified in index.ios.js)
 */

import React, { Component } from "react";
import { StyleSheet, View, Share, Platform } from "react-native";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import WebView from "react-native-webview";

// More info: https://stackoverflow.com/questions/58155621/react-native-webview-for-android-not-displaying-pdf-and-word-files
const GOOGLE_PDF_VIEWER_PREFIX =
  "http://docs.google.com/gview?embedded=true&url=";

let url = null;
let navbarText = null;

export default class WebStatic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portalContent: null
    };
  }

  componentDidMount() {
    const portalName = "TODO_REMOVE_THIS_PORTAL";
    // gets the content (url, title, etc) for this portal from the database
    Database.getPortalContent(portalName, (value) => {
      this.setState({ portalContent: value });
      Analytics.logEvent("view_webStatic", { key: portalName, url: value.url });
    });
  }

  render() {
    const { navigation } = this.props;
    const { portalContent } = this.state;
    if (portalContent == null) {
      // still waiting for response from the database
      return null;
    }
    // once the database has given us the contents of this portal...
    url = portalContent.url;
    navbarText = portalContent.title;

    const onShare = async () => {
      await Share.share({
        message: `${navbarText}: ${url}`
      });
    };

    return (
      <View style={styles.container}>
        <NavBar
          navigation={navigation}
          text={navbarText}
          screenType="share"
          onShare={onShare}
        />
        <WebView
          style={{
            flex: 1
          }}
          source={{
            uri: `${
              Platform.OS === "android" && url.endsWith(".pdf")
                ? GOOGLE_PDF_VIEWER_PREFIX
                : ""
            }${url}`
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Styles the entire container to hold multiple views */
  container: {
    flex: 1
  }
});
