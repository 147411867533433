import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import Glob from "src/globalConstants";
import HelpText from "src/components/HelpText";

const PHONE_SCREEN_IMAGE = require("resources/images/phoneLockScreen.png");

const { width } = Glob.get("dimensions");
const PHONE_SCREEN_IMAGE_WH_RATIO = 676 / 605;
const PHONE_SCREEN_IMAGE_WIDTH = Math.min(width * 0.9, 400);

export default function PreviewPushNotification({ title, body }) {
  return (
    <>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image
          source={PHONE_SCREEN_IMAGE}
          style={{
            width: PHONE_SCREEN_IMAGE_WIDTH,
            height: PHONE_SCREEN_IMAGE_WIDTH / PHONE_SCREEN_IMAGE_WH_RATIO
          }}
          resizeMode="contain"
        />
        <TouchableOpacity
          activeOpacity={0.85}
          style={{
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
            alignItems: "center"
          }}
        >
          <View
            style={{
              width: PHONE_SCREEN_IMAGE_WIDTH * 0.8,
              padding: 10,
              borderRadius: 18,
              backgroundColor: "#A9DBF5",
              flexDirection: "row"
            }}
          >
            <View style={{ justifyContent: "center" }}>
              <View
                style={{
                  width: PHONE_SCREEN_IMAGE_WIDTH * 0.08,
                  height: PHONE_SCREEN_IMAGE_WIDTH * 0.08,
                  backgroundColor: "#e8f8ff",
                  borderRadius: 7
                }}
              />
            </View>
            <View style={{ flex: 1, paddingLeft: 8 }}>
              <Text
                style={{ fontSize: 12, fontWeight: "bold", marginBottom: 0 }}
              >
                {(title || "Example Notification").truncate(34, true)}
              </Text>
              <Text style={{ fontSize: 12 }}>
                {(body || "").truncate(148, true)}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      {((title || "").length >= 34 || (body || "").length >= 148) && (
        <HelpText
          text="Recipients can expand the notification to read more."
          noteStyle
        />
      )}
    </>
  );
}
