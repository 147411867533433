import React, { useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import LottieView from "src/components/Lottie";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";

const SMARTPHONE_TAP_ANIMATION = require("resources/animations/smartphoneTap.json");

const { height, width } = Glob.get("dimensions");

export default function ViewAsMember({ navigation, route }) {
  const { turnOffAdminPrivileges } = route?.params || {};
  useEffect(() => {
    Analytics.logEvent("view_viewAsMember");
  }, []);

  const viewAsMember = () => {
    let messageInstructions = "close and reopen this app";
    if (Platform.OS === "web") messageInstructions = "refresh this page";
    Util.alert(
      "You're viewing everything as member now",
      `To get your admin superpowers back, just ${messageInstructions} 🦸`
    );
    turnOffAdminPrivileges();
    navigation.popToTop();
  };

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="View as Member" />
      <ScrollView
        style={{ paddingHorizontal: 15, marginTop: 5, width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View>
          <View style={{ height: height / 3, alignItems: "center" }}>
            <LottieView
              autoPlay
              loop
              style={{ height: "100%", width: "100%" }}
              source={SMARTPHONE_TAP_ANIMATION}
            />
          </View>
          <Text style={Style.get("headerText")}>
            <Text>Want to know </Text>
            <Text style={{ fontStyle: "italic" }}>exactly </Text>
            <Text>what your app looks like to your members?</Text>
          </Text>
          <Text style={styles.bodyText}>
            Just press the button below. And when you're done viewing as a
            member, close and reopen this app to get your superpowers back
            again.
          </Text>
        </View>
        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
          <Button text="View as Member" icon="eye" onPress={viewAsMember} />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  }
});
