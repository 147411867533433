/* global window */
import React, { useRef, useState } from "react";
import { View, Share, Platform, TouchableOpacity, Text } from "react-native";
import WebView from "react-native-webview";
// import { WebView as WebWebView } from "react-native-web-webview";
import Style from "src/globalStyles";
import NavBar from "src/components/navBar";

export default function NavWebView({
  navigation,
  URL,
  title,
  onPickURL,
  isFromMiniScreen
}) {
  const webViewRef = useRef(null);
  const [webViewLoading, setWebViewLoading] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);
  const [sslURL, setSSLURL] = useState(URL);
  const [url, setUrl] = useState(URL);

  const onNavigationStateChange = (navState) => {
    setCanGoBack(navState.canGoBack);
    setCanGoForward(navState.canGoForward);
    if (navState.url?.startsWith("http://"))
      setSSLURL(navState.url.replace("http://", "https://"));
    setUrl(navState.url);
  };

  const navigatePop = () => {
    navigation.goBack();
  };

  const onSelectURL = () => {
    if (onPickURL) onPickURL(url);
    navigatePop();
  };

  const goBack = () => {
    webViewRef.current.goBack();
  };

  const goForward = () => {
    webViewRef.current.goForward();
  };

  const onShare = async () => {
    if (Platform.OS === "web") window.open(url, "_blank");
    else
      await Share.share({
        message: url
      });
  };

  const showPDFReader =
    Platform.OS === "android" &&
    url &&
    url.endsWith(".pdf") &&
    !url.startsWith("https://wixlabs-pdf-dev.appspot.com/");
  let EmbeddedWebView = null;
  // if (Platform.OS === "web")
  //   EmbeddedWebView = (
  //     <>
  //       <WebWebView
  //         ref={webViewRef}
  //         style={{
  //           flex: 1
  //         }}
  //         onLoadStart={() => setWebViewLoading(true)}
  //         onLoadEnd={(aaa) => {
  //           console.log("aaa");
  //           console.log(aaa);
  //           setWebViewLoading(false)
  //         }}
  //         onResponderReject={(rej) => {
  //           console.log("rej");
  //           console.log(rej);
  //         }}
  //         onError={(err) => {
  //           console.log("err");
  //           console.log(err);
  //         }}
  //         onMessage={(mm) => {
  //           console.log("mm");
  //           console.log(mm);
  //         }}
  //         onNavigationStateChange={onNavigationStateChange}
  //         source={{ uri: `${URL}&output=embed` }}
  //         userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
  //       />
  //       {!!onPickURL && (
  //         <View style={{ position: "absolute", bottom: 10, width: "100%" }}>
  //           <TouchableOpacity
  //             activeOpacity={0.6}
  //             style={Style.get("button")}
  //             onPress={onSelectURL}
  //           >
  //             <View style={{ flexDirection: "row", alignItems: "center" }}>
  //               <Text
  //                 adjustsFontSizeToFit
  //                 numberOfLines={1}
  //                 style={Style.get("normalButtonText")}
  //               >
  //                 Use This Page
  //               </Text>
  //             </View>
  //           </TouchableOpacity>
  //         </View>
  //       )}
  //     </>
  //   );
  if (Platform.OS === "android" && isFromMiniScreen)
    EmbeddedWebView = (
      <View
        style={{
          justifyContent: "center",
          flex: 1,
          alignItems: "center",
          margin: 20
        }}
      >
        <Text style={Style.get("headerText")}>
          This screen will display the website "{url}"
        </Text>
      </View>
    );
  else if (Platform.OS === "web")
    EmbeddedWebView = (
      <iframe
        src={url}
        title={title?.toUpperCase() || URL || ""}
        style={{ height: "100%", width: "100%", border: 0 }}
        // onerror={(e) => {
        //   console.log("erred");
        //   console.log(e);
        // }}
        // onload={(e) => {
        //   console.log("loaded");
        //   console.log(e);
        // }}
        onLoad={(event) => {
          console.log("Loaded");
          console.log(event);
          console.log(`${event.target}`);
          // console.log("event.target.contentWindow.location");
          // console.log(`${event.target.contentWindow.location}`);
        }}
        onAbort={(e) => {
          console.log("Aborted");
          console.log(e);
        }}
        // onAbort={}
      />
    );
  else if (showPDFReader)
    EmbeddedWebView = (
      <>
        <WebView
          ref={webViewRef}
          style={{ flex: 1 }}
          onLoadStart={() => setWebViewLoading(true)}
          onLoadEnd={() => setWebViewLoading(false)}
          onNavigationStateChange={onNavigationStateChange}
          source={{
            uri: `https://drive.google.com/viewerng/viewer?embedded=true&url=${URL}`
          }}
          userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
        />
        {!!onPickURL && (
          <View style={{ position: "absolute", bottom: 10, width: "100%" }}>
            <TouchableOpacity
              activeOpacity={0.6}
              style={Style.get("button")}
              onPress={onSelectURL}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  style={Style.get("normalButtonText")}
                >
                  Use This Page
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  else
    EmbeddedWebView = (
      <>
        <WebView
          ref={webViewRef}
          style={{
            flex: 1
          }}
          onLoadStart={() => setWebViewLoading(true)}
          onLoadEnd={() => setWebViewLoading(false)}
          onNavigationStateChange={onNavigationStateChange}
          source={{ uri: sslURL }}
          userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
        />
        {!!onPickURL && (
          <View style={{ position: "absolute", bottom: 10, width: "100%" }}>
            <TouchableOpacity
              activeOpacity={0.6}
              style={Style.get("button")}
              onPress={onSelectURL}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  style={Style.get("normalButtonText")}
                >
                  Use This Page
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  return (
    <View style={{ flex: 1 }}>
      <NavBar
        navigation={navigation}
        text={title?.toUpperCase() || URL || ""}
        screenType="webNav"
        canGoBack={canGoBack}
        canGoForward={canGoForward}
        onPressGoBack={goBack}
        onPressGoForward={goForward}
        webViewIsLoading={webViewLoading}
        onShare={onShare}
      />
      {EmbeddedWebView}
    </View>
  );
}
