import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import DraggableList from "src/components/DraggableList";
import HelpButton from "src/components/HelpButton";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const { height, width } = Glob.get("dimensions");

export default function EditAllUserGroups({ navigation }) {
  const [allGroups, setAllGroups] = useState(null);
  const [allGroupIDsInOrder, setAllGroupIDsInOrder] = useState(null);
  const [alert, setAlert] = useState(null);

  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToManageUsers =
    !!adminPrivileges && adminPrivileges?.includes("ManageUsers");

  useEffect(() => {
    Analytics.logEvent("view_editAllUserGroups");
    fetchData();
  }, []);

  const fetchData = () => {
    Database.getAllUserGroups().then((groups) => setAllGroups(groups || []));
    Database.getAllUserGroupsDisplayOrder().then((displayOrder) =>
      setAllGroupIDsInOrder(displayOrder || [])
    );
  };

  const addNewGroup = () => {
    Analytics.logEvent("touch_editAllUserGroups_createGroup");
    Database.addTask("createUserGroup", "done");
    const newGroup = { name: "" };
    Database.addUserGroup(newGroup).then(fetchData);
  };

  const updateGroupName = (groupId, newName) => {
    setAllGroups({
      ...allGroups,
      [groupId]: { ...allGroups[groupId], name: newName }
    });
    Database.updateUserGroupName(groupId, newName);
  };

  const updateOrder = (newOrder) => {
    setAllGroupIDsInOrder(newOrder);
    Database.updateAllUserGroupsDisplayOrder(newOrder);
  };

  const deleteGroup = (groupId) => {
    Analytics.logEvent("touch_editAllUserGroups_pressDeleteGroup", { groupId });
    setAlert({
      title: "Are you sure?",
      message: "Deleting this group will remove anyone who's currently in it.",
      confirm: { text: "Cancel" },
      cancel: {
        text: "Delete Group",
        onPress: () => {
          Analytics.logEvent("touch_editAllUserGroups_confirmDeleteGroup", {
            groupId
          });
          const newAllGroups = { ...allGroups };
          delete newAllGroups[groupId];
          setAllGroups(newAllGroups);
          Database.deleteUserGroup(groupId).then(fetchData);
        }
      }
    });
  };

  const onPressReselectGroups = () => {
    setAlert({
      title: "Make users reselect groups?",
      message:
        "If you choose to do this, then the next time each user opens this app, they will be asked to reselect which groups they're in.",
      confirm: { text: "Cancel" },
      cancel: {
        text: "Force Reselection",
        onPress: () => {
          Analytics.logEvent("touch_editAllUserGroups_forceGroupReselection");
          Database.addTaskForAllUsers("registerForGroups").then((response) => {
            if (response.success) {
              setAlert({
                title: "Success!",
                message:
                  "All members will be asked to select their groups the next time they open the app."
              });
            }
          });
        }
      }
    });
  };

  if (!allGroups || !allGroupIDsInOrder) return null;

  const groupsData = [
    { isAddGroupButton: true },
    ...allGroupIDsInOrder.map((groupId) => ({
      ...allGroups[groupId],
      key: groupId
    }))
  ];

  const renderGroupRow = ({ item, drag, isActive }) => {
    if (item.isAddGroupButton)
      return <Button text="+ Add New Group" onPress={addNewGroup} />;
    return (
      <Pressable style={styles.rowContainer} onLongPress={drag}>
        <View
          style={[
            styles.row,
            isActive ? { elevation: 10, height: 58, width: "98%" } : {}
          ]}
        >
          <View style={{ flex: 5 }}>
            <TextInput
              placeholder="Group Name"
              style={styles.textInput}
              value={item.name}
              onChangeText={(text) => updateGroupName(item.key, text)}
              selectionColor={Rex.getConfig()?.colors?.primary}
            />
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "100%",
              cursor: isActive ? "grabbing" : "grab"
            }}
          >
            <Ionicons name="reorder-three-outline" size={28} color="gray" />
          </View>
        </View>
        <TouchableOpacity
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          onPress={() => deleteGroup(item.key)}
        >
          <MaterialIcons
            name="delete"
            size={22}
            color={Glob.get("dangerRed")}
          />
        </TouchableOpacity>
      </Pressable>
    );
  };

  const groupsExist = !!allGroupIDsInOrder && allGroupIDsInOrder.length > 0;

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text="Notification Groups"
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Notification Groups"
              message="When sending notifications, you can target them to specific user groups. Your members can join or leave these groups so that they only get information that's specifically relevant to them."
              navigation={navigation}
              videoUrl="https://youtu.be/0KRX1eJ6dwg"
            />
          </View>
        }
      />
      <View style={{ paddingHorizontal: 15, width }}>
        <Text
          style={{
            ...Style.get("headerText"),
            marginVertical: 15
          }}
        >
          {groupsExist
            ? "Edit which groups your users can join"
            : "Add groups for your users to join"}
        </Text>
        <View style={{ marginBottom: 20 }}>
          <HelpText
            text={
              groupsExist
                ? "Touch a group to rename it. Touch the trash icon to delete it. Hold the right edge and drag to reorder how they're displayed to users."
                : "You can send targeted notifications to specific user groups. Your members can join or leave these groups so that they only get information that's specifically relevant to them."
            }
          />
        </View>
      </View>
      <View style={{ width, flex: 1 }}>
        <DraggableList
          contentContainerStyle={{ width, paddingBottom: 40 }}
          removeClippedSubviews={false}
          keyboardDismissMode="on-drag"
          data={groupsData}
          onDragEnd={({ data }) =>
            updateOrder(data.map((g) => g.key).filter((g) => g))
          }
          keyExtractor={(item) => `draggable-item-${item.key}`}
          autoscrollSpeed={120}
          renderItem={renderGroupRow}
        />
      </View>
      {groupsExist && allowedToManageUsers && (
        <View style={{ width }}>
          <TouchableLink
            type="button"
            text="Advanced: Make all users reselect their groups"
            textStyle={{
              textAlign: "center",
              marginBottom: 50,
              marginTop: 10
            }}
            onPress={() => {
              Analytics.logEvent(
                "touch_editAllUserGroups_onPressReselectGroups"
              );
              onPressReselectGroups();
            }}
          />
        </View>
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const row = {
  display: "flex",
  flex: 7,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  paddingLeft: 10,
  paddingRight: 3,
  height: 50,
  marginVertical: 4,
  marginLeft: 15,
  shadowOpacity: 0.15,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 10,
  elevation: 5,
  borderRadius: 15
};
if (Platform.OS === "web") row.boxShadow = "0px 0px 10px rgba(0,0,0,0.15)";
const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  row,
  text: {
    marginBottom: 15
  },
  textInput: {
    fontSize: 20,
    height: 0.056 * height,
    borderColor: Glob.get("light gray"),
    color: "black"
  }
});
