import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ActivityIndicator,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import BottomSheetModal from "src/components/BottomSheetModal";
import Checkbox from "src/components/Checkbox";
import HelpText from "src/components/HelpText";
import { ImageZoom } from "@likashefqet/react-native-image-zoom";

const { height, width } = Glob.get("dimensions");
const STRIPE_DASHBOARD_URL = "https://dashboard.stripe.com/dashboard";
const STRIPE_FEES_URL = "https://stripe.com/pricing";
const EXAMPLE_PRICE_DOLLARS = 10;

export default function CommerceSettings({ navigation }) {
  const [accountLinkURL, setAccountLinkURL] = useState(null);
  const [loadedCommerceConfig, setLoadedCommerceConfig] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [
    isTogglingPassTransactionFeesToCustomers,
    setIsTogglingPassTransactionFeesToCustomers
  ] = useState(false);
  const [commerceConfig, setCommerceConfig] = useState({});
  const [exampleTransactionFees, setExampleTransactionFees] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);
  const stripeAccountIsConfigured =
    stripeAccount?.charges_enabled && stripeAccount?.payouts_enabled;

  useEffect(() => {
    Analytics.logEvent("view_commerceSettings");
    Database.subscribeToCommerce(async (data) => {
      const { config = {} } = data || {};
      if (config?.stripeConnect?.accountID) {
        const account = await Database.fetchStripeConnectedAccount();
        setStripeAccount(account);
        const fees = await Database.stripeConnectedAccountCalculateTransactionFees(
          EXAMPLE_PRICE_DOLLARS * 100
        );
        setExampleTransactionFees(fees);
      }
      setCommerceConfig(config);
      setLoadedCommerceConfig(true);
      setIsTogglingPassTransactionFeesToCustomers(false);
    });
    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromCommerce();
    };
  }, []);

  const {
    stripeConnect = {},
    onespotFees = {},
    passTransactionFeesToCustomers = false
  } = commerceConfig || {};
  const { accountID = null } = stripeConnect || {};

  const configureStripeConnect = async () => {
    Analytics.logEvent("touch_commerceSettings_stripeConfiguration");
    // If there's already an account ID, we don't need to configure
    let finalAccountID = accountID;
    if (!finalAccountID) {
      setIsLoadingButton(true);
      const { account, error } = await Database.createStripeConnectedAccount();
      Analytics.logEvent("action_commerceSettings_stripeAccountCreated", {
        account,
        error
      });
      finalAccountID = account;
      setIsLoadingButton(false);
    }

    if (finalAccountID) {
      Analytics.logEvent("action_commerceSettings_stripeAccountFetched", {
        accountID: finalAccountID
      });
      setIsLoadingButton(true);
      const { url, error } = await Database.createStripeConnectedAccountLink();
      Analytics.logEvent("action_commerceSettings_stripeAccountLinkFetched", {
        url,
        error
      });
      setIsLoadingButton(false);
      if (Platform.OS === "web") Util.openURL(url);
      else setAccountLinkURL(url);
    }
  };

  const togglePassTransactionFeesToCustomers = async () => {
    setIsTogglingPassTransactionFeesToCustomers(true);
    await Database.stripeConnectedAccountTogglePassTransactionFeesToCustomers();
  };

  const openAppInBrowser = () => {
    Database.fetchPrimaryMetaApp().then((metaApp) => {
      if (metaApp?.webLink) {
        Util.alert(
          "Open on your computer",
          "Open the web version of your app in your phone's browser. Then, copy the page link and send it to your computer so you can navigate back to this commerce page from there.",
          [
            {
              text: "Cancel",
              onPress: () => {},
              style: "cancel"
            },
            {
              text: "Open in Browser",
              onPress: () => Util.openURL(metaApp.webLink)
            }
          ]
        );
      } else Util.alert("No web version", "Sorry, no web version available.");
    });
  };

  if (!loadedCommerceConfig)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Onespot Payments" />
        <ScrollView style={{ paddingHorizontal: 20, width }}>
          <ActivityIndicator size="large" style={{ marginTop: 80 }} />
        </ScrollView>
      </View>
    );

  if (!stripeAccountIsConfigured) {
    const imageWidth = Math.min(width, height * 0.6);
    const imageHeight = imageWidth * (1351 / 2000); // exact dimensions of image
    return (
      <View style={styles.pageContent}>
        <NavBar
          navigation={navigation}
          text="Onespot Payments"
          backgroundColor={Glob.get("onespotColor")}
        />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          {Platform.OS === "web" ? (
            <Image
              source={Glob.get("commercePaymentsSchools")}
              style={{
                height: imageHeight,
                width: imageWidth,
                resizeMode: "contain",
                alignSelf: "center",
                zIndex: 999
              }}
            />
          ) : (
            <ImageZoom
              source={Glob.get("commercePaymentsSchools")}
              style={{
                height: imageHeight,
                width: imageWidth,
                resizeMode: "contain",
                alignSelf: "center",
                zIndex: 999
              }}
            />
          )}
          <Text
            style={{
              fontWeight: "bold",
              marginTop: 20,
              fontSize: 18
            }}
          >
            Ready to start taking payments?
          </Text>
          <Text style={styles.bodyText}>
            Onespot Payments makes it super easy to accept payments online. Our
            tools help you add a secure payment system to your app, protecting
            sensitive information and offering flexible payment options.
          </Text>
          <Text style={styles.bodyText}>
            Streamline transactions, reduce administrative burden, and provide
            an elegant payments solution for your whole community, all in one
            spot.
          </Text>
          <Button
            text="Watch an overview"
            style={{ marginTop: 15 }}
            color={Glob.get("onespotColor")}
            outline
            onPress={() => {
              navigation.push("webNav", {
                title: "Onespot Payments Overview",
                url: "https://www.youtube.com/watch?v=wPI1LA_8eVQ"
              });
            }}
          />
          <Button
            text="Schedule a call with us"
            color={Glob.get("onespotColor")}
            outline
            onPress={() => {
              navigation.push("webNav", {
                title: "Onespot Payments Overview",
                url: "https://calendly.com/seancann/onespot-payments"
              });
            }}
          />
          <Text style={styles.bodyText}>
            {!accountID
              ? "Onespot uses Stripe for enterprise-grade, secure payment processing. When you're ready to get started, configure"
              : "Finish configuring"}{" "}
            your Stripe account to start accepting payments:
          </Text>
          {isLoadingButton ? (
            <ActivityIndicator size="large" style={{ marginTop: 15 }} />
          ) : (
            <>
              {!accountID ? (
                <Button
                  text="Get started"
                  style={{
                    marginTop: 15,
                    marginBottom: Platform.OS === "web" ? 60 : 0
                  }}
                  textStyle={{ fontWeight: "bold" }}
                  color={Glob.get("onespotColor")}
                  onPress={configureStripeConnect}
                  showNotification
                />
              ) : (
                <Button
                  text="Finish setting up account"
                  style={{
                    marginTop: 15,
                    marginBottom: Platform.OS === "web" ? 60 : 0
                  }}
                  textStyle={{ fontWeight: "bold" }}
                  color={Glob.get("onespotColor")}
                  onPress={configureStripeConnect}
                  showNotification
                />
              )}
              {Platform.OS !== "web" && (
                <>
                  <Text style={styles.bodyText}>
                    Note: You can configure your account in the{" "}
                    {Platform.OS === "ios" ? "iOS" : "Android"} app if you want,
                    but it's a bit easier to do on a computer:
                  </Text>
                  <Button
                    text="Open app on computer"
                    style={{ marginTop: 15, marginBottom: 60 }}
                    outline
                    color={Glob.get("onespotColor")}
                    onPress={openAppInBrowser}
                  />
                </>
              )}
            </>
          )}
        </ScrollView>
        {!!accountLinkURL && (
          <BottomSheetModal
            onClose={() => setAccountLinkURL(null)}
            url={accountLinkURL}
          />
        )}
      </View>
    );
  }

  let helpText = "";
  if (exampleTransactionFees?.totalFee) {
    const { totalFee, totalFeeToCustomer, stripeFee } = exampleTransactionFees;
    if (passTransactionFeesToCustomers) {
      helpText = (
        <Text>
          <Text style={{ fontWeight: "bold" }}>You pay 0% fees. </Text>
          For a ${EXAMPLE_PRICE_DOLLARS} purchase, the customer pays $
          {(EXAMPLE_PRICE_DOLLARS + totalFeeToCustomer / 100).toFixed(2)} ($
          {EXAMPLE_PRICE_DOLLARS} + ${(totalFeeToCustomer / 100).toFixed(2)}{" "}
          transaction fees). You receive ${EXAMPLE_PRICE_DOLLARS}.{"\n"}
          <Text style={{ fontSize: 11 }}>
            Note: Technically, you receive $
            {(EXAMPLE_PRICE_DOLLARS + stripeFee / 100).toFixed(2)} and then pay
            ${(stripeFee / 100).toFixed(2)} in Stripe fees, which leaves you
            with ${EXAMPLE_PRICE_DOLLARS}.
          </Text>
        </Text>
      );
    } else {
      helpText = (
        <Text>
          For a ${EXAMPLE_PRICE_DOLLARS} purchase, you receive $
          {(EXAMPLE_PRICE_DOLLARS - totalFee / 100).toFixed(2)}
          {"\n"}($
          {EXAMPLE_PRICE_DOLLARS} - ${(totalFee / 100).toFixed(2)} transaction
          fees).
        </Text>
      );
    }
  }

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Onespot Payments" />
      <ScrollView style={{ paddingHorizontal: 20, width }}>
        <Image
          source={Glob.get("commerceComputer")}
          style={{
            height: height * 0.3,
            width: height * 0.3,
            resizeMode: "contain",
            alignSelf: "center"
          }}
        />
        <Text
          style={{
            fontWeight: "bold",
            marginVertical: 15,
            fontSize: 24,
            textAlign: "center"
          }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          Your account is set up 👍
        </Text>
        <Text style={styles.bodyText}>
          View your customers, orders, payouts, and more in your Stripe
          dashboard.
        </Text>
        <Button
          text="Stripe Dashboard"
          style={{ marginTop: 15 }}
          color="#3f5bb4"
          onPress={() => {
            navigation.push("webNav", {
              title: "Stripe Dashboard",
              url: STRIPE_DASHBOARD_URL
            });
          }}
        />
        <View
          style={{
            alignItems: "center",
            backgroundColor: "#e8ecfa",
            marginTop: 40,
            padding: 20,
            borderRadius: 20
          }}
        >
          <View style={{ width: "100%" }}>
            <Text
              style={{ fontWeight: "bold", fontSize: 24 }}
              scrollIndicatorInsets={{ right: 1 }}
            >
              Account Info
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Name: </Text>
              {stripeAccount?.business_profile?.name}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Email: </Text>
              {stripeAccount?.email}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Location: </Text>
              {stripeAccount?.business_profile?.support_address?.city},{" "}
              {stripeAccount?.business_profile?.support_address?.state}
            </Text>
          </View>
          <Button
            text="Edit Configuration"
            align="left"
            noMargin
            outline
            flat
            small
            color="#3f5bb4"
            onPress={configureStripeConnect}
          />
        </View>
        <View
          style={{
            alignItems: "center",
            backgroundColor: "#e8ecfa",
            marginTop: 10,
            marginBottom: 60,
            padding: 20,
            borderRadius: 20
          }}
        >
          <View style={{ width: "100%" }}>
            <Text
              style={{ fontWeight: "bold", fontSize: 24 }}
              scrollIndicatorInsets={{ right: 1 }}
            >
              Transaction Fees
            </Text>
            {/*
            TODO: Enable this to pass fees to customers
            {isTogglingPassTransactionFeesToCustomers ? (
              <ActivityIndicator
                size="small"
                style={{
                  alignSelf: "flex-start",
                  marginVertical: 6,
                  marginLeft: 6
                }}
              />
            ) : (
              <Checkbox
                text="Pass fees to customers"
                checked={passTransactionFeesToCustomers}
                style={{ width: null }}
                onChange={togglePassTransactionFeesToCustomers}
              />
            )}
            <HelpText text={helpText} />
            */}
            <Text style={{ fontWeight: "bold", marginTop: 5 }}>
              Onespot Fee:{" "}
            </Text>
            <Text>
              • All Payments:{" "}
              {`${parseFloat(
                ((onespotFees?.overallPercent || 0) * 100).toFixed(2)
              )}%`}
            </Text>
            <Text style={{ fontWeight: "bold", marginTop: 5 }}>
              Stripe Fees:{" "}
            </Text>
            <Text>• Cards: 2.9% + 30¢</Text>
            <Text>• ACH: 0.8% (max $5)</Text>
          </View>
          <Button
            text="Detailed Stripe Fees"
            align="left"
            noMargin
            outline
            flat
            small
            color="#3f5bb4"
            onPress={() => {
              navigation.push("webNav", {
                title: "Stripe Fees",
                url: STRIPE_FEES_URL
              });
            }}
          />
        </View>
      </ScrollView>
      {!!accountLinkURL && (
        <BottomSheetModal
          onClose={() => setAccountLinkURL(null)}
          url={accountLinkURL}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    marginTop: 10
  }
});
