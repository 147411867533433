import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  TextInput
} from "react-native";
import moment from "moment";
import * as Linking from "expo-linking";
import * as Crypto from "expo-crypto";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import ButtonSwitches from "../components/buttonSwitches";
import Util from "../utility";

const { height, width } = Dimensions.get("window");
const pageName = "COVID-19 Daily Survey";

const InformationSource = () => (
  <View
    style={{
      marginTop: 0.03 * height,
      backgroundColor: "rgba(200, 200, 200, 0.5)",
      padding: 7,
      borderRadius: 10
    }}
  >
    <Text style={{ fontSize: 10 }}>
      This screening questionaire is required by the New York State Department
      of Health. For more information, visit:
    </Text>
    <Text
      style={{
        color: Rex.getConfig()?.colors?.button,
        fontSize: 10
      }}
      onPress={() =>
        Linking.openURL(
          "https://www.governor.ny.gov/sites/governor.ny.gov/files/atoms/files/Higher_Education_Detailed_Guidelines.pdf"
        )
      }
    >
      https://www.governor.ny.gov/sites/governor.ny.gov/files/atoms/files/Higher_Education_Detailed_Guidelines.pdf
    </Text>
  </View>
);

export default function Covid19Form({ navigation }) {
  const [accountType, setAccountType] = useState(null); // "student" or "employee"
  const [schoolID, setSchoolID] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [question1, setQuestion1] = useState(null);
  const [question2, setQuestion2] = useState(null);
  const [question3, setQuestion3] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("pending"); // "pending", "passed", "failed", or "userNotApproved"
  const [allApprovedIDs, setAllApprovedIDs] = useState(DEFAULT_APPROVED_IDS);
  const [schoolIDIsApproved, setSchoolIDIsApproved] = useState(false);
  const [
    initialAccountTypeButtonState,
    setInitialAccountTypeButtonState
  ] = useState(false);
  const [showSubmitResultsWarning, setShowSubmitResultsWarning] = useState(
    false
  );

  useEffect(() => {
    Analytics.logEvent("view_covid19form");
    if (Rex.getSessionMemory("covid19FormStatus") === "passed")
      setSubmissionStatus("passed");
    Database.listenUserFirstName((first) => {
      setFirstName(first);
    });
    Database.listenUserLastName((last) => {
      setLastName(last);
    });
    Database.listenUserEmail((userEmail) => {
      setEmail(userEmail);
    });
    Database.listenUserSchoolID((userSchoolID) => {
      setSchoolID(userSchoolID);
    });
    Database.listenUserType((userType) => {
      switch (userType) {
        case "student":
          setAccountType("student");
          setInitialAccountTypeButtonState("left");
          break;
        case "faculty":
          setAccountType("employee");
          setInitialAccountTypeButtonState("right");
          break;
        case "staff":
          setAccountType("employee");
          setInitialAccountTypeButtonState("right");
          break;
        default:
          setAccountType("student");
          setInitialAccountTypeButtonState("left");
          break;
      }
    });
    Database.getCOVID19ApprovedIDsEncrypted((allIDs) => {
      // const diff = new Set([...allIDs].filter(x => !allApprovedIDs.has(x)))
      if (allIDs) {
        const union = new Set([...allApprovedIDs, ...allIDs]);
        setAllApprovedIDs(union);
      }
    });
  }, []);

  useEffect(() => {
    isIDApproved().then((isApproved) => setSchoolIDIsApproved(isApproved));
  }, [schoolID]);

  useEffect(() => {
    isIDApproved().then((isApproved) => {
      setSchoolIDIsApproved(isApproved);
      setShowSubmitResultsWarning(!isApproved && !!schoolID);
    });
  }, [accountType]);

  const readyForSubmission =
    !!accountType &&
    !!schoolID &&
    schoolID.length > 0 &&
    !!firstName &&
    firstName.length > 0 &&
    !!lastName &&
    lastName.length > 0 &&
    !!email &&
    email.length > 3 &&
    question1 !== null &&
    question2 !== null &&
    question3 !== null;

  const validateAnswers = () => {
    return !question1 && !question2 && !question3;
  };

  const fullSchoolID = () => {
    return `${accountType === "employee" ? 10000 : ""}${schoolID}`;
  };

  const isIDApproved = async () => {
    if (!accountType) return Promise.resolve(false);
    const id = fullSchoolID(); // employee IDs are 10000 + <last 4 of SSN>
    const md5 = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.MD5,
      `${id}`
    );
    return Promise.resolve(!!allApprovedIDs && allApprovedIDs.has(md5));
  };

  const onSubmitResponse = () => {
    isIDApproved().then((isApproved) => {
      const covid19FormStatus = !isApproved
        ? "userNotApproved"
        : validateAnswers()
        ? "passed"
        : "failed";
      setSubmissionStatus(covid19FormStatus);
      Rex.setSessionMemory("covid19FormStatus", covid19FormStatus, 3600); // cache result for 1 hour
      const fields = [
        accountType.capitalize(),
        fullSchoolID(),
        `${firstName} ${lastName}`,
        email,
        question1 ? "Yes" : "No",
        question2 ? "Yes" : "No",
        question3 ? "Yes" : "No"
      ];
      if (schoolID) Database.setUserSchoolID(schoolID);
      Util.fillStaticGoogleForm("covid-19", fields);
      Analytics.logEvent("touch_covid19form_submit");
    });
  };

  if (submissionStatus === "userNotApproved")
    return (
      <View style={[styles.pageContent, { backgroundColor: "#FF7477" }]}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            marginHorizontal: 30
          }}
        >
          <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
            📋
          </Text>
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              {moment().format("M/D")}
            </Text>
            <Text style={{ fontSize: 40, color: "white", fontWeight: "bold" }}>
              {moment().format("YYYY")}
            </Text>
          </View>
          <Text style={{ fontSize: 15, color: "white", fontWeight: "600" }}>
            According to our records, you have either not yet submitted your
            COVID-19 test results or the test result you submitted indicates
            that you may have been exposed to COVID-19. Please email a copy of
            your latest test results to healthrecords@ftc.edu before entering
            campus. If you have any questions, please email
            healthrecords@ftc.edu to ask.
          </Text>
        </View>
      </View>
    );

  if (submissionStatus === "passed")
    return (
      <View style={[styles.pageContent, { backgroundColor: "#7DC95E" }]}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            marginHorizontal: 30
          }}
        >
          <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
            👍
          </Text>
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              {moment().format("M/D")}
            </Text>
            <Text style={{ fontSize: 40, color: "white", fontWeight: "bold" }}>
              {moment().format("YYYY")}
            </Text>
          </View>
          <Text style={{ fontSize: 18, color: "white", fontWeight: "600" }}>
            You have passed the COVID-19 screening questionnaire today! If
            asked, please show this screen to a staff member in order to enter
            campus.
          </Text>
          <InformationSource />
        </View>
      </View>
    );

  if (submissionStatus === "failed")
    return (
      <View style={[styles.pageContent, { backgroundColor: "#FF7477" }]}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            marginHorizontal: 30
          }}
        >
          <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
            😷
          </Text>
          <View style={{ alignItems: "center" }}>
            <Text style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              {moment().format("M/D")}
            </Text>
            <Text style={{ fontSize: 40, color: "white", fontWeight: "bold" }}>
              {moment().format("YYYY")}
            </Text>
          </View>
          <Text style={{ fontSize: 16, color: "white", fontWeight: "600" }}>
            Based on your response, you have failed the Covid-19 survey and will
            not be permitted to enter campus today. Please contact your
            healthcare provider or seek medical evaluation. Once you have
            quarantined/isolated, you will be permitted back in campus as long
            as you have submitted documentation of medical clearance.
          </Text>
          <InformationSource />
        </View>
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={pageName} />
      <ScrollView
        style={{ marginHorizontal: 15 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 0.03 * height }}>
          <Text>
            For the safety of our community, please fill out this questionnaire
            every time you arrive on campus.
          </Text>
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            Are you fully vaccinated for COVID-19?
          </Text>
          <Text style={{ fontSize: 11 }}>(optional)</Text>
          <ButtonSwitches
            initialButtonState={null}
            leftOption="No"
            rightOption="Yes"
            leftAction={() => {}}
            rightAction={() => navigation.push("covid19VaccinePassport")}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>I am a...</Text>
          {!!initialAccountTypeButtonState && (
            <ButtonSwitches
              initialButtonState={initialAccountTypeButtonState || null}
              leftOption="Student"
              rightOption="Employee"
              leftAction={() => setAccountType("student")}
              rightAction={() => setAccountType("employee")}
            />
          )}
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            {accountType === "student"
              ? "Student ID Number"
              : "Last 4 Digits of Your Social Security Number"}
          </Text>
          <Text style={{ fontSize: 11 }}>
            {accountType === "student"
              ? "This can be found on your Student ID card"
              : "This will be used to check whether you have submitted your COVID-19 test results yet"}
            .
          </Text>
          <TextInput
            style={styles.textInput}
            value={schoolID}
            onChangeText={(text) => setSchoolID(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
            onBlur={() =>
              setShowSubmitResultsWarning(!schoolIDIsApproved && !!schoolID)
            }
          />
          {showSubmitResultsWarning && (
            <Text style={{ fontSize: 11, color: "orange" }}>
              Make sure you have submitted your COVID-19 test results to
              healthrecords@ftc.edu
            </Text>
          )}
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>First Name</Text>
          <TextInput
            style={styles.textInput}
            value={firstName}
            onChangeText={(text) => setFirstName(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>Last Name</Text>
          <TextInput
            style={styles.textInput}
            value={lastName}
            onChangeText={(text) => setLastName(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>Email</Text>
          <TextInput
            style={styles.textInput}
            value={email}
            onChangeText={(text) => setEmail(text)}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            Have you experienced any symptoms of COVID-19 in the past 14 days?
          </Text>
          <Text style={{ fontSize: 11 }}>
            (Cough, shortness of breath or difficulty breathing, fever, chills,
            muscle pain, sore throat, or new loss of taste or smell)
          </Text>
          <ButtonSwitches
            initialButtonState={null}
            leftOption="No"
            rightOption="Yes"
            leftAction={() => setQuestion1(false)}
            rightAction={() => setQuestion1(true)}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            Have you tested positive for COVID-19 in the past 14 days?
          </Text>
          <ButtonSwitches
            initialButtonState={null}
            leftOption="No"
            rightOption="Yes"
            leftAction={() => setQuestion2(false)}
            rightAction={() => setQuestion2(true)}
          />
        </View>
        <View style={{ marginTop: 0.03 * height }}>
          <Text style={Style.get("headerText")}>
            Have you knowingly been in close or proximate contact in the past 14
            days with anyone who has tested positive or who has or had symptoms
            of COVID-19?
          </Text>
          <ButtonSwitches
            initialButtonState={null}
            leftOption="No"
            rightOption="Yes"
            leftAction={() => setQuestion3(false)}
            rightAction={() => setQuestion3(true)}
          />
        </View>
        <View style={{ marginTop: 0.03 * height, marginBottom: 0.03 * height }}>
          <TouchableOpacity
            activeOpacity={0.6}
            style={[
              Style.get("button"),
              { opacity: readyForSubmission ? 1 : 0.3 }
            ]}
            onPress={onSubmitResponse}
            disabled={!readyForSubmission}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                adjustsFontSizeToFit
                numberOfLines={1}
                style={Style.get("normalButtonText")}
              >
                Submit
              </Text>
            </View>
          </TouchableOpacity>
          <InformationSource />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});

const DEFAULT_APPROVED_IDS = new Set([
  "NOT_A_REAL_ID"
  // "0ce17674d6c988363ca0ba40686ea18c",
  // "6500d1f61a378d49814ea22b2bf36ae5",
  // "78991a0846ee45c889593398dadce5e6",
  // "2e46a071e42b72af88c035fe61da42c0",
  // "fc8b686d8eea581d4693d6a4907171e5",
  // "5180bc09cdb2262c70b98858100dc876",
  // "d5656b185d97b5f78c07c795135c47e1",
  // "4e35b69f5b32463420e51b3d47fbe9f5",
  // "54a9238764f937aa4603d28435353bbb",
  // "35c02c8695ad9b19311cf85719cd0974",
  // "9117d506eb757fc304b47a63a6e24091",
  // "df41a5c7963fb3442fa709b2fbdaf266",
  // "689fb31a7baeb9882dc51ba843662743",
  // "37071ca076ec7bc09f4855a0b6f8f552",
  // "84d56218946b3f845a1d35ad744b7240",
  // "282de69fa634ac22475848470295654c",
  // "04a4c58ea8570ecc327c1cab0c52ed62",
  // "90cf02b64931a07f4d4c29b7ec4cb04e",
  // "1ca79a9d7c385e698ca60c218e563ed2",
  // "ed3ea19bbc5061cef8363f41bad6f27c",
  // "5b2a685cd9098463207a9c1b7d4befba",
  // "b3dc9a1ed229176b967cb047536af297",
  // "f04ee053e81cf0dbee12bc3215dfa141",
  // "8ca5a9b95567abdabcfb5053fd411a15",
  // "0a8e2b589066e416207e1dbdc17ebfde",
  // "197f0556ac6dd78432b5fce22ba9fe55",
  // "92bb795eb4d18ac2f603781d2116cbf5",
  // "6d6853e39c4bd97eb96f834596deba18",
  // "9e431b532c67adaad4baeede1528808f",
  // "ac7bab4baa73324c46ee81596e7b718f",
  // "33a10b57b95ddf4ad15f1d447c3d9afa",
  // "baa741eefcdd0d67dcf48b1e31c6e5b4",
  // "7a3152e58da8fc7f7f490fcc858087ea",
  // "d55df5b855c5a6088b6ef933f1e7a917",
  // "832524824d2564c5571f5852cb0ccb34",
  // "f14fd83971ef22544476e772fb3f9396",
  // "9e2eb98e45a16ded3aad3a0f95cf9759",
  // "b59804901c3465968130803590a4dc32",
  // "9341d0449d6ed4246c0fd32fdab5b372",
  // "31677e774c32f1e58636f6a45f337caa",
  // "beadcb20dc24d935b5229e5f9f9b1cd7",
  // "589162ba49bec07767e4a86bc26e7674",
  // "b84d177fdef168a961113d584728f7ef",
  // "70198e8bc0a05441d9eb4885737e7758",
  // "434b656bde590efe78b79807c4fa9035",
  // "98cd9c22b1dc01e512cec21cf8489548",
  // "0edc0b40eb9683df147f158b6686bf35",
  // "9e5a2926ffb710ef77074d33cb633378",
  // "77eb44a54d1c3812ce7cd4c945d2ebbe",
  // "bda6fc8dfbaca66bc10fa22f110efd39",
  // "6e03319ea1c2895e6e8c566f3cdbb5cf",
  // "a2b0d59ec1b7cb4af6432bd5cc0ddc2b",
  // "ac42fde73d8e8dbc078b4438c2d733e5",
  // "51c2db6c0423afd3c730ae833e9e6bae",
  // "b81032571a3af7ec8340f2091e86a490",
  // "8d9f3548b81379368aa8bb03fae9fe8d",
  // "b949bb89a0ab0323b31f9a572709f3a8",
  // "acbc13d17188ae869f9187d65b7dc765",
  // "7bc42e1b210c17f71742217ec4cb1681",
  // "891c32c2c70d7ef67be995dba538e75c",
  // "f31cd648e0489b7a7a2a045a2700f7f1",
  // "562ad57f7ea37bc0aa915f9987ac3cd4",
  // "62d1fd305752d7a0e2243e5a02377f70",
  // "03f8e6502f0fb4109134edaf2a6b87a6",
  // "8c76ea00c25a79d8c0de2bb8f09d3182",
  // "6144968b8c335320983d77b5b35cfe18",
  // "eafe451f1116533368f5ea1e21da40de",
  // "d30966024902ca1d541407c3533d0cac",
  // "b4e947d9817956b348161d2d44fcb571",
  // "d5438c745f6d59a80f5542bb5a9f728a",
  // "9c43ed4501504be407153b5ffea9f231",
  // "949f747f11930407107f9e3d116177a0",
  // "4af356798568f1f3432ccc32c9a9b270",
  // "c3f89b2f159a48f858a6cf1c6bf7289c",
  // "0b73c0dba4281e773a23f31cfea28072",
  // "4915cb662dd041b53c423d09ea88df9e",
  // "5141f0cb3da5280be6f97460abded0be",
  // "bbc5f9fa0c351251fddfafd72295f52d",
  // "56eb368637b6636f31e061aed1a47620",
  // "ec05decfdb4ded12e5f33475bd6be920",
  // "3192c474da52e45d1a4b8be5c5e0858e",
  // "99a01bd2df7aa99b3b7ae82407b511a6",
  // "962282acc00d1c41a634074440fda554",
  // "924640de68b73a8726740c899ff84f95",
  // "96599e655d7991ad3f12fe116f52c3d0",
  // "6bc095c9ad0afe58ba2fe2f95011cffb",
  // "b98f3c6ea23be0e3970f27c7a30389f9",
  // "8e25373825d16d640f27afd9ac3778d9",
  // "f3656af69ed11da7cb89f11300826572",
  // "4f70e23fe36e554684627729df9c53e7",
  // "6c65b5255bad3f0cc4c802b1aed48717",
  // "6e09185d54fbd723411209fbe1742548",
  // "7af6db330cad2952e66b87904192384b",
  // "53c308f63a7e3c499c8709e463dd7f0d",
  // "d0b810d297984bbfcacd4c0b41cdf441",
  // "a83d2dc0166ded8f771f36c60a4d732b",
  // "d201394e081e1969113cefce22df770a",
  // "f7f8cdfe73f919788a11fd4d96c2f254",
  // "b3f78f2cf991d811db716275e5f5475d",
  // "9bb41b825a53d5a40003a7b1f9c16c38",
  // "dbec76ca422c69518de0d6cfcbdef086",
  // "fab512273293519c09840a950c137dc0",
  // "ed1336355291cd41ef4f7315964215df",
  // "74783e8425bac98acbde7d2bf84363d9",
  // "bdb19cef1ab3407aa813656d2572b689",
  // "66c8632a5c2af06ca3557cc16ec57f37",
  // "420d01da475aaec0c34c55546401bf11",
  // "4fc4c397d0f0df52ead874345696c1bb",
  // "a22340247510fc478b791946536d7b4f",
  // "919d9326ee491759c7619732ed7100be",
  // "03e39afd7f1f0f5095e2e585f6a20cf5",
  // "079cd2df1135a5c49cedf78bd398056a",
  // "ef56178743ed6b99049608ff6af00240",
  // "ef01f3049896d2a60c25a1762f03049c",
  // "31403d137ff0488ed1789b1b05c25c04",
  // "d506c50208a67fbc1f297a08304645b4",
  // "c9f304a8d9df1cb244e18522f1ace4eb",
  // "c1b34984806db7e53003c574fa6f4a98",
  // "8ad51c196ec9d965de279158b2536c4d",
  // "d74511047b634788c74d8550145cc55d",
  // "da6de7e06b23c14a0edd5f91cf28d02c",
  // "ce2804704519d8d9b0879b812e17499a",
  // "b8badcd550301c641141593626a3524b",
  // "704bde2d0881ce9ef3ebf174830655d2",
  // "b60af10e9f0992f19846f549ad0bdedd",
  // "6d725cf193e73b7c6d167c28b2de98bb",
  // "1f427da580e2710330515cc45d04af72",
  // "119b3849dd01ac162682f98504a6146b",
  // "78b255b766d38c40fba192d6f10ef8a6",
  // "57556e59fd20a05ede26c3511b8cb08a",
  // "dbf11d2cd271010b6ba8b7f06399334f",
  // "2613c26aefb5e666b4275c68a8217331",
  // "2c8ac95f0c61058c4b0c420398f01eef",
  // "b00c4f09291430edbd8c0cd583904cca",
  // "32081088ef3d7b235513d05169596d3e",
  // "262d60a9487b08a4145e2481244ecbe5",
  // "876b41995ee80349f2aa0677d8f783b3",
  // "d9cabd75a66c7293095b1cbab1cce061",
  // "b2cd15771b2952348957f855c7ccd72a",
  // "affa97b85948b4150d23b7214b076ba5",
  // "4378c21b1ed821d7a23a1c4c7e62e1a2",
  // "d22a74d069b8f2132b926902177445ea",
  // "0e7a3295161d08020affa28338815c8e",
  // "f0d7a546cf83a7d0d3ab25fb9837d41a",
  // "19126a02bfd4a9e56a987615b3f4ecfb",
  // "3bb804a42450effcd879d0e1bd0ea246",
  // "45d21d17821bb165e392b70d51db3096",
  // "4f34c7a85a9dd5adefea3219e301335c",
  // "c30d80c53b1ceda6484cd072e7e40fb5",
  // "1e9f9514bc647e11ffd17206ae0bb9ef",
  // "0cb36982f28591e1f4a82ed01021a2c4",
  // "103fe0540a8de7d512242a86b4c4528e",
  // "54619baa72ad666d1a766dcc2d0930f8",
  // "7f74fc24634bd4d756411f0683268b96",
  // "f0314869eb66e00425751757dd3dd15a",
  // "7ec17e46d2fe0e3080f21142eda818d9",
  // "3d70f65df2d2b279c7d07ccafbd82bc8",
  // "2e4b14ea60c1944261bafde46ff38b03",
  // "a2a171320331b2c788d8d854766dcd22",
  // "f9984c1997298eb8fbd55be50e7d2020",
  // "aa0c09230634346373c4b5974b33de27",
  // "cf91015f4a02c4fcb6d1ec3ca3d6bb0b",
  // "6f3e962e3eb918438462c77ef0808f9b",
  // "49dc44d095acede252e9b734626d2c9b",
  // "fe7f0cea962fc2c61c9b256811738831",
  // "d86b9fad681050b3ae1c7b313eb3d535",
  // "c5bfc98e8abd54c336019458d9cc67a1",
  // "71fa3a163e8be933e6aa13d1b75c01a2",
  // "8a064d36b6823e022b9234c6191ca6fe",
  // "1cb2226998bef627e5b7ee497e004157",
  // "d348f84d419eebed8eb2602fae4c9bda",
  // "cf9991a90514a188b3bc69623d5cdc51",
  // "e325e5755005cf4cfe4ff50e1f7aa7c7",
  // "c46bfe5356a662e1ec016010a5084a0e",
  // "8d829d2267dcae4e87a62a59679756d7",
  // "5514bf24859eb8b3b6940ce5562ec9ed",
  // "dd36034b1fc014e43deb8abe9cb06c6a",
  // "02f158c4d77b4bcca7d83beb39b8eb3e",
  // "496252a1d2413a67929a330ace04a16b",
  // "6a1820950f036e52c724813b43d6b1e9",
  // "8b92f522b1663044f1756aa62fe900eb",
  // "4991343ec20db52ef489d9750381301f",
  // "6375aa2887cf43b144bbebba7b1fdf28",
  // "75010f28ed076adf98b3cacd2b287ee2",
  // "ccd0693d8004dcea02c7a916677edb21",
  // "a6d69b02d48fc6bc2ae0e5815f1e3d1f",
  // "6a81916f8fcda1098296824364101392",
  // "33b27b6012fe2c6a3657c020a950d4e9",
  // "22d452ca0b892666e1a744dd228f7c31",
  // "73f9c9543bd0027445f5b5c4f7f466f6",
  // "8ce5d127a74556cb985f8f09532d305e",
  // "a029f68267c67e628ddee3ef0ae03722",
  // "3f1361344caafec0a1c877ad6e912172",
  // "68e987515e0d35b82c78f397091ff33b",
  // "7272e9eca099599e7481eca833d465ec",
  // "b428ef2707cddf7e1f1fd5d0d244addb",
  // "904eba20e64c15097304b835e24f5bc1",
  // "1cd1be10168459dea63907e5b2f2f8a6",
  // "530af16ac5fad6cb7f7de9e1c2f74c04",
  // "0c494919a44a1ac5bb31f697a41fae3e",
  // "e3192e27e1b2cc8a9d3d3a27e61f65ad",
  // "5898f1dd495e5068dfa6cec2dedfe225",
  // "dd0fbd2aa8a7555ded8130bd0dcaa32f",
  // "a13989df32b6d2cf9086916baa68be7b",
  // "ae62a551c1c4471eafaeb9305c4a3cee",
  // "98163a7ecc10cb370493f9a17d093e0c",
  // "cf814aa182f22ec8182f288407c1e0d1",
  // "a54c14a73719d0662622f7bb7afa54c1",
  // "a6f122fe08144a047f53604e4922967c",
  // "834ffda910006023bf1fef51f0a4cae4",
  // "319c0b54c0a2208f809fbe66795f65c3",
  // "3020deb6d96bd3f0eabf46c5c45b253b",
  // "e8b37f7c43cfa35e991e3b8a7f8ce1e4",
  // "890bb6006856c42874d9f0ec68ba0215",
  // "bc07f2fad8b69c58f1984561567779bf",
  // "a4f66762733d2db2fabc2fdf09faf65e",
  // "b2c6f3e78e7042523b5a61d62811b79c",
  // "b94276292dc42e49b1a98de078d80c17",
  // "7bfb4d61298b9aa21b13ae85041e062b",
  // "26c93ad23e1c2b6fa6cb49c7c8a007f4",
  // "51a729dc338bac8e59673b95afe08015",
  // "cbb91ea262763e02cd4b88956008fe88",
  // "7929dc38e60f45775a3dd887527d1330",
  // "190d51bb7cb97f9c89cc1372c2788723",
  // "f4dd973621aabc6ee8a27d2474cc2e1f",
  // "d502304a169966881d2d8afc6f4b1e94",
  // "798cb4f558523b8fe0dcdd11c63cad7c",
  // "032d24032b45bee1d7fdc478d0566a14",
  // "6c0584b8ba2b3682a9a29c773c2f6933",
  // "141497e35cf2d40d963d494d7456f393",
  // "7532bb6b08c13ba6a9729d2b4289a3a2",
  // "98dab0df8a39c170c4ffac94aa882eb7",
  // "ce0c7bfddb4ff771ea7426f4aa914cf5",
  // "ba18ecb72addd09b43537b74bdfaa6e7",
  // "bc0518b3eceec4549f6db143c75be6ff",
  // "51ab1bb5686a9358ea6a43af4dff92c7",
  // "4b1dbfee55fa9b817b65918626e15796",
  // "67a62d28a10ea2b1cec8494c02b3784c",
  // "6a9f7aa6fa4841f6ea9e3524b0b3fe36",
  // "3ff3a414702f5241c60156e8de0fab19",
  // "5bb18611fb5ea236f2be3496c7ff2a74",
  // "2371ea071e5862184e442db9c8bdd668",
  // "565ddb2d191543a87b7ef1880109895f",
  // "ae25c1673806689de9930dc6d04d0d4f",
  // "ac187a4e9d8a90c9c20afd5e6f2f4bcd",
  // "ff83563a3f657b4d723516e346efffc3",
  // "a6ffce0fe892be6dcacb992735cf4efc",
  // "000da3b7a16b3bfd2bd4f403ac66253d",
  // "badd31618111b4b74c52190d5eb501c0",
  // "2f1a77cf3bfb9aa75825d2b3c1e479fa",
  // "b99a0a3ffe51c19f2d621d076787824d",
  // "1b246bb333da39f0c0aa73a1c3acff53",
  // "b135318fda373808a71eebdce4d2cd03",
  // "555a886b2b5f5a6d89d83b5b428f2cb5",
  // "994fb076a3119ef6a4b05ebdaa871886",
  // "7ad0b6ef287eb1a1cf7d88aeb116dd8a",
  // "922adac70e050490fbe36a45cfc3f719",
  // "5ffc482bdbd47ae5b0a1bb55000e3693",
  // "4bfbed1736edcb0fa4f68e378846a11a",
  // "251d28cd181fb8eab2fc689aafa97713",
  // "5fcffd10700dc53671fa8721bfd773a2",
  // "98ba6a6ad6331a02b5cde2ea9fe99ac1",
  // "c3fa02a02d2926f3ff6eb4f0f0825eac",
  // "1f34a614bf9014e3ec6eebefd137bfd2",
  // "e7700183423f739535272f26f527123e",
  // "c9280cc045de04a1826d8f93166adfdb",
  // "95271541b64e4d3ef8bb96f97862c508",
  // "5bda43d6b0219ccf5e2f2aa9718e8a2b",
  // "4035770dff4f18c980c3db0eb7b0166c",
  // "e89b26c52d44886ac005fb31c351785b",
  // "9203dbe636cde570157b7ceba8d0edb7",
  // "ac0fed442a923b108abd7036f03e8647",
  // "00a3b63ac9e49e1244cef56d214c75c9",
  // "61084d47aef543249c929db034223d9e",
  // "66cff85424004da0c9e75ed66c154de1",
  // "af29b7c5afae5791bceddd9d6ebe8d5f",
  // "1a39276bc6b5752ea933915e3de06062",
  // "6fdb4038cc1c0fb82ba9df013487378f",
  // "045c9b2c3ecaf581a999a2e2c5a1029a",
  // "c1c6535847437e4555e9a8ecaf4b52f7",
  // "7114e448187a69c712f6b30731e2c65f",
  // "31f62c344a1e09bfdb1a6c859452c0cc",
  // "1c40dbbfe2ae49ec855bbb18cd4203d8",
  // "c309403cf68ddee07ecc7303911a1324",
  // "91f9e2557de98f7ea5c9469ec9d0ea0e",
  // "767bc02eec2392c319e400d8165f864a",
  // "acc1a300453adf72d6a7dbe41b819fbe",
  // "a71fdabb447c51e79ca814d21ccccba2",
  // "d0646aedce642cbca64c80772bc45676",
  // "0be8e6db41782f744de6217d5275f1d2",
  // "84171e4b05201c4dee2c2885c1e5c522",
  // "c1177fd6aecaaea98dded1443412e88a",
  // "4aea3dc39ed269bd341ef524d45c05a6",
  // "05eaccd5cf808a8e03d6f099d6218611",
  // "d9a767463659853dcf5fa9fd454b3ba6",
  // "d8ef3a6c54b755663e31e648b6e16dd5",
  // "75d7fe96ca607b780a6a8c521561377f",
  // "ba12e1b01d61bddf6ca365ddae34c817",
  // "8e7ef5de6851c9c465d1299423f1cfeb",
  // "06e6b3d7ab97f2e08395213615836fbb",
  // "693f6e4f1cfe552b11d85d317f40e95c",
  // "f1706001f1525fb9dba314eb88c46f3c",
  // "256fd0419e66131ea640b3c7967d9f9d",
  // "975d02d89e7ae8c2e7cb8804ac45c2c5",
  // "1c7132353f41c20ddba99d99707a7a83",
  // "d5d215d6b85bae4690c2025ab4c87a0c",
  // "07ac7ed2ea9d0a6aaaa009c9d1d5c590",
  // "3918d50ebad3a770b31bc235ecaf9bfa",
  // "5a1885e8c89f2082bb237a1ae339a608",
  // "a6a676d425b20a419e9bb7504a661c3d",
  // "6fd36727e095e9f6ac959000735e62d6",
  // "274b01c2aff1f531bf9d453b797cb1c0",
  // "e1064475ac3707691c4e72e9f1fabbe3",
  // "b9533cf1258c3ea0c45c8a44832e8751",
  // "daa3a5c91fa8e8d542a860fd9510141a",
  // "fc41e553292c0e704ea2c44cb22890ef",
  // "42ffe34d678935ae9a6f78f1c48afc84",
  // "775ae5b3b0f2fb40be6405a15fe4634f",
  // "f3b16fd7b9a20508321e471a7d65354f",
  // "a8366d9de4dc7d8d1850f1da043fc114",
  // "2c86200a5956ecc9ce10bb26b9975e55",
  // "d8e37f0ebfb0ca9e835a77564dbfdfd0",
  // "122ff33ba01d2f1cab20ddf454066dfd",
  // "30a063198e21fbe6d8b6eec225b3cf60",
  // "115868d1d98a4b31730d356e2bfb4bb8",
  // "de916d789553606ca6cf472098c449fa",
  // "69c2f9f620eab3e3e91647ad472a4660",
  // "aaa4db3fecf5beaa2ffae3d5629ef739",
  // "a23cec6f3831958b830aeae814fe1cb6",
  // "54bb7047d01993f67f1853a8995c1e53",
  // "f719f427918cbd3c89b5fb5eb954844f",
  // "aecaf26afaf268272f3bf5e284f15675",
  // "1143e3c99583b836b216e9360e0181c1",
  // "6e2c427494684033c67a979561f9cf94",
  // "47470d0ba5b165e48d19a14fe62d9c21",
  // "d0c7af09f69c55042c7ccb43e026fe1a",
  // "4d9be2ffdff5168d347e772e05727881",
  // "fd0d8964b65f34cb591736291cfe8b16",
  // "c7a9db6de8d50185caa0980188b84972",
  // "52fc139a4cf3ed75198d979966e75757",
  // "375bc9276fb4674dc6d732c2814586f9",
  // "612794f2d1da8b0347e03b06b890a5ff",
  // "09cfe939f3793d2eb11097518453465f",
  // "0f1298a10a56db2b34a695a484b3c27f",
  // "692d52d6efbf6ea707a74897d7d7994f",
  // "bfb6f398d4f99ca4a8d71f697f31d861",
  // "073b97abf02f731159a0de0b7d6857f4",
  // "f03e61acd93d3e908e960b5e8e9a5ee7",
  // "41bc630e1c174bcea62ade592c9b7eef",
  // "2c08c45e46380b0b91cf8d6e4741ebf8",
  // "24d7593f73bce0719edf2625a4cb98ed",
  // "6acdbdd0ae2743f6348afade6d3ecc90",
  // "673a99fb52377109868b840a94e6b13e",
  // "095c41de1fdacadae376a142de5a8729",
  // "95b06c17b8a2aa3fc314100806ebbaf3",
  // "2839d2c0af223c773e073ea1308200ba",
  // "148012d5be8723977370a29c58d2e745",
  // "ecb13e2b58cb528242f62c597c184d99",
  // "975b7c70b8127434a7add7783a440889",
  // "805276e098376f0f632d298ac1a84955",
  // "07de226589abf76f02e1fec8538d32e6",
  // "f8db9ba8dfc92f2af78839c2aa9dad4f",
  // "fc5b1e59be9d0f3ea97f27df37145718",
  // "1e572372f99dc9c16d9f37a4bc44bbf4",
  // "e59b57e911433462f96690c56d824883",
  // "772b5bd8cfcc400494e43ecc6be20b4a",
  // "fa7149340903e7c5adec7c20d718163c",
  // "98d88678c2d2231ceaa7d0f0674bbede",
  // "16f6cdcc9ed490cbaf524ce3439a93d3",
  // "c4e7139a72a5f10dad3b55deac1a01a5",
  // "a7d6c2ba45664db285c3d44853193632",
  // "8db58f724392612fc5fe12daa2dafb6d",
  // "fdc03460989c9b29fce5bc62f88eef81",
  // "3ad2a5742d335cdb996519e3b0b75f81",
  // "9d1f9ae210d2f53690da627786e01077",
  // "33e6950aaa72d698eac9061b48d24fb7",
  // "9c434fa64f8e5300a02cdb99144bad00",
  // "ddd230192ca53103b6edae257656a37e",
  // "27fa99ac9f239b82cfc5d9a0ca4679ed",
  // "ed5a3e31507123920712a03d4a7d2d6f",
  // "509e48a921009706245a77ed2f2fa77b",
  // "6bb3f2a88ebf5dd0776f0701146ad361",
  // "2a2513c0cf60073f40feb345220ddb84",
  // "2ffe45cbfd6fbb5be443c06ab4b924c2",
  // "0c98d50478aa1215f72d7cf2d2688807",
  // "389df7eb8ec66cc8f2022175324521b8",
  // "2a75d1ccdc99e3f93a18531d5a23828b",
  // "4f3b638e356ea2443381cf72c8152c1c",
  // "a67e58c7f4eda343270e26b4d591c055",
  // "30e1a9f86b3aca59470ba2f97859c92a",
  // "b57bfe2927ed186b641abe0f01f201b5",
  // "f15553f2bf2b6119a12bad8e33ea102d",
  // "5b6a46edafd185b58d96cec22848bed7",
  // "7e4dae4b1f7d899c890c74e85469a45f",
  // "e932d5dd6fe0c936bf90286e6d65b10f",
  // "b9ce9949285c28249c0547cb512f8d43",
  // "15a48c971409844385fd1ec4d4f350b6",
  // "85c2fab2838b061f3d4266ce65cf1865",
  // "5c9be255b4cc0d1a7f566958aaefc936",
  // "a689d8c8ac4ff0a39bc281ac81d676aa",
  // "b9803287c61e52032ab97cdf6cefdf23",
  // "b8a36be2b962a416fd421ba2e67eeb8b",
  // "8342d74ba39c47fb225d5628399cdaae",
  // "73a11d70bd93902822a9f78fd6b05e5e",
  // "8577ef695d2dbcf0bd5afb21c6e0286b",
  // "5c969782162ecfa358920a30c52f239d",
  // "862ea21cc2daf1f1bb09f7877151cb46",
  // "6ad47aa0d523fe09aa452063cc926241",
  // "cde116e4ce1095f30b145aa2e88c2ed7",
  // "f356a0df55ab741f6835e5261c0210f5",
  // "87786cad1aa45ec6e7b5f5d60ee12982",
  // "5d77c7838dc18ac99a164f542c21686b",
  // "5ca0184023e0c95bcafbb103615f4d11",
  // "d11263fb056588f372261d2ba02f80d4",
  // "04a892544a8e05a5afe12862325f2c18",
  // "e7c25a0b1c13cd10a73a2aa57897f4ff",
  // "4609e1319bec1045fa7dcf753079186b",
  // "0080bc6a5f470a5b2a5d89a85b186115",
  // "e50f5e30589e968bf857835354e812db",
  // "79f6f5d07b376ccf60b36dd7f707f74b",
  // "5b9148b0bdefadee795e1284de2028b3",
  // "f6015714d5d875a8cb9f0abcadc79c08",
  // "bd9aa10c37aefa0fca9ee7d4cfa4a82d",
  // "ae4d555c316b9c39310d85175a144c04",
  // "74fcafa56ebe2e60d7037c3c7de7358e",
  // "93744cd99f2b227f1d6fea5286afd39a",
  // "0fa68baca4e4cd261ff65686d6fec5a3",
  // "0d8e1f642d9eca9066dd3f0b44c89598",
  // "0b9957aab8e3458d949d9855146c4915",
  // "91d805b25b9e98f6f9242dd3394e7c18",
  // "66267ef1a7bbb1e4cca94dcc65e7db2a",
  // "2776b1d3027a6fe168d2068c7f21c7ef",
  // "a06db1dd5915f31a7b829ae26ae7cd4a",
  // "f20bda5e6f06ec4880e745c57248bb54",
  // "3533bc8309951133e20579e78d341afa",
  // "0d11855867cbc44aad0ad9f05da7714c",
  // "44ee346cee0d41494d43800a4db293d8",
  // "024665ff2b2b5a21e0e595463b2c9cb2",
  // "f94d36970fde2607260d7b17750d2fe3",
  // "954028d64be5baed06e4410f70cc0d28",
  // "76ace8a139ef35bbb213cc095bbbe0dd",
  // "d23f46b4e6aa42c525fa884f755f5bf4",
  // "8f52f8ebcfce12f104b1fd107be5816d",
  // "dc96ed592a995ce035659db78cb7c52f",
  // "caf5941951a7037e22f65af9a2da15f3",
  // "09fcbebba8f206e459b310e487a330f0",
  // "1d435f77966be938b0af9f4be41c5e19",
  // "58876e98a18a157f72fa66c112237a6c",
  // "8ba6552815051b657fefa1763e52c212",
  // "becd815969b930ec9e770eb43d80e5b8",
  // "a45add25737ad54737b8994d2b811a52",
  // "fe1b9ee792780f1787e1f0ee5a308f08",
  // "a063b967d89d5dc7f2cf9c95cc2e90ce",
  // "b68c6f344959cba6ecf26c02e0983bb8",
  // "6d0b6f700216419a49a24b5ae297fff8",
  // "5ae188bccdc564ebc1294802d8d34524",
  // "a4c20e7e6393016894c2f773e627ecd5",
  // "05219e8b92683b227ea1f6d69fe29765",
  // "e7caf845f5ea7e7d303172d0fe78d2eb",
  // "2cd1b4bacddc913d39775ff0200c6ac2",
  // "8078b15b1910a91fa07d3ac038535d94",
  // "a031980a9d63284f94f4089c30b2fa85",
  // "093fb8803a1157cf0ad6e173537eefcd",
  // "4f9d3cf1579c87260ea6d59069a2b4c2",
  // "93cffb9abfea388070869670a9affbb5",
  // "ddaa526c3fbdc4dfb99fad56833bd053",
  // "6f80abdaa0e5b105436e19cbc747825a",
  // "32981065e9b50403a834242efcc1347a",
  // "1e8f2adcb778318c429b7ed4ccbbef11",
  // "6f082036694375ce900842a1a280c169",
  // "7e25ac209dffa59683fbfe4a980dc1d4",
  // "d0cd39d7c938adbe4cd73f6e52cf7e1b",
  // "339374df62e19ffb72905cc8535648c0",
  // "c1f3097f483665d548e7062129c872a0",
  // "ad2ca31829dc6b00e8a9d792bb45ba60",
  // "9c03ca0038e51ad1fe9968524503467b",
  // "3ac7f0630eff513e059767ba8388b336",
  // "b808326f048dc781ca8cf04b7f368369",
  // "dd1872287efefe7ec995de92c7ffa8d5",
  // "0d81a111626ccf78bc89d1bb4dd6dc5b",
  // "1865eff23cd5971695201614fbabf124",
  // "fdfbe308d0594d66739f6a9c4759e23c",
  // "177e83f4dbeed0af18fa577c7fe2193a",
  // "e1f84b9046b23ae6d2505bff7697e617",
  // "752029de421ff7c6bfac78da32c16ed2",
  // "804ca6628c99f7f6140b8a7825b68204",
  // "76b1b3f96368321da94ed1bdb26c6c37",
  // "237f9a33c7bb62e68150ac3a726f78e8",
  // "11fb716d7453ea12a3448db1b04eee20",
  // "25757cfa4fa8cab22aa94270e55e5bf0",
  // "c4c430116a734615bb5d92d97a1f1d53",
  // "aeed326da191d9c705d2b4758d101156",
  // "38bd75cb15b44f3f54d7b9f8d472033a",
  // "eb81644a760a47dce13db537cde1f60b",
  // "73575c3ea24c849bdd1ae23c9522f44d",
  // "81e144189dae724687a40251a423792e",
  // "f62a6c68367f57b380b95f6c82fb2f7e",
  // "5d1b0849c683183866fbe2757f5eb458",
  // "207f0697a7b56bc959d6371e68a3db68",
  // "e23ea824b234f20d6901baa0d7bcad46",
  // "6cb6f9907c950e6c4adcd32318a8b4a1",
  // "06927bd145113f6854c71796997a75ee",
  // "7c00aae382f0feabc0b74f372fde0e01",
  // "75fc0e58e2078e1caeb403d7854b1e97",
  // "8b0fbff45c3f076e6878a8e757e09859",
  // "eab32748c5397059a0c81c3b5cfab87b",
  // "f1477a96b278adabc045c01c1e320386",
  // "6c87000febb1f2f4d286c77971e691b2",
  // "4e04f6fc799b4142e5dbdab2f822c522",
  // "7dbacde1f696bda1ff29af41cdb70fb0",
  // "433177a55f730cd15edcb8358b938982",
  // "72166f729226cebe52ae986c2699c3a1",
  // "d2fb8f0e285233f6fb9cbd64e447dec4",
  // "613ae669e038f7ca9ad24e30b2f047b8",
  // "99c64b9f3af725908d7ad0362cad0ba6",
  // "a4981ce8fe83afb2476792d33b470674",
  // "f1e218d2e1afee4b1e22ac45808a3dd6",
  // "a2841d947947a979c2100a8bcffe31a5",
  // "b3db8e96571d80731cc7b985abd9fa68",
  // "2949497ce51196b6aa4dc840af44b6ed",
  // "b889ae9ac4695950c390fd9318b95789",
  // "e267b83168dcfb2ed3807c94adffd4ca",
  // "124601560acdf86fc8fe4451ccf7b997",
  // "c5cd1f4b16f0ff926bb98437b328cd52",
  // "7a0c5e970455c7445997abaa5ae18636",
  // "c738d4e46abd8f19e05df2f2a8084988",
  // "12574afe11f6738200da8d06be151061",
  // "dd91f2b62e0cffb2973ca35040d9fc5d",
  // "ddcbd5c438ce241fe4d13f29c169662d",
  // "41b9cffb7b942c69c3a304e13d7dc2c8",
  // "1c8c6c802072c994be5c807dcb7687d8",
  // "d8135c3a674c054540aa84e853676dbe",
  // "a3be57c8d5acebaa99f6ef6c4c4ae6f4",
  // "2beb701de78883f44b6f9828561c3c68",
  // "b7d2fc18f00f344a52ae0b8248e7a8d5",
  // "aa20f1988366e927fd7a10c6918b8897",
  // "6e79683a71fa148dfda18971540ca87a",
  // "e5d72a3b5b2e58b04d421463a2377907",
  // "b41e87bc31f073ce462b05d58fda08cb",
  // "6005c62f8bbb288b9f0f33382c055b56",
  // "7262fef18bbee50a04e817b2f4bc7a09",
  // "4933bbffb924676790c2719325c0fad4",
  // "331d43bdf1e6c266b9dafa19992e3007",
  // "38e62d54df0d4bd8cd4552f68b3519d0",
  // "5be526529c4bcaef901a6ff0b9a77087",
  // "fff4eb715674fca25c072b03580ad51f",
  // "92111dd0e07bd0977d83d85da8ff9771",
  // "f66c7ed4aa558d40040fa95799feb582",
  // "e3222264d2bd98992216d19006705de3",
  // "f41a9004dfaef45d6d7d8ea955c2e4f0",
  // "7f55ae47637a4d4087db2c8f1cc3507b",
  // "d69d3401d01a8ceed4549434e5ad9f40",
  // "d044ccb4ede2e4635692108022508b52",
  // "10599187dc25d70b3733e49027f9b2a1",
  // "4cc69753765b1c7ed86e971372216d25",
  // "9d52be6a9c40c68bfcc4b959cf698318",
  // "2be56e5c1d8d8232f8386bbebcf92f49",
  // "b6379885a695272dae3b2381b8459549",
  // "b39b58c8549ba5548da58bd299bbb587",
  // "d800fa8e1592f5660120dc7775d8d813",
  // "fa66749ec1f3349b5ab287340b55bef6",
  // "82b66362a4f49226e2fed6ab2dc8738a",
  // "f95d986f500bfb27d840f09ddbcd8e0d",
  // "c344dedf01a418f1b588b6289063896b",
  // "131fa4b16369a10b09ae0978d2be6988",
  // "969afddc639e499fda205a5358cde4ef",
  // "4cd322a3acdeda4faa3fdce48dd60194",
  // "f68dee070b657035d821b6c1ad1d9570",
  // "9abcf3abc259f9132e9ee058de6c1df3",
  // "aa30ced66ca3f50795d338ff4a98f3aa",
  // "07dc0ff5e21e073cd355cde1ed86d7ba",
  // "9bff26072afbddf3956a412616d51972",
  // "5e4da37f5e2145765ea1c3cb77118060",
  // "a5f3d4e4023dc85208c67e383c76bd3c",
  // "0124540e407bccda21fc5111cc28bf46",
  // "0bd54efc2a05462f68b9fdb32f0809af",
  // "4481b4ce4e73b3f7a877221033d1e78d",
  // "07d295f3c8e4d459d6bafb2eb9a6a314",
  // "fffedd5f7e2b01cefa0797bd98464ea9",
  // "4c2ca349b945da77230435caf6ed6fc1",
  // "ba39309a65d66704e1cc8e6add2a4ce4",
  // "85b0418ed5325c13bf4ba74d69f24eb4",
  // "53dd7d2a41bf1b7d0cc5a47a7d2b0466",
  // "c2de7589b00771bb325f0d63f0b9adc4",
  // "4e195e5b258f6944d7eee23f1c63f567",
  // "717c2ffac73c62480167231252e4fcb0",
  // "f473aea726c7414b5f1984d8456d0203",
  // "c6ac7f509ba8190be04a99391b1c7615",
  // "74ec0d1e232c65b8e62e7fe86562085f",
  // "1f2c732a3f5d24c5f4a5c4b1bf1072ac",
  // "e60a7fd03b77dc0405a53f77bb11ca1d",
  // "54e4ef855851327ed924915b3baed9c3",
  // "c308167049d0539f867a986e3d15553d",
  // "756103a43f1ec65248b939448bf3492d",
  // "34dc33715827e9201b7cf6f8febac503",
  // "4d0da46193613df7d86095f6d1c8950c",
  // "df6d305a3400266759aeb97d67f9e258",
  // "1cd0d785305b7d0bcc74dec8239e31af",
  // "c1f3f03dddc694f67dcfce22f7919a7b",
  // "7fc00c7854f16d5fadade0834c5959d8",
  // "aa992a076071bb47431ed41d5e04406a",
  // "e4d78133c1e453cb02b894d21683b7d8",
  // "655c0b1fb93c3c66855dfab544c1704c",
  // "012f5d8a8c8bffd75685a7ef2584e0ec",
  // "3e03c378a74d684f2f954bf80ccce903",
  // "53f5b237867d1e7dd0f163fc23793ac2",
  // "2cbe0f86339c7026550939a4ae0befb6",
  // "ad1fae6f9fc337fbaa645a07151ffcb9",
  // "62ec1daf42ce8f22a640c7c6e4f3e9b9",
  // "b75bf9abd8a1609a107c62c7e29f4037",
  // "a4fb1886597810372a2acaa7aadcc137",
  // "cb59e48e50b81b74668f7fcd3cf2d366",
  // "ff3377d3ab40078c8bd6ddabf2e538f1",
  // "083e896a6a9cb4c24c3b1a5d2aa45dc9",
  // "3be0895d583d5e3af6e399e915d718fc",
  // "79b65f9d046dd1c98043d55b8fce9b44",
  // "d4588c72efd6aecd8dad2aab0c405245",
  // "76d575adf8644c26df1b8a954fcee584",
  // "ebba7275963f60605f310506cb8aeb05",
  // "8a927382503ad40d78203ef5549d3b85",
  // "fc6475b65916fe46f8700288098e7b2f",
  // "3fbe81dd3dd2b3c4c9f708a88f17ee31",
  // "068464be8b1cf6110bcde95ed7cfe150",
  // "a1dc2f37d9f664f7401203f7cf79fcea",
  // "09be54dd8eddacf23e9f393ff1fff73e",
  // "59f97746a93025382b0c2ee3b56e82aa",
  // "552c37bda4e69bc08f06d7dc746e51d1",
  // "24f090b36880e4b76f355592a7c93a75",
  // "8c9d7f6c49ef5831c1b734fc7ab1fb2c",
  // "752f0394adb4966940c9c3bdc2ddcffb",
  // "26b1be5233855fc8b469ce79805aab12",
  // "f2e65a45c261289dc12d4d186f050881",
  // "a5699aff8e2a2ffe3051a1ac21bfc382",
  // "a08ede02f5b49351b10833c04abf7db4",
  // "fbef77af07c5b9e5ede225d820903a70",
  // "05912dc8a8342c7a6b277f3cc46380cd",
  // "42fc710b1f16594df9136d45b61753b4",
  // "c69e30ba5e77fd368dcc9a6c35435272",
  // "fee3685d4374f1c9a0b3341bb134c7b0",
  // "038d08954fdc4862824d57c3ddac7cf6",
  // "916f686217632c0e860ed179e9849ac7",
  // "887aa018f2dc3be372afa5aa63f75132",
  // "149ec152707dd4912a23ae7677ab0e0b",
  // "d5d116f227fe6f97fc43802082ff4247",
  // "2056d8d3de3b5e74f6c90014bc7a9724",
  // "3b7cdae8f54492fa57d5df84c4583cbb",
  // "75531bf3f63ba05832fae47ec5c6e2bd",
  // "1e99b213b5cdd1baabece03ac9957b07",
  // "d6db1bb2a3917f7dafb94516acaf76b8",
  // "77d8f1d981a91a13cf385c3ed0ca126e",
  // "42f945ac68140e33a959e26884e715c4",
  // "97dd99760e5ab3f7cc1720448c171a13",
  // "9d9eb1f6ffb381128416492c1fbd3d0a",
  // "1cba6551c8d5f2c4fc8bd9445289c74a",
  // "9e7ffd3e8c2fef6e78cb85902cdec58f",
  // "060cb173ba12fb7a2e0dc046e075049f",
  // "0baf5c07146cbaa2e16128b71d17c124",
  // "a72c658402cd37e6392df8e060b6e0e7",
  // "72080706290f97922c5df60b0864b480",
  // "7aec0f2bdc75c6bec29886f9b1e36482",
  // "b123aa59cbc0527d467d2b891536eb92",
  // "5a5ea04157ce4d020f65c3dd950f4fa3",
  // "fd9fa39517225d4f84ed9b3dcdd01961",
  // "a7e1982ef22a3c73833f5b9d77543f86",
  // "94a4b25ee06ebea962c8eccf0417f9f5",
  // "261fbb814ecf7b72bd4b98ac6be40c49",
  // "f69c036aa883347433f382d8a7376733",
  // "a957fe262eb91e2d9e60586ee5126e79",
  // "3ac7d6fe04b30124e9d8f8cfecc54a49",
  // "ff87fa1f2912ffbf85d71dc1214f3d88",
  // "c65db65e3ae54e83277d493bf7fa8322",
  // "bf98b2015aa711f5bb6d1f1199b59402",
  // "14c4b104f2e5e22e28630af960dd4cf2",
  // "edd268e1fc1c117793e9f86fc8726239",
  // "6dac963705ced66d092b0744bafe9437",
  // "a7151e50138f9b0b0b35e169aafaf9ba",
  // "3ad19f8b8fd227899fc3b8fb0e6ba18b",
  // "7ff7a88e676ff0610e0fc07d6a385ec9",
  // "fd035fc85a059377810f232810702e33",
  // "7c34b45c2f26c1107d4858f588a0cfab",
  // "3acfec5497f4ed1a40038847f42179ae",
  // "124601560acdf86fc8fe4451ccf7b997",
  // "71ed76480f365e5339cc2ef0a41d521b",
  // "bb4cb05f22d72bc706e8b45240ca5a32",
  // "47549f1a99903ede1fab701dd14f773f",
  // "3a79a948bd60ebd5ebfe0097d47efe63",
  // "db0d8cb05400b4f4e4e139a23f38a282",
  // "6bebd4c57f85431813d12a7ea8a51e96",
  // "3a610ad72febe139798cba1c1c7d35c5",
  // "8d7229872242222dd794357ebd8bac5e",
  // "d8119ea92a12a9dd332b73068718386f",
  // "22c588dc7f3193d48ed47a4de52d076c",
  // "8d587e32fc13146fecd024fd2e82d3fd",
  // "899d581f657ea52e89be08e8f6580b8c",
  // "64c9c62a105cf05925a07044f4b2b34c",
  // "7833de1a4f7a6b22e53354495f14cbef",
  // "312cbbd53524642b59f4dd5097075535",
  // "9cc7b8d6cb5611dfe66d0547d6aaa85f",
  // "51bf4b97ad7bb6273fa124cbee137f60",
  // "f44c907670d5f752a6fe89afd3558092",
  // "4503734f3f0a57fb5f6d76c43dae3874",
  // "e87a5e7678adb6cbb765cf02bec9ff6d",
  // "a990b0bb12acd6ca8d4135976fa01e20",
  // "938c1fd714337764c3b70fe86a72955f",
  // "71c6c35ba3c4d1136fe175962ee8e741",
  // "73b4087dc101cb98537fdcb1ffb54341",
  // "f9e61358abdd907e071ccee6cae2649f"
]);
