import React, { useState, useEffect } from "react";
import { View, ScrollView, TouchableOpacity, Text } from "react-native";
import { signInWithEmailAndPassword } from "firebase/auth/react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import School from "school/school";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import AlertModal from "src/components/AlertModal";
import NavBar from "src/components/navBar";
import AuthWidget from "src/components/auth/AuthWidget";

const { width } = Glob.get("dimensions");

export default function Login(props) {
  const { navigation } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSetSelfAsCreator, setShouldSetSelfAsCreator] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_login");
    Database.fetchAppMetadata().then((appMetadata) => {
      if (!appMetadata?.creator) {
        School.appWasBuiltOnThisDeviceAsync().then((wasBuiltOnThisDevice) => {
          const isOwner =
            wasBuiltOnThisDevice || !!Rex.getConfig()?.appIsUnclaimed;
          setShouldSetSelfAsCreator(isOwner);
        });
      }
    });
  }, []);

  const navigate = (screenName) => {
    navigation.push(screenName, props);
  };

  const portalsAreValid = (portals) =>
    portals !== "" &&
    portals !== "[]" &&
    portals !== null &&
    portals !== undefined &&
    !(portals.length <= 0);

  const logUserIn = () => {
    // If no owner has joined/claimed this app yet, mark it as claimed whenever the first person signs up
    if (Rex.getConfig()?.appIsUnclaimed) Database.markAppAsClaimed();
    if (shouldSetSelfAsCreator) {
      Analytics.logEvent("action_login_setSelfAsAppCreator");
      Database.setSelfAsAppCreator();
    }
    Rex.setLoginStatus(true);
    Analytics.setUserID();
    setIsLoading(false);
    navigate("root");
  };

  const handleUserExistsButNotInThisApp = () => {
    Rex.setLoginStatus(true);
    Analytics.setUserID();
    Analytics.logEvent("error_login_accountExistsButNotInThisApp", {
      email
    });
    setIsLoading(false);
    Util.alert(
      "Logged in! 🙌",
      `Now you can join ${Rex.getConfig()?.names?.nickname || "this app"}.`,
      [{ text: "OK", onPress: () => {} }]
    );
    navigate("welcome");
  };

  const login = async () => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(Firebase.getAuth(), email, password);
      Database.getUserPortals()
        .then((userPortalsValue) => {
          if (portalsAreValid(userPortalsValue)) {
            logUserIn();
          } else {
            Database.listenUserHomeOrder((homeOrderValue) => {
              if (portalsAreValid(homeOrderValue)) {
                Database.setUserPortals(JSON.parse(homeOrderValue));
                logUserIn();
              } else {
                logUserIn();
              }
            });
          }
        })
        .catch(handleUserExistsButNotInThisApp);
    } catch (e) {
      Analytics.logEvent("error_login_loginFailed", { email });
      setIsLoading(false);
      setAlert({
        title: "Login failed 😕",
        message:
          "Check your email/password and try again. Or if you've never created an account yet, go back and create an account first."
      });
    }
  };

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <NavBar
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <AuthWidget
          navigation={navigation}
          subtitle={`Welcome back to ${Rex.getConfig()?.names?.full ||
            "this app"}.`}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          isLoading={isLoading}
          showForgotPassword
          userIsAlreadyLoggedIn={false}
          onEmailAuth={() => {
            Analytics.logEvent("touch_login_loginButton", { email });
            login();
          }}
        />
        <TouchableOpacity
          activeOpacity={0.7}
          style={{ marginTop: 40, alignItems: "center" }}
          onPress={() => {
            Analytics.logEvent("touch_login_dontHaveAccountSignUp");
            navigation.goBack();
          }}
        >
          <Text style={{ color: "#868686" }}>
            Don't have an account yet?{" "}
            <Text
              style={{
                color: Rex.getConfig()?.colors?.button,
                fontWeight: "bold"
              }}
            >
              Sign up
            </Text>
          </Text>
        </TouchableOpacity>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
