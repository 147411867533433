// Based on https://stackoverflow.com/a/60682069
import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Keyboard,
  TouchableOpacity,
  Text
} from "react-native";
import Rex from "src/globalState";

const useKeyboard = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  function onKeyboardWillShow(e) {
    setKeyboardHeight(e.endCoordinates.height);
  }

  function onKeyboardWillHide() {
    setKeyboardHeight(0);
  }

  useEffect(() => {
    const showSubscription = Keyboard.addListener(
      "keyboardWillShow",
      onKeyboardWillShow
    );
    const hideSubscription = Keyboard.addListener(
      "keyboardWillHide",
      onKeyboardWillHide
    );
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return keyboardHeight;
};

export default function KeyboardToolbar({ components }) {
  const keyboardHeight = useKeyboard();

  if (keyboardHeight < 1) return null;
  if (!components || components.length < 1) return null;
  return (
    <View
      style={{
        position: "absolute",
        bottom: keyboardHeight,
        width: "100%",
        flex: 1,
        backgroundColor: "white",
        flexDirection: "row"
      }}
    >
      <ScrollView
        horizontal
        keyboardShouldPersistTaps="always"
        scrollIndicatorInsets={{ right: 1 }}
      >
        {components}
      </ScrollView>
      <View style={{ alignItems: "flex-end", justifyContent: "center" }}>
        <TouchableOpacity
          style={[
            {
              backgroundColor: Rex.getConfig()?.colors?.button,
              borderRadius: 1000,
              width: 36,
              height: 36,
              margin: 10,
              justifyContent: "center",
              alignItems: "center"
            }
          ]}
          onPress={() => Keyboard.dismiss()}
        >
          <Text style={{ fontSize: 18, color: "white", fontWeight: "bold" }}>
            ✓
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
