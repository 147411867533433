// TODO: This portal should be eliminated/improved
import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableHighlight,
  FlatList
} from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import School from "school/school";
import NavBar from "src/components/navBar";
import Analytics from "src/backend/analytics";

const { height, width } = Dimensions.get("window");
const NAVBAR_TEXT = "Buildings";

// info for reading xml file (google sheet)
const xmlURL = School.get("buildings url"); // NOTE: WOULD NORMALLY PUT IN FIREBASE BUT THIS WHOLE FILE IS TEMPORARY
// TODO: THIS DOESN'T WORK ANYMORE. FIX EVERYTHING.
// const { DOMParser } = require("xmldom");

// const parser = new DOMParser();
let xmlDoc = null;
const request = new XMLHttpRequest();
let responseXML = "";

let suggestionsSolicitation =
  "This portal will be replaced with a new, interactive version,";
suggestionsSolicitation +=
  " with many more of the buildings on campus. Click the button below to tell us";
suggestionsSolicitation += " which ones we should add first!";

request.onreadystatechange = (e) => {
  if (request.readyState !== 4) {
    return;
  }

  if (request.status === 200) {
    responseXML = request.responseText;
    // xmlDoc = parser.parseFromString(responseXML);
  } else {
    console.warn("error");
  }
};
if (xmlURL) {
  request.open("GET", xmlURL);
  request.send();
}

export default class Buildings extends Component {
  constructor(props) {
    super();
    const numItems = xmlDoc.getElementsByTagName("title").length;
    const dataRows = [];
    for (let i = 1; i < numItems; i += 1) {
      dataRows.push(xmlDoc.getElementsByTagName("title")[i].childNodes[0].data);
    }
    this.state = { dataRows };
    Analytics.logEvent("view_buildingHours");
  }

  navigate = (screenName) => {
    this.props.navigation.push(screenName, { navName: "web_SuggestionsBuildings" });
  };

  renderRow = ({ item }) => {
    console.log("item:");
    console.log(item);
    if (item === "OPEN NOW:" || item === "CLOSED NOW:") {
      return (
        <Text key={item} style={styles.listHeader}>
          {item}
        </Text>
      );
    }
    if (item.substring(0, 3) === " **") {
      return (
        <Text key={item} style={styles.listItemEmphasized}>
          {item}
        </Text>
      );
    }

    return (
      <Text key={item} style={styles.listItem}>
        {item}
      </Text>
    );
  };

  render() {
    return (
      <View style={{ backgroundColor: "white", flex: 1 }}>
        <NavBar navigation={this.props.navigation} text={NAVBAR_TEXT} />

        <View style={styles.mainContent}>
          <FlatList
            data={this.state.dataRows}
            renderItem={this.renderRow}
            keyExtractor={(item, index) => item + index}
          />
        </View>

        <Text style={styles.suggestionsSolicitationText}>
          {suggestionsSolicitation}
        </Text>

        <View style={styles.longButton}>
          <TouchableHighlight
            style={Style.get("button")}
            onPress={() => {
              this.navigate("webNav");
            }}
          >
            <Text style={Style.get("normalButtonText")}>Suggestions</Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Style for the main section that will hold all the of the content */
  mainContent: {
    marginTop: 0.022 * height,
    height: 0.6 * height,
    backgroundColor: "white",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column"
  },

  /* Style for the top text" */
  suggestionsSolicitationText: {
    fontSize: 0.027 * height,
    textAlign: "center",
    color: "black"
  },

  /* Styles for the list headers above the item */
  listHeader: {
    marginLeft: 0.027 * width,
    height: 0.033 * height,
    backgroundColor: Glob.get("light gray"),
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
    color: "black"
  },

  /* Styles for the emphasized list item */
  listItemEmphasized: {
    marginLeft: 0.027 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.024 * height,
    borderBottomColor: Glob.get("light gray"),
    textAlign: "center",
    fontWeight: "bold",
    color: "black"
  },

  /* Styles for the list item */
  listItem: {
    marginLeft: 0.027 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.024 * height,
    borderBottomColor: Glob.get("light gray"),
    color: "black"
  }
});
