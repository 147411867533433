// We should slowly deprecate this in favor of the new isLinkStyle option on the Button component

import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  Text,
  View,
  FlatList,
  StyleSheet,
  Platform
} from "react-native";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";

const { height, width } = Glob.get("dimensions");

export default function CustomList({
  dataRows, // This is primarily for backwards-compatibility with listWebNavs
  item,
  editable,
  isEditingAnItem,
  setIsEditingAnItem,
  onChangeItem,
  dataSourceRow,
  navigation,
  navigate = () => {}
}) {
  const [isEditing, setIsEditing] = useState(false);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;

  useEffect(() => {
    if (item?.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
    }
  }, []);

  const toggleEditing = () => {
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  const navigateToWebsite = (title, urlRaw) => {
    if (urlRaw) {
      let url = Util.textParser(urlRaw, { dataSourceRow }).join("");
      if (!url.includes("http")) url = `https://${url}`;
      navigate("webNav", {
        title: title ? Util.textParser(title, { dataSourceRow }).join("") : "",
        url
      });
    } else Util.alert("Oops!", "This item isn't linked to a website, sorry 😕");
  };

  const addRowItem = () => {
    onChangeItem({ ...item, rows: [...item?.rows, { title: "", url: "" }] });
  };

  const renderRow = ({ item: rowItem, index }) => {
    // This is primarily for backwards-compatibility with listWebNavs
    const title = rowItem.title || rowItem.navName;

    const onChangeRowItem = (key, value) => {
      const newRows = [...item?.rows];
      newRows[index] = { ...rowItem, [key]: value };
      onChangeItem({ ...item, rows: newRows });
    };

    const onDeleteRowItem = () => {
      const newRows = [...item?.rows];
      newRows.splice(index, 1);
      onChangeItem({ ...item, rows: newRows });
    };

    let onPress = () => navigateToWebsite(title, rowItem.url);
    if (editable && !isEditing) onPress = toggleEditing;
    else if (editable) onPress = () => {};

    return (
      <View>
        <TouchableOpacity
          underlayColor="transparent"
          activeOpacity={0.7}
          disabled={isEditingADifferentItem || (editable && isEditing)}
          onPress={onPress}
        >
          <View style={styles.rowSection}>
            <View style={{ ...styles.sectionText }}>
              <Text
                style={{
                  fontSize: 20,
                  color: Rex.getConfig()?.colors?.text
                }}
              >
                {Util.textParser(title, { dataSourceRow }).join("")}
              </Text>
            </View>
            {isEditing ? (
              <TouchableOpacity onPress={onDeleteRowItem}>
                <MaterialIcons
                  name="delete"
                  size={24}
                  style={styles.sectionButton}
                  color={Glob.get("dangerRed")}
                />
              </TouchableOpacity>
            ) : (
              <Entypo
                name="chevron-thin-right"
                size={20}
                color={Rex.getConfig()?.colors?.text}
                style={styles.sectionButton}
              />
            )}
          </View>
          {isEditing && (
            <View style={{ marginLeft: 30, marginTop: -20, marginBottom: 15 }}>
              <InputBox
                key="title"
                header="Title"
                value={title}
                onChangeText={(text) => onChangeRowItem("title", text)}
              />
              <InputBox
                key="image"
                header="Link"
                onChangeText={(url) => onChangeRowItem("url", url)}
                value={rowItem.url}
                navigation={navigation}
                browseForLinkOptions={{
                  show: true,
                  originalURL: "https://www.google.com",
                  onPickURL: (url) => onChangeRowItem("url", url),
                  disabled: false
                }}
              />
            </View>
          )}
        </TouchableOpacity>
        <View key={index} style={styles.separator} />
      </View>
    );
  };

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5,
    marginBottom: 80
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  const data = dataRows || item?.rows || [];

  return (
    <View style={isEditing ? editingStyle : {}}>
      {isEditing && data?.length > 1 && (
        <View>
          <Button
            key="doneButton"
            text="✓ Done"
            onPress={toggleEditing}
            style={{ backgroundColor: "#555" }}
            small
          />
        </View>
      )}
      <FlatList
        style={styles.section}
        data={data}
        renderItem={renderRow}
        keyExtractor={(rowItem, index) => rowItem + index}
      />
      {isEditing && (
        <View style={{ marginTop: 15 }}>
          <Button
            text="Add a Link"
            icon="plus"
            onPress={addRowItem}
            style={{
              backgroundColor: "#555",
              width: 0.8 * width
            }}
            small
          />
          <Button
            key="doneButton"
            text="✓ Done"
            onPress={toggleEditing}
            style={{ backgroundColor: "#555" }}
            small
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for the section of the list view */
  rowSection: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "flex-end",
    marginRight: 20
  },

  /* Style for the text of the section of the list view */
  sectionText: {
    flex: 1,
    marginTop: 15,
    marginBottom: 15
  },

  /* Style for the section separators */
  separator: {
    width: 0.9 * width,
    height: 1,
    alignSelf: "flex-end",
    marginRight: 20,
    backgroundColor: Glob.get("very light gray")
  },

  /* Style for the section's button */
  sectionButton: {
    flex: 0,
    height: 0.03 * height,
    marginRight: 0.04 * width,
    marginTop: 15,
    marginBottom: 15
  }
});
