import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import MessageHistory from "src/components/MessageHistory";
import HeaderAnimation from "src/components/MessageHistoryHeaderAnimation";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

export default function NotificationHistory({ navigation }) {
  const [loaded, setLoaded] = useState(false);
  const [history, setHistory] = useState(null);
  const [justUpdated, setJustUpdated] = useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [allAccountTypes, setAllAccountTypes] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [alert, setAlert] = useState(null);

  const loadedRef = useRef();
  useEffect(() => {
    loadedRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    Analytics.logEvent("view_notificationHistory");
    Database.subscribeToGlobalNotifications((data) => {
      const { all } = data || {};
      const formattedHistory = {};
      Object.keys(all || {}).forEach((key) => {
        const original = all[key];
        formattedHistory[key] = {
          ...original,
          message: `${original.title}${
            original.body ? `\n${original.body}` : ""
          }`
        };
      });
      setHistory(formattedHistory || {});
      if (loadedRef.current) {
        setJustUpdated(true);
        setTimeout(() => setJustUpdated(false), 500);
      } else {
        setLoaded(true);
      }
    });
    // groups
    Database.getAllUserGroups().then((groups) => setAllGroups(groups || {}));
    // account types
    Database.fetchAllAccountTypeDetails().then((types) =>
      setAllAccountTypes(types || {})
    );
    // users
    Database.fetchAllUsers().then((users) => {
      // convert array to object
      const usersObj = users.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
      }, {});
      setAllUsers(usersObj);
    });

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromGlobalNotifications();
    };
  }, []);

  const numberOfMessages = Object.keys(history || {}).length || 0;
  const numberOfDeliveries =
    Object.values(history || {}).reduce(
      (acc, msg) => acc + (msg.numberUsersSentTo || 0),
      0
    ) || 0;

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Notification History" />
      <ScrollView
        contentContainerStyle={{ width, alignItems: "center" }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <HeaderAnimation />
        <View style={{ width: width * 0.9 }}>
          {!loaded ? (
            <ActivityIndicator size="large" style={{ marginTop: 80 }} />
          ) : (
            <>
              {numberOfMessages > 1 && (
                <Text
                  style={[
                    Style.get("subheaderText"),
                    { marginTop: 20, marginBottom: 40 }
                  ]}
                >
                  We've delivered{" "}
                  <Text style={{ fontWeight: "bold", color: "black" }}>
                    {numberOfDeliveries.toLocaleString()} messages
                  </Text>{" "}
                  to your app's members
                  {numberOfMessages > 1
                    ? ` for ${numberOfMessages.toLocaleString()} separate notifications you've sent`
                    : ""}
                  .
                </Text>
              )}
              <MessageHistory
                history={history}
                navigation={navigation}
                latestMessageJustUpdated={justUpdated}
                isNotifications
                allGroups={allGroups}
                allAccountTypes={allAccountTypes}
                allUsers={allUsers}
                setAlert={setAlert}
              />
            </>
          )}
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  },
  historyRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  }
});
