// Room QR codes need to be formatted as: `{"room": "Room Name"}`

import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Alert } from "react-native";
import moment from "moment";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as SecureStore from "expo-secure-store";
import { Ionicons } from "@expo/vector-icons";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Util from "../utility";

const pageName = "Room Check-in";

export default function RoomCheckin({ navigation }) {
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [roomName, setRoomName] = useState(null);
  const [checkinTime, setCheckinTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    Analytics.logEvent("view_roomcheckin");
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    })();
    Database.listenUserFirstName((first) => {
      Database.listenUserLastName((last) => {
        setFullName(`${first} ${last}`);
      });
    });
    Database.listenUserEmail((userEmail) => {
      setEmail(userEmail);
    });
    SecureStore.getItemAsync("roomcheckinRoom").then((storedRoom) => {
      SecureStore.getItemAsync("roomcheckinTime").then((storedTime) => {
        setRoomName(storedRoom);
        setCheckinTime(storedTime);
      });
    });
    setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
  }, []);

  const onBarCodeScanned = ({ data }) => {
    setScanned(true);

    if (data.slice(0, 8) !== '{"room":') {
      Alert.alert("Sorry! Invalid QR code.", "", [
        { text: "OK", onPress: () => setScanned(false) }
      ]);
      return;
    }

    const { room } = JSON.parse(data);
    const now = moment().format("x");
    const fields = [fullName, email, "Checked in", room];
    Util.fillStaticGoogleForm("room check-in", fields);
    setRoomName(room);
    setCheckinTime(now);
    SecureStore.setItemAsync("roomcheckinRoom", room);
    SecureStore.setItemAsync("roomcheckinTime", now);
  };

  const onCheckout = () => {
    const fields = [fullName, email, "Checked out", roomName];
    Util.fillStaticGoogleForm("room check-in", fields);
    SecureStore.deleteItemAsync("roomcheckinRoom");
    SecureStore.deleteItemAsync("roomcheckinTime");
    setRoomName(null);
    setCheckinTime(null);
    setScanned(false);
  };

  if (roomName && checkinTime) {
    const checkinDateTime = moment(checkinTime, "x");
    const checkinDurationSeconds = currentTime.diff(checkinDateTime, "seconds");
    const checkinDurationString = Util.secondsToFriendlyString(
      checkinDurationSeconds
    );
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={pageName} />
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around"
          }}
        >
          <Text
            style={[styles.roomName, { color: Rex.getConfig()?.colors?.text }]}
          >
            Welcome to{"\n"}
            {roomName}!
          </Text>
          <Text style={{ fontSize: 20, textAlign: "center" }}>
            You have been here for{"\n"}
            {checkinDurationString}
          </Text>
          <TouchableOpacity
            activeOpacity={0.6}
            style={Style.get("button")}
            onPress={onCheckout}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                adjustsFontSizeToFit
                numberOfLines={1}
                style={Style.get("normalButtonText")}
              >
                Check Out
              </Text>
            </View>
          </TouchableOpacity>
          <Text>
            You checked in at {checkinDateTime.format("hh:mm A")} on{" "}
            {checkinDateTime.format("dddd, MMMM D")}
          </Text>
        </View>
      </View>
    );
  }

  if (hasPermission === null) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={pageName} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Requesting camera permission...
          </Text>
        </View>
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={pageName} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Please enable camera permission in order to use this feature.
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={pageName} />
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
        style={{ flex: 1 }}
      >
        <View style={styles.qrIcon}>
          <Ionicons name="scan" size={300} color="white" />
          <Text style={styles.qrHelperText}>
            Scan a room's QR code to check in.
          </Text>
        </View>
      </BarCodeScanner>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  roomName: {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center"
  },
  qrIcon: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center"
  },
  qrHelperText: {
    fontSize: 24,
    color: "white",
    width: "60%",
    textAlign: "center"
  },
  providePermissionsText: {
    fontSize: 24,
    width: "60%",
    textAlign: "center"
  }
});
