import React, { Component } from "react";
import { View } from "react-native";
import NavBar from "src/components/navBar";
import CustomList from "src/components/customList";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";

export default class ListWebNavs extends Component {
  constructor(props) {
    super(props);
    this.state = { dataRows: null };
    const logName = "view_".concat(this.props?.route?.params?.navName || "");
    if (!props.portalOverride) Analytics.logEvent(logName);
  }

  UNSAFE_componentWillMount() {
    const { portalOverride } = this.props;
    const portalName =
      (!!portalOverride && portalOverride.name) || this.props?.route?.params?.navName;
    Database.getPortalContent(portalName, (value) => {
      // value is a JSON object like {"banner": "httpUrl...", "canvas": "httpUrl...", etc}
      const webNavsList = [];
      Object.entries(value).forEach(([navNameVal, urlVal]) =>
        webNavsList.push({ navName: navNameVal, url: urlVal })
      );
      this.setState({ dataRows: webNavsList });
    });
  }

  render() {
    const { portalOverride, navigation } = this.props;
    const { dataRows } = this.state;
    const portalTitle =
      (!!portalOverride && portalOverride.title) ||
      this.props?.route?.params?.txtName;
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <NavBar navigation={navigation} text={portalTitle} />
        <CustomList
          dataRows={dataRows}
          navigation={navigation}
          navigate={(navName, newProps = {}) =>
            navigation.push(navName, { ...this.props, ...newProps })
          }
        />
      </View>
    );
  }
}
