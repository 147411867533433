import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import PostSkeleton from "src/components/activityFeeds/PostSkeleton";
import Checkbox from "src/components/Checkbox";
import Icon from "src/components/Icon";
import Dropdown from "./Dropdown";
import HelpText from "./HelpText";

const QUESTION_INPUT_TYPES = {
  text: {
    text: "Short answer",
    icon: "33afedab-b6e7-4b2a-aacc-7e66d6d15a5f"
  },
  number: {
    text: "Number",
    icon: "5bfb1d90-ad32-473a-a8f4-7febe4206ade"
  },
  dropdown: {
    text: "Dropdown",
    icon: "fc1a4221-05fa-47c1-864a-b6268101cfdb"
  }
};

const SUBSCRIPTION_INTERVALS_DROPDOWN_OPTIONS = [
  {
    text: "day",
    value: "day"
  },
  {
    text: "week",
    value: "week"
  },
  {
    text: "month",
    value: "month"
  },
  {
    text: "year",
    value: "year"
  }
];

const centsToDollarString = (cents) => {
  return `${(cents / 100 || 0).toFixed(2)}`;
};

const PillButton = ({
  text,
  onPress,
  icon,
  style = {},
  textStyle = {},
  iconSize = 20
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        backgroundColor: "#e0e0e0",
        borderRadius: 100,
        paddingHorizontal: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        height: 30,
        ...style
      }}
    >
      {icon && <Icon icon={icon} color="gray" size={iconSize} />}
      <Text
        style={{
          color: "gray",
          marginLeft: 5,
          fontWeight: "bold",
          ...textStyle
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const AnswerableProductQuestions = ({
  question,
  index,
  value,
  onChangeValue
}) => {
  if (question.inputType === "dropdown") {
    return (
      <Dropdown
        key={index}
        header={question.question}
        showAsOptional={!question.isRequired}
        value={value}
        items={question.dropdownOptions.map((option) => ({
          value: option,
          text: option
        }))}
        onSelect={onChangeValue}
      />
    );
  }
  return (
    <InputBox
      key={index}
      header={question.question}
      showAsOptional={!question.isRequired}
      onChangeText={onChangeValue}
      value={value}
      style={{ marginTop: 15 }}
      keyboardType={question.inputType === "number" ? "numeric" : "default"}
      isInvalid={
        value.length > 0 && question.inputType === "number" && !Number(value)
      }
      placeholder={question.inputType === "number" ? "Enter a number" : ""}
    />
  );
};

const EditableProductQuestion = ({
  question,
  index,
  productQuestions,
  onChangeProductQuestions
}) => {
  const [isPickingInputType, setIsPickingInputType] = useState(false);

  const addDropdownOption = () => {
    const updatedQuestions = [...productQuestions];
    if (!updatedQuestions[index].dropdownOptions) {
      updatedQuestions[index].dropdownOptions = [];
    }
    updatedQuestions[index].dropdownOptions.push("");
    onChangeProductQuestions(updatedQuestions);
  };

  return (
    <View
      key={question.id}
      style={{
        marginBottom: 20,
        flexDirection: "column",
        backgroundColor: "#f3f3f3",
        paddingHorizontal: 8,
        paddingBottom: 10,
        borderRadius: 8
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ flex: 1, alignItems: "center" }}>
          <InputBox
            placeholder="Enter question to ask customer"
            value={question.question}
            onChangeText={(text) => {
              const updatedQuestions = [...productQuestions];
              updatedQuestions[index].question = text;
              onChangeProductQuestions(updatedQuestions);
            }}
            flexibleWidth
            style={{ flex: 1 }}
          />
        </View>
        <TouchableOpacity
          onPress={() => {
            const updatedQuestions = productQuestions.filter(
              (_, i) => i !== index
            );
            onChangeProductQuestions(updatedQuestions);
          }}
          style={{ padding: 10 }}
        >
          <Icon
            icon="ec79581a-c8a5-4021-a5c1-82cc8e51ea00"
            color="gray"
            size={20}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 5
        }}
      >
        <View>
          {isPickingInputType ? (
            <View
              style={{
                width: "100%",
                backgroundColor: "white",
                paddingHorizontal: 10,
                borderRadius: 8
              }}
            >
              <Dropdown
                value={question.inputType}
                items={Object.entries(QUESTION_INPUT_TYPES).map(
                  ([key, { text }]) => ({
                    value: key,
                    text
                  })
                )}
                onSelect={(value) => {
                  const updatedQuestions = [...productQuestions];
                  updatedQuestions[index].inputType = value;
                  onChangeProductQuestions(updatedQuestions);
                  setIsPickingInputType(false);
                  if (value === "dropdown" && !question.dropdownOptions)
                    addDropdownOption();
                }}
                startOpen
                onCollapse={() => setIsPickingInputType(false)}
                flexibleWidth
              />
            </View>
          ) : (
            <PillButton
              text={
                question.inputType
                  ? QUESTION_INPUT_TYPES[question.inputType].text
                  : "Set Type"
              }
              onPress={() => setIsPickingInputType(true)}
              icon={
                question.inputType
                  ? QUESTION_INPUT_TYPES[question.inputType]?.icon
                  : null
              }
            />
          )}
        </View>
        {!isPickingInputType && (
          <View style={{ justifyContent: "flex-end" }}>
            <PillButton
              text={question.isRequired ? "Required" : "Optional"}
              icon={
                question.isRequired
                  ? "736e8e38-cfa7-4f9f-aa8a-9376b56f9573"
                  : null
              }
              iconSize={12}
              onPress={() => {
                const updatedQuestions = [...productQuestions];
                updatedQuestions[index].isRequired = !question.isRequired;
                onChangeProductQuestions(updatedQuestions);
              }}
            />
          </View>
        )}
      </View>
      {question.inputType === "dropdown" && !isPickingInputType && (
        <View
          style={{
            marginTop: 10,
            marginLeft: 0,
            borderWidth: 0.5,
            borderColor: "gray",
            borderRadius: 5,
            padding: 10,
            backgroundColor: "white"
          }}
        >
          {question.dropdownOptions?.map((option, optionIndex) => (
            <View
              key={optionIndex}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
                marginRight: 25
              }}
            >
              <InputBox
                placeholder="Enter dropdown option"
                value={option}
                onChangeText={(text) => {
                  const updatedQuestions = [...productQuestions];
                  updatedQuestions[index].dropdownOptions[optionIndex] = text;
                  onChangeProductQuestions(updatedQuestions);
                }}
                openStyle
                flexibleWidth
              />
              <TouchableOpacity
                onPress={() => {
                  const updatedQuestions = [...productQuestions];
                  updatedQuestions[index].dropdownOptions = updatedQuestions[
                    index
                  ].dropdownOptions.filter((_, i) => i !== optionIndex);
                  onChangeProductQuestions(updatedQuestions);
                }}
                style={{ padding: 10 }}
              >
                <Icon
                  icon="aa149ca4-4c0d-4a50-b442-5541da551067"
                  color="gray"
                  size={20}
                />
              </TouchableOpacity>
            </View>
          ))}
          <PillButton
            text="Add Dropdown Option"
            icon="cde42390-8051-4509-9bfb-462ff507af63"
            onPress={addDropdownOption}
          />
        </View>
      )}
    </View>
  );
};

export default function Product({
  productID,
  name,
  price,
  description,
  shippable,
  adjustableQuantity = false,
  subscriptionInterval = null,
  customQuestions,
  image,
  isEditing = false,
  isNew = false,
  isEditingAProduct,
  onSave = () => {},
  onArchive = () => {},
  onCancel = () => {},
  onPressPurchase = null,
  isLoadingProduct = false,
  isLoadingPurchaseButton = false,
  shrinkText = false
}) {
  const originalPriceString = centsToDollarString(price);
  const [productName, setProductName] = useState(name);
  const [productDescription, setProductDescription] = useState(description);
  const [productIsShippable, setProductIsShippable] = useState(shippable);
  const [productPrice, setProductPrice] = useState(originalPriceString);
  const [productQuestions, setProductQuestions] = useState(
    customQuestions || []
  );
  const [showCustomQuestions, setShowCustomQuestions] = useState(false);
  const [questionResponses, setQuestionResponses] = useState({});
  const [productAdjustableQuantity, setProductAdjustableQuantity] = useState(
    adjustableQuantity
  );
  const [
    productSubscriptionInterval,
    setProductSubscriptionInterval
  ] = useState(subscriptionInterval);
  const [changedProductQuestions, setChangedProductQuestions] = useState(false);

  useEffect(() => {
    setProductPrice(centsToDollarString(price));
  }, [price]);
  useEffect(() => {
    setProductIsShippable(shippable);
  }, [shippable]);
  useEffect(() => {
    setProductAdjustableQuantity(adjustableQuantity);
  }, [adjustableQuantity]);
  useEffect(() => {
    setProductSubscriptionInterval(subscriptionInterval);
  }, [subscriptionInterval]);

  const fontSizeMultiplier = shrinkText ? 0.8 : 1;
  const isEditingADifferentProduct = isEditingAProduct && !isEditing;
  const hasBeenEdited =
    name !== productName ||
    description !== productDescription ||
    shippable !== productIsShippable ||
    adjustableQuantity !== productAdjustableQuantity ||
    originalPriceString !== productPrice ||
    subscriptionInterval !== productSubscriptionInterval ||
    changedProductQuestions;
  const canSave = productName && productPrice && hasBeenEdited;

  const toggleProductIsSubscription = () => {
    setProductSubscriptionInterval(
      productSubscriptionInterval ? null : "month"
    );
  };

  if (isLoadingProduct)
    return (
      <View style={styles.productContainer}>
        <PostSkeleton />
      </View>
    );

  const imageSource = image
    ? { uri: image }
    : Glob.get(productIsShippable ? "cardboardBoxClosed" : "shoppingCart");

  const requiredQuestionsAreUnanswered =
    showCustomQuestions &&
    customQuestions.some(
      (question) =>
        question.isRequired &&
        (!questionResponses[question.question] ||
          questionResponses[question.question].trim() === "")
    );

  return (
    <View
      style={{
        ...styles.productContainer,
        shadowRadius: isEditing ? 20 : 5,
        opacity: isEditingADifferentProduct ? 0.3 : 1
      }}
    >
      <View
        style={{
          flexDirection: "row"
        }}
      >
        <Image
          source={imageSource}
          style={{
            width: 80,
            height: 80,
            borderRadius: 15,
            resizeMode: "cover"
          }}
        />
        <View style={{ flex: 1, marginLeft: 15 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={{ flex: 3, marginRight: 2 }}>
              {isEditing ? (
                <InputBox
                  placeholder="Title"
                  autoCapitalize="words"
                  flexibleWidth
                  openStyle
                  value={productName}
                  onChangeText={setProductName}
                  isInvalid={hasBeenEdited && !productName}
                />
              ) : (
                <Text
                  style={{
                    fontSize: 18 * fontSizeMultiplier,
                    fontWeight: "bold"
                  }}
                >
                  {name}
                </Text>
              )}
            </View>
            <View style={{ flex: 2, marginLeft: 2, alignItems: "flex-end" }}>
              {isEditing ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 15
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18 * fontSizeMultiplier,
                      fontWeight: "bold",
                      marginTop: 10
                    }}
                  >
                    $
                  </Text>
                  <InputBox
                    placeholder="Price"
                    flexibleWidth
                    keyboardType="numeric"
                    openStyle
                    value={productPrice}
                    onChangeText={setProductPrice}
                    isInvalid={hasBeenEdited && !productPrice}
                  />
                </View>
              ) : (
                <>
                  <Text
                    style={{
                      fontSize: 18 * fontSizeMultiplier,
                      fontWeight: "bold",
                      marginLeft: 15
                    }}
                  >
                    ${productPrice}
                  </Text>
                  {productSubscriptionInterval ? (
                    <Text style={{ fontSize: 12 * fontSizeMultiplier }}>
                      per {productSubscriptionInterval}
                    </Text>
                  ) : null}
                </>
              )}
            </View>
          </View>
          {isEditing ? (
            <>
              <InputBox
                placeholder="Description"
                flexibleWidth
                multiline
                openStyle
                value={productDescription}
                onChangeText={setProductDescription}
              />
              <Checkbox
                style={{ marginTop: 15 }}
                checked={productIsShippable}
                text="Require address for shipping"
                onChange={setProductIsShippable}
              />
              <Checkbox
                checked={productAdjustableQuantity}
                text="Let customers specify quantity"
                onChange={setProductAdjustableQuantity}
              />
              <Checkbox
                style={{ marginBottom: 15 }}
                checked={!!productSubscriptionInterval}
                text="This is a subscription"
                onChange={toggleProductIsSubscription}
              />
            </>
          ) : (
            <Text style={{ fontSize: 16 * fontSizeMultiplier, color: "gray" }}>
              {description}
            </Text>
          )}
        </View>
      </View>
      {isEditing && !!productSubscriptionInterval && (
        <View>
          <Text style={{ fontWeight: "bold", marginTop: 15 }}>
            Subscription Details
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 16,
                marginLeft: 15,
                marginRight: 5,
                marginTop: 16
              }}
            >
              Bill the customer every
            </Text>
            <View style={{ maxWidth: 120 }}>
              <Dropdown
                value={productSubscriptionInterval}
                items={SUBSCRIPTION_INTERVALS_DROPDOWN_OPTIONS}
                flexibleWidth
                onSelect={setProductSubscriptionInterval}
              />
            </View>
          </View>
          <HelpText
            text={`If a customer purchases ${
              productName ? `"${productName}"` : "this"
            }, they will be charged${
              productPrice ? ` $${productPrice}` : ""
            } once every ${productSubscriptionInterval} until they cancel.`}
            style={{ marginHorizontal: 10, marginBottom: 10 }}
          />
        </View>
      )}
      <View>
        {isEditing && (
          <>
            {productQuestions.length > 0 && (
              <Text
                style={{ fontWeight: "bold", marginTop: 15, marginBottom: 10 }}
              >
                Questions to Ask Customer
              </Text>
            )}
            <View style={{ marginTop: 0 }}>
              {productQuestions.map((question, index) => (
                <EditableProductQuestion
                  key={index}
                  index={index}
                  question={question}
                  productQuestions={productQuestions}
                  onChangeProductQuestions={(updatedQuestions) => {
                    setProductQuestions(updatedQuestions);
                    setChangedProductQuestions(true);
                  }}
                />
              ))}
              <TouchableOpacity
                onPress={() => {
                  setProductQuestions([
                    ...productQuestions,
                    {
                      question: "",
                      inputType: "text",
                      isRequired: true,
                      id: Date.now()
                    }
                  ]);
                }}
                style={{
                  marginTop: 10,
                  backgroundColor: "#f3f3f3",
                  borderRadius: 5,
                  padding: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon
                  icon="cde42390-8051-4509-9bfb-462ff507af63"
                  color="gray"
                  size={20}
                />
                <Text
                  style={{
                    color: "gray",
                    marginLeft: 10,
                    fontWeight: "bold"
                  }}
                >
                  {productQuestions.length < 1
                    ? "Ask the Customer Questions"
                    : "Add Question"}
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
      {!isEditing && !onPressPurchase && productIsShippable && (
        <Text
          style={{
            fontSize: 12 * fontSizeMultiplier,
            color: "gray",
            marginTop: 10,
            marginBottom: -10,
            alignSelf: "flex-end"
          }}
        >
          Shippable
        </Text>
      )}
      {!isLoadingProduct && !!onPressPurchase && (
        <>
          {!!showCustomQuestions && (
            <View style={{ marginTop: 10 }}>
              {customQuestions &&
                customQuestions
                  .filter((question) => question.question)
                  .map((question, index) => (
                    <AnswerableProductQuestions
                      key={index}
                      question={question}
                      index={index}
                      value={questionResponses[question.question] || ""}
                      onChangeValue={(value) =>
                        setQuestionResponses({
                          ...questionResponses,
                          [question.question]: value
                        })
                      }
                    />
                  ))}
            </View>
          )}
          {isLoadingPurchaseButton ? (
            <ActivityIndicator size="large" style={{ marginTop: 15 }} />
          ) : (
            <Button
              text="Purchase"
              style={{
                marginTop: 25,
                marginBottom: -10,
                opacity: requiredQuestionsAreUnanswered ? 0.3 : 1
              }}
              outline
              small
              disabled={requiredQuestionsAreUnanswered}
              onPress={() => {
                if (
                  customQuestions &&
                  customQuestions.length > 0 &&
                  !showCustomQuestions
                ) {
                  setShowCustomQuestions(true);
                } else {
                  onPressPurchase(questionResponses);
                }
              }}
            />
          )}
        </>
      )}
      {isEditing && (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            marginTop: 25,
            marginBottom: -10
          }}
        >
          {!isNew && (
            <Button
              text="Delete"
              textStyle={{ fontWeight: "bold" }}
              flat
              small
              outline
              flexibleWidth
              style={{ flex: 1 }}
              color={Glob.get("dangerRed")}
              onPress={onArchive}
            />
          )}
          <Button
            text="Cancel"
            textStyle={{ fontWeight: "bold" }}
            flat
            small
            outline
            flexibleWidth
            style={{ flex: canSave ? 1 : 2 }}
            color={Glob.get("primaryColor")}
            onPress={onCancel}
          />
          <Button
            text="Save"
            textStyle={{ fontWeight: "bold" }}
            style={{
              flex: canSave ? 2 : 1,
              opacity: canSave ? 1 : 0.3
            }}
            disabled={!canSave}
            flat
            small
            flexibleWidth
            color={Glob.get("primaryColor")}
            onPress={() =>
              onSave({
                name: productName,
                price: productPrice,
                description: productDescription,
                shippable: productIsShippable,
                adjustableQuantity: productAdjustableQuantity,
                subscriptionInterval: productSubscriptionInterval,
                customQuestions: productQuestions.map(
                  ({
                    question,
                    inputType,
                    isRequired,
                    dropdownOptions = null
                  }) => ({
                    question,
                    inputType,
                    isRequired,
                    dropdownOptions
                  })
                ),
                isNew,
                productID,
                isEditing
              })
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  productContainer: {
    marginVertical: 15,
    marginHorizontal: 20,
    paddingHorizontal: 15,
    paddingVertical: 25,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    borderRadius: 10,
    shadowRadius: 5,
    elevation: 3,
    opacity: 1
  }
});
