// TEMPORARY food hours sub-portal -- will be replaced by a nicer one

import React, { Component } from "react";
import { StyleSheet, Text, View, Dimensions, FlatList } from "react-native";
import School from "school/school";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";

const { height, width } = Dimensions.get("window");
const pageName = "Dining Hours";

const d = new Date();
const day = d.getDay();
const dayArray = [
  "sunday's hours",
  "monday's hours",
  "tuesday's hours",
  "wednesday's hours",
  "thursday's hours",
  "friday's hours",
  "saturday's hours"
];
const dayText = dayArray[day];

export default class FoodHours extends Component {
  constructor(props) {
    super();
    this.state = { dataRows: [] };
    Analytics.logEvent("view_foodHours");
  }

  componentDidMount() {
    Util.fetchGoogleSheetData(
      School.food("food hours sheet id"),
      "food",
      false
    ).then((rows) => {
      if (rows && rows.length > day && rows[day].length > 0) {
        this.setState({ dataRows: rows.map((r) => r[day]) });
      }
    });
  }

  navigate(routeName, transitionType = "normal") {
    const { navigator } = this.props;
    navigator.push({
      name: routeName,
      transitionType
    });
  }

  renderRow = ({ item }) => {
    return <Text style={styles.listItem}>{item}</Text>;
  };

  render() {
    const { navigation } = this.props;
    const { dataRows } = this.state;
    return (
      <View style={{ backgroundColor: "white" }}>
        <NavBar navigation={navigation} text={pageName} />
        <Text
          style={[
            styles.dayTextStyle,
            { color: Rex.getConfig()?.colors?.text }
          ]}
        >
          {dayText}
        </Text>
        <View style={styles.mainContent}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={dataRows}
            renderItem={this.renderRow}
            keyExtractor={(item, index) => item + index}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Style for the main section that will hold all the of the content */
  mainContent: {
    backgroundColor: "white",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 0.022 * height
  },

  /* Style for the words "current meal swipe:" */
  dayTextStyle: {
    fontSize: 0.045 * height,
    textAlign: "center",
    marginTop: 0.015 * height,
    marginBottom: 0.007 * height
  },

  /* Styles for the list headers above the item */
  listHeader: {
    marginLeft: 0.013 * width,
    height: 0.033 * height,
    backgroundColor: Glob.get("very light gray"),
    fontSize: 0.025 * height,
    textAlign: "center",
    fontWeight: "bold"
  },

  /* Styles for the emphasized list item */
  listItemEmphasized: {
    marginLeft: 0.013 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.03 * height,
    borderBottomColor: Glob.get("very light gray"),
    textAlign: "center",
    fontWeight: "bold"
  },

  /* Styles for the list item */
  listItem: {
    marginLeft: 0.013 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.03 * height,
    borderBottomColor: Glob.get("very light gray")
  },

  /* Style for the text on the buttons */
  buttonText: {
    color: "white",
    fontSize: 0.045 * height,
    marginTop: 0.012 * height
  },

  buttonsRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 0.015 * height,
    marginBottom: 0.052 * height,
    marginLeft: 0.027 * width,
    marginRight: 0.027 * width
  },

  halfButton: {
    width: 0.476 * width,
    justifyContent: "space-around",
    height: 0.075 * height
  },

  longButton: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0.015 * height,
    marginBottom: 0.027 * height,
    marginLeft: 0.013 * width,
    height: 0.075 * height,
    width: 0.95 * width
  },

  /* Style for the dimensions of the button images */
  buttonHeightWidth: {
    height: 0.083 * height,
    width: 0.833 * width
  },

  /* Style for the view of the button */
  buttonView: {
    flexDirection: "column",
    alignItems: "center"
  }
});
