/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Animated,
  Pressable,
  Platform
  // Easing
} from "react-native";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Icon from "src/components/Icon";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default function PortalRow({
  portal,
  navigation,
  type = "edit",
  isMoving,
  isActive = true,
  index,
  onUpdatePortal,
  inactiveHeader,
  showPortalType,
  drag,
  onPressProp
}) {
  const screenIsHiddenFromAllAccountTypes =
    !!portal?.allowedAccountTypes &&
    Object.keys(portal.allowedAccountTypes).length === 1 &&
    portal?.allowedAccountTypes[Glob.get("hiddenScreenAccountTypeKey")] ===
      true;
  if (type === "add")
    return (
      <AddPortalButton
        navigation={navigation}
        onUpdatePortal={onUpdatePortal}
      />
    );
  const { name: portalTypeName } = Util.portalTypeToDetails(portal.portalType);
  const portalContentIsEditable = ["dynamic", "webNav", "webStatic"].includes(
    portal.portalType
  );
  const portalMetadata = { ...portal };

  if (["move", "inactiveHeader"].includes(type))
    return (
      <MoveablePortalRow
        isMoving={isMoving}
        portal={portal}
        index={index}
        portalContentIsEditable={portalContentIsEditable}
        portalTypeName={portalTypeName}
        isActive={isActive}
        inactiveHeader={inactiveHeader}
        showPortalType={showPortalType}
        drag={drag}
      />
    );

  const hidePortalType = portal.portalType === "dynamic" || !showPortalType;
  return (
    <TouchableOpacity
      style={[
        styles.row,
        {
          alignSelf: "center",
          opacity: screenIsHiddenFromAllAccountTypes ? 0.5 : 1
        }
      ]}
      activeOpacity={screenIsHiddenFromAllAccountTypes ? 0.3 : 0.6}
      onPress={() => {
        if (type === "edit")
          navigation.push("editPortal", {
            portalMetadata,
            portalTypeName,
            onUpdatePortal
          });
        else if (type === "pressable") onPressProp();
      }}
    >
      <View style={styles.rowNameIconContainer}>
        <View style={styles.icon}>
          <Icon
            icon={portal.icon || portal.imgName} // backward-compatible
            iconURL={portal.iconURL}
            color={Rex.getConfig()?.colors?.primary}
            size={24}
          />
        </View>
        <Text style={{ fontSize: 18, color: Rex.getConfig()?.colors?.button }}>
          {portal.txtName}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: !hidePortalType ? 1 : 0.5,
          justifyContent: "flex-end"
        }}
      >
        {screenIsHiddenFromAllAccountTypes ? (
          <Icon
            icon="c7d4d67e-3597-430b-9690-36de3f7a95dc" // eye-off-outline
            color={Glob.get("gray")}
            size={30}
          />
        ) : (
          <>
            {!hidePortalType && (
              <Text
                style={
                  portalContentIsEditable
                    ? { fontSize: 12, color: Rex.getConfig()?.colors?.primary }
                    : styles.portalTypeUneditable
                }
              >
                {portalTypeName.toUpperCase()}
              </Text>
            )}
          </>
        )}
      </View>
    </TouchableOpacity>
  );
}

const AddPortalButton = ({ navigation, onUpdatePortal }) => {
  return (
    <TouchableOpacity
      style={[
        styles.row,
        styles.addPortalRow,
        { backgroundColor: Rex.getConfig()?.colors?.button }
      ]}
      activeOpacity={0.6}
      onPress={() =>
        navigation.push("editPortal", {
          isNew: true,
          portalTypeName: "Web",
          onUpdatePortal
        })
      }
    >
      <MaterialCommunityIcons
        name="file-plus-outline"
        size={22}
        color="white"
      />
      <Text style={styles.addPortalButtonText}>Make New Screen</Text>
    </TouchableOpacity>
  );
};

class MoveablePortalRow extends Component {
  //   NOTE: THIS CONSTRUCTOR IS OUTDATED (FOR WHEN WE USED react-native-sortable-list)
  // constructor(props) {
  //   super(props);
  //   this._active = new Animated.Value(0);
  //   this._style = {
  //     ...Platform.select({
  //       ios: {
  //         transform: [{
  //           scale: this._active.interpolate({
  //             inputRange: [0, 1],
  //             outputRange: [1, 1.1],
  //           }),
  //         }],
  //         shadowRadius: this._active.interpolate({
  //           inputRange: [0, 1],
  //           outputRange: [2, 10],
  //         }),
  //       },
  //       android: {
  //         transform: [{
  //           scale: this._active.interpolate({
  //             inputRange: [0, 1],
  //             outputRange: [1, 1.07],
  //           }),
  //         }],
  //         elevation: this._active.interpolate({
  //           inputRange: [0, 1],
  //           outputRange: [2, 6],
  //         }),
  //       },
  //     })
  //   };
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.isMoving !== nextProps.isMoving) {
  //     Animated.timing(this._active, {
  //       duration: 300,
  //       easing: Easing.bounce,
  //       toValue: Number(nextProps.isMoving),
  //     }).start();
  //   }
  // }

  render() {
    const {
      isMoving,
      portal,
      index,
      portalContentIsEditable,
      portalTypeName,
      isActive,
      inactiveHeader,
      showPortalType,
      drag
    } = this.props;

    if (inactiveHeader) {
      return (
        <Animated.View>
          <View style={styles.headerRow}>
            <Text
              style={{ fontSize: 26, color: Rex.getConfig()?.colors?.text }}
            >
              {inactiveHeader}
            </Text>
          </View>
        </Animated.View>
      );
    }

    const hidePortalType = portal.portalType === "dynamic" || !showPortalType;

    return (
      <Animated.View>
        <Pressable
          style={[
            styles.moveableRowContainer,
            { cursor: isMoving ? "grabbing" : "grab" }
          ]}
          onLongPress={drag}
        >
          <View style={styles.numberContainer}>
            {isActive && (
              <Text style={styles.portalTypeUneditable}>{index}.</Text>
            )}
          </View>
          <View
            style={[
              styles.row,
              isActive ? {} : { opacity: 0.5 },
              isMoving ? { elevation: 10, width: "98%" } : {}
            ]}
          >
            <View style={styles.rowNameIconContainer}>
              <View style={styles.icon}>
                <Icon
                  icon={portal.icon || portal.imgName} // backward-compatible
                  iconURL={portal.iconURL}
                  color={Rex.getConfig()?.colors?.primary}
                  size={24}
                />
              </View>
              <Text
                style={{ fontSize: 18, color: Rex.getConfig()?.colors?.button }}
              >
                {portal.txtName}
              </Text>
            </View>
            {!hidePortalType && (
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "flex-end"
                }}
              >
                <Text
                  style={
                    portalContentIsEditable
                      ? {
                          fontSize: 12,
                          color: Rex.getConfig()?.colors?.primary
                        }
                      : styles.portalTypeUneditable
                  }
                >
                  {portalTypeName.toUpperCase()}
                </Text>
              </View>
            )}
          </View>
        </Pressable>
      </Animated.View>
    );
  }
}

const row = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "92%",
  backgroundColor: "white",
  paddingHorizontal: 15,
  height: 50,
  marginVertical: 4,
  shadowOpacity: 0.15,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 10,
  elevation: 5,
  borderRadius: 15
};
if (Platform.OS === "web") row.boxShadow = "0px 0px 10px rgba(0,0,0,0.15)";

const styles = StyleSheet.create({
  moveableRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: 16
  },
  rowNameIconContainer: {
    flex: 2,
    flexDirection: "row",
    alignItems: "center"
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingRight: 5,
    width: "8%"
  },
  row,
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    marginVertical: 4,
    marginHorizontal: 16,
    borderRadius: 15
  },
  addPortalRow: {
    justifyContent: "center",
    alignSelf: "center"
  },
  addPortalButtonText: {
    fontSize: 18,
    marginLeft: 10,
    fontWeight: "bold",
    color: "white"
  },
  portalTypeUneditable: {
    fontSize: 12,
    color: Glob.get("gray")
  },
  icon: {
    resizeMode: "contain",
    width: 35,
    height: 35,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center"
  }
});
