import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import UserList from "src/components/UserList";

export default function SelectUsersForChat({ navigation, route }) {
  const { params: { chatPermissions } = {} } = route || {};
  const [filteredUserIDs, setFilteredUserIDs] = useState(null);
  const [filteredUsersLoaded, setFilteredUsersLoaded] = useState(false);

  const chatViewIsLimited = !!chatPermissions;

  useEffect(() => {
    Analytics.logEvent("view_selectUsersForChat");
    if (chatPermissions?.accountTypes) {
      Database.fetchAllUsers().then((users) => {
        const filteredUsers = users
          .filter((user) => chatPermissions.accountTypes[user.type])
          .map((user) => user.uid);
        setFilteredUserIDs(filteredUsers);
        setFilteredUsersLoaded(true);
      });
    } else setFilteredUsersLoaded(true);
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="New Message" />
      {filteredUsersLoaded ? (
        <UserList
          navigation={navigation}
          hideInvitations
          hideTopSummary
          hideDemoAccount
          hideUnknownUsers
          hideEmails={chatViewIsLimited}
          hideFilters={chatViewIsLimited}
          hideUserBadges={chatViewIsLimited}
          shouldShowShareOptions={false}
          onPress={({ user }) => {
            navigation.push("notificationDetails", { chatRecipient: user });
          }}
          filteredUserIDs={filteredUserIDs}
        />
      ) : (
        <ActivityIndicator size="large" style={{ marginTop: 40 }} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
