import React, { useState } from "react";
import {
  TouchableHighlight,
  Text,
  View,
  StyleSheet,
  Platform
} from "react-native";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import * as Haptics from "expo-haptics";

const { width } = Glob.get("dimensions");

// initialButtonState is "left", "right", or "center"
export default function ButtonSwitches({
  initialButtonState = "left",
  leftOption,
  centerOption = null,
  rightOption,
  leftAction = () => {},
  centerAction = () => {},
  rightAction = () => {},
  color = null,
  style = {}
}) {
  const [buttonState, setButtonState] = useState(initialButtonState || null);

  const buttonColor = color || Rex.getConfig()?.colors?.button;

  const onPressButton = (newButtonState) => {
    if (Platform.OS !== "web") Haptics.selectionAsync();
    if (newButtonState === "left") leftAction();
    else if (newButtonState === "center") centerAction();
    else if (newButtonState === "right") rightAction();
    setButtonState(newButtonState);
  };

  const selectedOptionStyle = [
    styles.selectedOption,
    { backgroundColor: buttonColor }
  ];
  const deselectedOptionTextStyle = [
    styles.deselectedOptionText,
    { color: buttonColor }
  ];

  return (
    <View style={[styles.basicFlexAround, style]}>
      <View style={styles.basicFlexBetween}>
        <View
          style={[styles.basicFlexBetweenOptions, { borderColor: buttonColor }]}
        >
          <TouchableHighlight
            underlayColor="transparent"
            style={
              buttonState === "left"
                ? selectedOptionStyle
                : styles.deselectedOption
            }
            onPress={() => onPressButton("left")}
          >
            <Text
              style={
                buttonState === "left"
                  ? styles.selectedOptionText
                  : deselectedOptionTextStyle
              }
            >
              {leftOption}
            </Text>
          </TouchableHighlight>
          {!!centerOption && (
            <TouchableHighlight
              underlayColor="transparent"
              style={
                buttonState === "center"
                  ? selectedOptionStyle
                  : styles.deselectedOption
              }
              onPress={() => onPressButton("center")}
            >
              <Text
                style={
                  buttonState === "center"
                    ? styles.selectedOptionText
                    : deselectedOptionTextStyle
                }
              >
                {centerOption}
              </Text>
            </TouchableHighlight>
          )}
          <TouchableHighlight
            underlayColor="transparent"
            style={
              buttonState === "right"
                ? selectedOptionStyle
                : styles.deselectedOption
            }
            onPress={() => onPressButton("right")}
          >
            <Text
              style={
                buttonState === "right"
                  ? styles.selectedOptionText
                  : deselectedOptionTextStyle
              }
            >
              {rightOption}
            </Text>
          </TouchableHighlight>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for the selected option */
  selectedOption: {
    height: 30,
    width: 0.286 * width,
    borderRadius: 10
  },

  /* Style for the deselected option */
  deselectedOption: {
    height: 30,
    width: 0.294 * width,
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 10
  },

  /* Style for the selected option text */
  selectedOptionText: {
    paddingTop: 3,
    fontSize: 18,
    textAlign: "center",
    color: "white"
  },

  /* Style for the deselected option text */
  deselectedOptionText: {
    paddingTop: 3,
    fontSize: 18,
    textAlign: "center"
  },

  /* Basic flex for options - between */
  basicFlexBetween: {
    flexDirection: "row",
    justifyContent: "space-between"
  },

  /* Basic flex for options - between */
  basicFlexBetweenOptions: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 30,
    borderWidth: 3
  },

  /* Basic flex for options - around */
  basicFlexAround: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 20
  }
});
