import React from "react";
import { Text } from "react-native";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Glob from "src/globalConstants";
import Rex from "src/globalState";

export default function PrivacyAndTerms({ navigation, style, message = "" }) {
  const config = Rex.getConfig() || {};
  const customTerms = config?.termsOfServiceLink;
  return (
    <Text style={style}>
      {message ? `${message}\n` : ""}
      <Text style={{ textDecorationLine: "underline" }}>
        <TouchableLink
          type="web"
          link={Glob.get("termsOfServiceURL")}
          text="Terms of Service"
          navigation={navigation}
          textStyle={style}
        />
      </Text>{" "}
      and{" "}
      <Text style={{ textDecorationLine: "underline" }}>
        <TouchableLink
          type="web"
          link={Glob.get("privacyPolicyURL")}
          text="Privacy Policy"
          navigation={navigation}
          textStyle={style}
        />
      </Text>
      .
      {!!customTerms && (
        <Text style={style}>
          {"\n"}
          {message ? "You also agree to the " : ""}
          <Text style={{ textDecorationLine: "underline" }}>
            <TouchableLink
              type="web"
              link={customTerms}
              text="Terms of Service"
              navigation={navigation}
              textStyle={style}
            />
          </Text>{" "}
          of {config?.names?.full || "this app."}.
        </Text>
      )}
    </Text>
  );
}
