import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import moment from "moment";
import Icon from "src/components/Icon";

export default function Message({
  msg,
  navigation,
  isNotifications = false,
  justUpdated = false,
  allGroups = {},
  allAccountTypes = {},
  allUsers = {},
  setAlert = () => {}
}) {
  const numberSentTo = isNotifications
    ? msg?.numberUsersSentTo || 0
    : msg?.phoneNumbers?.length || 0;

  const displayAudience = () => {
    const groupNames = Object.keys(msg?.audience?.groups || {}).map((group) => {
      return allGroups && allGroups[group]?.name
        ? allGroups[group].name
        : "Unknown Group";
    });
    const accountTypeNames = Object.keys(msg?.audience?.accountTypes || {}).map(
      (accountType) => {
        return allAccountTypes && allAccountTypes[accountType]?.title
          ? allAccountTypes[accountType].title
          : "Unknown Account Type";
      }
    );
    const userNames = Object.keys(msg?.audience?.users || {}).map((user) => {
      return allUsers && (allUsers[user]?.firstName || allUsers[user]?.lastName)
        ? `${allUsers[user]?.firstName} ${allUsers[user]?.lastName}`.trim()
        : "Unknown User";
    });
    let invitationStatusPhrase = null;
    if (
      msg?.audience?.invitationStatus ||
      msg?.audience?.invitationStatus !== "all"
    ) {
      if (msg?.audience?.invitationStatus === "invited")
        invitationStatusPhrase = "Only invited members who haven't yet joined";
      else if (msg?.audience?.invitationStatus === "current")
        invitationStatusPhrase =
          "Only current members who have joined this app";
    }
    setAlert({
      title: `Sent to ${numberSentTo.toLocaleString()} ${
        numberSentTo !== 1 ? "people" : "person"
      }`,
      message: (
        <View style={{ padding: 10 }}>
          <Text style={{ marginBottom: 10 }}>
            This notification was sent to the{" "}
            {numberSentTo === 1
              ? "1 member"
              : `${numberSentTo.toLocaleString()} members`}
            {msg?.audience ? " who met all of these criteria:" : "."}
          </Text>
          {userNames.length > 0 && (
            <Text style={{ marginTop: 5 }}>
              <Text style={{ fontWeight: "bold" }}>
                Specific User{userNames.length !== 1 ? "s" : ""}:{" "}
              </Text>
              {userNames.join(", ")}
            </Text>
          )}
          <Text style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bold" }}>
              Account Type{accountTypeNames.length !== 1 ? "s" : ""}:{" "}
            </Text>
            {accountTypeNames.length > 0
              ? accountTypeNames.join(", ")
              : "Any account type"}
          </Text>
          <Text style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bold" }}>
              Group{groupNames.length !== 1 ? "s" : ""}:{" "}
            </Text>
            {groupNames.length > 0 ? groupNames.join(", ") : "Any group"}
          </Text>
          {!!invitationStatusPhrase && (
            <Text style={{ marginTop: 5 }}>
              <Text style={{ fontWeight: "bold" }}>Invitation Status: </Text>
              {invitationStatusPhrase}
            </Text>
          )}
        </View>
      )
    });
  };

  return (
    <View
      style={{
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ccc"
      }}
    >
      <View
        key={msg.timestamp}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginRight: 10
            }}
          >
            {moment(msg.timestamp).year() === moment().year()
              ? moment(msg.timestamp).format("MMM D • h:mm a")
              : moment(msg.timestamp).format("MMM D, YYYY • h:mm a")}
          </Text>
          {!!msg.url && (
            <TouchableOpacity
              onPress={() =>
                navigation.push("webNav", {
                  title: msg?.title || "Link",
                  url: msg.url
                })
              }
            >
              <Icon
                icon="link"
                color="black"
                size={18}
                style={{ marginRight: 6 }}
              />
            </TouchableOpacity>
          )}
          {!!msg.sentPushNotification && (
            <Icon
              icon="46a76db7-22f8-4366-bc36-b24a669ed8a5" // notification
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentEmail && (
            <Icon
              icon="email"
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentTextMessage && (
            <Icon
              icon="ca1e2481-ebbc-4de1-858c-e65d8194ee7b" // chatbubble-outline
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentPhoneCall && (
            <Icon
              icon="495f4f3e-5f15-4376-83e6-05365e07950c" // call-outline
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
        </View>
        <TouchableOpacity
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
          onPress={displayAudience}
        >
          <Text
            style={{ color: justUpdated ? "#54d66f" : "#646464", fontSize: 14 }}
          >
            Sent to{" "}
            <Text style={{ fontWeight: "bold" }}>
              {numberSentTo.toLocaleString()}
            </Text>{" "}
            {numberSentTo !== 1 ? "people" : "person"}
          </Text>
          <Icon
            icon="991af1e3-302b-4bde-9ddd-0e6099e969a7"
            color={justUpdated ? "#54d66f" : "#646464"}
            size={12}
            style={{ marginLeft: 4 }}
          />
        </TouchableOpacity>
      </View>
      <Text
        style={{
          color: "#646464",
          fontSize: 14,
          fontStyle: "italic"
        }}
      >
        {msg.message}
      </Text>
    </View>
  );
}
