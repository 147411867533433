import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  Image
} from "react-native";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import StatusMessage from "src/components/StatusMessage";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import RenderHTML from "src/components/RenderHTML";

const { width, height } = Glob.get("dimensions");
const COLUMN = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  FULL_NAME: "Name",
  EMAIL: "Email",
  PHONE: "Phone Number"
};

const LoomVideo = ({ url }) => (
  <View style={{ alignItems: "center", marginVertical: 20 }}>
    <RenderHTML
      code={`<iframe width="640" height="360" src="${url}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`}
    />
  </View>
);

const YouTubeVideo = ({ videoID }) => (
  <View style={{ alignItems: "center", marginVertical: 20 }}>
    <RenderHTML
      code={`<iframe width="640" height="360" src="https://www.youtube.com/embed/${videoID}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`}
    />
  </View>
);

export default function ImportUsers({ navigation, route }) {
  const { params: { onSetContacts, existingEmails = new Set() } = {} } =
    route || {};
  const [source, setSource] = useState(null); // "csv", "transparentClassroom", "mrx", "cordelia", "wix", etc.
  const [downloadedFile, setDownloadedFile] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [duplicateEmailsCount, setDuplicateEmailsCount] = useState(0);
  const [existingEmailsCount, setExistingEmailsCount] = useState(0);
  const [invalidEmailsCount, setInvalidEmailsCount] = useState(0);
  const [invalidPhoneNumbersCount, setInvalidPhoneNumbersCount] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [columnIndexFirstName, setColumnIndexFirstName] = useState(-1);
  const [columnIndexLastName, setColumnIndexLastName] = useState(-1);
  const [columnIndexFullName, setColumnIndexFullName] = useState(-1);
  const [columnIndexEmail, setColumnIndexEmail] = useState(-1);
  const [columnIndexPhoneNumber, setColumnIndexPhoneNumber] = useState(-1);
  const [loadingAppType, setLoadingAppType] = useState(true);
  const [isK12School, setIsK12School] = useState(false);
  const [isMontessoriSchool, setIsMontessoriSchool] = useState(false);

  const canContinue =
    dataRows?.length > 0 && dataColumns?.length > 0 && columnIndexEmail > -1;

  useEffect(() => {
    Analytics.logEvent("view_importUsers");
    Database.fetchPrimaryMetaApp().then(({ key }) => {
      setLoadingAppType(false);
      if (key === "montessori") {
        setIsMontessoriSchool(true);
        setIsK12School(true);
      } else if (key === "onespotk12") setIsK12School(true);
    });
  }, []);

  useEffect(() => {
    const columnIdEmail =
      columnIndexEmail > -1 ? dataColumns[columnIndexEmail].columnId : null;
    const columnIdPhoneNumber =
      columnIndexPhoneNumber > -1
        ? dataColumns[columnIndexPhoneNumber].columnId
        : null;
    const newHighlights = [];
    const newContacts = [];
    const emails = new Set();
    let duplicates = 0;
    let alreadyExisting = 0;
    let invalidEmails = 0;
    let invalidPhoneNumbers = 0;
    dataRows.forEach((row) => {
      let contactIsValid = true;
      if (row.rowId === "header") return;
      const name = row.cells[columnIndexFullName]?.text || "";
      const email = row.cells[columnIndexEmail]?.text || "";
      const phoneNumber = row.cells[columnIndexPhoneNumber]?.text || "";
      const [firstName, lastName] = name
        ? Util.splitUserName(name)
        : [
            row.cells[columnIndexFirstName]?.text || "",
            row.cells[columnIndexLastName]?.text || ""
          ];
      if (columnIdEmail) {
        if ((name || email) && !email.isEmail()) {
          contactIsValid = false;
          invalidEmails += 1;
          newHighlights.push({
            columnId: columnIdEmail,
            rowId: row.rowId,
            borderColor: Glob.get("onespotRed")
          });
        } else if (emails.has(email)) {
          contactIsValid = false;
          duplicates += 1;
          newHighlights.push({
            columnId: columnIdEmail,
            rowId: row.rowId,
            borderColor: Glob.get("onespotRed")
          });
        } else if (existingEmails.has(email)) {
          contactIsValid = false;
          alreadyExisting += 1;
          newHighlights.push({
            columnId: columnIdEmail,
            rowId: row.rowId,
            borderColor: Glob.get("onespotRed")
          });
        }
      }
      if (columnIdPhoneNumber) {
        if (phoneNumber && !phoneNumber.isPhoneNumber()) {
          invalidPhoneNumbers += 1;
          newHighlights.push({
            columnId: columnIdPhoneNumber,
            rowId: row.rowId,
            borderColor: Glob.get("onespotRed")
          });
        }
      }
      if (!email) contactIsValid = false;
      if (contactIsValid) {
        emails.add(email);
        newContacts.push({
          firstName,
          lastName,
          email,
          ...(phoneNumber && phoneNumber.isPhoneNumber() ? { phoneNumber } : {})
        });
      }
    });
    setContacts(newContacts);
    setHighlights(newHighlights);
    setDuplicateEmailsCount(duplicates);
    setExistingEmailsCount(alreadyExisting);
    setInvalidEmailsCount(invalidEmails);
    setInvalidPhoneNumbersCount(invalidPhoneNumbers);
  }, [dataRows]);

  const sourceRef = useRef();
  useEffect(() => {
    sourceRef.current = source;
  }, [source]);

  const exportTemplateCSV = () => {
    Analytics.logEvent("touch_importUsers_exportTemplateCSV");
    // Create a Blob object with the CSV data
    const blob = new Blob(["Name,Email,Phone Number\n,,"], {
      type: "text/csv;charset=utf-8;"
    });
    // Save the CSV file using FileSaver.js
    FileSaver.saveAs(blob, "App Members.csv");
    setDownloadedFile(true);
  };

  const resetData = () => {
    Analytics.logEvent("touch_importUsers_resetData");
    setDataColumns([]);
    setDataRows([]);
    setHighlights([]);
    setDuplicateEmailsCount(0);
    setExistingEmailsCount(0);
    setInvalidEmailsCount(0);
    setInvalidPhoneNumbersCount(0);
    setContacts([]);
    setColumnIndexFirstName(-1);
    setColumnIndexLastName(-1);
    setColumnIndexFullName(-1);
    setColumnIndexEmail(-1);
    setColumnIndexPhoneNumber(-1);
  };

  const addRow = () => {
    Analytics.logEvent("touch_importUsers_addRow");
    if (dataRows?.length > 0 && dataColumns?.length > 0)
      setDataRows([
        ...dataRows,
        {
          rowId: `row-${dataRows.length}`,
          cells: Array.from({ length: dataColumns.length }, () => ({
            type: "text",
            text: ""
          }))
        }
      ]);
  };

  const onPressContinue = () => {
    Analytics.logEvent("touch_importUsers_continue");
    if (canContinue) {
      onSetContacts(contacts);
      navigation.goBack();
    }
  };

  const onDataCellsChanged = (changes) => {
    const newRows = [...dataRows];
    changes.forEach((change) => {
      const rowIndex = dataRows.findIndex((row) => row.rowId === change.rowId);
      const colIndex = dataColumns.findIndex(
        (col) => col.columnId === change.columnId
      );
      if (rowIndex > -1 && colIndex > -1) {
        newRows[rowIndex].cells[colIndex] = change.newCell;
      }
    });
    setDataRows(newRows);
  };

  const onDrop = useCallback((acceptedFiles) => {
    Analytics.logEvent("touch_importUsers_onDropFile");
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      let cols = parsedData.shift();
      if (sourceRef.current === "transparentClassroom")
        cols = cols.map((c) => {
          if (c === "First name") return COLUMN.FIRST_NAME;
          if (c === "Last name") return COLUMN.LAST_NAME;
          if (c === "Email") return COLUMN.EMAIL;
          if (c === "Mobile") return COLUMN.PHONE;
          return c;
        });
      if (sourceRef.current === "mrx")
        cols = cols.map((c) => {
          if (c === "INDIVIDUALS NAME") return COLUMN.FULL_NAME;
          if (c === "EMAIL") return COLUMN.EMAIL;
          if (c === "PHONE") return COLUMN.PHONE;
          return c;
        });
      if (sourceRef.current === "wix")
        cols = cols.map((c) => {
          if (c === "Recipient name") return COLUMN.FULL_NAME;
          if (c === "Contact email") return COLUMN.EMAIL;
          return c;
        });
      if (sourceRef.current === "cordelia") {
        // There are 2 parents for each row in Cordelia's format, so we fully reformat parsedData
        const firstNameIndexParent1 = cols.findIndex(
          (col) => col === "Parent1 First Name"
        );
        const lastNameIndexParent1 = cols.findIndex(
          (col) => col === "Parent1 Last Name"
        );
        const emailIndexParent1 = cols.findIndex(
          (col) => col === "Parent1 Email"
        );
        const phoneIndexParent1 = cols.findIndex(
          (col) => col === "Parent1 Preferred Phone"
        );
        const firstNameIndexParent2 = cols.findIndex(
          (col) => col === "Parent2 First Name"
        );
        const lastNameIndexParent2 = cols.findIndex(
          (col) => col === "Parent2 Last Name"
        );
        const emailIndexParent2 = cols.findIndex(
          (col) => col === "Parent2 Email"
        );
        const phoneIndexParent2 = cols.findIndex(
          (col) => col === "Parent2 Preferred Phone"
        );
        cols = [COLUMN.FULL_NAME, COLUMN.EMAIL, COLUMN.PHONE];
        const newParsedData = [];
        parsedData.forEach((row) => {
          const rowParent1 = [null, null, null];
          const rowParent2 = [null, null, null];
          row.forEach((cell, idx) => {
            if (idx === firstNameIndexParent1) rowParent1[0] = cell;
            else if (idx === lastNameIndexParent1 && rowParent1[0])
              rowParent1[0] = `${rowParent1[0]} ${cell}`;
            else if (idx === emailIndexParent1) rowParent1[1] = cell;
            else if (idx === phoneIndexParent1) rowParent1[2] = cell;
            else if (idx === firstNameIndexParent2) rowParent2[0] = cell;
            else if (idx === lastNameIndexParent2 && rowParent2[0])
              rowParent2[0] = `${rowParent2[0]} ${cell}`;
            else if (idx === emailIndexParent2) rowParent2[1] = cell;
            else if (idx === phoneIndexParent2) rowParent2[2] = cell;
          });
          newParsedData.push(rowParent1);
          if (rowParent2[0]) newParsedData.push(rowParent2);
        });
        parsedData = newParsedData;
      }
      const firstNameIndexOriginal = cols.findIndex(
        (col) => col === COLUMN.FIRST_NAME
      );
      const lastNameIndexOriginal = cols.findIndex(
        (col) => col === COLUMN.LAST_NAME
      );
      const nameIndexOriginal = cols.findIndex(
        (col) => col === COLUMN.FULL_NAME
      );
      const emailIndexOriginal = cols.findIndex((col) => col === COLUMN.EMAIL);
      const phoneNumberIndexOriginal = cols.findIndex(
        (col) => col === COLUMN.PHONE
      );
      const newCols = cols
        .map((_, i) => ({ columnId: `col-${i}` }))
        .filter((_, i) =>
          [
            firstNameIndexOriginal,
            lastNameIndexOriginal,
            nameIndexOriginal,
            emailIndexOriginal,
            phoneNumberIndexOriginal
          ].includes(i)
        );
      setDataColumns(newCols);
      const newRows = parsedData.map((row, i) => ({
        rowId: `row-${i}`,
        cells: cols
          .map((_, j) => {
            let text = `${row[j] || ""}`;
            // Clean cells
            if (sourceRef.current === "mrx") {
              if (j === nameIndexOriginal && text.includes(", ")) {
                const [last, first] = text.split(", ");
                text = `${first} ${last}`;
              }
            }
            return { type: "text", text };
          })
          .filter((_, j) =>
            [
              firstNameIndexOriginal,
              lastNameIndexOriginal,
              nameIndexOriginal,
              emailIndexOriginal,
              phoneNumberIndexOriginal
            ].includes(j)
          )
      }));
      newRows.unshift({
        rowId: "header",
        cells: cols
          .map((cell) => ({ type: "header", text: `${cell}` }))
          .filter((_, i) =>
            [
              firstNameIndexOriginal,
              lastNameIndexOriginal,
              nameIndexOriginal,
              emailIndexOriginal,
              phoneNumberIndexOriginal
            ].includes(i)
          )
      });
      cols = cols.filter((c) =>
        [
          COLUMN.FIRST_NAME,
          COLUMN.LAST_NAME,
          COLUMN.FULL_NAME,
          COLUMN.EMAIL,
          COLUMN.PHONE
        ].includes(c)
      );
      setColumnIndexFirstName(cols.findIndex((c) => c === COLUMN.FIRST_NAME));
      setColumnIndexLastName(cols.findIndex((c) => c === COLUMN.LAST_NAME));
      setColumnIndexFullName(cols.findIndex((c) => c === COLUMN.FULL_NAME));
      setColumnIndexEmail(cols.findIndex((c) => c === COLUMN.EMAIL));
      setColumnIndexPhoneNumber(cols.findIndex((c) => c === COLUMN.PHONE));
      setDataRows(newRows);
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
        cursor: "pointer"
      },
      ...(isFocused
        ? {
            borderColor: "#2196f3"
          }
        : {}),
      ...(isDragAccept
        ? {
            borderColor: "#00e676"
          }
        : {}),
      ...(isDragReject
        ? {
            borderColor: "#ff1744"
          }
        : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Invite Members" />
      <View style={{ width: width * 0.9 }}>
        {!source ? (
          <View>
            <Text
              style={[
                Style.get("headerText"),
                { textAlign: "center", marginTop: 30 }
              ]}
            >
              Import from a spreadsheet
            </Text>
            <Button
              text="Import from CSV file"
              onPress={() => {
                Analytics.logEvent("touch_importUsers_selectSource", {
                  source: "csv"
                });
                setSource("csv");
              }}
              icon="2853bb65-85d9-4f35-b90e-731ecd0d2c17" // table
              outline
            />
            {loadingAppType && <ActivityIndicator size="large" />}
            {isK12School && (
              <Text
                style={[
                  Style.get("headerText"),
                  { textAlign: "center", marginTop: 30 }
                ]}
              >
                Import from your school's SIS/LMS
              </Text>
            )}
            {isMontessoriSchool && (
              <Button
                text="Transparent Classroom"
                onPress={() => {
                  Analytics.logEvent("touch_importUsers_selectSource", {
                    source: "transparentClassroom"
                  });
                  setSource("transparentClassroom");
                }}
                icon="aa05dc26-e4b9-4d50-ae14-abd04c29cd83"
                outline
              />
            )}
            {isMontessoriSchool && (
              <Button
                text="Montessori Records Xpress"
                onPress={() => {
                  Analytics.logEvent("touch_importUsers_selectSource", {
                    source: "mrx"
                  });
                  setSource("mrx");
                }}
                icon="aa05dc26-e4b9-4d50-ae14-abd04c29cd83"
                outline
              />
            )}
            {isMontessoriSchool && (
              <Button
                text="Cordelia"
                onPress={() => {
                  Analytics.logEvent("touch_importUsers_selectSource", {
                    source: "cordelia"
                  });
                  setSource("cordelia");
                }}
                icon="aa05dc26-e4b9-4d50-ae14-abd04c29cd83"
                outline
              />
            )}
            {isK12School && (
              <Button
                text="Request an Integration"
                onPress={() => {
                  Analytics.logEvent("touch_importUsers_requestNewSource");
                  Database.fetchAllUserData().then((data) => {
                    const userEmail = data?.email;
                    Util.openURL(
                      `https://docs.google.com/forms/d/e/1FAIpQLSeUfflL9GY3BsTKuQ3CsbhJdypnIX44vJ5SHfphQEXHBpO8Sw/viewform?usp=pp_url&entry.1724793718=${
                        Rex.getConfig()?.names?.full
                      }&entry.752267785=${userEmail}`
                    );
                  });
                }}
                icon="plus"
                outline
              />
            )}
            <Text
              style={[
                Style.get("headerText"),
                { textAlign: "center", marginTop: 30 }
              ]}
            >
              Import from another source
            </Text>
            <Button
              text="Wix"
              onPress={() => {
                Analytics.logEvent("touch_importUsers_selectSource", {
                  source: "wix"
                });
                setSource("wix");
              }}
              icon="aa05dc26-e4b9-4d50-ae14-abd04c29cd83"
              outline
            />
          </View>
        ) : (
          <View>
            {!downloadedFile ? (
              <>
                {source === "csv" && (
                  <>
                    <HelpText
                      text="First, download this template CSV file to your computer."
                      noteStyle
                    />
                    <Button
                      text="Download template file"
                      onPress={exportTemplateCSV}
                      icon="d8c199f7-3d74-4198-a877-bc2d2448a5c7" // table-arrow-down
                    />
                    <View style={{ alignItems: "center" }}>
                      <TouchableLink
                        type="button"
                        text="I've already downloaded it"
                        textStyle={{
                          textDecorationLine: "underline",
                          marginTop: 10
                        }}
                        onPress={() => {
                          Analytics.logEvent(
                            "touch_importUsers_alreadyDownloadedFile"
                          );
                          setDownloadedFile(true);
                        }}
                      />
                    </View>
                  </>
                )}
                {source === "transparentClassroom" && (
                  <>
                    <HelpText
                      text="Export your contacts from Transparent Classroom, then click the Continue button below."
                      noteStyle
                    />
                    <LoomVideo url="https://www.loom.com/embed/d11a8d651b9a451a9416a2502b4bb776" />
                    <Button
                      text="Open Transparent Classroom"
                      outline
                      onPress={() => {
                        Analytics.logEvent(
                          "touch_importUsers_openTransparentClassroom"
                        );
                        Util.openURL(
                          "https://www.transparentclassroom.com/souls/sign_in"
                        );
                      }}
                      icon="d24166f4-d993-4d3a-8692-ae839b70b6af" // external link
                    />
                    <Button
                      text="Continue"
                      onPress={() => {
                        Analytics.logEvent(
                          "touch_importUsers_alreadyDownloadedFile"
                        );
                        setDownloadedFile(true);
                      }}
                      icon="check"
                    />
                  </>
                )}
                {source === "mrx" && (
                  <>
                    <HelpText
                      text="Export your contacts from MRX, then click the Continue button below."
                      noteStyle
                    />
                    <LoomVideo url="https://www.loom.com/embed/da2a7b1b5ee843aaaf5e87d275bbeb8e" />
                    <Button
                      text="Open MRX"
                      outline
                      onPress={() => {
                        Analytics.logEvent("touch_importUsers_openMRX");
                        Util.openURL("https://mrxreinvented.com");
                      }}
                      icon="d24166f4-d993-4d3a-8692-ae839b70b6af" // external link
                    />
                    <Button
                      text="Continue"
                      onPress={() => {
                        Analytics.logEvent(
                          "touch_importUsers_alreadyDownloadedFile"
                        );
                        setDownloadedFile(true);
                      }}
                      icon="check"
                    />
                  </>
                )}
                {source === "cordelia" && (
                  <>
                    <HelpText
                      text="Export your contacts from Cordelia, then click the Continue button below."
                      noteStyle
                    />
                    <YouTubeVideo videoID="Mm2pyigVNtI" />
                    <Button
                      text="Open Cordelia"
                      outline
                      onPress={() => {
                        Analytics.logEvent("touch_importUsers_openCordelia");
                        Util.openURL("https://my.cordeliaeducation.com");
                      }}
                      icon="d24166f4-d993-4d3a-8692-ae839b70b6af" // external link
                    />
                    <Button
                      text="Continue"
                      onPress={() => {
                        Analytics.logEvent(
                          "touch_importUsers_alreadyDownloadedFile"
                        );
                        setDownloadedFile(true);
                      }}
                      icon="check"
                    />
                  </>
                )}
                {source === "wix" && (
                  <>
                    <HelpText
                      text="Export your contacts from Wix, then click the Continue button below."
                      noteStyle
                    />
                    <Image
                      source={{
                        uri:
                          "https://d2x3xhvgiqkx42.cloudfront.net/12345678-1234-1234-1234-1234567890ab/8769cf44-f342-494c-b25f-cc98c9da3e82/2021/10/17/f3d40111-7f3f-436e-8332-3c269a19fac7/d6b45ee8-e9f1-4038-8a43-467c19f78fcc.gif"
                      }}
                      style={{
                        height: height * 0.5,
                        width: "100%"
                      }}
                      resizeMode="contain"
                    />
                    <Button
                      text="Open Wix"
                      outline
                      onPress={() => {
                        Analytics.logEvent("touch_importUsers_openWix");
                        Util.openURL("https://manage.wix.com");
                      }}
                      icon="d24166f4-d993-4d3a-8692-ae839b70b6af" // external link
                    />
                    <Button
                      text="Continue"
                      onPress={() => {
                        Analytics.logEvent(
                          "touch_importUsers_alreadyDownloadedFile"
                        );
                        setDownloadedFile(true);
                      }}
                      icon="check"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {!dataRows || dataRows.length < 1 ? (
                  <>
                    {source === "csv" ? (
                      <HelpText
                        text={`Now open that file you downloaded ("All Members.csv"). Paste your members' names, emails, and phone numbers in the file. Then save the file and upload it below.\n\nYou need to add a valid email address in the "Email" column for each row, but the "Name" and "Phone Number" columns are optional.`}
                        noteStyle
                      />
                    ) : (
                      <HelpText
                        text="Now upload your exported contacts document below."
                        noteStyle
                      />
                    )}
                    <section>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <Text>Drop it here! 👍</Text>
                        ) : (
                          <Text>
                            {dataRows?.length > 0
                              ? "Drop another file here to replace all the data."
                              : "Drag and drop your file here, or click to select it."}
                          </Text>
                        )}
                      </div>
                    </section>
                  </>
                ) : (
                  <View>
                    <HelpText
                      text={`Preview and edit the data in the table below. When you're ready, click "Continue".`}
                      noteStyle
                    />
                    <StatusMessage
                      type={contacts?.length > 0 ? "success" : "error"}
                      message={
                        !contacts?.length
                          ? "No valid contacts were detected. Edit the table below to fix any issues. Or edit your file and then try uploading it again."
                          : `${contacts?.length} valid contact${
                              contacts?.length === 1 ? "" : "s"
                            } will be imported.`
                      }
                      style={{ marginBottom: 2 }}
                    />
                    {columnIndexEmail < 0 && (
                      <StatusMessage
                        type="error"
                        message='No "Email" column was detected. Add an "Email" column to your CSV file and try uploading it again.'
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {columnIndexFullName < 0 && columnIndexFirstName < 0 && (
                      <StatusMessage
                        type="warning"
                        message='No "Name" column was detected. If you want to specify a name for each of your members, add a "Name" column to your file (or "First Name" and "Last Name" columns) and try uploading it again.'
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {invalidEmailsCount > 0 && (
                      <StatusMessage
                        type="warning"
                        message={`${invalidEmailsCount} contact${
                          invalidEmailsCount === 1 ? "" : "s"
                        } with missing/invalid email${
                          invalidEmailsCount === 1 ? "" : "s"
                        } will be skipped.`}
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {invalidPhoneNumbersCount > 0 && (
                      <StatusMessage
                        type="warning"
                        message={`${invalidPhoneNumbersCount} invalid phone number${
                          invalidPhoneNumbersCount === 1 ? "" : "s"
                        } will be skipped.`}
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {duplicateEmailsCount > 0 && (
                      <StatusMessage
                        type="warning"
                        message={`${duplicateEmailsCount} contact${
                          duplicateEmailsCount === 1 ? "" : "s"
                        } with duplicate email${
                          duplicateEmailsCount === 1 ? "" : "s"
                        } will be skipped.`}
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {existingEmailsCount > 0 && (
                      <StatusMessage
                        type="warning"
                        message={`${existingEmailsCount} ${
                          existingEmailsCount === 1
                            ? "contact who is currently a member or who was"
                            : "contacts who are currently members or who were"
                        } invited previously will be skipped.`}
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    {dataRows?.length > 30 && (
                      <StatusMessage
                        type="info"
                        message="Note that there's a lot of data, so you might not be able to preview/edit all rows here. To make changes, consider editing the original file and then uploading it again."
                        style={{ marginBottom: 2 }}
                      />
                    )}
                    <View
                      style={{
                        marginTop: 20,
                        marginBottom: 40,
                        alignItems: "center"
                      }}
                    >
                      <ReactGrid
                        rows={dataRows}
                        columns={dataColumns}
                        highlights={highlights}
                        onCellsChanged={onDataCellsChanged}
                        enableRangeSelection
                        enableColumnSelection
                      />
                      {dataRows?.length > 0 && (
                        <TouchableOpacity
                          activeOpacity={0.7}
                          style={styles.plusButton}
                          onPress={addRow}
                        >
                          <Icon icon="plus" size={18} color="#ccc" />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Button
                      text="Continue"
                      onPress={onPressContinue}
                      icon="check"
                      disabled={!canContinue}
                      style={{ opacity: canContinue ? 1 : 0.4 }}
                    />
                    <Button
                      text="Replace Data"
                      onPress={resetData}
                      icon="41a527a9-db4d-4bfa-bd4d-b918f8fdb800" // undo
                      small
                      flat
                      outline
                      color={Glob.get("dangerRed")}
                    />
                  </View>
                )}
              </>
            )}
          </View>
        )}
        <View style={{ height: 350 }} />
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  },
  plusButton: {
    borderRadius: 24,
    height: 24,
    width: 24,
    backgroundColor: "white",
    marginTop: 5,
    borderColor: "#ccc",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
