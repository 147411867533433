import React, { useState, useEffect } from "react";
import { View, ScrollView, ActivityIndicator } from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import AuthScreenHeader from "src/components/auth/AuthScreenHeader";
import UserTypeButton from "src/components/UserTypeButton";

const { width } = Glob.get("dimensions");

export default function PickAccountType({ route, navigation }) {
  const { params: { fullName, userInvitation } = {} } = route || {};
  const [userType, setUserType] = useState("");
  const [allUserTypes, setAllUserTypes] = useState(null);

  const numberOfTypes = Object.keys(allUserTypes || []).length;

  useEffect(() => {
    Analytics.logEvent("view_pickAccountType");
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) {
        const allUserTypeIDs = Object.keys(details);
        if (allUserTypeIDs?.length === 1) setUserType(allUserTypeIDs[0]);
        setAllUserTypes(details);
      } else {
        // Handle legacy database structure
        Database.getAllDefaultPortals((portals) => {
          const allUserTypeIDs = Object.keys(portals);
          const newAllUserTypes = {};
          allUserTypeIDs.forEach((type) => {
            newAllUserTypes[type] = true;
          });
          if (allUserTypeIDs?.length === 1) setUserType(allUserTypeIDs[0]);
          setAllUserTypes(newAllUserTypes);
        });
      }
    });
  }, []);

  const continueToSignup = (type) => {
    const selectedType = type || userType;
    if (selectedType && selectedType !== "") {
      const userTypeDetails = allUserTypes && allUserTypes[selectedType];
      if (Rex.getConfig()?.granularSecurityEnabled && userTypeDetails?.locked) {
        navigation.push("joinLockedAccountType", {
          fullName,
          userInvitation,
          accountType: { ...userTypeDetails, key: selectedType }
        });
      } else {
        Rex.setUserType(selectedType);
        navigation.push("signup", {
          fullName,
          userInvitation,
          accountType: { ...userTypeDetails, key: selectedType }
        });
      }
    }
  };

  const AllUserTypeButtons = () => {
    let marginHorizontal = 0;
    // If 5 or 6 buttons, make the rows be 3+2 or 3+3 (rather than 4+1 or 4+2)
    if (numberOfTypes > 4 && numberOfTypes < 7) {
      const buttonWidth = Glob.get("widthOfUserTypeButton");
      if (width > buttonWidth * 3)
        marginHorizontal = (width - buttonWidth * 3) / 2 - 6;
    }
    return (
      <View style={{ justifyContent: "center" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 20,
            marginBottom: 60,
            marginHorizontal
          }}
        >
          {allUserTypes ? (
            Object.entries(allUserTypes).map(([type, details]) => (
              <UserTypeButton
                type={type}
                title={
                  details?.title
                    ? `${
                        Rex.getConfig()?.granularSecurityEnabled &&
                        details?.locked
                          ? "🔒 "
                          : ""
                      }${details?.title}`
                    : type
                }
                icon={details?.icon}
                iconURL={details?.iconURL}
                outlineSelection
                selected={userType === type}
                onPress={() => {
                  Analytics.logEvent("touch_userType_selectType", { type });
                  setUserType(type);
                  continueToSignup(type);
                }}
                textColor="#555"
              />
            ))
          ) : (
            <ActivityIndicator size="large" />
          )}
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <NavBar
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ padding: 20 }}>
          <AuthScreenHeader
            title="Welcome!"
            subtitle="Select the account type you most identify with."
          />
        </View>
        {!!allUserTypes && numberOfTypes > 1 && <AllUserTypeButtons />}
      </ScrollView>
    </View>
  );
}
