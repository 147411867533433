import React, { useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import QRCode from "react-native-qrcode-svg";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";

const { height, width } = Glob.get("dimensions");
const QR_CODE_SIZE = Math.min(width * 0.9, height * 0.7)

export default function DisplayQRCode({ route, navigation }) {
  const {
    params: {
      title = "QR Code",
      qrCode = "",
      TopContent = () => null,
      BottomContent = () => null
    } = {}
  } = route || {};

  useEffect(() => {
    Analytics.logEvent("view_displayQRCode");
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={title} />
      <ScrollView
        contentContainerStyle={{ width: width * 0.9 }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          <TopContent />
          <QRCode size={QR_CODE_SIZE} value={qrCode} />
          <BottomContent />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
