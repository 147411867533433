import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  Text
} from "react-native";
import { fetchSignInMethodsForEmail } from "firebase/auth/react-native";
import indefinite from "indefinite";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Util from "src/utility";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import Dropdown from "src/components/Dropdown";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const { height, width } = Glob.get("dimensions");
const NAVBAR_TEXT = "Edit Profile";

export default function EditProfile({ route, navigation }) {
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsValues, setCustomFieldsValues] = useState({});
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userTypePasscode, setUserTypePasscode] = useState(null);
  const [originalUserType, setOriginalUserType] = useState(null);
  const [allUserTypes, setAllUserTypes] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);
  const [alert, setAlert] = useState(null);
  const userTypeDetails = allUserTypes && allUserTypes[userType];
  const attemptingToJoinLockedAccountType =
    userType !== originalUserType &&
    userTypeDetails?.locked &&
    Rex.getConfig()?.granularSecurityEnabled;

  useEffect(() => {
    Analytics.logEvent("view_editProfile");
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) setAllUserTypes(details);
      else {
        // Handle legacy database structure
        Database.getAllDefaultPortals((portals) => {
          const newAllUserTypes = {};
          Object.keys(portals).forEach((type) => {
            newAllUserTypes[type] = true;
          });
          setAllUserTypes(newAllUserTypes);
        });
      }
    });
    Database.fetchUserAccountFields().then(({ fieldsArray }) => {
      setCustomFields(fieldsArray);
      Database.fetchAllUserData().then((data) => {
        const newValues = {};
        fieldsArray.forEach((field) => {
          if (field.key) newValues[field.key] = data[field.key] || null;
        });
        setCustomFieldsValues(newValues);
        setUserFirstName(data?.firstName);
        setUserLastName(data?.lastName);
        setUserEmail(data?.email);
        setUserType(data?.type);
        setOriginalUserType(data?.type);
        // Note (Feb 2023): We don't yet use google auth as a sign-in method.
        // See the google-auth branch and this pull request: https://github.com/seabirdapps/aa-dev-app/pull/59
        fetchSignInMethodsForEmail(Firebase.getAuth(), data?.email).then(
          (signInMethods) => {
            if (signInMethods.includes("google.com")) setIsGoogleAccount(true);
          }
        );
      });
    });
  }, []);

  const overWritePortalsWithDefaults = (type) => {
    const { checkRefresh } = route?.params || {};
    Database.getDefaultPortalMetadata(type, (portals) => {
      Rex.orderChanged(portals);
      checkRefresh();
    });
  };

  const userTypeTitle = (type) => {
    return allUserTypes && allUserTypes[type] && allUserTypes[type].title
      ? allUserTypes[type].title.toLowerCase()
      : type;
  };

  const onPressSave = () => {
    setSavingChanges(true);
    if (attemptingToJoinLockedAccountType) {
      Database.tryToUnlockAccountType(userType, userTypePasscode)
        .then((response) => {
          const { success } = response;
          setSavingChanges(false);
          if (success) saveAllSettings();
          else
            Util.alert(
              "Failed to save your changes",
              "Try a different passcode to access that secure account type."
            );
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          setSavingChanges(false);
          Util.alert(
            "Something went wrong, sorry!",
            "Double-check your account type passcode and try again."
          );
        });
    } else {
      saveAllSettings();
    }
  };

  const saveAllSettings = () => {
    if (userType !== originalUserType) {
      setAlert({
        title: "You changed your account type!",
        message: `Do you want to also reset your portal configuration to be the default ${userTypeTitle(
          userType
        )} portal configuration? This would overwrite the order of everything on your homescreen.`,
        disableOutsideTouch: true,
        confirm: {
          text: "✋ Nope",
          onPress: () => console.log("Overwrite Cancelled")
        },
        cancel: {
          text: "💥 Overwrite",
          onPress: () => overWritePortalsWithDefaults(userType)
        }
      });
    }
    const first = userFirstName || "";
    const last = userLastName || "";
    const email = userEmail || "";

    Analytics.setUserProperties({
      first_name: first,
      last_name: last,
      email,
      ...customFieldsValues
    });
    Analytics.logEvent("touch_editProfile_saveChanges", {
      first_name: first,
      last_name: last,
      email,
      ...customFieldsValues
    });
    const emailSection = isGoogleAccount ? {} : { email };
    Database.writeUserData({
      firstName: first,
      lastName: last,
      type: userType,
      ...emailSection,
      ...customFieldsValues
    }).then(() => {
      setOriginalUserType(userType);
      setSavingChanges(false);
      if (userType === originalUserType) {
        setAlert({ title: "Account details updated 👍" });
      }
    });
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text={NAVBAR_TEXT} />

      <ScrollView
        style={{ paddingHorizontal: 20, width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        {isGoogleAccount && (
          <View style={{ marginTop: 20 }}>
            <Text style={Style.get("headerText")}>
              You're signed in with Google 👍
            </Text>
            <HelpText
              text={[
                `You're signed into this app with your ${userEmail} Google account. You can use that one account across all apps in `,
                <Text style={{ textDecorationLine: "underline" }}>
                  <TouchableLink
                    type="web"
                    link="https://www.1spot.app"
                    text="the Onespot ecosystem"
                    openExternally
                  />
                </Text>,
                "."
              ]}
            />
          </View>
        )}
        <InputBox
          header="First Name"
          openStyle
          value={userFirstName}
          maxLength={20}
          onChangeText={setUserFirstName}
        />
        <InputBox
          header="Last Name"
          openStyle
          value={userLastName}
          maxLength={20}
          onChangeText={setUserLastName}
        />
        {!isGoogleAccount && (
          <InputBox
            header="Email"
            openStyle
            value={userEmail}
            maxLength={50}
            onChangeText={setUserEmail}
          />
        )}

        {customFields.map((field) => {
          if (field.key === "year")
            return (
              <InputBox
                header={field.title}
                openStyle
                value={customFieldsValues[field.key]}
                onChangeText={(text) =>
                  setCustomFieldsValues({
                    ...customFieldsValues,
                    [field.key]: text
                  })
                }
                maxLength={4}
                keyboardType="numeric"
              />
            );
          return (
            <InputBox
              header={field.title}
              openStyle
              value={customFieldsValues[field.key]}
              onChangeText={(text) =>
                setCustomFieldsValues({
                  ...customFieldsValues,
                  [field.key]: text
                })
              }
            />
          );
        })}

        {!!allUserTypes && (
          <View>
            <Dropdown
              header="Account Type"
              value={userType}
              items={Object.entries(allUserTypes).map(([type, details]) => ({
                value: type,
                text: details?.title || type
              }))}
              onSelect={setUserType}
            />
            {attemptingToJoinLockedAccountType && (
              <InputBox
                key="passcode"
                openStyle
                header="Account Type Passcode"
                description={`${
                  userTypeDetails.title
                } is a secure account type. You need a secret code in order to use this app as ${indefinite(
                  userTypeDetails.title
                ).toLowerCase()}. If you have the secret code, please enter it here.`}
                placeholder="Passcode"
                value={userTypePasscode}
                onChangeText={setUserTypePasscode}
              />
            )}
          </View>
        )}

        <View style={{ marginTop: 30 }}>
          {savingChanges ? (
            <ActivityIndicator style={{ marginTop: 20 }} size="large" />
          ) : (
            <Button text="Save Changes" onPress={onPressSave} />
          )}
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: "center", backgroundColor: "white" },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    marginRight: 37,
    color: "black"
  }
});
