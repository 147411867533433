import React, { useState, useEffect, useRef } from "react";
import { TouchableOpacity, Animated, View, Text } from "react-native";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import TextItem from "src/components/dynamicContent/TextItem";

export default function ExpandableListItem({
  shouldBeExpanded,
  onPress,
  disabled,
  setKeyboardToolbarComponents,
  headerItem,
  bodyItem,
  onChangeHeaderItem,
  onChangeBodyItem,
  onDeleteRow,
  canDelete,
  renderableItem,
  dataSourceRow,
  isEditing
}) {
  const [maxHeight, setMaxHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const animation = useRef(new Animated.Value(0)).current;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    // Mimics componentDidUpdate
    else if (expanded && !shouldBeExpanded) collapse();
    else if (!expanded && shouldBeExpanded) expand();
  });

  const expand = () => {
    setExpanded(true);
    animation.setValue(0);
    Animated.spring(animation, {
      toValue: 1,
      useNativeDriver: true
    }).start();
  };

  const collapse = () => {
    setExpanded(false);
    animation.setValue(1);
    Animated.timing(animation, {
      toValue: 0,
      duration: 200, // ms
      useNativeDriver: true
    }).start();
  };

  const _setMaxHeight = (event) => {
    if (!maxHeight) {
      // height of view plus a small buffer (so it won't cut off any of the body)
      setMaxHeight(event.nativeEvent.layout.height + 20);
    }
  };

  return (
    <>
      {isEditing ? (
        <View style={{ marginTop: -20, marginBottom: 15 }}>
          <TextItem
            key="header"
            item={headerItem}
            editable
            isEditingAnItem={false}
            setIsEditingAnItem={() => {}}
            setKeyboardToolbarComponents={setKeyboardToolbarComponents}
            onChangeItem={onChangeHeaderItem}
            dataSourceRow={dataSourceRow}
          />
          <TextItem
            key="body"
            item={bodyItem}
            editable
            isEditingAnItem={false}
            setIsEditingAnItem={() => {}}
            setKeyboardToolbarComponents={setKeyboardToolbarComponents}
            onChangeItem={onChangeBodyItem}
            dataSourceRow={dataSourceRow}
          />
          {canDelete && (
            <TouchableOpacity
              onPress={onDeleteRow}
              style={{
                position: "absolute",
                padding: 3,
                top: 20,
                right: 20
              }}
            >
              <MaterialIcons
                name="delete"
                size={24}
                color={Glob.get("dangerRed")}
              />
            </TouchableOpacity>
          )}
        </View>
      ) : (
        <>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={onPress}
              style={{
                padding: 3,
                paddingTop: 18 // Looks good if renderableItem.header is of style headerText
              }}
            >
              <Entypo
                name={expanded ? "minus" : "plus"}
                size={24}
                color={Rex.getConfig()?.colors?.text}
              />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={onPress}
              disabled={disabled}
              style={{ flex: 1 }}
            >
              <>{renderableItem.header}</>
            </TouchableOpacity>
          </View>
          <Animated.View
            onLayout={_setMaxHeight}
            style={{
              transform: [
                {
                  scaleY: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 1]
                  })
                }
              ],
              ...(maxHeight && {
                height: shouldBeExpanded ? maxHeight : 0
              }),
              marginLeft: 30,
              overflow: "hidden",
              opacity: animation
            }}
          >
            {renderableItem.body}
          </Animated.View>
        </>
      )}
    </>
  );
}
