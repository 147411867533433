import { getDatabase, ref } from "firebase/database";
import {
  getStorage,
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
import Constants from "expo-constants";
import FirebaseAuth from "src/backend/firebaseAuth";
import School from "school/school";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;
const IS_DEVELOPMENT_MODE = FIREBASE_CONFIG.projectId !== "seabirdmain";

export default class Firebase {
  static getAuth() {
    return FirebaseAuth.getAuth();
  }

  static getUser() {
    // console.log("ACCESS TOKEN:");
    // console.log(FirebaseAuth.getUser()?.stsTokenManager?.accessToken);
    return FirebaseAuth.getUser();
  }

  static getUserID() {
    return FirebaseAuth.getUserID();
  }

  static onAuthStateChanged() {
    return FirebaseAuth.onAuthStateChanged();
  }

  static getUserCredential(password) {
    return FirebaseAuth.getUserCredential(password);
  }

  static logoutUser() {
    return FirebaseAuth.logoutUser();
  }

  static sendPasswordResetEmail(email = null) {
    return FirebaseAuth.sendPasswordResetEmail(email);
  }

  static baseDbRoute() {
    return `/apps/${School.getDatabaseAppID()}`;
  }

  static baseDbMetadataRoute() {
    return `/appsMetadata/${School.getDatabaseAppID()}`;
  }

  static baseDbGlobalUserRoute() {
    return `/users/${FirebaseAuth.getUserID()}`;
  }

  static baseAPIRoute() {
    return `https://us-central1-seabirdmain${
      IS_DEVELOPMENT_MODE ? "development" : ""
    }.cloudfunctions.net/api`;
  }

  // function to get firebase.database().ref(path_name)
  static getDbRef(path) {
    return ref(getDatabase(), `${this.baseDbRoute()}/${path}`);
  }

  static getDbMetadataRef() {
    return ref(getDatabase(), `${this.baseDbMetadataRoute()}`);
  }

  static getDbGlobalUserRef() {
    return ref(getDatabase(), `${this.baseDbGlobalUserRoute()}`);
  }

  static getDbMetaAppsRef(path) {
    return ref(getDatabase(), `/metaApps/${path}`);
  }

  static getDbScheduledEventsRef() {
    return ref(getDatabase(), `/scheduledEvents/${School.getDatabaseAppID()}`);
  }

  // todo: store this in session memory to reduce quantity of data used
  static getMediaURLAsync(fileName) {
    return getDownloadURL(
      storageRef(getStorage(), `${this.baseDbRoute()}/${fileName}`)
    );
  }

  static generateUploadedFileName(localFileName, uploadedByEndUser = false) {
    const getFileExtension = (fileName) => {
      if (!fileName) return "";
      const filePieces = fileName.toLowerCase().split(".");
      if (!filePieces || filePieces.length < 2) return "";
      return filePieces[filePieces.length - 1];
    };
    const userID = FirebaseAuth.getUserID();
    const timestamp = new Date().valueOf();
    const randomNumber = Math.floor(Math.random() * 1000000);
    const extension = getFileExtension(localFileName);
    return `/${
      uploadedByEndUser ? `userUploads/${userID}/` : `uploads/${userID}-`
    }${timestamp}-${randomNumber}.${extension || "jpg"}`;
  }

  // Function to convert a URI to a Blob object
  // Stack Overflow: https://stackoverflow.com/a/76761937
  // Previously just used `fetch`, but then image-uploading crashed the app on Android
  static uriToBlob(uri) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // If successful -> return with blob
      xhr.onload = () => resolve(xhr.response);

      // reject on error
      xhr.onerror = () => reject(new Error("uriToBlob failed"));

      // Set the response type to "blob" - this means the server's response
      // will be accessed as a binary object
      xhr.responseType = "blob";

      // Initialize the request. The third argument set to "true" denotes
      // that the request is asynchronous
      xhr.open("GET", uri, true);

      // Send the request. The "null" argument means that no body content is given for the request
      xhr.send(null);
    });
  }

  static async uploadMedia(
    uri,
    {
      locationName = null,
      title = null,
      uploadedByEndUser = false,
      onProgressUpdate = () => {}
    } = {}
  ) {
    const fileName = locationName
      ? `/${locationName}`
      : this.generateUploadedFileName(uri, uploadedByEndUser);
    const imageBlob = await this.uriToBlob(uri);
    const firebaseImagePath = `${this.baseDbRoute()}${fileName}`;

    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const imageRef = storageRef(storage, firebaseImagePath);
      const uploadTask = uploadBytesResumable(
        imageRef,
        imageBlob,
        title ? { customMetadata: { name: title } } : undefined
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress updates here
          if (onProgressUpdate) {
            const progressPercentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgressUpdate(Math.round(progressPercentage || 0));
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          this.getMediaURLAsync(fileName).then((mediaURL) => resolve(mediaURL));
        }
      );
    });
  }

  static async deleteImage(fileName) {
    const firebaseImagePath = `${this.baseDbRoute()}/${fileName}`;
    return new Promise((resolve, reject) => {
      deleteObject(storageRef(getStorage(), firebaseImagePath))
        .then(resolve)
        .catch(reject);
    });
  }
}
