import React from "react";
import { TouchableOpacity, Platform } from "react-native";
import Rex from "src/globalState";

export default function FloatingActionButton({ icon, onPress, style = {} }) {
  return (
    <TouchableOpacity
      style={[
        {
          width: 80,
          height: 80,
          position: Platform.OS === "web" ? "fixed" : "absolute",
          right: 30,
          bottom: 30,
          borderRadius: 100,
          justifyContent: "center",
          alignItems: "center",
          shadowOpacity: 0.2,
          shadowOffset: { width: 0, height: 0 },
          shadowRadius: 20,
          elevation: 10
        },
        Platform.OS === "web"
          ? { boxShadow: "0px 0px 10px rgba(0,0,0,0.15)" }
          : {},
        { backgroundColor: Rex.getConfig()?.colors?.button },
        style
      ]}
      onPress={onPress}
    >
      {icon}
    </TouchableOpacity>
  );
}
