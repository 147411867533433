import React, { useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import LottieView from "src/components/Lottie";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";

const PRODUCT_ASSEMBLY_ANIMATION = require("resources/animations/productAssembly.json");

const { height, width } = Glob.get("dimensions");

export default function Help({ navigation }) {
  useEffect(() => {
    Analytics.logEvent("view_help");
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Help Me" />
      <ScrollView
        style={{ paddingHorizontal: 15, marginTop: 5, width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View>
          <Text
            style={{
              ...Style.get("headerText"),
              marginTop: 15,
              textAlign: "center"
            }}
          >
            Confused by any of our features?
          </Text>
          <View style={{ height: height / 3.8, alignItems: "center" }}>
            <LottieView
              autoPlay
              loop
              style={{ height: "100%", width: "100%" }}
              source={PRODUCT_ASSEMBLY_ANIMATION}
            />
          </View>
          <Text
            style={{
              ...Style.get("headerText"),
              fontSize: 42,
              marginTop: 15,
              textAlign: "center"
            }}
          >
            Don't panic!
          </Text>
          <Text style={styles.bodyText}>
            We believe in <Text style={{ fontWeight: "bold" }}>insanely</Text>{" "}
            good customer service.
          </Text>
          <Text style={styles.bodyText}>
            That's why we give you direct access to contact our CEO about any
            question or concern, no matter how small. No phone trees, no bots,
            no nonsense.
          </Text>
          <Text style={styles.bodyText}>
            Yes, you have our CEO on speed dial now. His name is Sean.
          </Text>
          <Text style={styles.bodyText}>We know, insane 🤷</Text>
        </View>
        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
          <Button
            text="Instant Chat"
            onPress={() => {
              Analytics.logEvent("touch_help_chatMessage");
              Util.startChatWithSuperAdmin(navigation, true);
            }}
          />
          <Button
            text="Email Us"
            type="email"
            options={{ emailAddress: "team@seabirdapps.com" }}
          />
          <Button
            text="Call Our CEO"
            type="call"
            options={{ phoneNumber: "4407817739" }}
          />
          <Text style={styles.noteText}>
            (Please leave a voicemail if he doesn't answer)
          </Text>
          <Button
            text="Text Our CEO"
            type="sms"
            options={{ smsRecipient: "4407817739" }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  questionDescription: {
    fontSize: 0.021 * height,
    color: "gray"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  },
  noteText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center"
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});
