import React, { useState, useEffect } from "react";
import { View, Platform } from "react-native";
import * as Haptics from "expo-haptics";
import Util from "src/utility";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import RenderHTML from "src/components/RenderHTML";

export default function HTMLItem({
  item,
  editable,
  isEditingAnItem,
  setIsEditingAnItem = () => {},
  onChangeItem = () => {},
  dataSourceRow
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [codeToRender, setCodeToRender] = useState(item.code);

  useEffect(() => {
    if (item.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
    }
  }, []);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;
  const formattedCodeToRender = Util.textParser(codeToRender, {
    dataSourceRow
  }).join("");

  const toggleEditing = () => {
    if (Platform.OS !== "web") Haptics.selectionAsync();
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  return (
    <View style={isEditing ? editingStyle : { margin: 5, padding: 5 }}>
      {editable && !isEditing && (
        <Button
          key="editHTMLCodeButton"
          text="Edit HTML"
          onPress={toggleEditing}
          style={{ backgroundColor: "#555" }}
          small
          disabled={isEditingADifferentItem}
        />
      )}
      {editable && !!isEditing && (
        <View
          style={{
            width: "90%",
            alignSelf: "center",
            padding: 5,
            paddingBottom: 10,
            marginBottom: 10
          }}
        >
          <InputBox
            key="code"
            multiline
            isCode
            header="HTML Code"
            value={item.code}
            onChangeText={(text) => onChangeItem({ ...item, code: text })}
          />
          <View style={{ marginTop: 15 }}>
            <Button
              key="updateButton"
              text="Render"
              onPress={() => setCodeToRender(item.code)}
              small
            />
            <Button
              key="doneButton"
              text="✓ Done"
              onPress={toggleEditing}
              style={{ backgroundColor: "#555" }}
              small
            />
          </View>
        </View>
      )}
      <View style={{ marginTop: 10, alignItems: "center" }}>
        <RenderHTML code={formattedCodeToRender} />
      </View>
    </View>
  );
}
