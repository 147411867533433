import React from "react";
import { Text, View, Platform } from "react-native";

export default function AuthScreenHeader({
  title = "Log in",
  subtitle = "Welcome back again!"
}) {
  return (
    <View style={{ marginTop: Platform.OS === "android" ? 40 : 10 }}>
      <Text style={{ fontWeight: "700", fontSize: 24 }}>{title}</Text>
      <Text style={{ fontSize: 15 }}>{subtitle}</Text>
    </View>
  );
}
