import React from "react";
import Glob from "src/globalConstants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { height, width } = Glob.get("dimensions");

// Note: This was mostly written by AI
export default function DraggableList(props) {
  const { data, onDragEnd, renderItem, keyExtractor } = props;
  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) return;
        const newData = Array.from(data);
        const [removed] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, removed);
        onDragEnd({ data: newData });
      }}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ width, paddingBottom: height * 0.5 }}
          >
            {data.map((item, index) => (
              <Draggable
                key={keyExtractor(item)}
                draggableId={keyExtractor(item)}
                index={index}
              >
                {(provided2, snapshot) => (
                  <div
                    ref={provided2.innerRef}
                    {...provided2.draggableProps}
                    {...provided2.dragHandleProps}
                  >
                    {renderItem({
                      item,
                      drag: provided2.dragHandleProps,
                      isActive: snapshot.isDragging,
                      index
                    })}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
