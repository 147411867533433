import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import LottieView from "src/components/Lottie";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import Util from "src/utility";

const NEW_HOME_ANIMATION = require("resources/animations/newHome.json");

const { height, width } = Glob.get("dimensions");

export default function EditUserHomeScreenSettings({ navigation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [homeScreenSettings, setHomeScreenSettings] = useState(null);
  const [
    userHasCustomizedHomeScreen,
    setUserHasCustomizedHomeScreen
  ] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_editUserHomeScreenSettings");
    Database.fetchAllUserData().then((user) => {
      setHomeScreenSettings(user?.homeScreenSettings);
      setUserHasCustomizedHomeScreen(
        user?.tasks?.done?.customizeHomeScreen || false
      );
      setIsLoading(false);
    });
  }, []);

  let examplePhoneName = "phone";
  if (Platform.OS === "ios") examplePhoneName = "iPhone";
  else if (Platform.OS === "android") examplePhoneName = "Android phone";

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Your Home Screen" />
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{ paddingHorizontal: 20, width }}
      >
        <View style={{ height: height / 3, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop={false}
            style={{ height: "100%", width: "100%" }}
            source={NEW_HOME_ANIMATION}
          />
        </View>
        <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
          Personalize your home screen
        </Text>
        {isLoading ? (
          <ActivityIndicator size="large" style={{ marginTop: 30 }} />
        ) : (
          <>
            {!userHasCustomizedHomeScreen ? (
              <>
                <Text style={{ marginTop: 15 }}>
                  You can customize your app's home screen by pressing down on
                  any portal icon for 2 seconds. It's just like how you
                  rearrange the apps on your {examplePhoneName}.
                </Text>
                <Button
                  text="Try it now"
                  style={{ marginTop: 30 }}
                  onPress={() => {
                    Analytics.logEvent(
                      "touch_editUserHomeScreenSettings_tryCustomizing"
                    );
                    // Go back twice to get to the home screen
                    navigation.goBack();
                    navigation.goBack();
                  }}
                />
              </>
            ) : (
              <>
                {homeScreenSettings?.useDefaultPortalsForHomeScreen ? (
                  <>
                    <Text style={{ marginTop: 15 }}>
                      You're using this app's default arrangement for your home
                      screen.
                    </Text>
                    <Text style={{ marginTop: 15 }}>
                      If you want, you can customize your app's home screen by
                      pressing down on any portal icon for 2 seconds, just like
                      how you rearrange the apps on your {examplePhoneName}.
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={{ marginTop: 15 }}>
                      You've customized the arrangement of portals on your app's
                      home screen ✨
                    </Text>
                    <Text style={{ marginTop: 15 }}>
                      If you want, you can go back to using this app's default
                      arrangement for your home screen:
                    </Text>
                    {isSaving ? (
                      <>
                        <ActivityIndicator
                          size="small"
                          style={{ marginTop: 30 }}
                        />
                        <Text
                          style={{
                            marginTop: 5,
                            textAlign: "center",
                            color: "gray"
                          }}
                        >
                          Reloading your app...
                        </Text>
                      </>
                    ) : (
                      <Button
                        text="Switch back to the default"
                        style={{ marginTop: 30 }}
                        onPress={() => {
                          Analytics.logEvent(
                            "touch_editUserHomeScreenSettings_useDefault"
                          );
                          setIsSaving(true);
                          Database.useDefaultPortalsForHomeScreen();
                          setTimeout(Util.reloadApp, 1000);
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
