import React from "react";
import { Platform } from "react-native";
import { Helmet } from "react-helmet";

export default function WebPageMetaTitle({ title }) {
  if (Platform.OS === "web")
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  return null;
}
