import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Checkbox from "src/components/Checkbox";

const { width } = Glob.get("dimensions");

const DEFAULT_PREFERENCES = {
  receivePushNotifications: true,
  receiveEmailForAll: true,
  receiveTextMessages: true,
  receivePhoneCalls: true
};

function NotificationPreferences({ navigation }) {
  const [preferences, setPreferences] = useState({});
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { textMessagingEnabled, phoneCallsEnabled } = Rex.getConfig() || {};

  useEffect(() => {
    Analytics.logEvent("view_notificationPreferences");

    Database.subscribeToNotificationPreferences((pref) => {
      setPreferences(pref);
      // Set preferences if none yet
      if (!pref) Database.setNotificationPreferences(DEFAULT_PREFERENCES);
    });

    Database.fetchAllUserData().then((data) => {
      setEmail(data?.email);
      setPhoneNumber(data?.phoneNumber || null);
    });

    // cleanup called when component is unmounting
    return Database.unsubscribeFromNotificationPreferences;
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Notification Preferences" />
      <View style={{ paddingHorizontal: 15, flex: 5, width }}>
        <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
          Customize which notifications you receive
        </Text>
        <Text style={{ ...Style.get("subheaderText"), marginBottom: 15 }}>
          Note that you might miss important alerts if you turn off
          notifications.
        </Text>
        <Checkbox
          checked={preferences?.receivePushNotifications}
          text="Receive push notifications"
          onChange={(receive) => {
            Analytics.logEvent(
              "touch_notificationPreferences_togglePreference",
              { receivePushNotifications: receive }
            );
            Database.setNotificationPreferences({
              receivePushNotifications: receive
            });
          }}
        />
        <Checkbox
          checked={preferences?.receiveEmailForAll}
          text={`Receive email notifications${email ? ` (${email})` : ""}`}
          onChange={(receive) => {
            Analytics.logEvent(
              "touch_notificationPreferences_togglePreference",
              { receiveEmailForAll: receive }
            );
            Database.setNotificationPreferences({
              receiveEmailForAll: receive
            });
          }}
        />
        {textMessagingEnabled && (
          <Checkbox
            checked={!phoneNumber ? false : preferences?.receiveTextMessages}
            text={`Receive text messages${
              phoneNumber ? ` at ${phoneNumber}` : " (no phone number set)"
            }`}
            onChange={(receive) => {
              if (phoneNumber) {
                Analytics.logEvent(
                  "touch_notificationPreferences_togglePreference",
                  { receiveTextMessages: receive }
                );
                Database.setNotificationPreferences({
                  receiveTextMessages: receive
                });
              }
            }}
            style={{ opacity: phoneNumber ? 1 : 0.3 }}
          />
        )}
        {phoneCallsEnabled && (
          <Checkbox
            checked={!phoneNumber ? false : preferences?.receivePhoneCalls}
            text={`Receive phone calls${
              phoneNumber ? ` at ${phoneNumber}` : " (no phone number set)"
            }`}
            onChange={(receive) => {
              if (phoneNumber) {
                Analytics.logEvent(
                  "touch_notificationPreferences_togglePreference",
                  { receivePhoneCalls: receive }
                );
                Database.setNotificationPreferences({
                  receivePhoneCalls: receive
                });
              }
            }}
            style={{ opacity: phoneNumber ? 1 : 0.3 }}
          />
        )}
      </View>
    </View>
  );
}

export default NotificationPreferences;

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
