import React from "react";
import { Text, View, Image } from "react-native";
import Rex from "src/globalState";

export default function DigitalID({
  name,
  email,
  image,
  subtitle,
  bottomText,
  color
}) {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        padding: 15,
        width: "100%",
        shadowOpacity: 0.15,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 0 }
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <View
          style={{
            height: "100%"
          }}
        >
          <Image
            source={image}
            style={{
              height: 80,
              width: 80,
              resizeMode: "contain"
            }}
          />
        </View>
        <View style={{ flex: 1, paddingLeft: 10 }}>
          <Text
            style={{
              fontSize: 32,
              fontWeight: "bold",
              color: Rex.getConfig()?.colors?.text
            }}
          >
            {name}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color
            }}
          >
            {subtitle}
          </Text>
          <Text
            style={{
              fontSize: 14,
              color
            }}
          >
            {email}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 20 }}>
        <Text
          style={{
            fontSize: 14,
            color: "#808080",
            textAlign: "center"
          }}
        >
          {bottomText}
        </Text>
      </View>
    </View>
  );
}
