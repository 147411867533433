import React, { useState, useEffect } from "react";
import { Text, View, Platform, Share } from "react-native";
import * as Haptics from "expo-haptics";
import Constants from "expo-constants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Util from "src/utility";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import Dropdown from "src/components/Dropdown";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import HelpText from "src/components/HelpText";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import DocumentPicker from "src/components/DocumentPicker";

const IS_ONESPOT = Constants.expoConfig.slug === "onespot";

const BUTTON_TYPES = {
  button_web: {
    friendlyName: "Web Link",
    helpText:
      "Pressing this button will open a specified web page within your app."
  },
  button_nav: {
    friendlyName: "Navigate to Screen",
    helpText:
      "Pressing this button will navigate the user to a specified portal."
  },
  button_call: {
    buttonComponent: "call",
    friendlyName: "Call",
    helpText:
      "Pressing this button will let the user call a specified phone number."
  },
  button_email: {
    buttonComponent: "email",
    friendlyName: "Email",
    helpText:
      "Pressing this button will let the user email a specified email address."
  },
  button_sms: {
    buttonComponent: "sms",
    friendlyName: "SMS",
    helpText:
      "Pressing this button will start composing a text message to a specified recipient."
  },
  button_chat: {
    friendlyName: "Instant Chat",
    helpText:
      "Pressing this button will start an instant chat with a specified user."
  },
  button_app_switcher: {
    friendlyName: "App Switcher (Advanced)",
    helpText:
      "Pressing this button will let a user switch to a different app in the Onespot ecosystem."
  },
  button_share_app: {
    friendlyName: "Share This App",
    helpText:
      "Pressing this button will open the share dialog to let the user share this app."
  },
  button_pdf: {
    friendlyName: "PDF Document",
    helpText: "Pressing this button will open a PDF document within your app."
  }
};

const ERROR_MESSAGES = {
  navUnlinkedDeleted:
    "This button isn't linked to anything. The screen it used to be linked to might have been deleted.",
  navUnlinked: "This button isn't linked to anything.",
  webUnlinked: "This button isn't linked to a website.",
  chatUnlinked: "This chat button isn't linked to anyone.",
  callEmpty: "This button isn't connected to a phone number.",
  emailEmpty: "This button isn't connected to an email address.",
  smsRecipientEmpty: "This button has no SMS recipient specified.",
  appUnlinked: "This button isn't linked to another app.",
  pdfUnlinked: "This button isn't linked to a PDF document."
};

export default function ButtonItem({
  item,
  editable,
  isEditingAnItem,
  setIsEditingAnItem = () => {},
  onChangeItem,
  dataSourceRow,
  userName,
  navigation,
  navigate = () => {},
  onUploadPDF = () => {}
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [showStyleOptions, setShowStyleOptions] = useState(false);
  const [editedTitle, setEditedTitle] = useState(!!item.title);
  const [portalDropdownItems, setPortalDropdownItems] = useState([]);
  const [userDropdownItems, setUserDropdownItems] = useState([]);
  const [appsDropdownItems, setAppsDropdownItems] = useState([]);
  const [appsMetadata, setAppsMetadata] = useState(null);

  const chatEnabled = Rex.getConfig()?.chatEnabled;
  const advancedComponentsEnabled = Rex.getConfig()?.advancedComponentsEnabled;
  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToManageUsers =
    !!adminPrivileges && adminPrivileges?.includes("ManageUsers");

  const BUTTON_TYPES_DROPDOWN_ITEMS = Object.entries(BUTTON_TYPES)
    .filter(([key]) => chatEnabled || key !== "button_chat")
    .filter(
      ([key]) => advancedComponentsEnabled || key !== "button_app_switcher"
    )
    .map(([key, value]) => ({ value: key, text: value.friendlyName }));

  useEffect(() => {
    if (item.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
      setIsNewItem(true);
    }
    if (item.type === "button_app_switcher") fetchAndSetAllApps();
  }, []);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;
  let options = {};
  let onPress = null;

  // todo: Improve navigation in general...
  const navigateToPortal = (portal) => {
    if (portal) {
      Database.getPortalMetadata(portal).then((metadata) => {
        if (metadata) {
          if (metadata.portalType === "native") {
            // e.g. 'food'
            navigate(portal, {
              navName: portal,
              txtName: metadata.txtName
            });
          } else {
            // e.g. 'webNav'
            navigate(metadata.portalType, {
              navName: portal,
              txtName: metadata.txtName
            });
          }
        } else {
          Util.alert("Oops! 😕", ERROR_MESSAGES.navUnlinkedDeleted);
        }
      });
    } else {
      Util.alert("Oops! 😕", ERROR_MESSAGES.navUnlinked);
    }
  };

  const switchApps = (appID) => {
    if (appID && appsMetadata && appsMetadata[appID]) {
      const app = { ...appsMetadata[appID], key: appID };
      navigate("onespotJoin", {
        navigatedFromProfile: true,
        navigateToApp: app,
        fullName: userName
      });
    } else {
      Util.alert("Oops! 😕", ERROR_MESSAGES.appUnlinked);
    }
  };

  const navigateToWebsite = (
    urlRaw,
    title,
    errorMessage = ERROR_MESSAGES.webUnlinked
  ) => {
    const { openExternally } = item;
    if (!urlRaw) Util.alert("Oops! 😕", errorMessage);
    else {
      let url = urlRaw.trim();
      if (!url.includes("http")) url = `https://${url}`;
      if (
        Platform.OS === "web" ||
        openExternally ||
        Firebase.getUserID() === Glob.get("demoAccountUserID")
      )
        Util.openURL(url);
      else navigate("webNav", { title, url });
    }
  };

  const navigateToChat = (uid, firstName) => {
    if (uid) {
      navigate("notificationDetails", {
        chatRecipient: { firstName, lastName: "", uid }
      });
    } else {
      Util.alert("Oops! 😕", ERROR_MESSAGES.chatUnlinked);
    }
  };

  let invalidErrorMessage = null;
  let shouldHide = false;
  // Web
  if (item.type === "button_web") {
    const url = Util.textParser(item.url, { dataSourceRow }).join("");
    const title = Util.textParser(item.title, { dataSourceRow }).join("");
    if (!item.url) invalidErrorMessage = ERROR_MESSAGES.webUnlinked;
    if (dataSourceRow && !url) shouldHide = true;
    onPress = () => navigateToWebsite(url, title);
  }
  // To Screen
  else if (item.type === "button_nav") {
    const portal = Util.textParser(item.portal, { dataSourceRow }).join(""); // Note: this must be the exact portal key
    if (!item.portal) invalidErrorMessage = ERROR_MESSAGES.navUnlinked;
    if (dataSourceRow && !portal) shouldHide = true;
    onPress = () => navigateToPortal(portal);
  }
  // Instant Chat
  else if (item.type === "button_chat") {
    const uid = Util.textParser(item.recipient, { dataSourceRow }).join("");
    const firstName = Util.textParser(item.title, { dataSourceRow }).join("");
    onPress = () => navigateToChat(uid, firstName);
    if (!item.recipient) invalidErrorMessage = ERROR_MESSAGES.chatUnlinked;
    if (dataSourceRow && !uid) shouldHide = true;
  }
  // App Switcher
  else if (item.type === "button_app_switcher") {
    const appID = Util.textParser(item.appID, { dataSourceRow }).join(""); // Note: this must be the exact database app ID
    if (!item.appID) invalidErrorMessage = ERROR_MESSAGES.appUnlinked;
    if (dataSourceRow && !appID) shouldHide = true;
    onPress = () => switchApps(appID);
  }
  // Call
  else if (item.type === "button_call") {
    options = {
      phoneNumber: Util.textParser(item.number || "", { dataSourceRow }).join(
        ""
      )
    };
    if (!item.number) invalidErrorMessage = ERROR_MESSAGES.callEmpty;
    if (dataSourceRow && !options.phoneNumber) shouldHide = true;
  }
  // Email
  else if (item.type === "button_email") {
    options = {
      emailAddress: Util.textParser(item.email || "", { dataSourceRow }).join(
        ""
      )
    };
    if (!item.email) invalidErrorMessage = ERROR_MESSAGES.emailEmpty;
    if (dataSourceRow && !options.emailAddress) shouldHide = true;
  }
  // SMS
  else if (item.type === "button_sms") {
    options = {
      smsRecipient: Util.textParser(item.recipient || "123456789", {
        dataSourceRow
      }).join(""),
      smsContent: Util.textParser(item.content || "", { dataSourceRow }).join(
        ""
      )
    };
    if (!item.recipient) invalidErrorMessage = ERROR_MESSAGES.smsRecipientEmpty;
  }
  // Share App
  else if (item.type === "button_share_app") {
    onPress = async () => {
      try {
        const { webLink } = await Database.fetchPrimaryMetaApp();
        const shareLink = `${webLink}&openAppStore=true`;
        const result = await Share.share({
          message: shareLink
        });
        if (result.action === Share.sharedAction) {
          Analytics.logEvent("action_shareAppButton_shared");
        }
      } catch (error) {
        console.error("Error sharing app:", error);
      }
    };
  }
  // PDF
  else if (item.type === "button_pdf") {
    const url = Util.textParser(item.url, { dataSourceRow }).join("");
    const title = Util.textParser(item.title, { dataSourceRow }).join("");
    if (!item.url) invalidErrorMessage = ERROR_MESSAGES.pdfUnlinked;
    if (dataSourceRow && !url) shouldHide = true;
    onPress = () => navigateToWebsite(url, title, ERROR_MESSAGES.pdfUnlinked);
  }

  const fetchAndSetPortalDropdownItems = () => {
    if (!portalDropdownItems || portalDropdownItems.length < 1)
      Database.getAllPortalMetadata().then((allMetadata) => {
        setPortalDropdownItems(
          Object.entries(allMetadata || [])
            .map(([name, metadata]) => ({
              value: name,
              text: metadata.txtName
            }))
            .sort((a, b) => {
              if (a.text < b.text) return -1;
              if (a.text > b.text) return 1;
              return 0;
            })
        );
      });
  };

  const fetchAndSetAllUsers = () => {
    if (allowedToManageUsers) {
      Database.fetchAllUsers().then((allUsers) => {
        setUserDropdownItems(
          allUsers
            .map((user) => ({
              value: user.uid,
              text: `${user.firstName} ${user.lastName}`
            }))
            .sort((a, b) => {
              const aName = `${a.text}`;
              const bName = `${b.text}`;
              if (aName < bName) return -1;
              if (aName > bName) return 1;
              return 0;
            })
        );
      });
    }
  };

  const fetchAndSetAllApps = () => {
    Database.onespotFetchAllAppsMetadata().then((allApps) => {
      setAppsMetadata(allApps);
      setAppsDropdownItems(
        Object.entries(allApps || {})
          .filter(([appID, app]) => {
            const shouldShow = app.public && !(IS_ONESPOT && app.hideInOnespot);
            if (Rex.getMetaApp()?.apps)
              return shouldShow && appID in Rex.getMetaApp().apps;
            return shouldShow;
          })
          .map(([appID, app]) => ({
            value: appID,
            text: `${app.name}`
          }))
          .sort((a, b) => {
            const aName = `${a.text}`;
            const bName = `${b.text}`;
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          })
      );
    });
  };

  const toggleEditing = () => {
    if (Platform.OS !== "web") Haptics.selectionAsync();
    if (isEditing) setIsNewItem(false);
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
    setShowStyleOptions(isEditing);
    if (item.type === "button_nav") fetchAndSetPortalDropdownItems();
    else if (item.type === "button_chat") fetchAndSetAllUsers();
    else if (item.type === "button_app_switcher") fetchAndSetAllApps();
  };

  const onSetNavigateToScreen = (portal) => {
    let { title } = item;
    if (!editedTitle) {
      // Just in case somehow the data isn't formatted right
      try {
        const portalTitle = portalDropdownItems.filter(
          (p) => p.value === portal
        )[0].text;
        title = portalTitle;
      } catch {
        console.log("data poorly formatted");
      }
    }
    onChangeItem({ ...item, portal, title });
  };

  if (editable) onPress = toggleEditing;

  const allPortalKeys = portalDropdownItems.map((p) => p.value);
  const allUserIDs = userDropdownItems.map((u) => u.value);
  const allAppIDs = appsDropdownItems.map((a) => a.value);

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  if (!editable && shouldHide) return null;

  const buttonText = editable
    ? item.title
    : Util.textParser(item.title, { dataSourceRow });

  return (
    <View style={isEditing ? editingStyle : {}}>
      <Button
        key={item.key}
        text={buttonText}
        type={BUTTON_TYPES[item.type].buttonComponent}
        options={options}
        onPress={onPress}
        style={item.style}
        textStyle={{ fontWeight: "500", ...(item.textStyle || {}) }}
        small={item.small}
        flat={item.flat}
        outline={item.outline}
        color={item.color}
        align={item.align}
        isLinkStyle={item.isLinkStyle}
        disabled={isEditingADifferentItem}
        showError={editable && !isNewItem && !!invalidErrorMessage}
        errorMessage={invalidErrorMessage}
      />
      {isEditing && (
        <View
          style={{
            width: "90%",
            alignSelf: "center",
            padding: 5,
            paddingBottom: 10,
            marginBottom: 10
          }}
        >
          <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
            Action
          </Text>
          <Dropdown
            value={item.type}
            items={BUTTON_TYPES_DROPDOWN_ITEMS}
            onSelect={(v) => {
              setIsNewItem(true);
              if (v === "button_nav") fetchAndSetPortalDropdownItems();
              else if (v === "button_chat") fetchAndSetAllUsers();
              else if (v === "button_app_switcher") fetchAndSetAllApps();
              onChangeItem({ ...item, type: v });
            }}
          />
          {!!BUTTON_TYPES[item.type].helpText && (
            <HelpText text={BUTTON_TYPES[item.type].helpText} />
          )}
          <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
            Design
          </Text>
          {showStyleOptions ? (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <View style={{ flexDirection: "column", flex: 1 }}>
                  <Text style={{ ...Style.get("subheaderText") }}>Style</Text>
                  <Checkbox
                    text="Filled"
                    checked={!item.outline && !item.isLinkStyle}
                    radio
                    onChange={() => {
                      const newItem = { ...item };
                      delete newItem.outline;
                      delete newItem.isLinkStyle;
                      onChangeItem(newItem);
                    }}
                  />
                  <Checkbox
                    text="Outlined"
                    checked={item.outline && !item.isLinkStyle}
                    radio
                    onChange={() => {
                      const newItem = { ...item, outline: true };
                      delete newItem.isLinkStyle;
                      onChangeItem(newItem);
                    }}
                  />
                  <Checkbox
                    text="Link"
                    checked={item.isLinkStyle}
                    radio
                    onChange={() =>
                      onChangeItem({ ...item, isLinkStyle: true })
                    }
                  />
                </View>
                {item.isLinkStyle ? (
                  <View
                    style={{ flexDirection: "column", flex: 1, marginLeft: 20 }}
                  >
                    <HelpText text="Tip: Buttons styled like links look great when in a list." />
                  </View>
                ) : (
                  <>
                    <View style={{ flexDirection: "column", flex: 1 }}>
                      <Text
                        style={{ ...Style.get("subheaderText"), marginTop: 5 }}
                      >
                        Size
                      </Text>
                      <Checkbox
                        text="Big"
                        checked={!item.small}
                        radio
                        onChange={() => {
                          const newItem = { ...item };
                          delete newItem.small;
                          onChangeItem(newItem);
                        }}
                      />
                      <Checkbox
                        text="Small"
                        checked={item.small}
                        radio
                        onChange={() => onChangeItem({ ...item, small: true })}
                      />
                    </View>
                    <View style={{ flexDirection: "column", flex: 1 }}>
                      <Text
                        style={{ ...Style.get("subheaderText"), marginTop: 5 }}
                      >
                        Align
                      </Text>
                      <Checkbox
                        text="Left"
                        checked={item.align === "left"}
                        radio
                        onChange={() =>
                          onChangeItem({ ...item, align: "left" })
                        }
                      />
                      <Checkbox
                        text="Center"
                        checked={!item.align || item.align === "center"}
                        radio
                        onChange={() =>
                          onChangeItem({ ...item, align: "center" })
                        }
                      />
                      <Checkbox
                        text="Right"
                        checked={item.align === "right"}
                        radio
                        onChange={() =>
                          onChangeItem({ ...item, align: "right" })
                        }
                      />
                    </View>
                  </>
                )}
              </View>
              <Text
                style={{
                  ...Style.get("subheaderText"),
                  marginTop: 5,
                  marginBottom: 0
                }}
              >
                Color
              </Text>
              <InputBox
                onChangeText={(color) => onChangeItem({ ...item, color })}
                value={item.color || Rex.getConfig()?.colors?.button}
                colorPicker
              />
            </View>
          ) : (
            <Button
              text="Edit design"
              flat
              small
              outline
              color="#555"
              icon="453068a6-6d1d-4b5f-9d70-c7cfab2d1a56" // paintbrush
              align="left"
              onPress={() => setShowStyleOptions(true)}
            />
          )}
          {item.type === "button_nav" && (
            <>
              <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
                Navigate to...
              </Text>
              <Dropdown
                enableAutocomplete
                value={allPortalKeys.includes(item.portal) ? item.portal : null}
                items={portalDropdownItems}
                onSelect={onSetNavigateToScreen}
              />
            </>
          )}
          {item.type === "button_chat" && (
            <>
              <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
                Chat with...
              </Text>
              {allowedToManageUsers ? (
                <Dropdown
                  enableAutocomplete
                  value={
                    allUserIDs.includes(item.recipient) ? item.recipient : null
                  }
                  items={userDropdownItems}
                  onSelect={(recipient) => onChangeItem({ ...item, recipient })}
                />
              ) : (
                <Text style={Style.get("subheaderText")}>
                  Sorry, you don't have permission to view app users.
                </Text>
              )}
            </>
          )}
          {item.type === "button_app_switcher" && (
            <>
              <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
                Switch to...
              </Text>
              <Dropdown
                enableAutocomplete
                value={allAppIDs.includes(item.appID) ? item.appID : null}
                items={appsDropdownItems}
                onSelect={(appID) => onChangeItem({ ...item, appID })}
              />
            </>
          )}
          <InputBox
            key="title"
            header="Title"
            value={item.title}
            onChangeText={(text) => {
              setEditedTitle(true);
              onChangeItem({ ...item, title: text });
            }}
          />
          {item.type === "button_web" && (
            <>
              <InputBox
                key="web_link"
                header="Link"
                value={item.url}
                navigation={navigation}
                onChangeText={(url) => onChangeItem({ ...item, url })}
                keyboardType="url"
                browseForLinkOptions={{
                  show: true,
                  originalURL: "https://www.google.com",
                  onPickURL: (url) => onChangeItem({ ...item, url }),
                  disabled: false
                }}
              />
              <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
                Embedding
              </Text>
              <Checkbox
                key="web_open_externally"
                checked={item.openExternally || false}
                text="Open externally in browser"
                onChange={(openExternally) =>
                  onChangeItem({ ...item, openExternally })
                }
              />
              <HelpText
                text={
                  item.openExternally
                    ? "The website will open externally in the user's device's web browser (not recommended, except for donations/payments)"
                    : "The website will open seamlessly within your app (recommended)"
                }
              />
            </>
          )}
          {item.type === "button_sms" && (
            <InputBox
              key="sms_message"
              header="Text Message"
              description="This is the text message that will be populated by default"
              multiline
              value={item.content}
              onChangeText={(text) => onChangeItem({ ...item, content: text })}
            />
          )}
          {item.type === "button_sms" && (
            <InputBox
              key="sms_recipient"
              header="Text Message Recipient"
              description="This is phone number of who the text will go to by default"
              value={item.recipient}
              onChangeText={(text) =>
                onChangeItem({ ...item, recipient: text })
              }
            />
          )}
          {item.type === "button_email" && (
            <InputBox
              key="email_recipient"
              header="Email Address"
              description="This is who will receive the email"
              keyboardType="email-address"
              value={item.email}
              onChangeText={(text) => onChangeItem({ ...item, email: text })}
            />
          )}
          {item.type === "button_call" && (
            <InputBox
              key="call_number"
              header="Phone Number"
              description="This is the number that will be called when the user presses this button"
              value={item.number}
              keyboardType="number-pad"
              onChangeText={(text) => onChangeItem({ ...item, number: text })}
            />
          )}
          {item.type === "button_pdf" && (
            <>
              <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
                PDF Document
              </Text>
              {item.url ? (
                <Text>{item.url}</Text>
              ) : (
                <Text>No PDF uploaded yet.</Text>
              )}
              <DocumentPicker
                isNew={!item.url}
                onUpload={(url, name) => {
                  onChangeItem({ ...item, url, title: name });
                  onUploadPDF(url, name);
                }}
              />
            </>
          )}
          <View style={{ marginTop: 15 }}>
            <Button
              key="doneButton"
              text="✓ Done"
              onPress={toggleEditing}
              style={{ backgroundColor: "#555" }}
              small
            />
          </View>
        </View>
      )}
    </View>
  );
}
