import React from "react";
import { View, Image, Text } from "react-native";
import Glob from "src/globalConstants";

export default function OnespotProBadge({ text = null }) {
  return (
    <View
      style={{
        borderRadius: 20,
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderWidth: 0.5,
        borderColor: "#ffd000",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff9cf"
      }}
    >
      {!text && (
        <Image
          source={Glob.get("onespotSmileImage")}
          style={{
            height: 12,
            width: 12,
            resizeMode: "contain",
            tintColor: "#e8cb00"
          }}
        />
      )}
      <Text
        style={{
          fontSize: 10,
          fontWeight: "bold",
          color: "#e8cb00"
        }}
      >
        {text || " Pro"}
      </Text>
    </View>
  );
}
