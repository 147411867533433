import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import Icon from "src/components/Icon";
import SearchBar from "src/components/searchBar";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import IconCollection from "resources/icons/Icons";
import Analytics from "src/backend/analytics";
import Firebase from "src/backend/firebase";
import TouchableLink from "./dynamicContent/TouchableLink";
import InputBox from "./InputBox";
import Button from "./Button";

const { height, width } = Glob.get("dimensions");
const PICKABLE_ICONS = IconCollection.filter((icon) => !icon.hiddenInPicker);
const NUMBER_OF_ICONS = PICKABLE_ICONS.length.toLocaleString();

export default function IconPicker({
  icon,
  iconURL,
  onSelectIcon = () => {},
  onSetIconURL = () => {},
  onTogglePicker = () => {}
}) {
  const [showPicker, setShowPicker] = useState(false);
  const [pickingCustomIcon, setPickingCustomIcon] = useState(!!iconURL);
  const [showImageURLInput, setShowImageURLInput] = useState(false);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filteredIcons, setFilteredIcons] = useState(PICKABLE_ICONS);
  const [textToSearch, setTextToSearch] = useState(null);

  const onSearch = (text) => {
    setTextToSearch(text);
  };

  useEffect(() => {
    if (textToSearch !== null) {
      const searchResults = Util.searchItems(
        PICKABLE_ICONS,
        textToSearch,
        "iconName"
      );
      setFilteredIcons(searchResults);
      setTextToSearch(null);
    }
  }, [textToSearch]);

  const pickMedia = () => {
    setShowImageURLInput(false);
    Analytics.logEvent("touch_IconPicker_pickMedia", { type: "image" });
    Util.pickMedia({ type: "image" })
      .then((uri) => {
        Analytics.logEvent("action_IconPicker_pickMediaStarting", {
          type: "image",
          uri
        });
        setIsUploadingMedia(true);
        Firebase.uploadMedia(uri, { onProgressUpdate: setUploadProgress }).then(
          (media) => {
            Analytics.logEvent("action_IconPicker_pickMediaComplete", {
              type: "image",
              uri,
              media
            });
            onSetIconURL(media);
            setIsUploadingMedia(false);
            setUploadProgress(0);
          }
        );
      })
      .catch(() => {
        Util.alert("Enable camera permissions to continue");
      });
  };

  return (
    <View
      style={[
        styles.container,
        {
          borderWidth: showPicker ? 1 : 0,
          alignItems: showPicker ? "center" : "flex-start"
        }
      ]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity
          style={[
            styles.icon,
            Platform.OS === "web"
              ? { boxShadow: "0px 0px 7px rgba(0,0,0,0.2)" }
              : {}
          ]}
          onPress={() => {
            const shouldShowPicker = !showPicker;
            onTogglePicker(shouldShowPicker);
            setShowPicker(shouldShowPicker);
            if (!shouldShowPicker) setShowImageURLInput(false);
          }}
        >
          <Icon
            icon={icon}
            iconURL={iconURL}
            color={Rex.getConfig()?.colors?.background}
            size={38}
          />
        </TouchableOpacity>
      </View>
      {showPicker && (
        <>
          <TouchableOpacity
            style={[
              styles.doneButton,
              { backgroundColor: Rex.getConfig()?.colors?.button }
            ]}
            onPress={() => {
              onTogglePicker(false);
              setShowPicker(false);
              setShowImageURLInput(false);
            }}
          >
            <Text style={styles.doneButtonText}>Done</Text>
          </TouchableOpacity>
          {pickingCustomIcon ? (
            <>
              <Text
                style={{
                  marginTop: 10,
                  paddingHorizontal: 20,
                  fontSize: 12
                }}
              >
                Set a custom image below or{" "}
                <Text style={{ textDecorationLine: "underline" }}>
                  <TouchableLink
                    type="button"
                    text="pick from our icon set"
                    onPress={() => {
                      Analytics.logEvent(
                        "touch_IconPicker_showPickFromIconSet"
                      );
                      setPickingCustomIcon(false);
                      setShowImageURLInput(false);
                    }}
                  />
                </Text>
                .
              </Text>
              {isUploadingMedia ? (
                <>
                  <ActivityIndicator size="large" style={{ marginTop: 20 }} />
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#aaa",
                      marginTop: 10,
                      marginBottom: 20
                    }}
                  >
                    {uploadProgress}% uploaded...
                  </Text>
                </>
              ) : (
                <View
                  style={{
                    width: width * 0.9,
                    paddingHorizontal: 10,
                    paddingBottom: 20
                  }}
                >
                  {showImageURLInput && (
                    <InputBox
                      key="iconURL-inputbox"
                      header="Custom Icon Link"
                      description="Paste a URL to an image file (png, jpg, etc.)"
                      placeholder="https://example.com/icon.png"
                      value={iconURL}
                      flexibleWidth
                      openStyle
                      multiline
                      onChangeText={(text) => onSetIconURL(text)}
                    />
                  )}
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignSelf: "center",
                      maxWidth: showImageURLInput ? width * 0.6 : undefined,
                      marginTop: 15
                    }}
                  >
                    <Button
                      text={`Upload Image${
                        showImageURLInput ? " Instead" : ""
                      }`}
                      onPress={pickMedia}
                      small
                      flat
                      flexibleWidth
                      icon="upload"
                    />
                    {!showImageURLInput && (
                      <Button
                        text="From URL"
                        onPress={() => setShowImageURLInput(true)}
                        small
                        flat
                        flexibleWidth
                        icon="link"
                      />
                    )}
                  </View>
                </View>
              )}
            </>
          ) : (
            <>
              <Text
                style={{ marginTop: 10, paddingHorizontal: 20, fontSize: 12 }}
              >
                Pick from {NUMBER_OF_ICONS} icons below or{" "}
                <Text style={{ textDecorationLine: "underline" }}>
                  <TouchableLink
                    type="button"
                    text="set a custom icon"
                    onPress={() => {
                      Analytics.logEvent("touch_IconPicker_showsSetCustomIcon");
                      setPickingCustomIcon(true);
                    }}
                  />
                </Text>
                .
              </Text>
              <View style={styles.searchSectionContainer}>
                <SearchBar
                  placeholder="Search"
                  onChangeText={onSearch}
                  isSearching={!!textToSearch}
                />
                {filteredIcons?.length > 0 ? (
                  <FlatList
                    data={filteredIcons}
                    nestedScrollEnabled
                    renderItem={({ item }) => (
                      <TouchableOpacity
                        style={styles.iconContainer}
                        onPress={() => onSelectIcon(item.uuid)}
                      >
                        <Icon
                          icon={item.uuid}
                          color={Rex.getConfig()?.colors?.background}
                          size={20}
                        />
                      </TouchableOpacity>
                    )}
                    keyExtractor={(item) => item.uuid}
                    contentContainerStyle={{ alignItems: "center" }}
                    numColumns={Math.floor(width / 70)}
                    scrollIndicatorInsets={{ right: 1 }}
                  />
                ) : (
                  <>
                    <Text
                      style={{
                        marginTop: 45,
                        fontSize: 16,
                        alignSelf: "center",
                        textAlign: "center",
                        color: "#777"
                      }}
                    >
                      No icons found.
                    </Text>
                    <Text
                      style={{
                        marginTop: 15,
                        fontSize: 12,
                        alignSelf: "center",
                        textAlign: "center",
                        color: "#777"
                      }}
                    >
                      None of our {NUMBER_OF_ICONS} icons matched your search.
                      {"\n"}
                      Try searching for a different, similar term.
                    </Text>
                  </>
                )}
              </View>
            </>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "lightgray",
    borderWidth: 1,
    borderRadius: 10
  },
  icon: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 80,
    width: 80,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
    marginHorizontal: 20,
    opacity: 1,
    shadowOpacity: 0.2,
    shadowRadius: 7,
    shadowOffset: { width: 0, height: 0 },
    elevation: 5
  },
  searchSectionContainer: {
    width: width * 0.9,
    height: 0.33 * height
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 50,
    margin: 5,
    justifyContent: "center",
    marginTop: 10,
    alignItems: "center"
  },
  doneButton: {
    position: "absolute",
    right: 10,
    top: 10,
    padding: 6,
    paddingHorizontal: 10,
    backgroundColor: "#94A3B8",
    borderRadius: 8
  },
  doneButtonText: {
    color: "white",
    fontSize: 12,
    fontWeight: "700"
  }
});
