import React, { useEffect, useState } from "react";
import { View, ScrollView, ActivityIndicator } from "react-native";
import Rex from "src/globalState";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import AuthScreenHeader from "src/components/auth/AuthScreenHeader";

const { width } = Glob.get("dimensions");

export default function JoinLockedApp({ route, navigation }) {
  const { params: { fullName } = {} } = route || {};
  const [passcode, setPasscode] = useState("");
  const [checkingPasscode, setCheckingPasscode] = useState(false);

  const showBackButton = Glob.get("appIsOnespotlike") && navigation.canGoBack();

  useEffect(() => {
    Analytics.logEvent("view_joinLockedApp");
  }, []);

  const joinApp = () => {
    setCheckingPasscode(true);
    Database.tryToUnlockApp(passcode)
      .then((response) => {
        const { success } = response;
        setCheckingPasscode(false);
        if (success) navigation.push("welcome", { fullName });
        else Util.alert("Failed to unlock", "Try a different passcode.");
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setCheckingPasscode(false);
        Util.alert(
          "Something went wrong, sorry!",
          "Double-check your passcode and try again."
        );
      });
  };

  return (
    <View style={{ flex: 1, alignItems: "center", backgroundColor: "white" }}>
      <NavBar
        cannotNavigateBack={!showBackButton}
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ marginBottom: 20 }}>
          <AuthScreenHeader
            title="Enter passcode"
            subtitle={`${
              Rex.getConfig()?.names?.full
            } is a private app. If you have their secret code, please enter it here to join.`}
          />
        </View>
        <InputBox
          key="passcode"
          openStyle
          placeholder="Passcode"
          icon="8e4e505d-bcc9-445d-a842-c2a792aca31e" // key-outline
          value={passcode}
          onChangeText={setPasscode}
        />
        <View style={{ marginTop: 60 }}>
          {checkingPasscode ? (
            <ActivityIndicator size="large" />
          ) : (
            <Button
              text="Submit"
              onPress={joinApp}
              disabled={!passcode}
              style={{ opacity: passcode ? 1 : 0.3 }}
              textStyle={{ fontWeight: "bold" }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}
