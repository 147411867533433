/* global window */

/*
 * This class stores all global variables, images, and styles to be used across the app.
 * Anything global and static ACROSS ALL APPS, like gray colors, styles, etc, should be stored here.
 * Make the names of the keys fully descriptive of what they return (e.g. "light gray").
 * To access the variable from elsewhere in the app:
 *   import Glob from 'src/globalConstants';
 *   Glob.get("light gray");   or   Style.get("searchBar");
 *
 */

import React from "react";
import { Dimensions, Platform } from "react-native";
import Constants, { ExecutionEnvironment } from "expo-constants";
import * as Application from "expo-application";
import {
  AntDesign,
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5
} from "@expo/vector-icons";

const { height, width } = Dimensions.get("window");
const aspectRatio = height / width;
const formattedWidth = width > height ? height : width;
const GRAY = "#5D5D5C";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;
const IS_DEVELOPMENT_MODE = FIREBASE_CONFIG.projectId !== "seabirdmain";
const IS_EXPO_GO =
  Constants.executionEnvironment === ExecutionEnvironment.StoreClient;

// Determine whether the device is an iPhoneX (or has a similar aspect ratio, e.g. iPhone 11 Pro Max)
const DEVICE_HAS_IPHONE_X_DIMENSIONS = aspectRatio > 2;
const DEVICE_HAS_TABLET_DIMENSIONS = aspectRatio < 1.6;
const DEVICE_HAS_DESKTOP_DIMENSIONS = aspectRatio < 1;

const TUTORIAL_TASKS = [
  "viewAdmin",
  "viewAllMembers",
  "createNewPortal",
  "editPortal",
  "createUserGroup",
  "viewAccountTypes",
  "editDefaultPortals",
  "sendPushNotification",
  "setBannerMessage",
  "viewAppDesign",
  "publishApp",
  "viewShareApp"
];

const ADMIN_PRIVILEGES_CATEGORIES_SORTED = [
  "Onespot Payments",
  "Members",
  "Content",
  "Communication",
  "Settings",
  "Publishing"
];

const ALL_ADMIN_PRIVILEGES = {
  ManageUsers: {
    name: "All members",
    screen: "manageUsers",
    sortOrder: 0,
    category: "Members",
    icon: <AntDesign name="contacts" size={22} color={GRAY} />
  },
  EditAllPortals: {
    name: "All screens",
    screen: "editAllPortals",
    sortOrder: 1,
    category: "Content",
    icon: (
      <MaterialCommunityIcons
        name="file-multiple-outline"
        size={22}
        color={GRAY}
      />
    )
  },
  PushNotifications: {
    name: "Send notification",
    screen: "sendPushNotification",
    sortOrder: 2,
    category: "Communication",
    icon: <MaterialCommunityIcons name="send-outline" size={22} color={GRAY} />
  },
  HomeScreenBanner: {
    name: "Banner message",
    screen: "setBannerMessage",
    sortOrder: 3,
    category: "Communication",
    icon: <FontAwesome name="flag-o" size={22} color={GRAY} />
  },
  // Only show this places if textMessagingEnabled || phoneCallsEnabled
  TextAndCall: {
    name: "Texting & calling",
    screen: "textsAndCalls",
    sortOrder: 4,
    category: "Communication",
    icon: (
      <MaterialCommunityIcons name="cellphone-message" size={22} color={GRAY} />
    )
  },
  // Only show this places if commerceEnabled
  Commerce: {
    name: "Settings & dashboard",
    screen: "commerceSettings",
    sortOrder: 5,
    category: "Onespot Payments",
    icon: <MaterialCommunityIcons name="cash-multiple" size={22} color={GRAY} />
  },
  GlobalConfig: {
    name: "App settings & design",
    screen: "editGlobalConfig",
    sortOrder: 6,
    category: "Settings",
    icon: <MaterialIcons name="design-services" size={22} color={GRAY} />
  },
  EditAccountTypes: {
    name: "Account types",
    screen: "editAccountTypes",
    sortOrder: 7,
    category: "Settings",
    icon: <FontAwesome5 name="user-circle" size={22} color={GRAY} />
  },
  EditUserGroups: {
    name: "Notification groups",
    screen: "editAllUserGroups",
    sortOrder: 8,
    category: "Settings",
    icon: <MaterialIcons name="groups" size={22} color={GRAY} />
  },
  EditDefaultPortals: {
    name: "Home screen arrangement (i.e. default portals)",
    screen: "editDefaultPortals",
    sortOrder: 9,
    category: "Settings",
    icon: <MaterialIcons name="dashboard-customize" size={22} color={GRAY} />
  },
  // TODO: Add an EditUserFields superpower for editing userAccountConfig/fields
  Publish: {
    name: "Publishing & privacy",
    screen: "publish",
    sortOrder: 10,
    category: "Publishing",
    icon: <MaterialIcons name="publish" size={22} color={GRAY} />
  }
};

// todo: this would be more secure (and reduce duplication) if it were sent from our API
// and ideally it should be stored in the database
const ONESPOT_CHECKOUT_CODES = {
  SCHOOL_TYPE: {
    "General (K-12)": "8l14hptn",
    Montessori: "wydnmny9"
  },
  DO_NOT_AUTO_SUBMIT: "m2hnn2ml",
  DO_NOT_SAVE_TO_SPREADSHEET: "ozno1ppa",
  FREE_TRIAL_DAYS: {
    "0": "none",
    "30": "jw1fvwgh",
    "60": "25dov3sj",
    "90": "oxmx14pj",
    "180": "2u4bx82a"
  },
  PRICE_PER_STUDENT: {
    "7": "efkjf83r",
    "9": "gphpn1fm",
    "10": "xgfl22a7",
    "12": "rfzxttmj"
  },
  REMOVE_STANDALONE_FEE: "b2l1uni7",
  ALLOW_PROMO_CODES: "p9wc8et8",
  ADD_BUILD_FEE_DISCOUNT: "rug2vpxi"
};

const ONESPOT_PURPLE = "#5b59d6";
const appVersion =
  IS_EXPO_GO || Platform.OS === "web"
    ? Constants.expoConfig.version
    : Application.nativeApplicationVersion || "0.0.0";
const globVars = {
  appVersion,
  appVersionFull: `${appVersion}-${Constants.expoConfig.extra.publishedVersion}`,
  metaAppID: Constants.expoConfig?.extra?.metaAppID,
  appIsOnespot: Constants.expoConfig?.slug === "onespot",
  appIsOnespotlike:
    !!Constants.expoConfig?.extra?.metaAppID ||
    Constants.expoConfig?.slug === "onespot" ||
    Constants.expoConfig?.slug === "onespotcampus",
  isExpoGo: IS_EXPO_GO,
  appIsUphillNewEngland: Constants.expoConfig?.slug === "uphillnewengland",
  revenueCatMinimumAppVersion: "17.4.0",
  primaryColor: Constants.expoConfig?.primaryColor || ONESPOT_PURPLE,
  gray: GRAY,
  "light gray": "#EEEEEE",
  "very light gray": "#DBDBDB",
  "extremely light gray": "#DBDBDB",
  widthOfUserTypeButton: DEVICE_HAS_TABLET_DIMENSIONS
    ? 0.08 * formattedWidth + 35
    : 0.143 * formattedWidth + 35,
  privacyPolicyURL: "http://www.onespotapps.com/privacy",
  termsOfServiceURL: "http://www.onespotapps.com/terms",
  onespotChangelogURL:
    "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NpbK40LVpOoHBZ5mibs&embedded=true",
  demoAccountEmail: "seabirdapps+demo@gmail.com",
  demoAccountUserID: IS_DEVELOPMENT_MODE
    ? "y8h6co4mGog7NWG8yrY68xCbTju2"
    : "0s8jR6u7SoTBRiDq5qKybBIN4H72",
  dangerRed: "#bd0000",
  onespotColor: ONESPOT_PURPLE,
  onespotYellow: "#FFE845",
  onespotGreen: "#5EE97B",
  onespotRed: "#EB4520",
  onespotOrange: "#FFA945",
  // TODO: Eventually, we should pull this from our API or something
  // For now though, worst case is someone could steal it and use up our Google Sheets API limit
  googleAPIKey: "AIzaSyC6VFLUCHJ3H44p1dTkDJCYLu4bJXdO464",
  smartlookProjectKey: "e3e51c43e050049328e3d13718d822cd141828e5",
  revenueCatAPIKey:
    Platform.OS === "ios"
      ? "appl_OGZpaQlpMEnjikPQfcgZhSNwFBF"
      : "goog_ddGpCQZYrOtgglxLAplkzjyZSJI",
  stripeCustomerPortalLink: IS_DEVELOPMENT_MODE
    ? "https://billing.stripe.com/p/login/test_dR67wu0t4buE7e0144"
    : "https://billing.stripe.com/p/login/aEUeXZaDN15LcWA9AA",
  stripePricingTableIDAndKey: IS_DEVELOPMENT_MODE
    ? `pricing-table-id="prctbl_1Lo8M6LQAFQ247RgddAS3FPL" publishable-key="pk_test_51Jpn5QLQAFQ247RgtQ7egbNSj7vEfaHuEzbe2DK7gRgN0akjuIjbio80HFXOyisrqutX0gnBb48YpFASo6nWqkUz00F3yFDj5O"`
    : `pricing-table-id="prctbl_1LphvzLQAFQ247RgobFH0bdY" publishable-key="pk_live_51Jpn5QLQAFQ247RgQI7MBSP8KWHdqF2AyTxrZ2wySZxkhoIT5GmJR8OMiPBLW9zw5vPVj67YI33yttgd6eF029Z500UcW1Fset"`,
  stripePublishableKey: IS_DEVELOPMENT_MODE
    ? "pk_test_51Jpn5QLQAFQ247RgtQ7egbNSj7vEfaHuEzbe2DK7gRgN0akjuIjbio80HFXOyisrqutX0gnBb48YpFASo6nWqkUz00F3yFDj5O"
    : "pk_live_51Jpn5QLQAFQ247RgQI7MBSP8KWHdqF2AyTxrZ2wySZxkhoIT5GmJR8OMiPBLW9zw5vPVj67YI33yttgd6eF029Z500UcW1Fset",
  allAdminPrivileges: ALL_ADMIN_PRIVILEGES,
  allAdminPrivilegeCategories: ADMIN_PRIVILEGES_CATEGORIES_SORTED,
  onespotCheckoutCodes: ONESPOT_CHECKOUT_CODES,
  dimensions: {
    height,
    // Format nicely for wider screens (e.g. desktop web browsers)
    width: formattedWidth,
    fullWidth: width
  },
  webURL:
    IS_DEVELOPMENT_MODE && Platform.OS === "web"
      ? "http://localhost:19006"
      : "https://1spot.app",
  // Based on https://stackoverflow.com/a/901144
  urlParams:
    Platform.OS === "web"
      ? new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop)
        })
      : {},
  superAdminUserIDs: [
    "9cl6D5FxwbPbbgSciUfd0V17GP73", // samuel@seabirdapps.com
    "W6ulMR9DHXVBbAdj5SmO6NEJtAL2", // tyler@seabirdapps.com
    "3p2LVw3KrZRA3aN6aZSKNPcrN6l2", // vida@seabirdapps.com
    "IjR2vtTHWCQq0m1qE4fsO1Qh2i52", // dprimm05@gmail.com
    "seEBR0R0P6hkvPwxZB77rz5d74s1", // cannse@gmail.com (prod)
    "QcjQ7iTqIoX0eYf9lGGrZYv6Wak2", // cannse@gmail.com (dev)
    "XAhszttH4YdjFekjN4LfZBYaNS33" // samuel@seabirdapps.com (dev)
  ],
  seanCannUserID: IS_DEVELOPMENT_MODE
    ? "QcjQ7iTqIoX0eYf9lGGrZYv6Wak2"
    : "seEBR0R0P6hkvPwxZB77rz5d74s1",
  samuelBuchananUserID: "9cl6D5FxwbPbbgSciUfd0V17GP73",
  exampleGoogleSheetID: "1lBL9ZatuOVyH7OefTAgU0HSAjszqiD84Q72yRg-9V_o",
  onespotLogoImage: require("resources/images/onespot-logo.png"),
  onespotSmileImage: require("resources/images/onespot-smile.png"),
  onespotCampusLogoImage: require("resources/images/onespotcampus-logo.png"),
  onespotK12LogoImage: require("resources/images/onespotk12-logo.png"),
  seabirdAppsLogoImage: require("resources/images/seabird-apps-logo.png"),
  onespotVzyLogoImage: require("resources/images/onespotVzyLogo.png"),
  appStoreListings: require("resources/images/appStoreListings.png"),
  appStoreIcons: require("resources/images/appStoreIcons.png"),
  demoOnAppStores: require("resources/images/demoOnAppStores.png"),
  commercePhone: require("resources/images/commercePhonePurple.png"),
  commerceComputer: require("resources/images/commerceComputerDashboard.png"),
  commercePaymentsSchools: require("resources/images/commercePaymentsSchools.png"),
  cardboardBoxOpen: require("resources/images/cardboardBoxOpen.png"),
  cardboardBoxClosed: require("resources/images/cardboardBoxClosed.png"),
  shoppingCart: require("resources/images/shoppingCart.png"),
  moonPhonesMockup: require("resources/images/moonPhonesMockup.png"),
  meditationWoman: require("resources/images/meditationWoman.png"),
  uphillNewEnglandLogoBadge: require("resources/images/uphillNewEnglandLogoBadge.png"),
  onespotAppStoreLinks: {
    android:
      "https://play.google.com/store/apps/details?id=com.seabirdapps.onespot",
    ios:
      "https://apps.apple.com/us/app/onespot-apps-for-communities/id1589752313"
  },
  montessoriAppStoreLinks: {
    android:
      "https://play.google.com/store/apps/details?id=com.seabirdapps.montessori",
    ios: "https://apps.apple.com/us/app/the-montessori-app/id1633344465"
  },
  onespotk12AppStoreLinks: {
    android:
      "https://play.google.com/store/apps/details?id=com.seabirdapps.onespotk12",
    ios: "https://apps.apple.com/us/app/onespot-k12/id1671695897"
  },
  onespotCampusAppStoreLinks: {
    android:
      "https://play.google.com/store/apps/details?id=com.seabirdapps.onespotcampus",
    ios: "https://apps.apple.com/us/app/onespot-campus/id1637131793"
  },
  tutorialTasks: TUTORIAL_TASKS,
  navBarHeight: 0.09 * height + 12,
  maxTextMessageCharacters: 280,
  formRequestMontessoriApp:
    "https://calendly.com/seabirdapps/school-mobile-app-clone", // old: "https://forms.gle/qQNKvnkQ8Z5mmuFB8",
  formRequestK12App: "https://forms.gle/zmf8v7ckofkfT7FY8",
  onespotCampusApps: {
    "-NdvDJH77BPkTviYmoq1": "beloitcollege", // Beloit College
    "-NdCL9rgvfMgSer_YGIr": "bentleyuniversity", // Bentley University
    "-NeeRxisDj6xNXngV1uk": "bethanylutherancollege", // Bethany Lutheran College
    "-MtdhPigCaU0X2WD4pne": "dartmouth", // Dartmouth
    "-N85mbcTuxwm4fJ27I2M": "duke", // Duke
    "-MsvN5NRUsbby5aj2QMv": "fivetowns", // Five Towns
    "-Nd2a-9SuYJ-7mvW0OyF": "gramblingstateuniversity", // Grambling State University
    "-Ncu9lPSDAyKLTExpSZ2": "lehighuniversity", // Lehigh University
    "-NeaQyv0WuGgxEPBTHY_": "macalestercollege", // Macalester College
    "-NcKDO-79O0o2nA6bJlk": "middleburycollege", // Middlebury College
    "-NcQQifjGKILCl_vd_mq": "saintanselmcollege", // Saint Anselm College
    "-N8AFqVLztLYkJsHIEjh": "texastech", // Texas Tech
    "-NASVN832iMvgRXgqpal": "ucsantacruz", // UCSC
    "-Nccaq-IAHovuH8OcBvM": "universityofvermont", // University of Vermont
    "-NcFe4Tpl8WbBBVwCDoc": "universityofnewhaven", // University of New Haven
    "-NcpDqx3glnm3j8zRJFU": "universityofwisconsinriverfalls", // University of Wisconsin River Falls
    "-NeW6-EG6YSvzzOlwomM": "universityofwisconsinstout", // The University of Wisconsin-Stout (UW-Stout)
    "-N6u7XwZAoZTBlRgvvJh": "upenn", // UPenn
    "-NdamaSEKbRkDfUR105o": "vanderbiltuniversity", // Vanderbilt University
    "-MskM8LYE2dk2qDUMhXa": "wakeforest", // Wake
    "-Ne15a5fuPVZ54GUPwXn": "winonastateuniversity", // Winona State University
    "-N85l7-JS2nL5FLSEQqt": "yale" // Yale
    // "-MqCHvzfXd6dagvEE6Gj": "fivetowns", // Development
  },
  iosSubscriptionManagementURL: "https://apps.apple.com/account/subscriptions",
  androidSubscriptionManagementURL:
    "https://play.google.com/store/account/subscriptions",
  // Important: This value is stored in the database, so do not change the name "ThisScreenIsHiddenFromAllAccountTypes"
  hiddenScreenAccountTypeKey: "ThisScreenIsHiddenFromAllAccountTypes"
};

const ALL_ACCOUNT_TYPES = [
  {
    key: "member",
    buttonText: "Member",
    typeText: "a member",
    iconName: "person"
  },
  {
    key: "visitor",
    buttonText: "Visitor",
    typeText: "a visitor",
    iconName: "person"
  },
  {
    key: "staff",
    buttonText: "Staff",
    typeText: "a staff member",
    iconName: "professional"
  },
  {
    key: "other",
    buttonText: "Other",
    typeText: "just a visitor",
    iconName: "person"
  },
  {
    key: "student",
    buttonText: "Student",
    typeText: "a student",
    iconName: "person"
  },
  { key: "alum", buttonText: "Alum", typeText: "an alum", iconName: "alum" },
  {
    key: "parent",
    buttonText: "Parent",
    typeText: "a parental figure",
    iconName: "people"
  },
  {
    key: "faculty",
    buttonText: "Faculty",
    typeText: "a faculty member",
    iconName: "teacher"
  },
  {
    key: "customer",
    buttonText: "Customer",
    typeText: "a customer",
    iconName: "person"
  },
  {
    key: "employee",
    buttonText: "Employee",
    typeText: "an employee",
    iconName: "professional"
  },
  {
    key: "employer",
    buttonText: "Employer",
    typeText: "an employer",
    iconName: "person"
  },
  {
    key: "manager",
    buttonText: "Manager",
    typeText: "an manager",
    iconName: "professional"
  },
  {
    key: "resident",
    buttonText: "Resident",
    typeText: "a resident",
    iconName: "home"
  },
  {
    key: "business",
    buttonText: "Business",
    typeText: "a local business owner",
    iconName: "professional"
  },
  {
    key: "novice",
    buttonText: "Novice",
    typeText: "a newbie",
    iconName: "person"
  },
  {
    key: "connoisseur",
    buttonText: "Connoisseur",
    typeText: "an expert",
    iconName: "professional"
  },
  {
    key: "founder",
    buttonText: "Founder",
    typeText: "a founder",
    iconName: "stats"
  },
  {
    key: "investor",
    buttonText: "Investor",
    typeText: "an investor",
    iconName: "money"
  },
  {
    key: "attendee",
    buttonText: "Attendee",
    typeText: "an attendee",
    iconName: "person"
  },
  {
    key: "coach",
    buttonText: "Coach",
    typeText: "a coach",
    iconName: "sports"
  },
  {
    key: "supporter",
    buttonText: "Supporter",
    typeText: "a supporter",
    iconName: "person"
  },
  {
    key: "endorser",
    buttonText: "Endorser",
    typeText: "an endorser",
    iconName: "professional"
  },
  {
    key: "donor",
    buttonText: "Donor",
    typeText: "a donor",
    iconName: "money"
  },
  {
    key: "volunteer",
    buttonText: "Volunteer",
    typeText: "a volunteer",
    iconName: "person"
  },
  {
    key: "aCaddie",
    buttonText: "A Caddie",
    typeText: "an A caddie",
    iconName: "person",
    hideOption: true
  },
  {
    key: "bCaddie",
    buttonText: "B Caddie",
    typeText: "a B caddie",
    iconName: "person",
    hideOption: true
  },
  {
    key: "cCaddie",
    buttonText: "C Caddie",
    typeText: "a C caddie",
    iconName: "person",
    hideOption: true
  },
  {
    key: "aRefugee",
    buttonText: "A Refugee",
    typeText: "a refugee",
    iconName: "person",
    hideOption: true
  },
  {
    key: "honorsCaddie",
    buttonText: "Honors",
    typeText: "an honors caddie",
    iconName: "person",
    hideOption: true
  },
  {
    key: "captain",
    buttonText: "Captain",
    typeText: "a captain",
    iconName: "person",
    hideOption: true
  },
  {
    key: "aSwing",
    buttonText: "A-Swing",
    typeText: "an A-swing caddie",
    iconName: "person",
    hideOption: true
  },
  {
    key: "apprentice",
    buttonText: "Apprentice",
    typeText: "an apprentice",
    iconName: "person",
    hideOption: true
  }
];
const ACCOUNT_TYPES = {};
ALL_ACCOUNT_TYPES.forEach((type) => {
  ACCOUNT_TYPES[type.key] = type;
});

const ALL_COMMUNITY_TYPES = [
  {
    key: "company",
    name: "Business/company",
    example: "Seabird Apps, Inc.",
    userTypes: ["customer", "employee", "visitor"],
    icon: "985b7625-e324-40cc-8a5a-b74a7ccb16d7"
  },
  {
    key: "project",
    name: "Personal project",
    example: "My scarf knitting advice",
    userTypes: ["supporter"],
    icon: "3adfa90f-a816-49d7-928a-64e6a1eed78a"
  },
  {
    key: "conference",
    name: "Conference/event",
    example: "Seabird Software Convention",
    userTypes: ["attendee", "staff", "other"],
    icon: "de0a5e91-d8a4-4458-9152-6c6e4a7a47f8"
  },
  {
    key: "school",
    name: "School/college/university",
    example: "Dartmouth College",
    userTypes: ["student", "parent", "alum", "faculty", "staff", "visitor"],
    icon: "ee624859-0a99-4531-b9cc-55758ec8855b"
  },
  {
    key: "faithGroup",
    name: "Faith-based organization",
    example: "St. Mary's Church",
    userTypes: ["member", "staff", "visitor"],
    icon: "church"
  },
  {
    key: "restaurant",
    name: "Restaurant/cafe",
    example: "Seabird Apps, Inc.",
    userTypes: ["customer", "staff", "visitor"],
    icon: "dining"
  },
  {
    key: "ecommerce",
    name: "Ecommerce",
    example: "Seabird Apps, Inc.",
    userTypes: ["customer", "employee", "visitor"],
    icon: "e50dae4d-f0eb-41a0-ba7c-bf41ec58ef75"
  },
  {
    key: "enterpriseTeam",
    name: "Team within a company",
    example: "Seabird Dev Team",
    userTypes: ["member", "manager"],
    icon: "180dbe07-6c70-4284-a3d0-a5137d1c2a68"
  },
  {
    key: "agent",
    name: "Real estate agent",
    example: "Jane Austen",
    userTypes: ["customer", "employee", "visitor"],
    icon: "person"
  },
  {
    key: "neighborhood",
    name: "Neighborhood",
    example: "Afton Village",
    userTypes: ["resident", "business", "visitor"],
    icon: "b142565d-8d6f-4572-9e7f-8d993d4eee4f"
  },
  {
    key: "town",
    name: "Town/city",
    example: "Vermilion, Ohio",
    userTypes: ["resident", "business", "visitor"],
    icon: "db11dcd7-3cd0-40af-acd4-41d9612cf739"
  },
  {
    key: "careerFair",
    name: "Career fair",
    example: "Dartmouth Engineers 2023",
    userTypes: ["attendee", "student", "alum", "staff", "employer"],
    icon: "a2a327c5-cfa0-4e66-bc78-0546e7a1a0ea"
  },
  {
    key: "wedding",
    name: "Wedding",
    example: "Samuel & Natalie's Wedding",
    userTypes: ["attendee", "member", "staff"],
    icon: "be1c5e61-0b7f-4ba8-94b2-4e7acf27d5cd"
  },
  {
    key: "campaignFundraiser",
    name: "Campaign/fundraiser",
    example: "Samuel for Mayor 2028",
    userTypes: ["staff", "supporter", "endorser", "donor", "volunteer"],
    icon: "e1190796-2f72-4b68-89e5-58e338095e43"
  },
  {
    key: "festivalParade",
    name: "Festival/parade",
    example: "The Seabird Festival",
    userTypes: ["member", "staff", "business", "visitor"],
    icon: "9a527dd6-88b0-41ed-a03e-6fa44025fa38"
  },
  {
    key: "race",
    name: "Race/marathon",
    example: "The Boston Marathon",
    userTypes: ["member", "staff", "other"],
    icon: "a507409a-d706-444e-8bdd-406924040951"
  },
  {
    key: "clubTeam",
    name: "Club/team",
    example: "Sean's Book Club",
    userTypes: ["member", "visitor", "manager"],
    icon: "people"
  },
  {
    key: "camp",
    name: "Camp/summer camp",
    example: "Seabird Outdoor Summer Camp",
    userTypes: ["member", "staff", "parent", "alum", "visitor"],
    icon: "4c5c4cd2-9b99-4ff8-af85-88c627a7e8cf"
  },
  {
    key: "apartment",
    name: "Apartment complex",
    example: "Afton Apartments",
    userTypes: ["resident", "staff", "visitor"],
    icon: "b6a52ee0-2fb0-4239-8230-cdab9a385681"
  },
  {
    key: "golfClub",
    name: "Golf club",
    example: "Elyria Country Club",
    userTypes: ["member", "staff", "visitor", "investor"],
    icon: "86ed6c89-bb39-42c8-86f9-bb423b369da9"
  },
  {
    key: "library",
    name: "Library",
    example: "Ritter Public Library",
    userTypes: ["member", "staff", "visitor"],
    icon: "4c43b630-1804-4343-bb71-608226f51ea1"
  },
  {
    key: "other",
    name: "Other",
    example: "Theodore's Theater",
    userTypes: ["member", "visitor"],
    icon: "82a0c53a-9445-4f7f-8e20-2addb0bf6f76"
  }
];
const COMMUNITY_TYPES = {
  montessoriSchool: {
    key: "montessoriSchool",
    name: "Montessori school",
    example: "Atlas Academy",
    userTypes: ["parent", "staff", "visitor"],
    icon: "ee624859-0a99-4531-b9cc-55758ec8855b"
  }
};
ALL_COMMUNITY_TYPES.forEach((type) => {
  COMMUNITY_TYPES[type.key] = type;
});

export default class Glob {
  static get(x) {
    return globVars[x];
  }

  static getAccountType(type) {
    return type in ACCOUNT_TYPES ? ACCOUNT_TYPES[type] : ACCOUNT_TYPES.other;
  }

  static getAllAccountTypes() {
    return ALL_ACCOUNT_TYPES;
  }

  static getCommunityType(type) {
    return type in COMMUNITY_TYPES
      ? COMMUNITY_TYPES[type]
      : COMMUNITY_TYPES.other;
  }

  static getAllCommunityTypes() {
    return ALL_COMMUNITY_TYPES;
  }

  static deviceHasiPhoneXDimensions() {
    return DEVICE_HAS_IPHONE_X_DIMENSIONS;
  }

  static deviceHasTabletDimensions() {
    return DEVICE_HAS_TABLET_DIMENSIONS;
  }

  static deviceHasDesktopDimensions() {
    return DEVICE_HAS_DESKTOP_DIMENSIONS;
  }
}
