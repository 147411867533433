/*  global window */
// This should only ever be accessed as a web page

import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  ActivityIndicator
} from "react-native";
import Database from "src/backend/database";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";

const { width } = Glob.get("dimensions");
const CODES = Glob.get("onespotCheckoutCodes");
const DYNAMIC_FORM = {
  id: "1FAIpQLSfYvyhv_n0XIYCIfoO9MVCJdzQJoo0mjXTCTQUQLs0Leu8q9Q",
  questions: [
    { text: "Name", fieldID: 1803599727 },
    { text: "Email", fieldID: 351035121 },
    { text: "Phone number", fieldID: 894222234 },
    { text: "School name", fieldID: 1312147843 },
    { text: "School website", fieldID: 605456234 },
    { text: "Number of students", fieldID: 329705149 }
  ]
};

export default function OnespotCheckoutPage({ navigation }) {
  const checkout = Glob.get("urlParams")?.checkout || "";
  const prefilledName = Glob.get("urlParams")?.name;
  const prefilledEmail = Glob.get("urlParams")?.email;
  const prefilledSchoolName = Glob.get("urlParams")?.school;
  const prefilledNumberOfStudents = Glob.get("urlParams")?.students;

  if (!checkout || Platform.OS !== "web")
    return (
      <View style={styles.pageContent}>
        <NavBar text="Oops..." navigation={navigation} cannotNavigateBack />
        <View style={{ flex: 1, alignItems: "center", margin: 30 }}>
          <Text style={{ fontSize: 22, marginTop: 20 }}>
            There was an issue loading this page; sorry!
            {Platform.OS !== "web"
              ? " This page can only be accessed as a web page. "
              : " "}
            Please reach out to us for help: team@seabirdapps.com.
          </Text>
        </View>
      </View>
    );

  const schoolTypeIsMontessori = checkout.includes(
    CODES.SCHOOL_TYPE.Montessori
  );
  const autoSubmit = !checkout.includes(CODES.DO_NOT_AUTO_SUBMIT);
  const saveToSpreadsheet = !checkout.includes(
    CODES.DO_NOT_SAVE_TO_SPREADSHEET
  );

  const [name, setName] = useState(prefilledName);
  const [email, setEmail] = useState(prefilledEmail);
  const [phone, setPhone] = useState(null);
  const [schoolName, setSchoolName] = useState(prefilledSchoolName);
  const [schoolWebsite, setSchoolWebsite] = useState(null);
  const [students, setStudents] = useState(prefilledNumberOfStudents);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_onespotCheckoutPage");
    if (autoSubmit && prefilledNumberOfStudents) onSubmitResponse();
  }, []);

  const onSubmitResponse = () => {
    setIsSubmitted(true);
    if (saveToSpreadsheet)
      Util.fillDynamicForm(DYNAMIC_FORM, [
        name,
        email,
        phone,
        schoolName,
        schoolWebsite,
        students
      ]);
    Database.createStripeCheckoutSessionUnauthenticated({
      type: "school",
      students,
      email,
      checkoutCodesString: checkout,
      cancelURL: window.location.href
    })
      .then((data) => {
        console.log("data");
        console.log(data);
        if (data?.success && data?.checkoutSessionURL) {
          window.open(data.checkoutSessionURL, "_self");
        } else if (data?.message) {
          Util.alert(data.message);
          setIsSubmitted(false);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        Util.alert(
          "Something went wrong, sorry! Please reach out to us for assistance (team@seabirdapps.com)"
        );
        setIsSubmitted(false);
      });
  };

  if (isSubmitted)
    return (
      <View style={styles.pageContent}>
        <NavBar
          text={`Get a ${
            !schoolTypeIsMontessori ? "School" : "Montessori"
          } App`}
          navigation={navigation}
          cannotNavigateBack
          isOnespot
        />
        <View style={{ flex: 1, alignItems: "center", margin: 30 }}>
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 22, marginTop: 20 }}>
            Calculating the all-inclusive price for{" "}
            {schoolName || "your school"}. Please wait a minute...
          </Text>
        </View>
      </View>
    );

  const canSubmit = !!name && !!email && !!schoolName && !!students;

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        text={`Get a ${!schoolTypeIsMontessori ? "School" : "Montessori"} App`}
        navigation={navigation}
        cannotNavigateBack
        isOnespot
      />
      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <Text style={{ marginTop: 20 }}>
          Fill out this form to get a custom app for your{" "}
          {schoolTypeIsMontessori ? "Montessori " : ""}school!
        </Text>
        <InputBox
          header="Your name"
          placeholder={
            schoolTypeIsMontessori ? "Maria Montessori" : "Jane Wright"
          }
          headerColorOverride={Glob.get("onespotColor")}
          onChangeText={setName}
          value={name}
        />
        <InputBox
          header="Your email"
          placeholder={
            schoolTypeIsMontessori
              ? "maria@montessori.org"
              : "you@yourschool.com"
          }
          headerColorOverride={Glob.get("onespotColor")}
          onChangeText={setEmail}
          value={email}
          keyboardType="email-address"
        />
        <InputBox
          header="Your phone number"
          placeholder="(123) 456-7890"
          headerColorOverride={Glob.get("onespotColor")}
          description="(optional)"
          onChangeText={setPhone}
          value={phone}
          keyboardType="phone-pad"
        />
        <InputBox
          header="Your school's name"
          placeholder={
            schoolTypeIsMontessori
              ? "Montessori Academy"
              : "Intellectual Academy"
          }
          headerColorOverride={Glob.get("onespotColor")}
          onChangeText={setSchoolName}
          value={schoolName}
        />
        <InputBox
          header="Your school's website"
          placeholder={
            schoolTypeIsMontessori
              ? "montessoriacademy.com"
              : "intellectualacademy.com"
          }
          headerColorOverride={Glob.get("onespotColor")}
          description="(optional)"
          onChangeText={setSchoolWebsite}
          value={schoolWebsite}
          keyboardType="url"
        />
        <InputBox
          header="How many students are enrolled in your school?"
          placeholder="100"
          headerColorOverride={Glob.get("onespotColor")}
          onChangeText={setStudents}
          value={students}
          keyboardType="number-pad"
        />
        <Button
          text="Next"
          color={Glob.get("onespotColor")}
          style={{ opacity: canSubmit ? 1 : 0.3, marginTop: 40 }}
          disabled={!canSubmit}
          onPress={onSubmitResponse}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  }
});
