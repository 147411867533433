// TODO: This portal should be eliminated
import React, { Component } from "react";
import { StyleSheet, View, Dimensions, Image } from "react-native";
import School from "school/school";
import NavBar from "src/components/navBar";
import Analytics from "src/backend/analytics";

const NAVBAR_TEXT = "Schedule";
const { height, width } = Dimensions.get("window");

export default class Schedule extends Component {
  constructor(props) {
    super(props);
    Analytics.logEvent("view_schedule");
  }

  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={NAVBAR_TEXT} />
        <View style={styles.mainContent}>
          <View>
            <Image
              source={School.get("schedule image")}
              style={{
                height: 0.909 * height,
                width: 0.952 * width
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Style for the enter page */
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },

  /* Style for the main section that will hold all the of the Schedule content */
  mainContent: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
});
