import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Platform
} from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import LottieView from "src/components/Lottie";
import HelpText from "src/components/HelpText";
import UserEditor from "src/components/UserEditor";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const { width } = Glob.get("dimensions");

const PAPER_AIRPLANE_ANIMATION = require("resources/animations/paperAirplane.json");

export default function InviteUsers({ navigation }) {
  const [emailsText, setEmailsText] = useState(null);
  const [emails, setEmails] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [message, setMessage] = useState(null);
  const [user, setUser] = useState(null);
  const [existingEmails, setExistingEmails] = useState(new Set());
  const [invalidEmailCount, setInvalidEmailCount] = useState(0);
  const [duplicateEmailCount, setDuplicateEmailCount] = useState(0);
  const [invalidEmailExistingCount, setInvalidEmailExistingCount] = useState(0);
  const [didSendInvitations, setDidSendInvitations] = useState(false);
  const [webURL, setWebURL] = useState("https://www.1spot.app");
  const [webURLBase, setWebURLBase] = useState("www.1spot.app");

  useEffect(() => {
    Analytics.logEvent("view_inviteUsers");
    Database.fetchAllUsers().then((allUsers) => {
      Database.fetchAllUserInvitations().then((userInvitations) => {
        let existingEmailList = allUsers.map((u) => u.email);
        if (userInvitations?.length > 0) {
          existingEmailList = [
            ...existingEmailList,
            ...userInvitations.map((invitation) => invitation.email)
          ];
        }
        setExistingEmails(new Set(existingEmailList));
      });
    });
    Database.fetchPrimaryMetaApp().then(({ webLink, webLinkBase }) => {
      setWebURL(webLink);
      setWebURLBase(webLinkBase);
    });
  }, []);

  const onChangeEmailsText = (text) => {
    setEmailsText(text);
    if (!text) {
      setEmails([]);
      setInvalidEmailCount(0);
      setDuplicateEmailCount(0);
      setInvalidEmailExistingCount(0);
    } else {
      const [newEmails, numInvalidEmails] = Util.parseEmailList(text);
      let newEmailsFiltered = [...new Set(newEmails)];
      const duplicates = newEmails.length - newEmailsFiltered.length;
      const numInvalidEmailsExisting = newEmailsFiltered.filter((email) =>
        existingEmails.has(email)
      ).length;
      newEmailsFiltered = newEmails.filter(
        (email) => !existingEmails.has(email)
      );
      setEmails(newEmailsFiltered);
      setInvalidEmailCount(numInvalidEmails);
      setDuplicateEmailCount(duplicates);
      setInvalidEmailExistingCount(numInvalidEmailsExisting);
    }
  };

  const onPressInviteUsers = () => {
    Analytics.logEvent("touch_inviteUsers_sendInvitations", {
      emails,
      message,
      contacts
    });
    Database.addTask("viewShareApp", "done");
    setDidSendInvitations(true);
    Database.sendInvitationEmails({ emails, message, contacts, account: user });
  };

  const onSetContacts = (newContacts) => {
    const newContactsFiltered =
      newContacts?.length > 0
        ? newContacts.filter((c) => !existingEmails.has(c.email))
        : null;
    setContacts(newContactsFiltered);
    setEmails(
      newContactsFiltered?.length > 0
        ? newContactsFiltered.map((c) => c.email)
        : null
    );
  };

  if (didSendInvitations) {
    const plural = emails?.length !== 1;
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Sending Invitations" />
        <View style={{ flex: 2, maxHeight: "50%", width }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop
            source={PAPER_AIRPLANE_ANIMATION}
          />
        </View>
        <View
          style={{ flex: 1, paddingHorizontal: 20, marginBottom: 20, width }}
        >
          <Text style={Style.get("headerText")}>Sending now!</Text>
          <Text style={Style.get("subheaderText")}>
            Over the next few seconds, your invitation{plural ? "s" : ""} will
            be sent to {plural ? "these " : "this"}
            {plural ? emails?.length || 0 : ""} email{plural ? "s" : ""}:
          </Text>
          <ScrollView
            style={{ marginBottom: 20 }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Text style={Style.get("subheaderText")}>
              {(emails || ["None"]).join(", ")}
            </Text>
          </ScrollView>
          <Button text="Done" onPress={navigation.goBack} />
        </View>
      </View>
    );
  }

  const bottomContainerStyle = {
    paddingTop: 24,
    paddingBottom: 32,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    elevation: 20,
    width: "100%"
  };
  if (Platform.OS === "web") {
    bottomContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";
    bottomContainerStyle.position = "fixed";
    bottomContainerStyle.bottom = 0;
  }

  const contactsText = (contacts || [])
    .slice(0, 2)
    .map((c) =>
      c.firstName || c.lastName
        ? `${c.firstName || ""} ${c.lastName || ""} (${c.email})`
        : c.email
    )
    .join(contacts?.length === 2 ? " and " : ", ");

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Invite Members" />

      <ScrollView
        contentContainerStyle={{ width: width * 0.9 }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <HelpText
          text={`${
            contacts?.length > 0
              ? ""
              : "List the email(s) of everyone you'd like to invite. "
          }We'll send out a special invitation code to each person for you. You can see all pending invitations in the "All members" screen, and you can edit or revoke invitations at any time.`}
          noteStyle
        />
        {contacts?.length > 0 ? (
          <View style={{ marginVertical: 20 }}>
            <Text style={Style.get("headerText")}>Imported Contacts</Text>
            <Text style={Style.get("subheaderText")}>
              <Text style={{ fontWeight: "bold" }}>
                This will send to {contacts.length} contact
                {contacts.length === 1 ? "" : "s"}
              </Text>
              {contacts.length > 2
                ? `, including ${contactsText}, and others`
                : `: ${contactsText}`}
              .
            </Text>
            <Button
              text="Replace"
              onPress={() => {
                Analytics.logEvent("touch_inviteUsers_clearImportedContacts");
                onSetContacts(null);
              }}
              icon="41a527a9-db4d-4bfa-bd4d-b918f8fdb800" // undo
              small
              flat
              outline
              color={Glob.get("dangerRed")}
              style={{ marginTop: 14 }}
            />
          </View>
        ) : (
          <>
            <InputBox
              key="emails"
              header="Emails"
              placeholder="person1@email.com, person2@email.com"
              description="Separate email addresses with commas, new lines, or tabs."
              onChangeText={onChangeEmailsText}
              value={emailsText}
              multiline
              minHeight={80}
              autoCapitalize="none"
            />
            {invalidEmailCount > 0 && (
              <Text style={{ color: Glob.get("onespotOrange") }}>
                {invalidEmailCount} invalid email
                {invalidEmailCount === 1 ? "" : "s"} will be skipped
              </Text>
            )}
            {duplicateEmailCount > 0 && (
              <Text style={{ color: Glob.get("onespotOrange") }}>
                {duplicateEmailCount} duplicate email
                {duplicateEmailCount === 1 ? "" : "s"} will be skipped
              </Text>
            )}
            {invalidEmailExistingCount > 0 && (
              <Text style={{ color: Glob.get("onespotOrange") }}>
                {invalidEmailExistingCount} email
                {invalidEmailExistingCount === 1
                  ? " of an existing member/invitation"
                  : "s of existing members/invitations"}{" "}
                will be skipped
              </Text>
            )}
            {!emailsText && (
              <>
                {Platform.OS === "web" ? (
                  <Text style={Style.get("subheaderText")}>
                    Or,{" "}
                    <TouchableLink
                      type="button"
                      text="import members from another source"
                      textStyle={{ textDecorationLine: "underline" }}
                      onPress={() => {
                        Analytics.logEvent("touch_inviteUsers_importUsers");
                        navigation.push("importUsers", {
                          onSetContacts,
                          existingEmails
                        });
                      }}
                    />
                  </Text>
                ) : (
                  <Text style={Style.get("subheaderText")}>
                    Or, import members from another source using{" "}
                    <TouchableLink
                      type="web"
                      link={webURL}
                      text="the web app"
                      textStyle={{ textDecorationLine: "underline" }}
                      openExternally
                    />{" "}
                    on a computer ({webURLBase})
                  </Text>
                )}
              </>
            )}
          </>
        )}
        <InputBox
          key="message"
          header="Message"
          showAsOptional
          placeholder="Welcome to our app!"
          onChangeText={setMessage}
          value={message}
          minHeight={80}
          multiline
        />
        <View style={{ marginTop: 30 }}>
          <HelpText
            text="If you want, you can simplify your members' signup process by setting their account type, groups, privileges, and more."
            noteStyle
          />
          <UserEditor
            user={{
              type: "ANY_ACCOUNT_TYPE",
              groups: {},
              privileges: {}
            }}
            onUserUpdated={setUser}
            onUserDeleted={() => {}}
            navigation={navigation}
            isInvitation
            isNewInvitation
          />
        </View>
        <View style={{ height: 350 }} />
      </ScrollView>
      <View style={bottomContainerStyle}>
        <Button
          text={`Send ${emails?.length || "0"} invitation${
            emails?.length === 1 ? "" : "s"
          }`}
          icon="send"
          onPress={onPressInviteUsers}
          disabled={!(emails?.length > 0)}
          style={{ opacity: emails?.length > 0 ? 1 : 0.4 }}
        />
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  }
});
