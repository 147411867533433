import React, { useState } from "react";
import { View, Platform, TouchableOpacity } from "react-native";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import LottieView from "src/components/Lottie";

const { width, height } = Glob.get("dimensions");
const PHONE_ANIMATION = require("resources/animations/womanSittingOnPhone.json");
const BIRD_ANIMATION_BLUE = require("resources/animations/flyingBirdBlue.json");
const BIRD_ANIMATION_YELLOW = require("resources/animations/flyingBirdYellow.json");
const BIRD_ANIMATION_RED = require("resources/animations/flyingBirdRed.json");
const AIRPLANE_ANIMATION = require("resources/animations/airplaneBackground.json");

const BIRDS = [BIRD_ANIMATION_BLUE, BIRD_ANIMATION_YELLOW, BIRD_ANIMATION_RED];

export default function HeaderAnimation() {
  const [birdIndex, setBirdIndex] = useState(0);
  const [birdFlying, setBirdFlying] = useState(null);
  const [airplaneFlew, setAirplaneFlew] = useState(false);
  return (
    <TouchableOpacity
      style={{ width, height: height / 4, alignItems: "center" }}
      disabled={!!birdFlying}
      onPress={() => {
        Analytics.logEvent("touch_textsAndCalls_headerAnimation");
        if (birdIndex === 3 && !airplaneFlew && Platform.OS !== "android") {
          // Note that AIRPLANE_ANIMATION crashes the app on Android,
          // because I guess you can't fly a plane with an Android device...
          setAirplaneFlew(true);
        } else {
          const newBird = BIRDS[birdIndex % BIRDS.length];
          setBirdFlying(newBird);
          setTimeout(() => {
            setBirdFlying(null);
            setBirdIndex((i) => i + 1);
          }, 4500);
        }
      }}
    >
      {airplaneFlew && (
        <View style={{ position: "absolute", width, height: height / 4 }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop={false}
            source={AIRPLANE_ANIMATION}
            resizeMode="cover"
          />
        </View>
      )}
      <LottieView
        style={{ height: "100%", width: "100%" }}
        autoPlay
        loop
        source={PHONE_ANIMATION}
      />
      {!!birdFlying && (
        <View style={{ position: "absolute", width, height: height / 4 }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop={false}
            source={birdFlying}
            resizeMode="cover"
          />
        </View>
      )}
    </TouchableOpacity>
  );
}
