import React from "react";
import { StyleSheet, Platform } from "react-native";
import Glob from "src/globalConstants";
import Button from "src/components/Button";

export default function AppButton({ app, onPress, style = {} }) {
  if (!app) return null;
  const unpublished = app.userIsCreator && !app.public;
  let appName = app.name;
  if (unpublished) appName = `✨ ${app.name} (unpublished)`;
  else if (app.locked) appName = `🔒 ${app.name}`;
  return (
    <Button
      text={appName}
      onPress={() => onPress(app)}
      textStyle={styles.buttonText}
      style={[
        styles.button,
        unpublished
          ? {
              borderColor: "#F1D302",
              borderWidth: 3
            }
          : {},
        style
      ]}
    />
  );
}

const button = {
  backgroundColor: "white",
  marginTop: 6,
  marginBottom: 3,
  width: "90%",
  maxWidth: 600, // just for tablets
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 4,
  elevation: 4
};
if (Platform.OS === "web") button.boxShadow = "0px 0px 4px rgba(0,0,0,0.2)";

const styles = StyleSheet.create({
  button,
  buttonText: {
    color: Glob.get("primaryColor"),
    marginHorizontal: 15,
    textAlign: "left",
    fontWeight: "500"
  }
});
