import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Platform,
  StyleSheet
} from "react-native";
import tinycolor from "tinycolor2";
import Communications from "react-native-communications";
import { Entypo } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import * as SMS from "expo-sms";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import Icon from "src/components/Icon";
import LottieView from "src/components/Lottie";

const { height, width } = Glob.get("dimensions");

const NOTIFICATION_ANIMATION = require("resources/animations/notificationPulseRed.json");

export default function Button({
  text = "Submit",
  disabled = false,
  style = {},
  textStyle = {},
  isLinkStyle = false,
  icon,
  onPress,
  type = "default",
  small,
  flat,
  outline,
  color,
  align,
  noMargin,
  showError,
  errorMessage,
  flexibleWidth,
  showNotification = false,
  options = {} // phoneNumber, emailAddress, smsContent, smsRecipient, alignLeft
}) {
  let onPressFinal = onPress;
  let iconFinal = icon;

  const composeSMS = () => {
    SMS.isAvailableAsync().then((SMSIsAvailable) => {
      if (SMSIsAvailable)
        SMS.sendSMSAsync([options.smsRecipient], options.smsContent);
      else
        Util.alert(
          `Sorry! SMS is not available on this device.${
            Platform.OS === "web"
              ? " Install the mobile app to use SMS and lots of other features!"
              : ""
          }`
        );
    });
  };

  switch (type) {
    case "call":
      if (!onPress) {
        if (options.phoneNumber)
          onPressFinal = () =>
            Communications.phonecall(
              `${options.phoneNumber}`.replace(/\D/g, ""),
              true
            );
        else onPressFinal = () => Util.alert("Oops! 😕", errorMessage);
      }
      if (!icon) iconFinal = "call";
      break;
    case "email":
      if (!onPress) {
        if (options.emailAddress)
          onPressFinal = () =>
            Linking.openURL(`mailto:${options.emailAddress}`);
        else onPressFinal = () => Util.alert("Oops! 😕", errorMessage);
      }
      if (!icon) iconFinal = "email";
      break;
    case "sms":
      if (!onPress && options.smsRecipient) onPressFinal = composeSMS;
      if (!icon) iconFinal = "sms";
      break;
    default:
      break;
  }

  const buttonColor =
    color || style?.backgroundColor || Rex.getConfig()?.colors?.button;
  const textColor = tinycolor(buttonColor).isLight() ? "black" : "white";
  let alignStyle = {};
  if (align === "left")
    alignStyle = {
      alignSelf: "flex-start",
      marginHorizontal: noMargin ? 0 : 15
    };
  else if (align === "right")
    alignStyle = { alignSelf: "flex-end", marginHorizontal: noMargin ? 0 : 15 };

  if (isLinkStyle)
    return (
      <TouchableOpacity
        underlayColor="transparent"
        activeOpacity={0.7}
        onPress={
          onPressFinal
            ? () => {
                Analytics.logEvent("touch_button", { text, type, options });
                onPressFinal();
              }
            : () => {}
        }
      >
        <View style={styles.rowSection}>
          <View style={{ ...styles.sectionText }}>
            <Text
              style={{
                fontSize: 20,
                color: Rex.getConfig()?.colors?.text
              }}
            >
              {text}
            </Text>
          </View>
          <Entypo
            name="chevron-thin-right"
            size={20}
            color={Rex.getConfig()?.colors?.text}
            style={styles.sectionButton}
          />
        </View>
        {showError && !!errorMessage && (
          <Text
            style={{ position: "absolute", top: 40, left: 20, color: "red" }}
          >
            {errorMessage}
          </Text>
        )}
        <View
          style={[
            styles.separator,
            {
              backgroundColor:
                showError && !!errorMessage
                  ? "red"
                  : Glob.get("very light gray")
            }
          ]}
        />
      </TouchableOpacity>
    );

  let iconColor = outline ? buttonColor : textColor;
  iconColor = textStyle?.color || iconColor;

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      style={[
        Style.get("button"),
        { backgroundColor: buttonColor },
        alignStyle,
        small ? { width: "20%", minWidth: 180 } : {},
        flat ? { minHeight: 25 } : {},
        outline
          ? {
              backgroundColor: "transparent",
              borderColor: buttonColor,
              borderWidth: 1
            }
          : {},
        flexibleWidth ? { flex: 1, minWidth: 0 } : {},
        style,
        showError
          ? { borderColor: "red", borderWidth: 3, marginBottom: 30 }
          : {}
      ]}
      // eslint-disable-next-line
      onPress={onPressFinal ? () => {
              Analytics.logEvent("touch_button", { text, type, options });
              onPressFinal();
            }
          : () => {}
      }
      disabled={disabled}
    >
      <View
        style={{ flexDirection: "row", alignItems: "center", width: "100%" }}
      >
        {!!iconFinal && (
          <View style={{ marginHorizontal: small ? 10 : 15 }}>
            <Icon icon={iconFinal} color={iconColor} size={small ? 18 : 24} />
          </View>
        )}
        <Text
          adjustsFontSizeToFit
          numberOfLines={1}
          style={[
            Style.get("normalButtonText"),
            Platform.OS === "web" ? { fontSize: 19 } : {},
            {
              textAlign: options.alignLeft ? "left" : "center",
              color: textColor
            },
            small ? { fontSize: 16 } : {},
            flat ? { fontSize: 14 } : {},
            outline ? { color: buttonColor } : {},
            textStyle
          ]}
        >
          {text}
        </Text>
        {!!iconFinal && <View style={{ marginHorizontal: 15, opacity: 0 }} />}
      </View>
      {showError && !!errorMessage && (
        <Text
          style={{ position: "absolute", top: flat ? 35 : 50, color: "red" }}
        >
          {errorMessage}
        </Text>
      )}
      {showNotification && (
        <View
          style={{
            position: "absolute",
            right: small ? -8 : -12,
            top: small ? -8 : -12
          }}
        >
          <LottieView
            style={{ width: small ? 30 : 40, height: small ? 30 : 40 }}
            autoPlay
            loop
            source={NOTIFICATION_ANIMATION}
          />
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  rowSection: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "flex-end",
    marginRight: 20
  },
  sectionText: {
    flex: 1,
    marginTop: 15,
    marginBottom: 15
  },
  separator: {
    width: 0.9 * width,
    height: 1,
    alignSelf: "flex-end",
    marginRight: 20,
    backgroundColor: Glob.get("very light gray")
  },
  sectionButton: {
    flex: 0,
    height: 0.03 * height,
    marginRight: 0.04 * width,
    marginTop: 15,
    marginBottom: 15
  }
});
