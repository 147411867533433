import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Pressable,
  Platform
} from "react-native";
import { Skeleton } from "native-base";
import { Ionicons, MaterialIcons, Feather, Entypo } from "@expo/vector-icons";
import Analytics from "src/backend/analytics";
import Glob from "src/globalConstants";

const { width, fullWidth } = Glob.get("dimensions");
const HANDLE_WIDTH = 50;
const HANDLE_MARGIN = 3;
const PLUS_BUTTON_HEIGHT = 26;
const MENU_BUTTON_HEIGHT = 60;

const ItemPlaceholder = () => {
  return (
    <View
      style={{
        flexDirection: "row",
        marginVertical: 10,
        width: "100%",
        padding: 20,
        alignSelf: "center",
        backgroundColor: "#eef2ff"
      }}
    >
      <Skeleton
        flex="1"
        h="12"
        rounded="full"
        startColor="#e0e7ff"
        endColor="#a5b4fc"
      />
      <Skeleton.Text flex="3" px="4" startColor="#e0e7ff" endColor="#a5b4fc" />
    </View>
  );
};

export default function EditableItemWrapper({
  isIndexToAddItem,
  onSetHeight,
  component,
  drag,
  add,
  duplicate,
  remove,
  isActive,
  isEditingAnItem,
  index
}) {
  const [showingMenu, setShowingMenu] = useState(false);
  const [showingDelete, setShowingDelete] = useState(false);
  const [height, setHeight] = useState(null);
  const itemWrapperRef = useRef(null);

  useEffect(() => {
    onSetHeight(index, height);
  }, [index, height]);

  let RightComponent = null;
  if (isEditingAnItem) {
    // Leave RightComponent null
  } else {
    RightComponent = (
      <>
        {showingMenu && !showingDelete && (
          <View
            style={[
              {
                position: "absolute",
                top:
                  (height - MENU_BUTTON_HEIGHT - PLUS_BUTTON_HEIGHT) / 2 -
                  HANDLE_MARGIN,
                right:
                  width < fullWidth
                    ? HANDLE_WIDTH + HANDLE_MARGIN
                    : HANDLE_WIDTH,
                flexDirection: "row",
                zIndex: 999999,
                shadowOpacity: 0.2,
                shadowOffset: { width: 0, height: 0 },
                shadowRadius: 20,
                elevation: 10
              },
              Platform.OS === "web"
                ? { boxShadow: "0px 0px 10px rgba(0,0,0,0.15)" }
                : {}
            ]}
          >
            <TouchableOpacity
              activeOpacity={0.6}
              style={[
                styles.draggableRowHandle,
                {
                  backgroundColor: "#bee8fa",
                  height: MENU_BUTTON_HEIGHT,
                  marginVertical: 0,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5
                }
              ]}
              onPress={() => {
                setShowingMenu(!showingMenu);
                duplicate();
              }}
            >
              <Feather name="copy" size={28} color="#2c98c7" />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.6}
              style={[
                styles.draggableRowHandle,
                {
                  backgroundColor: "#ebcece",
                  height: MENU_BUTTON_HEIGHT,
                  marginVertical: 0
                }
              ]}
              onPress={() => setShowingDelete(true)}
            >
              <MaterialIcons
                name="delete"
                size={28}
                color={Glob.get("dangerRed")}
              />
            </TouchableOpacity>
          </View>
        )}
        {showingMenu && showingDelete && (
          <TouchableOpacity
            activeOpacity={0.6}
            style={[
              styles.draggableRowHandle,
              { backgroundColor: Glob.get("dangerRed"), width: 75 }
            ]}
            onPress={remove}
          >
            <Text style={{ color: "white", fontWeight: "bold" }}>Delete</Text>
          </TouchableOpacity>
        )}
        <Pressable
          style={[
            styles.draggableRowHandle,
            {
              borderRadius: 5,
              cursor: isActive ? "grabbing" : "grab",
              marginRight: width < fullWidth ? HANDLE_MARGIN : 0,
              shadowOpacity: isActive ? 0 : 0.2,
              shadowOffset: { width: 0, height: 0 },
              shadowRadius: 2,
              elevation: 5
            },
            Platform.OS === "web" && !isActive
              ? { boxShadow: "0px 0px 5px rgba(0,0,0,0.15)" }
              : {}
          ]}
          onLongPress={drag}
          delayLongPress={200}
          onPress={() => {
            Analytics.logEvent(
              `touch_editableItemWrapper_${showingMenu ? "hide" : "show"}Menu`
            );
            setShowingMenu(!showingMenu);
            setShowingDelete(false);
          }}
        >
          <Ionicons
            name={showingMenu ? "close" : "reorder-three-outline"}
            size={28}
            color="gray"
          />
        </Pressable>
      </>
    );
  }
  const activeStyle = {
    elevation: 10,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10
  };
  if (Platform.OS === "web")
    activeStyle.boxShadow = "0px 0px 10px rgba(0,0,0,0.15)";

  const calculateYPosition = () => {
    if (itemWrapperRef?.current) {
      itemWrapperRef?.current.measure((x, y, width, height, pageX, pageY) =>
        setHeight(height)
      );
    }
  };

  calculateYPosition();

  return (
    <View
      style={styles.draggableRowContainer}
      ref={itemWrapperRef}
      collapsable={false}
      onLayout={calculateYPosition}
    >
      <View style={[styles.draggableRow, isActive ? activeStyle : {}]}>
        <View style={{ flex: 8 }}>{component}</View>
        {RightComponent}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginVertical: 2
        }}
      >
        {isEditingAnItem || isActive ? (
          <View style={{ height: 28 }} />
        ) : (
          <>
            <View
              style={{
                flex: 1,
                height: 1,
                backgroundColor: Glob.get("onespotColor"),
                opacity: 1
              }}
            />

            <TouchableOpacity
              activeOpacity={0.6}
              style={[
                {
                  borderRadius: PLUS_BUTTON_HEIGHT,
                  height: PLUS_BUTTON_HEIGHT,
                  width: 40,
                  backgroundColor: Glob.get("onespotColor"),
                  alignItems: "center",
                  justifyContent: "center",
                  shadowOpacity: 0.2,
                  shadowOffset: { width: 0, height: 0 },
                  shadowRadius: 2,
                  elevation: 5
                },
                Platform.OS === "web"
                  ? { boxShadow: "0px 0px 5px rgba(0,0,0,0.15)" }
                  : {}
              ]}
              onPress={() => {
                Analytics.logEvent(
                  "touch_editableItemWrapper_addItemLowerButton"
                );
                add();
              }}
            >
              <Entypo name="plus" size={18} color="white" />
            </TouchableOpacity>
            <View
              style={{
                flex: 1,
                height: 1,
                backgroundColor: Glob.get("onespotColor"),
                opacity: 1
              }}
            />
          </>
        )}
      </View>
      {isIndexToAddItem && <ItemPlaceholder />}
    </View>
  );
}

const styles = StyleSheet.create({
  draggableRowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  draggableRow: {
    display: "flex",
    flex: 7,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  draggableRowHandle: {
    width: HANDLE_WIDTH,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: HANDLE_MARGIN,
    backgroundColor: "white"
  }
});
