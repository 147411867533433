/* global window */

// NOTE: This page intentionally only works on web
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ActivityIndicator
} from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import School from "school/school";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";

const { width } = Glob.get("dimensions");

export default function SubscribePay({ navigation, route }) {
  const { params: { communityType } = {} } = route || {};
  const [userEmail, setUserEmail] = useState(null);
  const [numberOfStudents, setNumberOfStudents] = useState("100");
  const [fetchingCheckoutSession, setFetchingCheckoutSession] = useState(false);

  const databaseAppID = School.getDatabaseAppID();

  useEffect(() => {
    Analytics.logEvent("view_subscribePay");
    Database.fetchAllUserData().then((data) => {
      setUserEmail(data?.email);
    });
  }, []);

  if (!userEmail)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Subscribe & Publish" />
        <ActivityIndicator size="large" style={{ marginTop: 30 }} />
      </View>
    );

  if (Platform.OS !== "web")
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Subscribe & Publish" />
        <Text style={Style.get("headerText")}>
          Sorry, you can only access this screen on Onespot Web.
        </Text>
      </View>
    );

  const numberOfStudentsIsValid =
    !!numberOfStudents &&
    Number.isInteger(Number(numberOfStudents)) &&
    numberOfStudents > 0;
  const tooManyStudents =
    numberOfStudentsIsValid && Number(numberOfStudents) > 10000;
  const cannotCheckout = !numberOfStudentsIsValid || tooManyStudents;

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Subscribe & Publish" />
      {communityType === "school" ? (
        <View
          style={{
            flex: 1,
            width,
            paddingTop: 20,
            paddingHorizontal: 15
          }}
        >
          <InputBox
            header="How many students are at your school?"
            description="Input the number of enrolled students your school has."
            onChangeText={setNumberOfStudents}
            value={numberOfStudents}
          />
          {!numberOfStudentsIsValid && (
            <Text style={{ color: Glob.get("dangerRed") }}>
              Looks like that's an invalid number of students, sorry.
            </Text>
          )}
          {tooManyStudents && (
            <Text style={{ color: Glob.get("dangerRed") }}>
              That's a lot of students! Contact us for a discount.
            </Text>
          )}
          {fetchingCheckoutSession ? (
            <View style={{ alignItems: "center" }}>
              <ActivityIndicator
                size="large"
                style={{ marginTop: 30, marginBottom: 10 }}
              />
              <Text>Calculating your all-inclusive price...</Text>
            </View>
          ) : (
            <Button
              text="Next"
              disabled={cannotCheckout}
              onPress={() => {
                setFetchingCheckoutSession(true);
                Database.createStripeCheckoutSession({
                  type: "school",
                  students: numberOfStudents
                }).then((data) => {
                  setFetchingCheckoutSession(false);
                  if (data?.checkoutSessionURL) {
                    window.open(data.checkoutSessionURL, "_self");
                  }
                });
              }}
              style={{ marginTop: 20, opacity: !cannotCheckout ? 1 : 0.3 }}
            />
          )}
        </View>
      ) : (
        <View style={{ flex: 1, width: "100%", alignItems: "center" }}>
          <View style={{ width, paddingHorizontal: 15, marginTop: 20 }}>
            <Text style={Style.get("headerText")}>Pick your plan</Text>
            <Text style={Style.get("subheaderText")}>
              Once you select a plan and pay, your app will be instantly
              published within Onespot. You can unpublish or unsubscribe at any
              time.
            </Text>
          </View>
          <iframe
            srcDoc={`
                <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                <stripe-pricing-table
                  ${Glob.get("stripePricingTableIDAndKey")}
                  customer-email="${userEmail}"
                  client-reference-id="${databaseAppID}"
                >
                </stripe-pricing-table>
              `}
            title="Subscribe"
            style={{ height: "100%", width: "100%", border: 0 }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  formDescription: {
    marginTop: 20,
    marginBottom: 5
  }
});
