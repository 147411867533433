import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment";
import * as SecureStore from "expo-secure-store";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import LottieView from "src/components/Lottie";
import StatusMessage from "src/components/StatusMessage";
import Button from "src/components/Button";
import DigitalID from "../components/DigitalID";

const screenName = "My Uphill Pass";
const SNOW_ANIMATION = require("resources/animations/snowFalling.json");

const { width, height } = Glob.get("dimensions");

export default function Passport({ navigation }) {
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [placeName, setPlaceName] = useState(null);
  const [checkinTime, setCheckinTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    Analytics.logEvent("view_passport");
    Database.listenUserFirstName((first) => {
      Database.listenUserLastName((last) => {
        setFullName(`${first} ${last}`);
      });
    });
    Database.listenUserEmail((userEmail) => {
      setEmail(userEmail);
    });
    SecureStore.getItemAsync("placeCheckInLocationName").then(
      (storedPlaceName) => {
        SecureStore.getItemAsync("placeCheckInTime").then((storedTime) => {
          setPlaceName(storedPlaceName);
          setCheckinTime(storedTime);
        });
      }
    );
    setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
  }, []);

  const userIsCheckedIn = !!placeName && !!checkinTime;

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={screenName} />
      <View
        style={{
          alignItems: "center",
          paddingHorizontal: 15,
          backgroundColor: "#e6f2ff",
          flex: 1
        }}
      >
        <LottieView
          style={{ position: "absolute", height: "100%", width: "100%" }}
          autoPlay
          loop
          source={SNOW_ANIMATION}
        />
        <StatusMessage
          type={userIsCheckedIn ? "success" : "error"}
          message={
            userIsCheckedIn
              ? `${fullName} checked in at ${placeName} on ${moment(
                  checkinTime,
                  "x"
                ).format("dddd, MMMM D, h:mm A")}`
              : `${fullName} has not yet checked in.`
          }
        />
        {!userIsCheckedIn && (
          <Button
            text="Check In"
            style={{ marginTop: 10 }}
            color="#b91c1c"
            flat
            small
            onPress={() =>
              navigation.push("placecheckin", {
                onCheckIn: (place, time) => {
                  setPlaceName(place?.Name || "UNKNOWN");
                  setCheckinTime(time);
                }
              })
            }
          />
        )}
        <View style={{ marginVertical: 30, width: "100%" }}>
          <DigitalID
            name="Passholder"
            subtitle={fullName}
            bottomText="This pass is valid for uphill skiing on all mountains affiliated with Uphill New England."
            email={email}
            image={Glob.get("uphillNewEnglandLogoBadge")}
            color="#55b9c6"
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  fullName: {
    fontSize: 40,
    fontWeight: "bold"
  }
});
