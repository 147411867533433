/* global window */

import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import School from "school/school";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import HelpText from "src/components/HelpText";
import Icon from "src/components/Icon";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import MessageHistory from "src/components/MessageHistory";
import HeaderAnimation from "src/components/MessageHistoryHeaderAnimation";

const { width } = Glob.get("dimensions");

export default function TextsAndCalls({ navigation }) {
  const [loaded, setLoaded] = useState(false);
  const [credits, setCredits] = useState(0);
  const [history, setHistory] = useState(null);
  const [savedPhoneNumbers, setSavedPhoneNumbers] = useState(null);
  const [phoneNumberFieldStatus, setPhoneNumberFieldStatus] = useState(null);
  const [
    showPhoneNumberFieldSettings,
    setShowPhoneNumberFieldSettings
  ] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showGetMoreCredits, setShowGetMoreCredits] = useState(false);
  const [
    fetchingStripeCheckoutSession,
    setFetchingStripeCheckoutSession
  ] = useState(false);

  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToSendNotifications =
    !!adminPrivileges && adminPrivileges?.includes("PushNotifications");

  useEffect(() => {
    Analytics.logEvent("view_sendTextMessage");
    Database.subscribeToTextsAndCalls((data) => {
      const {
        currentCredits: dbCurrentCredits,
        history: dbHistory,
        savedPhoneNumbers: dbSavedPhoneNumbers
      } = data || {};
      setCredits(dbCurrentCredits || 0);
      setHistory(dbHistory || {});
      setSavedPhoneNumbers(dbSavedPhoneNumbers);
      setLoaded(true);
    });
    Database.fetchUserAccountFields().then(({ fields }) => {
      if (!fields?.phoneNumber) setPhoneNumberFieldStatus("off");
      else if (!fields.phoneNumber.required)
        setPhoneNumberFieldStatus("optional");
      else setPhoneNumberFieldStatus("required");
    });
    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromTextsAndCalls();
    };
  }, []);

  // status = "off", "optional", or "required"
  const updatePhoneNumberField = (status) => {
    Database.fetchUserAccountFields().then(({ fields }) => {
      const { phoneNumber, ...phonelessFields } = fields;
      if (status === "off") {
        Database.updateUserAccountFields(phonelessFields);
      } else if (status === "optional") {
        Database.updateUserAccountFields({
          ...fields,
          phoneNumber: { required: false, title: "Phone Number" }
        });
      } else if (status === "required") {
        Database.updateUserAccountFields({
          ...fields,
          phoneNumber: { required: true, title: "Phone Number" }
        });
      }
      setPhoneNumberFieldStatus(status);
    });
  };

  let helpTextSuffix = "your app won't ask them for their phone number.";
  if (phoneNumberFieldStatus === "optional")
    helpTextSuffix =
      "they will be asked for their phone number, but it will be optional.";
  else if (phoneNumberFieldStatus === "required")
    helpTextSuffix = "they will be required to provide their phone number.";
  const helpText = `When a new member creates an account, ${helpTextSuffix}`;

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Texting & Calling" />

      <ScrollView
        contentContainerStyle={{ width, alignItems: "center" }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <HeaderAnimation />
        <View style={{ width: width * 0.9 }}>
          {!loaded ? (
            <ActivityIndicator size="large" style={{ marginTop: 80 }} />
          ) : (
            <>
              <Text style={Style.get("headerText")}>
                Send messages that can't be missed
              </Text>
              <Text style={Style.get("subheaderText")}>
                90% of text messages are opened within 3 minutes.
              </Text>
              <View style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  text="Send Bulk Message"
                  icon="announcements"
                  onPress={() =>
                    navigation.push("sendTextsAndCalls", {
                      credits,
                      savedPhoneNumbers
                    })
                  }
                />
                {allowedToSendNotifications && (
                  <Button
                    text="Send Notification"
                    icon="send"
                    outline
                    onPress={() =>
                      navigation.push("sendPushNotification", {
                        sendAsTextMessage: true
                      })
                    }
                  />
                )}
                <Text
                  style={[
                    Style.get("subheaderText"),
                    { textAlign: "center", fontSize: 18 }
                  ]}
                >
                  Phone Credits:{" "}
                  <Text
                    style={{ fontWeight: "bold", color: "black", fontSize: 24 }}
                  >
                    {(credits || 0).toLocaleString()}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#eee",
                  marginBottom: 10,
                  padding: 10,
                  borderRadius: 10
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    Analytics.logEvent("touch_sendTextMessage_settings");
                    setShowPhoneNumberFieldSettings(
                      !showPhoneNumberFieldSettings
                    );
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Text style={[Style.get("headerText"), { color: "#333" }]}>
                    Settings
                  </Text>
                  <Icon
                    icon={
                      showPhoneNumberFieldSettings
                        ? "aba344c6-435e-4da8-965f-54ed12a04792"
                        : "5429cf21-f257-4ada-964f-a34e34e1b541"
                    } // chevron-left/right
                    color="#333"
                    size={24}
                  />
                </TouchableOpacity>
                {showPhoneNumberFieldSettings && (
                  <View>
                    <Checkbox
                      checked={phoneNumberFieldStatus === "required"}
                      radio
                      text={
                        <Text>
                          Phone numbers are{" "}
                          <Text style={{ fontWeight: "bold" }}>required</Text>
                        </Text>
                      }
                      onChange={() => updatePhoneNumberField("required")}
                    />
                    <Checkbox
                      checked={phoneNumberFieldStatus === "optional"}
                      radio
                      text={
                        <Text>
                          Phone numbers are{" "}
                          <Text style={{ fontWeight: "bold" }}>optional</Text>
                        </Text>
                      }
                      onChange={() => updatePhoneNumberField("optional")}
                    />
                    <Checkbox
                      checked={phoneNumberFieldStatus === "off"}
                      radio
                      text={
                        <Text>
                          <Text style={{ fontWeight: "bold" }}>Don't ask</Text>{" "}
                          users for phone numbers
                        </Text>
                      }
                      onChange={() => updatePhoneNumberField("off")}
                    />
                    <HelpText text={helpText} />
                  </View>
                )}
              </View>
              <View
                style={{
                  backgroundColor: "#eee",
                  marginBottom: 10,
                  padding: 10,
                  borderRadius: 10
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    Analytics.logEvent("touch_sendTextMessage_help");
                    setShowGetMoreCredits(!showGetMoreCredits);
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Text style={[Style.get("headerText"), { color: "#333" }]}>
                    Get more phone credits
                  </Text>
                  <Icon
                    icon={
                      showGetMoreCredits
                        ? "aba344c6-435e-4da8-965f-54ed12a04792"
                        : "5429cf21-f257-4ada-964f-a34e34e1b541"
                    } // chevron-left/right
                    color="#333"
                    size={24}
                  />
                </TouchableOpacity>
                {showGetMoreCredits && (
                  <>
                    {Platform.OS === "web" ? (
                      <View>
                        <Text style={Style.get("subheaderText")}>
                          Let's restock your phone credits!{"\n\n"}Each phone
                          credit is worth $0.10. How many would you like to buy?
                        </Text>
                        {!fetchingStripeCheckoutSession ? (
                          <>
                            <Button
                              text="+100"
                              onPress={() => {
                                setFetchingStripeCheckoutSession(true);
                                Database.createStripeCheckoutSessionToBuyPhoneCredits(
                                  100
                                ).then((data) => {
                                  setFetchingStripeCheckoutSession(false);
                                  if (data?.checkoutSessionURL) {
                                    window.open(
                                      data.checkoutSessionURL,
                                      "_self"
                                    );
                                  }
                                });
                              }}
                              small
                              flat
                              outline
                            />
                            <Button
                              text="+500"
                              onPress={() => {
                                setFetchingStripeCheckoutSession(true);
                                Database.createStripeCheckoutSessionToBuyPhoneCredits(
                                  500
                                ).then((data) => {
                                  setFetchingStripeCheckoutSession(false);
                                  if (data?.checkoutSessionURL) {
                                    window.open(
                                      data.checkoutSessionURL,
                                      "_self"
                                    );
                                  }
                                });
                              }}
                              small
                              flat
                              outline
                            />
                            <Button
                              text="+1,000"
                              onPress={() => {
                                setFetchingStripeCheckoutSession(true);
                                Database.createStripeCheckoutSessionToBuyPhoneCredits(
                                  1000
                                ).then((data) => {
                                  setFetchingStripeCheckoutSession(false);
                                  if (data?.checkoutSessionURL) {
                                    window.open(
                                      data.checkoutSessionURL,
                                      "_self"
                                    );
                                  }
                                });
                              }}
                              small
                              flat
                            />
                            <Button
                              text="+5,000"
                              onPress={() => {
                                setFetchingStripeCheckoutSession(true);
                                Database.createStripeCheckoutSessionToBuyPhoneCredits(
                                  5000
                                ).then((data) => {
                                  setFetchingStripeCheckoutSession(false);
                                  if (data?.checkoutSessionURL) {
                                    window.open(
                                      data.checkoutSessionURL,
                                      "_self"
                                    );
                                  }
                                });
                              }}
                              small
                              flat
                              outline
                            />
                            <Button
                              text="+10,000"
                              onPress={() => {
                                setFetchingStripeCheckoutSession(true);
                                Database.createStripeCheckoutSessionToBuyPhoneCredits(
                                  10000
                                ).then((data) => {
                                  setFetchingStripeCheckoutSession(false);
                                  if (data?.checkoutSessionURL) {
                                    window.open(
                                      data.checkoutSessionURL,
                                      "_self"
                                    );
                                  }
                                });
                              }}
                              small
                              flat
                              outline
                            />
                          </>
                        ) : (
                          <ActivityIndicator
                            size="large"
                            style={{ marginTop: 20 }}
                          />
                        )}
                      </View>
                    ) : (
                      <View>
                        <Text style={Style.get("subheaderText")}>
                          Visit this screen on the web version of your app to
                          get more phone credits.
                        </Text>
                        <ButtonItem
                          navigate={navigation.push}
                          item={{
                            type: "button_web",
                            url: `https://www.1spot.app?app=${School.getDatabaseAppID()}`,
                            title: "Open on Web",
                            small: true,
                            openExternally: true
                          }}
                        />
                      </View>
                    )}
                  </>
                )}
              </View>
              <View
                style={{
                  backgroundColor: "#eee",
                  marginBottom: 40,
                  padding: 10,
                  borderRadius: 10
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    Analytics.logEvent("touch_sendTextMessage_help");
                    setShowHelp(!showHelp);
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Text style={[Style.get("headerText"), { color: "#333" }]}>
                    Help
                  </Text>
                  <Icon
                    icon={
                      showHelp
                        ? "aba344c6-435e-4da8-965f-54ed12a04792"
                        : "5429cf21-f257-4ada-964f-a34e34e1b541"
                    } // chevron-left/right
                    color="#333"
                    size={24}
                  />
                </TouchableOpacity>
                {showHelp && (
                  <View>
                    <Text style={Style.get("subheaderText")}>
                      Being able to send text messages and automated phone calls
                      is a powerful tool for communicating critical information
                      to your community members.
                    </Text>
                    <Button
                      text="Learn More"
                      icon="3a1c6da5-3e54-4e2f-9ff4-35d8001f12e9" // lightbulb
                      small
                      onPress={() =>
                        navigation.push("webNav", {
                          title: "Learn More",
                          url:
                            "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NTKkg3oTVfj5lnDbfto"
                        })
                      }
                    />
                  </View>
                )}
              </View>
              <MessageHistory history={history} />
            </>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  },
  historyRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  }
});
