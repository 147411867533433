import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Platform } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import UserList from "src/components/UserList";
import Button from "src/components/Button";

export default function ManageUsers({ navigation }) {
  const [showShareOptions, setShowShareOptions] = useState(true);

  useEffect(() => {
    Analytics.logEvent("view_manageUsers");
    Database.addTask("viewAllMembers", "done");
  }, []);

  const bottomContainerStyle = {
    position: Platform.OS === "web" ? "fixed" : "absolute",
    bottom: 0,
    paddingTop: 24,
    paddingBottom: 32,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    elevation: 20,
    width: "100%"
  };
  if (Platform.OS === "web")
    bottomContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";

  return (
    <View style={styles.pageContent}>
      <UserList navBarTitle="All Members" navigation={navigation} />
      {showShareOptions && (
        <View style={bottomContainerStyle}>
          <Button
            text="Quick share"
            icon="8a4a8b06-5206-4c53-9d17-5925616a0ec0" // share
            small
            textStyle={{ fontWeight: "bold" }}
            outline
            onPress={() => navigation.push("shareApp")}
          />
          <Button
            text="Send invites"
            icon="send"
            small
            textStyle={{ fontWeight: "bold" }}
            onPress={() => navigation.push("inviteUsers")}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 7,
              justifyContent: "center",
              alignItems: "center"
            }}
            activeOpacity={0.6}
            onPress={() => {
              setShowShareOptions(false);
              Analytics.logEvent("touch_manageUsers_hideShareOptions");
            }}
          >
            <MaterialCommunityIcons
              name="window-close"
              size={24}
              color="gray"
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
