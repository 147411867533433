// TEMPORARY food portal -- will be replaced by a nicer one

import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableHighlight,
  FlatList
} from "react-native";
import School from "school/school";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";

const { height, width } = Dimensions.get("window");
const pageName = "Food";

export default class Food extends Component {
  constructor(props) {
    super();
    this.state = { dataRows: [] };
    Analytics.logEvent("view_food");
  }

  componentDidMount() {
    Util.fetchGoogleSheetData(School.food("food sheet id"), "food", false).then(
      (rows) => {
        if (rows && rows.length > 0 && rows[0].length > 0) {
          this.setState({ dataRows: rows.map((r) => r[0]) });
        }
      }
    );
  }

  navigate(routeName, navName) {
    const { navigation } = this.props;
    navigation.push(routeName, { navName });
  }

  renderRow = ({ item }) => {
    if (item === "OPEN NOW:" || item === "CLOSED NOW:") {
      return <Text style={styles.listHeader}>{item}</Text>;
    }
    if (item && item.substring(0, 3) === " **") {
      return <Text style={styles.listItemEmphasized}>{item}</Text>;
    }

    return <Text style={styles.listItem}>{item}</Text>;
  };

  // renders the long button in the Food portal (e.g. "Get 15% off Domino's")
  renderLongButton = () => {
    if (School.food("long button active")) {
      return (
        <View
          style={[
            styles.longButton,
            { backgroundColor: Rex.getConfig()?.colors?.button }
          ]}
        >
          <TouchableHighlight
            style={styles.buttonView}
            underlayColor="transparent"
            onPress={() => {
              this.navigate("webNav", School.food("long button navigate"));
            }}
          >
            <Text style={styles.buttonText}>
              {School.food("long button text")}
            </Text>
          </TouchableHighlight>
        </View>
      );
    }
    // otherwise, return a blank space
    return <View style={styles.blankSpace}></View>;
  };

  renderMeal = () => {
    if (School.food("meal active")) {
      return (
        <View>
          <Text
            style={[
              styles.currentSwipe,
              { color: Rex.getConfig()?.colors?.text }
            ]}
          >
            {School.food("meal text 1")}
          </Text>
          <Text
            style={[
              styles.currentSwipeText,
              { color: Rex.getConfig()?.colors?.text }
            ]}
          >
            {School.food("meal text 2")}
          </Text>
        </View>
      );
    }
    return null;
  };

  render() {
    const { navigation } = this.props;
    const { dataRows } = this.state;
    return (
      <View style={{ backgroundColor: "white" }}>
        <NavBar navigation={navigation} text={pageName} />

        <View style={styles.buttonsRow}>
          <View
            style={[
              styles.halfButton,
              { backgroundColor: Rex.getConfig()?.colors?.button }
            ]}
          >
            <TouchableHighlight
              style={styles.buttonView}
              underlayColor="transparent"
              onPress={() => this.navigate("foodHours")}
            >
              <Text style={styles.buttonText}>Hours</Text>
            </TouchableHighlight>
          </View>

          <View
            style={[
              styles.halfButton,
              { backgroundColor: Rex.getConfig()?.colors?.button }
            ]}
          >
            <TouchableHighlight
              style={styles.buttonView}
              underlayColor="transparent"
              onPress={() => {
                this.navigate("webNav", "web_FoodMenus");
              }}
            >
              <Text style={styles.buttonText}>Menus</Text>
            </TouchableHighlight>
          </View>
        </View>

        {this.renderLongButton()}

        {this.renderMeal()}

        <View style={styles.mainContent}>
          <FlatList
            data={dataRows}
            renderItem={this.renderRow}
            keyExtractor={(item, index) => item + index}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  /* Style for the main section that will hold all the of the content */
  mainContent: {
    backgroundColor: "white",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 0.022 * height
  },

  /* Style for the words "current meal swipe:" */
  currentSwipe: {
    fontSize: 0.027 * height,
    textAlign: "center",
    marginTop: 0.015 * height,
    marginBottom: 0.007 * height
  },

  /* Style for the meal and value of the current swipe */
  currentSwipeText: {
    fontSize: 0.045 * height,
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0.007 * height
  },

  /* Styles for the list headers above the item */
  listHeader: {
    marginLeft: 0.027 * width,
    height: 0.033 * height,
    backgroundColor: Glob.get("very light gray"),
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold"
  },

  /* Styles for the emphasized list item */
  listItemEmphasized: {
    marginLeft: 0.027 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.024 * height,
    borderBottomColor: Glob.get("very light gray"),
    textAlign: "center",
    fontWeight: "bold"
  },

  /* Styles for the list item */
  listItem: {
    marginLeft: 0.027 * width,
    height: 0.04 * height,
    backgroundColor: "white",
    fontSize: 0.024 * height,
    borderBottomColor: Glob.get("very light gray")
  },

  /* Style for the text on the buttons */
  buttonText: {
    color: "white",
    fontSize: 0.042 * height,
    marginTop: 0.012 * height
  },

  buttonsRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 0.015 * height,
    marginBottom: 0.052 * height,
    marginLeft: 0.027 * width,
    marginRight: 0.022 * width
  },

  halfButton: {
    width: 0.472 * width,
    justifyContent: "space-between",
    height: 0.075 * height
  },

  longButton: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0.027 * height,
    margin: 0.015 * height,
    height: 0.075 * height,
    width: 0.95 * width
  },

  blankSpace: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0.027 * height,
    margin: 0.015 * height,
    height: 0.02 * height,
    width: 0.95 * width
  },

  /* Style for the dimensions of the button images */
  buttonHeightWidth: {
    height: 0.083 * height,
    width: 0.833 * width
  },

  /* Style for the view of the button */
  buttonView: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  }
});
