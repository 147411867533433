import React from "react";
import { Text, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default function HelpText({ text, noteStyle, style = {} }) {
  const containerStyle = [
    { flexDirection: "row", padding: 5 },
    noteStyle
      ? {
          marginVertical: 10,
          borderRadius: 10,
          padding: 10,
          backgroundColor: "#fff8e6"
        }
      : {},
    style
  ];
  return (
    <View style={containerStyle}>
      <MaterialCommunityIcons
        name="lightbulb-on-outline"
        size={24}
        color="gray"
      />
      <Text style={{ color: "gray", marginHorizontal: 5, flex: 1 }}>
        {text}
      </Text>
    </View>
  );
}
