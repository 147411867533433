import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity
} from "react-native";
import CircularProgress from "src/components/CircularProgress";
import { Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import BottomSheetModal from "src/components/BottomSheetModal";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");
const GRAY = "#cecdcd";
const GREEN = "#2DD881";
const LIGHT_GREEN = "#d5f5e4";
const TASKS = {
  makeApp: {
    name: "Make an app",
    message:
      "Build a mobile app for your business, organization, or community.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfINC-4zyAnk5iQoBg"
  },
  viewAdmin: {
    name: "Explore your superpowers",
    message:
      "Learn about the ways you can engage your community and expand your app.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfIT_9aVThg0YX-_mR",
    associatedPrivilege: "(NONE)"
  },
  viewAllMembers: {
    name: "View your app's members",
    message: "You can see and manage everyone who's joined your app.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NW94dOi8JSVVVStB39h",
    associatedPrivilege: "ManageUsers"
  },
  createNewPortal: {
    name: "Make a screen",
    message: "Make a screen with our drag-and-drop editor.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAbOMTRxQf_n48Ak0o4",
    associatedPrivilege: "MAKE_NEW_SCREEN"
  },
  editPortal: {
    name: "Edit a screen",
    message: "Edit one of the screens you made.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAf6iKhG8KyHls5vmeC",
    associatedPrivilege: "EditAllPortals"
  },
  createUserGroup: {
    name: "Make a notification group",
    message:
      "Each of your members can join multiple notification groups. Notification groups let you send messages to specific segments of your community.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfIZaqcslOdDd6G1XM",
    associatedPrivilege: "EditUserGroups"
  },
  viewAccountTypes: {
    name: "Explore account types",
    message: "Each of your members can have a single account type.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfIcv2iebB4keRI9_f",
    associatedPrivilege: "EditAccountTypes"
  },
  editDefaultPortals: {
    name: "Check out default portals",
    message:
      "You can configure the default screens that show up when a new member joins your app.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfIo8PAV6FhTUo_8ll",
    associatedPrivilege: "EditDefaultPortals"
  },
  sendPushNotification: {
    name: "Send a notification",
    message:
      "Notifications are a great way to communicate with your community. You can send notifications to any segment of your community.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfIuDM1uR0zNgZrDu8",
    associatedPrivilege: "PushNotifications"
  },
  setBannerMessage: {
    name: "Set a banner message",
    message:
      "Banner messages show up at the top of your app's home screen. You can use them to highlight important information or to promote a new feature.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfJ-kvdov_4GCyVYd7",
    associatedPrivilege: "HomeScreenBanner"
  },
  viewAppDesign: {
    name: "Explore your app's design",
    message:
      "You can customize your app's colors, logo, background image, and more.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfJ7m0wNzCLL82vJdn",
    associatedPrivilege: "GlobalConfig"
  },
  publishApp: {
    name: "Publish 🚀",
    message:
      "You can publish your app within Onespot or to the App Store and Google Play.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfJD4_d_xjkfPLld-E",
    associatedPrivilege: "Publish"
  },
  viewShareApp: {
    name: "Share your app",
    message: "Share your app with your community.",
    helpPage:
      "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAfJJsxjndM_L5AFyjX",
    associatedPrivilege: "INVITE_MEMBERS"
  }
};

export default function Tutorial({ route, navigation }) {
  const {
    userID,
    hideProgress,
    showUnopenedTutorialTask = () => {},
    setTutorialToCurrentTask = () => {}
  } = route?.params || {};
  const [tasksDone, setTasksDone] = useState({});
  const [page, setPage] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_tutorial");
    if (!hideProgress) {
      Database.fetchTasks(userID).then((tasks) => {
        const done = {};
        if (tasks?.done) {
          Glob.get("tutorialTasks").forEach((task) => {
            if (task in tasks.done) done[task] = true;
          });
          Analytics.logEvent("action_tutorial_showTasksDone", {
            percentDone: calculatePercentDone(done),
            ...(done ? { tasksDone: Object.keys(done) } : {})
          });
          setTasksDone(done);
        }
      });
    }
  }, []);

  const ToDoItem = ({ done, taskID }) => {
    const task = TASKS[taskID];
    const onPress = () => {
      if (task.associatedPrivilege) {
        setAlert({
          title: task.name,
          message: task.message,
          cancel: {
            text: "👀 Guide Me There",
            onPress: () => {
              Rex.setSessionMemory("currentTutorialTask", {
                task: taskID,
                privilege: task.associatedPrivilege
              });
              showUnopenedTutorialTask();
              setTutorialToCurrentTask();
              navigation.goBack();
            }
          },
          confirm: {
            text: "💡 Learn More",
            onPress: () => setPage(task.helpPage)
          }
        });
      } else {
        setAlert({
          title: task.name,
          message: task.message,
          confirm: {
            text: "💡 Learn More",
            onPress: () => setPage(task.helpPage)
          }
        });
      }
    };
    return (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          alignItems: "center",
          height: 56,
          width: "100%",
          marginTop: 10,
          borderRadius: 8,
          borderColor: done ? GREEN : GRAY,
          backgroundColor: done ? LIGHT_GREEN : "white",
          borderWidth: 1
        }}
        onPress={onPress}
      >
        {done ? (
          <Ionicons
            name="checkmark"
            size={30}
            color={GREEN}
            style={{
              marginHorizontal: 10
            }}
          />
        ) : (
          <SimpleLineIcons
            name="question"
            size={30}
            color={GRAY}
            style={{
              marginHorizontal: 10
            }}
          />
        )}
        <Text style={{ fontSize: 16 }}>{task.name}</Text>
      </TouchableOpacity>
    );
  };

  const calculatePercentDone = (done) =>
    Math.round(
      (100 * (Object.keys(done).length + 1)) /
        (Glob.get("tutorialTasks").length + 1)
    );

  const getStartedSubtext = hideProgress
    ? "Make a powerful app + website in 15 minutes without a single line of code."
    : `You made an app for ${
        Rex.getConfig()?.names?.full
      }! Now let's get it finished, published, and into the hands of your members within 15 minutes.`;
  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text={hideProgress ? "Tutorials" : "Tutorial"}
      />
      <ScrollView
        style={{ paddingHorizontal: 20, width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        {!hideProgress && (
          <View style={{ alignItems: "center", marginTop: 20 }}>
            <CircularProgress
              value={calculatePercentDone(tasksDone)}
              color={GREEN}
            />
          </View>
        )}
        <View style={{ marginBottom: 20 }}>
          <Text style={styles.headerText}>1. Get started</Text>
          <Text style={styles.descriptionText}>{getStartedSubtext}</Text>
          <ToDoItem done={!hideProgress} taskID="makeApp" />
          <ToDoItem
            done={!hideProgress && "viewAdmin" in tasksDone}
            taskID="viewAdmin"
          />
          <ToDoItem
            done={!hideProgress && "viewAllMembers" in tasksDone}
            taskID="viewAllMembers"
          />
          <Text style={styles.headerText}>2. Add some content</Text>
          <Text style={styles.descriptionText}>
            You can make unlimited screens ("portals") and you can put anything
            in them—text, buttons, websites, calendars, images, etc. Editing
            your mobile app is as easy as writing a note on your phone.
          </Text>
          <ToDoItem
            done={!hideProgress && "createNewPortal" in tasksDone}
            taskID="createNewPortal"
          />
          <ToDoItem
            done={!hideProgress && "editPortal" in tasksDone}
            taskID="editPortal"
          />
          <Text style={styles.headerText}>
            3. Set up groups & account types
          </Text>
          <Text style={styles.descriptionText}>
            Members can pick their account type and join whatever groups are
            relevant to them. This lets you target your notifications to
            specific segments of your userbase.
          </Text>
          <ToDoItem
            done={!hideProgress && "viewAccountTypes" in tasksDone}
            taskID="viewAccountTypes"
          />
          <ToDoItem
            done={!hideProgress && "createUserGroup" in tasksDone}
            taskID="createUserGroup"
          />
          <ToDoItem
            done={!hideProgress && "editDefaultPortals" in tasksDone}
            taskID="editDefaultPortals"
          />
          <Text style={styles.headerText}>4. Try sending some messages</Text>
          <Text style={styles.descriptionText}>
            You can send push notifications to any segment of your community.
            You can set a banner message that shows up at the top of your app's
            home screen. You can directly chat with any member. So many ways to
            communicate! And it's all as easy as sending text messages.
          </Text>
          <ToDoItem
            done={!hideProgress && "sendPushNotification" in tasksDone}
            taskID="sendPushNotification"
          />
          <ToDoItem
            done={!hideProgress && "setBannerMessage" in tasksDone}
            taskID="setBannerMessage"
          />
          <Text style={styles.headerText}>
            5. Finish designing it & publish
          </Text>
          <Text style={styles.descriptionText}>
            You can add a logo, set a background image, change your colors, and
            more. Once you're ready, publish it all and then share your new app
            with the world.
          </Text>
          <ToDoItem
            done={!hideProgress && "viewAppDesign" in tasksDone}
            taskID="viewAppDesign"
          />
          <ToDoItem
            done={!hideProgress && "publishApp" in tasksDone}
            taskID="publishApp"
          />
          <ToDoItem
            done={!hideProgress && "viewShareApp" in tasksDone}
            taskID="viewShareApp"
          />
        </View>
      </ScrollView>
      {!!page && <BottomSheetModal onClose={() => setPage(null)} url={page} />}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  headerText: {
    ...Style.get("headerText"),
    fontSize: 20,
    marginTop: 30
  },
  descriptionText: {
    fontSize: 14,
    color: "gray"
  }
});
