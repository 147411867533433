import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  ImageBackground,
  Platform
} from "react-native";
import indefinite from "indefinite";
import LottieView from "src/components/Lottie";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Rex from "src/globalState";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Dropdown from "src/components/Dropdown";
import HelpButton from "src/components/HelpButton";
import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import AlertModal from "src/components/AlertModal";
import PageList from "src/components/pageList";
import Util from "src/utility";
import BottomSheetModal from "src/components/BottomSheetModal";
import AddPortalsToHomeScreen from "src/components/AddPortalsToHomeScreen";

const NEW_HOME_ANIMATION = require("resources/animations/newHome.json");

const { height, width } = Glob.get("dimensions");

export default function EditDefaultPortals({ navigation }) {
  const allPortals = Rex.getAllPortals();
  const portalKeyToPortal = {};
  allPortals.forEach((p) => {
    portalKeyToPortal[p.navName] = p;
  });
  const [allAccountTypeDetails, setAllAccountTypeDetails] = useState(null);
  const [showingByAccountType, setShowingByAccountType] = useState(false);
  const [
    userTypeToDefaultPortalKeys,
    setUserTypeToDefaultPortalKeys
  ] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [defaultPortalsConfig, setDefaultPortalsConfig] = useState(null);
  const [backgroundImageURL, setBackgroundImageURL] = useState(null);
  const [showAddPortalsBottomSheet, setShowAddPortalsBottomSheet] = useState(
    false
  );
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_editDefaultPortals");
    // Note: this is technically "viewDefaultPortals" now, but we're keeping the old name for backwards compatibility
    Database.addTask("editDefaultPortals", "done");
    Database.setAccountTypesIfLegacyDb();
    Database.getAllDefaultPortals((portals) => {
      setUserTypeToDefaultPortalKeys(portals);
      setSelectedUserType(Object.keys(portals)[0]);
    });
    Database.fetchAllAccountTypeDetails().then(setAllAccountTypeDetails);
    Database.subscribeToDefaultPortalsConfig(setDefaultPortalsConfig);
    Firebase.getMediaURLAsync("background.png").then(setBackgroundImageURL);

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromDefaultPortalsConfig();
    };
  }, []);

  const onUpdatePortalKeys = (userType, newPortalKeys) => {
    if (newPortalKeys?.length > 0) {
      setUserTypeToDefaultPortalKeys({
        ...userTypeToDefaultPortalKeys,
        [userType]: newPortalKeys
      });
      Database.setDefaultPortals(userType, newPortalKeys);
    }
  };

  const onPressAddPortal = (newPortals) => {
    const newPortalKeys = newPortals.map((p) => p.navName);
    onUpdatePortalKeys(selectedUserType, newPortalKeys);
  };

  const userTypeTitle = (type) => {
    return allAccountTypeDetails &&
      allAccountTypeDetails[type] &&
      allAccountTypeDetails[type].title
      ? allAccountTypeDetails[type].title.toLowerCase()
      : type;
  };

  if (!userTypeToDefaultPortalKeys || !selectedUserType) return null;

  const allUserTypes = Object.keys(userTypeToDefaultPortalKeys);

  const setHomePortalsAsDefaultPortals = () => {
    const homePortals = Rex.getHomePortals().map((p) => p.navName);
    Database.setAllDefaultPortals(homePortals);
    Database.addTask("editDefaultPortals", "done");
  };

  const toggleTrackOwnHomeScreen = (trackOneHomeScreenForAllDefaults) => {
    Analytics.logEvent(
      `touch_editDefaultPortals_setTrackOwnHomeScreen${
        trackOneHomeScreenForAllDefaults ? "On" : "Off"
      }`
    );
    const newConfig = {
      ...defaultPortalsConfig,
      trackOneHomeScreenForAllDefaults
    };
    if (trackOneHomeScreenForAllDefaults) {
      newConfig.userToTrack = Firebase.getUserID();
      setHomePortalsAsDefaultPortals();
      setAlert({
        title: "Default portals updated 👍",
        message:
          "Any new member who joins will start with your personal home screen arrangement as their default."
      });
    }
    Database.setDefaultPortalsConfig(newConfig);
    Rex.setConfig({ ...Rex.getConfig(), defaultPortalsConfig: newConfig });
  };

  if (!showingByAccountType) {
    const showAsRadioButtons =
      !!defaultPortalsConfig &&
      (!defaultPortalsConfig.trackOneHomeScreenForAllDefaults ||
        defaultPortalsConfig.userToTrack === Firebase.getUserID());
    const trackingSomeoneElse =
      !!defaultPortalsConfig &&
      !!defaultPortalsConfig.trackOneHomeScreenForAllDefaults &&
      defaultPortalsConfig.userToTrack !== Firebase.getUserID();
    return (
      <View style={styles.pageContent}>
        <NavBar
          navigation={navigation}
          text="Home Screen Arrangement"
          RightButton={
            <View style={{ alignItems: "flex-end" }}>
              <HelpButton
                title="Configuring Default Portals"
                message="On this screen, you can configure what your app will look like as soon as a new user signs up. This can look different for different account types."
                navigation={navigation}
                videoUrl="https://youtu.be/pOEc8AXNhzw"
              />
            </View>
          }
        />
        <ScrollView
          style={{ paddingHorizontal: 15, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <View style={{ height: height / 3, alignItems: "center" }}>
            <LottieView
              autoPlay
              loop={false}
              style={{ height: "100%", width: "100%" }}
              source={NEW_HOME_ANIMATION}
            />
          </View>
          <Text style={{ ...Style.get("headerText") }}>
            Configure what new members first see.
          </Text>
          <Text style={styles.text}>
            When a new member joins your app for the first time, they are given
            a default arrangement of portals on their home screen. After that,
            they can customize their home screen however they want.
          </Text>
          <Text style={[styles.text, { marginBottom: 20 }]}>
            To give your new members a nice experience, you can set exactly what
            you want them to first see by default:
          </Text>
          {trackingSomeoneElse && (
            <Text
              style={[
                styles.text,
                { marginBottom: 20, fontWeight: "bold", textAlign: "center" }
              ]}
            >
              This app is currently tracking someone else's home screen as the
              default arrangement.
            </Text>
          )}
          {showAsRadioButtons ? (
            <View style={{ width: width * 0.8 }}>
              <Checkbox
                checked={defaultPortalsConfig?.trackOneHomeScreenForAllDefaults}
                radio
                text="Use my personal home screen arrangement as the default for all new members"
                onChange={() => toggleTrackOwnHomeScreen(true)}
                style={{ marginBottom: 20 }}
              />
              <Checkbox
                checked={
                  !defaultPortalsConfig?.trackOneHomeScreenForAllDefaults
                }
                radio
                text="Use a separate default home screen arrangement for each account type"
                onChange={() => toggleTrackOwnHomeScreen(false)}
                style={{ marginBottom: 20 }}
              />
            </View>
          ) : (
            <Button
              text="Use My Home Screen as Default"
              icon="customize"
              onPress={() => toggleTrackOwnHomeScreen(true)}
            />
          )}
          {(!showAsRadioButtons ||
            !defaultPortalsConfig?.trackOneHomeScreenForAllDefaults) && (
            <Button
              text="Set by Account Type"
              icon="people"
              outline={!showAsRadioButtons}
              onPress={() => {
                toggleTrackOwnHomeScreen(false);
                setShowingByAccountType(true);
              }}
              style={{ marginBottom: 50 }}
            />
          )}
        </ScrollView>
        <AlertModal alert={alert} setAlert={setAlert} />
      </View>
    );
  }

  const portalsExist = !!allPortals && allPortals.length > 0;
  const portalKeysForSelectedUserType =
    userTypeToDefaultPortalKeys[selectedUserType] || [];
  const portalsForSelectedUserType = Util.filterPortals(
    portalKeysForSelectedUserType
      .filter((k) => portalKeyToPortal[k])
      .map((k) => portalKeyToPortal[k]),
    selectedUserType
  );

  const numberOfPages = Math.ceil(portalsForSelectedUserType.length / 6);

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Home Screen Arrangement"
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Configuring Default Portals"
              message="On this screen, you can configure what your app will look like as soon as a new user signs up. This can look different for different account types."
              navigation={navigation}
              videoUrl="https://youtu.be/pOEc8AXNhzw"
            />
          </View>
        }
      />
      <View style={{ paddingHorizontal: 15, width }}>
        <Text
          style={{
            ...Style.get("headerText"),
            marginTop: 15
          }}
        >
          Select an account type to edit:
        </Text>
        <Dropdown
          value={selectedUserType}
          items={allUserTypes.map((userType) => {
            return {
              value: userType,
              text: userTypeTitle(userType).capitalize()
            };
          })}
          onSelect={(userType) => {
            Analytics.logEvent("touch_editDefaultPortals_switchAccountType", {
              type: userType
            });
            setSelectedUserType(userType);
            setShowAddPortalsBottomSheet(false);
          }}
        />
      </View>

      <View
        style={{
          marginTop: 10,
          paddingHorizontal: 15,
          width
        }}
      >
        {portalsExist ? (
          <Text style={{}}>
            When {indefinite(userTypeTitle(selectedUserType))} joins your app,
            this is how their home screen will be arranged. You can add screens,
            remove screens, or hold & drag to rearrange screens.
          </Text>
        ) : (
          <Text style={{}}>
            <Text style={{ fontWeight: "bold" }}>
              No screens have been created yet.
            </Text>{" "}
            Go back and create some portals before you can set the default
            arrangement for any new {userTypeTitle(selectedUserType)} who signs
            up.
          </Text>
        )}
      </View>

      {!!portalsExist && (
        <View style={{ backgroundColor: "white" }}>
          <View
            style={{
              alignSelf: "center",
              transform: [{ scale: 0.75 }, { translateY: -0.12 * height }],
              backgroundColor: Rex.getConfig()?.colors?.background,
              shadowColor: "black",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.25,
              shadowRadius: 5,
              elevation: 7,
              borderRadius: Platform.OS === "web" ? 0 : 30
            }}
          >
            <ImageBackground
              source={{ uri: backgroundImageURL }}
              borderRadius={Platform.OS === "web" ? 0 : 25}
            >
              <PageList
                isEditing
                portals={portalsForSelectedUserType}
                navigation={navigation}
                userIsAllowedToCreatePortals={false}
                userIsAllowedToCustomizeHomeScreen={false}
                numberOfPages={numberOfPages}
                updateUserPortals={false}
                onOrderChanged={(newOrder) => {
                  const newKeys = newOrder.map((p) => p.navName);
                  Database.addTask("editDefaultPortals", "done");
                  onUpdatePortalKeys(selectedUserType, newKeys);
                }}
                viewHeight={0.8 * height}
                showBackgroundBlurOnWeb={false}
              />
              <Button
                key="addNewPortalButton"
                text="Add Screens"
                icon="plus"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  marginBottom: 10
                }}
                textStyle={{ color: "black", fontWeight: "bold" }}
                onPress={() => setShowAddPortalsBottomSheet(true)}
              />
              {showAddPortalsBottomSheet && (
                <BottomSheetModal
                  onClose={() => setShowAddPortalsBottomSheet(false)}
                  content={
                    <AddPortalsToHomeScreen
                      navigation={navigation}
                      accountType={selectedUserType}
                      initialTitle="Add to this default home screen"
                      initialSubtitle={`Touch any screen below to add it to the default ${userTypeTitle(
                        selectedUserType
                      )} home screen arrangement.`}
                      initialActivePortals={portalsForSelectedUserType}
                      updateUserPortals={false}
                      onPressAddPortal={onPressAddPortal}
                    />
                  }
                  fullStyle
                />
              )}
            </ImageBackground>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  text: { marginTop: 15 }
});
