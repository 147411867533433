import React, { useEffect } from "react";
import { Platform, Text } from "react-native";
import { BlurView } from "expo-blur";
import Dots from "react-native-dots-pagination";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withDelay,
  Easing
} from "react-native-reanimated";

export default function HomePaginationDots({
  currentPage,
  numberOfPages,
  showCustomizeTip
}) {
  const textFadeAnim = useSharedValue(0);
  const fadeAnim = useSharedValue(1);
  const slideAnim = useSharedValue(30);

  useEffect(() => {
    slideAnim.value = withTiming(0, {
      duration: 2000,
      easing: Easing.inOut(Easing.quad)
    });
    fadeAnim.value = withDelay(4000, withTiming(0, { duration: 1000 }));
    textFadeAnim.value = withTiming(1, { duration: 1000 });
  }, [textFadeAnim, fadeAnim, slideAnim]);

  const fadeMoveStyle = useAnimatedStyle(() => {
    return {
      opacity: textFadeAnim.value,
      transform: [{ translateX: slideAnim.value }]
    };
  });

  const fadeStyle = useAnimatedStyle(() => {
    return {
      opacity: fadeAnim.value,
      width: "100%"
    };
  });

  const inverseFadeStyle = useAnimatedStyle(() => {
    return {
      opacity: 1 - fadeAnim.value
    };
  });

  return (
    <>
      {!!showCustomizeTip && (
        <Animated.View style={fadeStyle}>
          <BlurView
            intensity={10}
            style={{
              padding: 20,
              paddingBottom: 40,
              width: "100%",
              backgroundColor:
                Platform.OS === "android" ? "rgba(0,0,0,0.2)" : null
            }}
          >
            <Animated.View style={fadeMoveStyle}>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: 16
                }}
              >
                Touch & hold any portal to reorder
              </Text>
            </Animated.View>
          </BlurView>
        </Animated.View>
      )}
      <Animated.View
        style={[
          showCustomizeTip ? inverseFadeStyle : {},
          { bottom: 10, position: "absolute" }
        ]}
      >
        <Dots
          length={numberOfPages}
          active={currentPage}
          passiveColor="rgba(255,255,255,0.3)"
          activeColor="white"
          passiveDotWidth={12}
          passiveDotHeight={12}
          activeDotWidth={20}
          activeDotHeight={20}
          marginHorizontal={7}
        />
      </Animated.View>
    </>
  );
}
