import React from "react";
import { View } from "react-native";
import { HStack, VStack, Text, Alert } from "native-base";
import Glob from "src/globalConstants";

const { width } = Glob.get("dimensions");

// type: "success", "warning", "error", "info"
export default function StatusMessage({ type, message, style }) {
  return (
    <View style={[{ width: width - 30 }, style]}>
      <Alert w="100%" variant="left-accent" colorScheme={type} status={type}>
        <VStack space={2} flexShrink={1} w="100%">
          <HStack
            flexShrink={1}
            space={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack space={2} flexShrink={1} alignItems="center">
              <Alert.Icon />
              <Text color="coolGray.800" paddingRight={5}>
                {message}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </Alert>
    </View>
  );
}
