import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Share,
  Text,
  View,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import QRCode from "react-native-qrcode-svg";
import { isMobile } from "react-device-detect";
import LottieView from "src/components/Lottie";
import Style from "src/globalStyles";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import School from "school/school";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const PEOPLE_ANIMATION = require("resources/animations/peopleNetwork.json");

const { width, height } = Glob.get("dimensions");

const IS_DESKTOP = Platform.OS === "web" && !isMobile;

export default function ShareApp({ route, navigation }) {
  const { params: { userWantsToInstallMobileApp } = {} } = route || {};
  const [magicLink, setMagicLink] = useState(null);
  const [appStoreLinks, setAppStoreLinks] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_shareApp");
    if (!userWantsToInstallMobileApp) Database.addTask("viewShareApp", "done");
    Database.fetchAppStoreInfo().then((info) => {
      if (info?.appLinks) setAppStoreLinks(info.appLinks);
      // for backwards compatibility
      else
        setAppStoreLinks({
          android: School.get("app link android"),
          ios: School.get("app link ios")
        });
    });
    Database.fetchPrimaryMetaApp().then(({ webLink }) =>
      setMagicLink(`${webLink}&openAppStore=true`)
    );
  }, []);

  const onShare = async () => {
    Analytics.logEvent("touch_shareApp_shareButton");
    try {
      await Share.share({ message: magicLink });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  const screenTitle = userWantsToInstallMobileApp
    ? "Install the App"
    : "Share This App";

  if (!appStoreLinks || !magicLink)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={screenTitle} />
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" />
        </View>
      </View>
    );

  const linkStyle = {
    fontSize: 12,
    textAlign: "center",
    marginVertical: 5
  };

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={screenTitle} />

      <ScrollView
        contentContainerStyle={{ width: width * 0.9 }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <View style={{ height: height / 2.6, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop
            style={{ height: "100%", width: "100%" }}
            source={PEOPLE_ANIMATION}
          />
        </View>
        {IS_DESKTOP ? (
          <View style={{ alignItems: "center" }}>
            {!userWantsToInstallMobileApp && (
              <Text
                style={[
                  styles.descriptionText,
                  { marginBottom: 20, textAlign: "center" }
                ]}
              >
                Invite others to join you in the{" "}
                {Rex.getConfig()?.names?.nickname} app by sending them this
                magic link:
              </Text>
            )}
            <Text style={Style.get("headerText")}>
              {userWantsToInstallMobileApp
                ? "Install the Mobile App"
                : "Magic Link"}
            </Text>
            <TouchableLink
              type="web"
              link={magicLink}
              text={magicLink}
              textStyle={linkStyle}
            />
          </View>
        ) : (
          <>
            <Text style={styles.descriptionText}>
              Invite others to join you in the{" "}
              {Rex.getConfig()?.names?.nickname} app:
            </Text>
            <Button text="Share" onPress={onShare} icon="send" />
          </>
        )}

        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          <View style={{ alignItems: "center", width: width / 3 }}>
            <QRCode size={height / 4} value={magicLink} />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  },
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20,
    marginTop: 40
  },
  descriptionText: {
    fontSize: 18
  }
});
