import React from "react";
import { View, StyleSheet } from "react-native";
import Checkbox from "src/components/Checkbox";

// items parameter should be: [{key: 1, value: true, text: "Example"}, ...]
export default function Multiselect({
  radio,
  items = [],
  onToggle = (key, value) => {},
  editable = true
}) {
  return (
    <View style={styles.container}>
      {items.map((item) => (
        <Checkbox
          key={item.key}
          checked={item.value}
          text={item.text}
          onChange={(newValue) => onToggle(item.key, newValue)}
          radio={radio}
          disabled={!editable}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    width: "100%"
  }
});
