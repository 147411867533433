import React from "react";
import { Text, View, Image, ScrollView } from "react-native";
import Glob from "src/globalConstants";
import HelpText from "src/components/HelpText";

const TEXT_SCREEN_IMAGE = require("resources/images/phoneTextScreen.png");

const { width } = Glob.get("dimensions");
const TEXT_SCREEN_IMAGE_WH_RATIO = 683 / 1345;
const TEXT_SCREEN_IMAGE_WIDTH = Math.min(width * 0.7, 400);
const TEXT_SCREEN_IMAGE_HEIGHT =
  TEXT_SCREEN_IMAGE_WIDTH / TEXT_SCREEN_IMAGE_WH_RATIO;

export default function PreviewTextMessage({ message, isTruncated }) {
  return (
    <>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image
          source={TEXT_SCREEN_IMAGE}
          style={{
            width: TEXT_SCREEN_IMAGE_WIDTH,
            height: TEXT_SCREEN_IMAGE_HEIGHT
          }}
          resizeMode="contain"
        />
        <View
          style={{
            position: "absolute",
            top: TEXT_SCREEN_IMAGE_HEIGHT * 0.175,
            left: 0,
            right: 0,
            alignItems: "center"
          }}
        >
          <ScrollView
            style={{
              width: TEXT_SCREEN_IMAGE_WIDTH * 0.8,
              height: TEXT_SCREEN_IMAGE_HEIGHT * 0.42
            }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <View
              style={{
                width: TEXT_SCREEN_IMAGE_WIDTH * 0.68,
                padding: 7,
                borderRadius: 10,
                borderBottomLeftRadius: 0,
                backgroundColor: "#E9E9EB",
                flexDirection: "row"
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 11.5, marginBottom: 0 }}>
                  {message}
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
      {!!isTruncated && (
        <HelpText
          text={
            <Text style={{}}>
              <Text style={{ fontWeight: "bold" }}>
                Your text message is truncated because it's longer than{" "}
                {Glob.get("maxTextMessageCharacters")} characters.
              </Text>{" "}
              Consider making your message shorter.
            </Text>
          }
          noteStyle
        />
      )}
    </>
  );
}
