import React from "react";
import { Platform } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import Rex from "src/globalState";

// alert structure:
// {
//   title: "Title",
//   message: "Message",
//   confirm: {text: "OK", color: "blue", onPress: () => {}},
//   cancel: {text: "OK", color: "red", onPress: () => {}},
//   disableOutsideTouch: true
// }
export default function AlertModal({ alert, setAlert }) {
  if (!alert) return null;
  return (
    <AwesomeAlert
      alertContainerStyle={Platform.OS === "web" ? { position: "fixed" } : {}}
      show={!!alert}
      showProgress={false}
      title={alert?.title}
      message={alert?.message}
      closeOnTouchOutside={!alert?.disableOutsideTouch}
      closeOnHardwareBackPress={false}
      showCancelButton={!!alert?.cancel?.text}
      showConfirmButton
      cancelText={alert?.cancel?.text}
      confirmText={alert?.confirm?.text || "OK"}
      cancelButtonColor={alert?.cancel?.color || "gray"}
      confirmButtonColor={
        alert?.confirm?.color || Rex.getConfig()?.colors?.button
      }
      customView={alert?.customView || null}
      onDismiss={() => setAlert(null)}
      onConfirmPressed={() => {
        setAlert(null);
        if (alert?.confirm?.onPress) alert.confirm.onPress();
      }}
      onCancelPressed={() => {
        setAlert(null);
        if (alert?.cancel?.onPress) alert.cancel.onPress();
      }}
    />
  );
}
