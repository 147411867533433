import React from "react";
import { View } from "react-native";
import { Skeleton } from "native-base";

export default function PostSkeleton() {
  return (
    <View
      style={{
        flexDirection: "row",
        marginBottom: 10,
        width: "100%",
        padding: 20,
        alignSelf: "center",
        backgroundColor: "#fff"
      }}
    >
      <Skeleton
        flex="1"
        h="12"
        rounded="full"
        startColor="#eee"
        endColor="#ddd"
      />
      <Skeleton.Text flex="3" px="4" startColor="#eee" endColor="#ddd" />
    </View>
  );
}
