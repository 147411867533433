import React, { useState, useEffect } from "react";
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform
} from "react-native";
import { ImageZoom } from "@likashefqet/react-native-image-zoom";
import getVideoId from "get-video-id";
import LottieView from "src/components/Lottie";
import Firebase from "src/backend/firebase";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import InputBox from "src/components/InputBox";
import ButtonSwitches from "src/components/buttonSwitches";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Video from "src/components/Video";
import Checkbox from "src/components/Checkbox";
import HelpText from "src/components/HelpText";

const { height, width } = Glob.get("dimensions");
const videoServiceTypeToName = {
  youtube: "YouTube",
  vimeo: "Vimeo"
};
const SENDING_IMAGE = require("resources/animations/sendingImage.json");

export default function MediaItem({
  item,
  editable,
  isEditingAnItem,
  setIsEditingAnItem,
  navigation,
  onChangeItem,
  dataSourceRow,
  dontAllowZoom = false
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;
  const { type } = item; // "image" or "video"

  // If it's pulling from Firebase, it's a URL. If from School.js, it's a required file.
  // TODO: Remove this check once dynamic info is moved from all School.js files into Firebase.
  const source =
    typeof item[type] === "string"
      ? { uri: Util.textParser(item[type], { dataSourceRow }).join("") }
      : item[type];
  const imageWidthMultiplier = item.small ? 0.4 : item.width || 0.9;
  const imageHeightMultiplier = item.small ? 0.4 : item.height || 0.9;

  useEffect(() => {
    if (item.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
      if (item.mediaToUpload) {
        setIsUploadingMedia(true);
        Firebase.uploadMedia(item.mediaToUpload, {
          onProgressUpdate: setUploadProgress
        }).then((media) => {
          setIsUploadingMedia(false);
          setUploadProgress(0);
          delete newItem.mediaToUpload;
          const uploadedItem = { ...newItem, [type]: media, uploaded: true };
          onChangeItem(uploadedItem);
        });
      }
    }
    if (source?.uri) {
      Image.getSize(source?.uri, (w, h) => {
        setAspectRatio(w / h);
      });
    }
  }, []);

  const toggleEditing = () => {
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  const allowEditingURL = !isUploadingMedia && !item.uploaded;

  let imageHeight = imageHeightMultiplier * width;
  let imageWidth = imageWidthMultiplier * width;

  // Remove the whitespace borders around the image
  if (aspectRatio) {
    // width > height
    if (aspectRatio > 1) imageHeight /= aspectRatio;
    // height > width
    else imageWidth *= aspectRatio;
  }

  // The image should never take up more than 70% of the screen
  if (imageHeight > height * 0.7) imageHeight = height * 0.7;

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  const renderMedia = () => {
    const sourceIsInvalid =
      !source ||
      ("uri" in source && (!source.uri || !source.uri.includes("http")));
    if (isUploadingMedia)
      return (
        <View style={{ paddingBottom: 10, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop
            style={{ height: imageHeight, width: imageWidth }}
            source={SENDING_IMAGE}
          />
          <Text style={Style.get("headerText")}>
            {uploadProgress}% uploaded...
          </Text>
        </View>
      );
    if (type === "video")
      return (
        <Video
          source={source}
          autoPlay={item.autoPlay}
          loop={item.loop}
          onPress={editable && !isEditing ? toggleEditing : null}
        />
      );
    if (!editable && sourceIsInvalid) return null;
    // If we weren't able to get the source (e.g. if this media is in a data feed section)
    if (editable && !aspectRatio)
      return (
        <>
          {Platform.OS === "web" || dontAllowZoom ? (
            <Image
              source={source}
              style={[
                styles.image,
                {
                  height: imageHeight,
                  width: imageWidth
                },
                item.style,
                { backgroundColor: "#d0d0f7" }
              ]}
            />
          ) : (
            <ImageZoom
              source={source}
              style={[
                styles.image,
                {
                  height: imageHeight,
                  width: imageWidth,
                  zIndex: 999,
                  elevation: 999
                },
                item.style,
                { backgroundColor: "#d0d0f7" }
              ]}
            />
          )}
          {sourceIsInvalid && (
            <Icon
              style={{
                position: "absolute",
                top: imageHeight * 0.5 - 20,
                left: width * 0.5 - 20
              }}
              icon="photos"
              color={Glob.get("onespotColor")}
              size={40}
            />
          )}
        </>
      );
    return (
      <>
        {!!loadingImage && (
          <ActivityIndicator
            size="large"
            style={{
              position: "absolute",
              top: imageHeight * 0.5 - 20,
              left: width * 0.5 - 40
            }}
          />
        )}
        {Platform.OS === "web" || dontAllowZoom ? (
          <Image
            source={source}
            style={[
              styles.image,
              {
                height: imageHeight,
                width: imageWidth
              },
              item.style
            ]}
            onLoad={() => setLoadingImage(false)}
          />
        ) : (
          <ImageZoom
            source={source}
            style={[
              styles.image,
              {
                height: imageHeight,
                width: imageWidth,
                zIndex: 999,
                elevation: 999
              },
              item.style
            ]}
            onLoad={() => setLoadingImage(false)}
          />
        )}
      </>
    );
  };
  const { service: embeddedVideoService } = source?.uri
    ? getVideoId(source?.uri)
    : {};
  const disableEditing =
    !editable || isEditingADifferentItem || (type === "video" && isEditing);
  return (
    <View style={isEditing ? editingStyle : {}}>
      {disableEditing ? (
        renderMedia()
      ) : (
        <TouchableOpacity
          key={item.key}
          activeOpacity={0.7}
          onPress={toggleEditing}
        >
          {renderMedia()}
        </TouchableOpacity>
      )}
      {isEditing && (
        <View
          style={{
            width: "90%",
            alignSelf: "center",
            padding: 5,
            paddingBottom: 10,
            marginBottom: 10
          }}
        >
          {type === "image" && (
            <ButtonSwitches
              initialButtonState={item.small ? "left" : "right"}
              leftOption="Small"
              rightOption="Large"
              rightAction={() => {
                const newItem = { ...item };
                delete newItem.small;
                onChangeItem(newItem);
              }}
              leftAction={() => onChangeItem({ ...item, small: true })}
            />
          )}
          {allowEditingURL && (
            <>
              <InputBox
                key="mediaLink"
                header={`Link to ${type === "video" ? "Video" : "Image"}`}
                description="YouTube, Vimeo, or a file URL (mp4, mov, etc.)"
                value={item[type]}
                navigation={navigation}
                onChangeText={(text) => onChangeItem({ ...item, [type]: text })}
                browseForLinkOptions={{
                  show: true,
                  originalURL: "https://www.google.com/imghp",
                  onPickURL: (text) => onChangeItem({ ...item, [type]: text }),
                  disabled: false
                }}
              />
              {!!embeddedVideoService &&
                !!videoServiceTypeToName[embeddedVideoService] && (
                  <HelpText
                    text={`Detected a ${videoServiceTypeToName[embeddedVideoService]} link 👍`}
                  />
                )}
            </>
          )}
          {type === "video" && !embeddedVideoService && (
            <>
              <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
                Settings
              </Text>
              {/* todo: This doesn't work on mobile (because uses a WebView as of Feb 3, 2023) */}
              {/* <Checkbox
                text="Start playing automatically"
                checked={item.autoPlay}
                onChange={(autoPlay) => onChangeItem({ ...item, autoPlay })}
              /> */}
              <Checkbox
                text="Loop video at end"
                checked={item.loop}
                onChange={(loop) => onChangeItem({ ...item, loop })}
              />
            </>
          )}
          <Button
            text="✓ Done"
            onPress={toggleEditing}
            small
            style={{
              backgroundColor: "#555",
              marginTop: 30
            }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    alignSelf: "center",
    marginVertical: 10,
    resizeMode: "contain"
  }
});
