import React, { useState, useRef } from "react";
import { TouchableOpacity, View, Platform } from "react-native";
import { Video as ExpoVideo, VideoFullscreenUpdate } from "expo-av";
import getVideoId from "get-video-id";
import WebView from "react-native-webview";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import HTMLItem from "src/components/dynamicContent/HTMLItem";

const { height, width } = Glob.get("dimensions");
const YOUTUBE_VIDEO_ASPECT_RATIO = 560 / 315;
const VIMEO_VIDEO_ASPECT_RATIO = 640 / 360;
const DEFAULT_VIDEO_WIDTH = 0.9 * width;

export default function Video({ source, autoPlay, loop, onPress }) {
  const video = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(null);

  let videoHeight = Math.min(DEFAULT_VIDEO_WIDTH, height * 0.8);

  // Remove the whitespace borders around the image if width > height
  if (aspectRatio && aspectRatio > 1) videoHeight /= aspectRatio;

  const playButtonWidth = Math.min(videoHeight * 0.33, 100);
  const fullscreenButtonWidth = Math.min(playButtonWidth * 0.5, 50);

  const { id, service } = getVideoId(source.uri);
  // If the source uri is a link to YouTube, Vimeo, etc.
  if (id && service) {
    let code = "<p>No video found.</p>";
    if (service === "youtube")
      code = `<iframe
        width="${DEFAULT_VIDEO_WIDTH}"
        height="${DEFAULT_VIDEO_WIDTH / YOUTUBE_VIDEO_ASPECT_RATIO}"
        src="https://www.youtube.com/embed/${id}"
        title="YouTube Video Player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>`;
    else if (service === "vimeo")
      code = `<iframe
        src="https://player.vimeo.com/video/${id}?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0"
        width="${DEFAULT_VIDEO_WIDTH}"
        height="${DEFAULT_VIDEO_WIDTH / VIMEO_VIDEO_ASPECT_RATIO}"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
      ></iframe>`;
    if (onPress)
      return (
        <TouchableOpacity
          style={{ marginVertical: 10 }}
          activeOpacity={0.8}
          onPress={onPress}
        >
          <View pointerEvents="none">
            <HTMLItem item={{ code }} />
          </View>
        </TouchableOpacity>
      );
    return <HTMLItem item={{ code }} />;
  }

  // Otherwise, the source uri is a link to a file (including an uploaded file). For example:
  // -> { uri: "https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4" }
  // -> { uri: "https://firebasestorage.googleapis.com/v0/b/seabirdmain.appspot.com/o/apps%2Fafton-village%2Fuploads%2FseEBR0R0P6hkvPwxZB77rz5d74s1-1675298554570-792974.mov?alt=media&token=d8ba47cb-fa6f-4f05-b832-6d5aa35a2572" }

  // Note: This is because expo-av sometimes crashes the standalone mobile app (but not Expo Go) as of Feb 3, 2023
  if (Platform.OS !== "web") {
    const webVideoHeight =
      Platform.OS === "android"
        ? DEFAULT_VIDEO_WIDTH
        : DEFAULT_VIDEO_WIDTH / YOUTUBE_VIDEO_ASPECT_RATIO;
    if (onPress)
      return (
        <TouchableOpacity
          style={{ marginVertical: 10, alignItems: "center", width: "100%" }}
          activeOpacity={0.8}
          onPress={onPress}
        >
          <View
            pointerEvents="none"
            style={{
              width: DEFAULT_VIDEO_WIDTH,
              height: webVideoHeight,
              backgroundColor: "black",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <View
              style={{
                width: playButtonWidth * 0.5,
                height: playButtonWidth * 0.5,
                borderRadius: playButtonWidth * 0.5,
                backgroundColor: "#333",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FontAwesome5
                name="play"
                size={playButtonWidth * 0.2}
                color="white"
                style={{ marginLeft: playButtonWidth * 0.04 }}
              />
            </View>
          </View>
        </TouchableOpacity>
      );
    return (
      <View
        style={{
          width: DEFAULT_VIDEO_WIDTH,
          height: webVideoHeight,
          alignSelf: "center",
          marginVertical: 10
        }}
      >
        <WebView
          scrollEnabled={false}
          // This line fixes a bug (https://github.com/meliorence/react-native-render-html/issues/393#issuecomment-1277533605)
          style={{ opacity: 0.99 }}
          source={{
            // Note: Adding #t=0.1 to end of src url preloads the image (https://stackoverflow.com/a/50386123)
            html: `
              <video width="100%" height="100%" style="background-color:black" controls loop=${!!loop} allowfullscreen>
                <source src="${source?.uri}#t=0.1">
              </video>
            `
          }}
          userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
        />
      </View>
    );
  }

  // Web only
  return (
    <View style={{ marginVertical: 10 }}>
      <TouchableOpacity
        style={{ height: videoHeight, width: "100%" }}
        activeOpacity={0.8}
        onPress={() => {
          if (onPress) onPress();
          else if (!isFullscreen && video && video.current) {
            if (isPlaying) video.current.pauseAsync();
            else video.current.playAsync();
          }
        }}
      >
        <ExpoVideo
          ref={video}
          style={{ flex: 1 }}
          source={source}
          resizeMode="contain"
          shouldPlay={autoPlay}
          islooping={loop}
          onReadyForDisplay={(e) => {
            let w;
            let h;
            if (Platform.OS === "web") {
              w = e.target.offsetWidth;
              h = e.target.offsetHeight;
            } else {
              w = e.naturalSize.width;
              h = e.naturalSize.height;
            }
            setAspectRatio(w / h);
          }}
          onFullscreenUpdate={({ fullscreenUpdate }) => {
            if (
              fullscreenUpdate === VideoFullscreenUpdate.PLAYER_WILL_PRESENT ||
              fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_PRESENT
            )
              setIsFullscreen(true);
            else {
              if (video && video.current) video.current.pauseAsync();
              setIsFullscreen(false);
            }
          }}
          onPlaybackStatusUpdate={(status) => {
            setIsPlaying(() => !!status?.isPlaying);
          }}
          playsInSilentModeIOS
        />
        {!isPlaying && (
          <View
            style={{
              width: playButtonWidth,
              height: playButtonWidth,
              borderRadius: playButtonWidth,
              backgroundColor: "gray",
              position: "absolute",
              top: (videoHeight - playButtonWidth) * 0.5,
              left: (width - playButtonWidth) * 0.5,
              opacity: 0.6,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FontAwesome5
              name="play"
              size={playButtonWidth * 0.5}
              color="white"
              style={{ marginLeft: playButtonWidth * 0.1 }}
            />
          </View>
        )}
      </TouchableOpacity>
      {!isPlaying && (
        <TouchableOpacity
          style={{
            width: fullscreenButtonWidth,
            height: fullscreenButtonWidth * 0.65,
            borderRadius: fullscreenButtonWidth * 0.15,
            backgroundColor: "gray",
            position: "absolute",
            top: fullscreenButtonWidth * 0.4,
            right: 0.05 * width + 10,
            opacity: 0.6,
            justifyContent: "center",
            alignItems: "center"
          }}
          onPress={() => {
            if (onPress) onPress();
            else if (video && video.current) {
              video.current.presentFullscreenPlayer();
              video.current.playAsync();
            }
          }}
        >
          <MaterialCommunityIcons
            name="arrow-expand"
            size={fullscreenButtonWidth * 0.5}
            color="white"
          />
        </TouchableOpacity>
      )}
    </View>
  );
}
