import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { fetchSignInMethodsForEmail } from "firebase/auth/react-native";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

export default function ChangePassword({ navigation, route }) {
  const { userIsCreator, canManageBilling, billingManagementURL } =
    route?.params || {};
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState(null);
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_changePassword");
    Database.listenUserEmail((userEmail) => {
      setEmail(userEmail);
      // Note (Feb 2023): We don't yet use google auth as a sign-in method.
      // See the google-auth branch and this pull request: https://github.com/seabirdapps/aa-dev-app/pull/59
      fetchSignInMethodsForEmail(Firebase.getAuth(), userEmail).then(
        (signInMethods) => {
          if (signInMethods.includes("google.com")) setIsGoogleAccount(true);
        }
      );
      setIsLoading(false);
    });
  }, []);

  const errorAlert = (message) => setAlert({ title: "Error", message });

  const tryUpdatePassword = () => {
    if (oldPassword === null) errorAlert("Please enter your current password.");
    else if (newPassword === null) errorAlert("Please enter a new password.");
    else if (newPassword.length < 6)
      errorAlert("Please enter a password that is at least 6 characters long.");
    else if (confirmPassword === null)
      errorAlert("Please confirm your new password.");
    else if (newPassword !== confirmPassword)
      errorAlert("Passwords do not match.");
    else updatePassword();
  };

  const updatePassword = () => {
    Analytics.logEvent("touch_changePassword_saveChanges");
    Database.updateUserPassword(oldPassword, newPassword)
      .then(() => {
        setAlert({ title: "Your password has been updated!" });
        navigation.goBack();
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password")
          errorAlert(
            "The current password you entered was incorrect. If you can't remember your current password, consider reseting your password by email."
          );
        else errorAlert(`${error}`);
      });
  };

  const onPressResetPasswordByEmail = () => {
    Analytics.logEvent("touch_changePassword_resetPasswordViaEmail");
    setAlert({
      title: "Send a password-reset email?",
      message: `This will log you out of your account right now and will send an email to ${email}.`,
      cancel: {
        text: "Cancel",
        onPress: () => {}
      },
      confirm: { text: "Yes", onPress: () => sendPasswordResetEmail(email) }
    });
  };

  const sendPasswordResetEmail = () => {
    Analytics.logEvent("touch_changePassword_passwordResetViaEmailConfirm");
    Database.sendPasswordResetEmail()
      .then(() => {
        Rex.setLoginStatus(false);
        Database.logoutUser()
          .then(() => {
            Util.alert(`Email sent to ${email}! You're now logged out.`);
            Util.reloadApp();
          })
          .catch((error) => {
            Util.alert(error);
            Util.reloadApp();
          });
      })
      .catch((error) => errorAlert(`${error}`));
  };

  if (isLoading)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Account Settings" />
        <ActivityIndicator size="large" style={{ marginTop: 50 }} />
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Account Settings" />
      {isGoogleAccount ? (
        <View
          style={{
            width,
            paddingHorizontal: 15,
            marginTop: 20,
            marginBottom: 50
          }}
        >
          <Text style={Style.get("headerText")}>
            You're signed in with Google 👍
          </Text>
          <HelpText
            text={[
              `You're signed into this app with your ${email} Google account. You can use that one account across all apps in `,
              <Text style={{ textDecorationLine: "underline" }}>
                <TouchableLink
                  type="web"
                  link="https://www.1spot.app"
                  text="the Onespot ecosystem"
                  openExternally
                />
              </Text>,
              "."
            ]}
          />
        </View>
      ) : (
        <>
          <View
            style={{
              paddingHorizontal: 15,
              width
            }}
          >
            <Text
              style={[
                Style.get("headerText"),
                { marginTop: 20, color: "gray" }
              ]}
            >
              Change Your Password
            </Text>
            {email !== Glob.get("demoAccountEmail") && (
              <>
                <InputBox
                  secure
                  key="newPassword"
                  header="Current Password"
                  description="The password you have"
                  onChangeText={setOldPassword}
                  value={oldPassword}
                />
                <InputBox
                  secure
                  key="newPassword"
                  header="New Password"
                  description="The password you want"
                  onChangeText={setNewPassword}
                  value={newPassword}
                />
                <InputBox
                  secure
                  key="confirmPassword"
                  header="Confirm New Password"
                  description="Type your new password again"
                  onChangeText={setConfirmPassword}
                  value={confirmPassword}
                />
                <Button onPress={tryUpdatePassword} text="Update Password" />
              </>
            )}
          </View>
          <Text style={{ marginTop: 30, color: "gray" }}>
            Can't remember your current password?
          </Text>
          <Button
            small
            flat
            outline
            onPress={onPressResetPasswordByEmail}
            text="Reset by Email"
          />
        </>
      )}

      <View
        style={{
          padding: 15,
          marginTop: 20,
          width: 0.96 * width,
          borderColor: Glob.get("dangerRed"),
          borderWidth: 5,
          backgroundColor: "#aaa"
        }}
      >
        <Text style={[Style.get("headerText"), { color: "black" }]}>
          Danger Zone
        </Text>
        <Text style={{ color: "black" }}>
          {canManageBilling
            ? "Make sure you've cancelled your Onespot subscription before permanently deleting your account."
            : "Submit this form to permanently delete your account:"}
        </Text>
        {canManageBilling && (
          <Button
            small
            flat
            color={Glob.get("dangerRed")}
            text="Cancel My Subscription"
            onPress={() => {
              Analytics.logEvent("touch_changePassword_cancelSubscription");
              Util.openURL(billingManagementURL);
            }}
          />
        )}
        <Button
          small
          flat
          color={Glob.get("dangerRed")}
          text="Delete My Account"
          outline
          onPress={() => {
            Analytics.logEvent("touch_changePassword_deleteAccount");
            navigation.push("dynamicForm", {
              navName: "DeleteOnespotAccount",
              isGlobalForm: true,
              userIsCreator
            });
          }}
        />
      </View>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
