import React, { Component } from "react";
import { View } from "react-native";
import NavBar from "src/components/navBar";
import CustomList from "src/components/customList";
import ButtonSwitches from "src/components/buttonSwitches";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";

export default class ListWebNavsSplit extends Component {
  constructor(props) {
    super(props);
    // note that "tabs" is the two sub-entities in firebase under the portal (e.g. ["mens", "womens"] under "sports")
    this.state = {
      dataRows: [],
      tabs: [],
      portalName: null
    };
    const logName = "view_".concat(this.props?.route?.params?.navName || "");
    if (!props.portalOverride) Analytics.logEvent(logName);
  }

  componentDidMount() {
    const { portalOverride } = this.props;
    const portalName =
      (!!portalOverride && portalOverride.name) || this.props?.route?.params?.navName;
    this.setState({ portalName });
    Database.getPortalContent(portalName, (value) => {
      // value is a JSON object like {"mens": {...}, "womens": {...}}
      const tabs = [];
      for (const tab in value) {
        tabs.push(tab);
      }
      this.setState({ tabs });
      this.loadDataForTab(0);
    });
  }

  loadDataForTab = (tabNumber) => {
    Database.getPortalContent(
      this.state.portalName.concat("/").concat(this.state.tabs[tabNumber]),
      (value) => {
        const logName = "touch_"
          .concat(this.state.portalName)
          .concat(`_tab${tabNumber}`);
        Analytics.logEvent(logName); // e.g. touch_sports_tab1 (tab1 means Women's)
        // value is a JSON object like {"banner": "httpUrl...", "canvas": "httpUrl...", etc}
        const webNavsList = [];
        for (const navNameVal in value) {
          const urlVal = value[navNameVal];
          webNavsList.push({ navName: navNameVal, url: urlVal });
        }
        this.setState({ dataRows: webNavsList });
      }
    );
  };

  render() {
    if (this.state.tabs.length === 0) {
      // still waiting for response from the database
      return null;
    }

    const { portalOverride } = this.props;
    const portalTitle =
      (!!portalOverride && portalOverride.title) ||
      this.props?.route?.params?.txtName;

    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <NavBar navigation={this.props.navigation} text={portalTitle} />
        <ButtonSwitches
          leftOption={this.state.tabs[0]}
          rightOption={this.state.tabs[1]}
          leftAction={() => this.loadDataForTab(0)}
          rightAction={() => this.loadDataForTab(1)}
        />
        <CustomList
          dataRows={this.state.dataRows}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}
