import React, { useState } from "react";
import {
  Keyboard,
  TouchableWithoutFeedback,
  View,
  ScrollView,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  Text
} from "react-native";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import BottomSheetModal from "src/components/BottomSheetModal";
import Icon from "src/components/Icon";
import MediaItem from "src/components/dynamicContent/MediaItem";
import DocumentPicker from "src/components/DocumentPicker";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import HelpText from "src/components/HelpText";

const { width } = Glob.get("dimensions");

const NewPostModalContent = ({
  userName,
  onSubmit,
  isChatStyle,
  showRSVPOption = true
}) => {
  const [newPostMessage, setNewPostMessage] = useState(null);
  const [mediaURL, setMediaURL] = useState(null);
  const [pdfURL, setPDFURL] = useState(null);
  const [pdfName, setPDFName] = useState(null);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isPosting, setIsPosting] = useState(false);
  const [allowRSVP, setAllowRSVP] = useState(false);

  const noMessage =
    (!newPostMessage || newPostMessage.length < 1) &&
    (!mediaURL || mediaURL.length < 1) &&
    (!pdfURL || pdfURL.length < 1);

  const pickMedia = () => {
    Analytics.logEvent("touch_NewPostModal_uploadMedia", { type: "image" });
    Util.pickMedia({ type: "image" })
      .then((uri) => {
        Analytics.logEvent("action_NewPostModal_uploadMediaStarting", {
          type: "image",
          uri
        });
        setIsUploadingMedia(true);
        Firebase.uploadMedia(uri, {
          uploadedByEndUser: true,
          onProgressUpdate: setUploadProgress
        }).then((media) => {
          Analytics.logEvent("action_NewPostModal_uploadMediaComplete", {
            type: "image",
            uri,
            media
          });
          setMediaURL(media);
          setIsUploadingMedia(false);
          setUploadProgress(0);
        });
      })
      .catch(() => {
        Util.alert("Enable camera permissions to continue");
      });
  };

  const onUploadPDF = (url, name) => {
    Analytics.logEvent("action_NewPostModal_uploadPDFComplete", {
      type: "pdf",
      url,
      name
    });
    setPDFURL(url);
    setPDFName(name);
    setIsUploadingMedia(false);
    setUploadProgress(0);
  };

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === "web" ? () => {} : Keyboard.dismiss}
    >
      <ScrollView
        contentContainerStyle={{ alignItems: "center" }}
        style={{
          padding: 20,
          width,
          height: "100%"
        }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <InputBox
          header={userName || "New Post"}
          placeholder="Write your message here"
          multiline
          openStyle
          value={newPostMessage}
          onChangeText={setNewPostMessage}
        />
        {isPosting ? (
          <View
            style={{
              alignItems: "center",
              width: "100%",
              paddingTop: 20,
              marginBottom: 60
            }}
          >
            <View>
              <ActivityIndicator size="large" />
              <Text style={{ color: "#8D969D", marginTop: 5 }}>Posting...</Text>
            </View>
          </View>
        ) : (
          <>
            {isUploadingMedia && !mediaURL && !pdfURL ? (
              <>
                <ActivityIndicator size="large" style={{ marginTop: 30 }} />
                <Text style={{ fontSize: 16, color: "#aaa", marginTop: 10 }}>
                  {uploadProgress}% uploaded...
                </Text>
              </>
            ) : (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginRight: 10
                  }}
                  onPress={mediaURL ? () => setMediaURL(null) : pickMedia}
                >
                  <Icon
                    icon={
                      mediaURL
                        ? "8004262d-c0fe-431d-a477-bad410569d7f"
                        : "photos"
                    }
                    color="#868686"
                    size={20}
                  />
                  <Text
                    style={{ color: "#868686", marginLeft: 10, fontSize: 16 }}
                  >
                    {mediaURL ? "Remove" : "Add"} Image
                  </Text>
                </TouchableOpacity>
                <View style={{ marginLeft: 10 }}>
                  <DocumentPicker
                    isNew={!pdfURL}
                    onUpload={onUploadPDF}
                    useChatStyle
                  />
                </View>
                {showRSVPOption && (
                  <TouchableOpacity
                    activeOpacity={0.7}
                    style={{
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      marginTop: 10
                    }}
                    onPress={() => setAllowRSVP(!allowRSVP)}
                  >
                    <MaterialCommunityIcons
                      name={
                        allowRSVP ? "calendar-check" : "calendar-blank-outline"
                      }
                      size={20}
                      color={allowRSVP ? "#4CAF50" : "#868686"}
                    />
                    <Text
                      style={{
                        color: allowRSVP ? "#4CAF50" : "#868686",
                        marginLeft: 10,
                        fontSize: 16
                      }}
                    >
                      Event
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            )}
            {!!mediaURL && (
              <MediaItem
                item={{ type: "image", image: mediaURL }}
                dontAllowZoom
              />
            )}
            {!!pdfURL && (
              <View style={{ marginTop: 10, width: "100%" }}>
                <TouchableLink
                  type="web"
                  link={pdfURL}
                  text={`Attachment: ${pdfName}`}
                  navigate={() => Util.openURL(pdfURL)}
                />
              </View>
            )}
            {!!allowRSVP && (
              <HelpText
                text={`This post will include an option for members to RSVP (similar to the "like" button). This is useful if this post is about an upcoming event and you want to know who will be attending. You (and everyone in this ${
                  isChatStyle ? "group chat" : "feed"
                }) will be able to see who RSVPs by pressing and holding the "RSVP" button.`}
                noteStyle
              />
            )}
            <Button
              icon="send"
              text="Post"
              onPress={() => {
                onSubmit(
                  newPostMessage,
                  mediaURL,
                  pdfURL && pdfName ? { url: pdfURL, name: pdfName } : null,
                  allowRSVP
                );
                setNewPostMessage(null);
                setIsPosting(true);
              }}
              small
              disabled={noMessage}
              style={{
                marginTop: 20,
                opacity: noMessage ? 0.3 : 1,
                marginBottom: 60
              }}
              textStyle={{ fontWeight: "bold", fontSize: 24 }}
            />
          </>
        )}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

export default function NewPostModal({
  onClose,
  userName,
  onSubmit,
  isChatStyle,
  showRSVPOption = true
}) {
  return (
    <BottomSheetModal
      onClose={onClose}
      content={
        <NewPostModalContent
          userName={userName}
          onSubmit={onSubmit}
          isChatStyle={isChatStyle}
          showRSVPOption={showRSVPOption}
        />
      }
      fullStyle
    />
  );
}
