import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import indefinite from "indefinite";
import { MaterialIcons } from "@expo/vector-icons";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";
import IconPicker from "src/components/IconPicker";
import UserTypeButton from "src/components/UserTypeButton";
import Checkbox from "src/components/Checkbox";
import OnespotProBadge from "src/components/OnespotProBadge";
import Multiselect from "src/components/Multiselect";
import Icon from "src/components/Icon";

const { width } = Glob.get("dimensions");

const AccountType = ({
  type,
  details,
  passcode,
  updatePasscode,
  deleteAccountType,
  allowedToEditSecurity,
  accountTypes
}) => {
  const titleWithIndefinite = details.title
    ? indefinite(details.title).toLowerCase()
    : "this type";

  const [accountTypeCheckboxes, setAccountTypeCheckboxes] = useState([]);
  const [showChangesSaved, setShowChangesSaved] = useState(false);
  const [showingIconPicker, setShowingIconPicker] = useState(false);

  useEffect(() => {
    if (
      details.chatPermissions &&
      typeof details.chatPermissions === "object"
    ) {
      const checkboxes = Object.keys(accountTypes).map((accountType) => ({
        key: accountType,
        value: !!details.chatPermissions.accountTypes?.[accountType],
        text: indefinite(
          (accountTypes[accountType]?.title || accountType || "").toLowerCase()
        ).capitalize()
      }));
      setAccountTypeCheckboxes(checkboxes);
    }
  }, [details.chatPermissions, accountTypes]);

  const updateAccountTypeDetails = (newDetails = {}) => {
    Database.setAccountTypeDetails(type, {
      ...details,
      ...newDetails
    });
    setShowChangesSaved(true);
    setTimeout(() => setShowChangesSaved(false), 1500);
  };

  const updateAccountTypePasscode = (newPasscode) => {
    updatePasscode(newPasscode);
    setShowChangesSaved(true);
    setTimeout(() => setShowChangesSaved(false), 1500);
  };

  const onToggleAccountTypeChatPermission = (accountType, value) => {
    const newChatPermissions = {
      ...details.chatPermissions,
      accountTypes: {
        ...details.chatPermissions.accountTypes,
        [accountType]: value
      }
    };
    updateAccountTypeDetails({ chatPermissions: newChatPermissions });
  };
  return (
    <View style={[Style.get("card"), { paddingTop: 0 }]}>
      <View style={{ alignItems: "center", marginTop: 20 }}>
        <IconPicker
          icon={details.icon}
          iconURL={details.iconURL}
          onSelectIcon={(icon) => updateAccountTypeDetails({ icon })}
          onSetIconURL={(iconURL) => updateAccountTypeDetails({ iconURL })} // AI generated (todo)
          onTogglePicker={setShowingIconPicker}
        />
      </View>
      <InputBox
        placeholder="Title"
        onChangeText={(title) => updateAccountTypeDetails({ title })}
        value={details.title}
        style={{ marginTop: 0, alignItems: "center" }}
      />
      {!!allowedToEditSecurity && (
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              height: 40,
              width: width - 30,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center"
            }}
          >
            <View
              style={{
                height: 3,
                width: "100%",
                backgroundColor: "#f7f7f7"
              }}
            />
          </View>
          <Text style={Style.get("headerText")}>
            Security & Permissions <OnespotProBadge />
          </Text>
          <Button
            key={`button-locked-${type}`}
            small
            text={`Security: ${details.locked ? "Private" : "Public"}`}
            flat
            outline={!details.locked}
            noMargin
            style={{ marginTop: 12, marginBottom: 0, minWidth: 200 }}
            textStyle={{ fontWeight: "bold" }}
            align="left"
            icon={
              details.locked
                ? "3054679b-9dcb-46da-96b7-9f89e221983a"
                : "25c375b9-df75-42f3-b964-bf80b1fdbc58"
            }
            onPress={() =>
              updateAccountTypeDetails({ locked: !details.locked })
            }
          />
          <Text style={{ color: "gray", marginTop: 5 }}>
            {!details.locked
              ? `Anyone can join this app as ${titleWithIndefinite}.`
              : `People can only join this app as ${titleWithIndefinite} if they know this secret passcode you set:`}
          </Text>
          {details.locked && (
            <InputBox
              key={`passcode-${type}`}
              placeholder="Enter a passcode"
              value={passcode}
              onChangeText={updateAccountTypePasscode}
            />
          )}
          {!!details.locked && (
            <Text style={{ color: "gray", marginTop: 8 }}>
              You can also invite people to join this app as{" "}
              {titleWithIndefinite} by sending them an invitation. You can send
              invitations within the "Invite Members" screen.
            </Text>
          )}
          <Button
            key={`button-direct-chats-${type}`}
            small
            text={`Direct Chats: ${details.chatPermissions ? "On" : "Off"}`}
            flat
            outline={!details.chatPermissions}
            noMargin
            style={{ marginTop: 25, marginBottom: 0, minWidth: 200 }}
            textStyle={{ fontWeight: "bold" }}
            align="left"
            icon={
              details.chatPermissions
                ? "f825ea8e-8d55-43cb-b31d-2b7dc8560e88"
                : "80a026be-d6d8-4fda-a234-12ae04946dfc"
            }
            onPress={() =>
              updateAccountTypeDetails({
                chatPermissions: !details.chatPermissions
              })
            }
          />
          <Text style={{ color: "gray", marginTop: 8 }}>
            {titleWithIndefinite.capitalize()}{" "}
            {details.chatPermissions ? "can" : "can't"} start a chat with
            {!details.chatPermissions || details.chatPermissions === true
              ? " anyone else in the app."
              : ":"}
          </Text>
          {details.chatPermissions === true && (
            <Button
              small
              text="Limit Chat Permissions"
              flat
              outline
              noMargin
              style={{ marginTop: 10, marginBottom: 0 }}
              align="left"
              color="gray"
              onPress={() => {
                let newChatPermissions = true;
                if (details.chatPermissions === true) {
                  newChatPermissions = {
                    // all set to true
                    accountTypes: (
                      Object.keys(accountTypes || {}) || []
                    ).reduce((acc, key) => {
                      acc[key] = true;
                      return acc;
                    }, {})
                  };
                }
                updateAccountTypeDetails({
                  chatPermissions: newChatPermissions
                });
              }}
            />
          )}
          {!!details.chatPermissions &&
            typeof details.chatPermissions === "object" && (
              <>
                <ScrollView
                  style={{
                    maxHeight: 300,
                    marginLeft: 35,
                    marginTop: 10,
                    marginBottom: 10
                  }}
                  scrollIndicatorInsets={{ right: 1 }}
                >
                  <Multiselect
                    items={accountTypeCheckboxes}
                    onToggle={onToggleAccountTypeChatPermission}
                  />
                  <Checkbox
                    checked={false}
                    text="Anyone"
                    iconColor="black"
                    textStyle={{ fontWeight: "bold" }}
                    onChange={() =>
                      updateAccountTypeDetails({ chatPermissions: true })
                    }
                  />
                  <Checkbox
                    checked={false}
                    text="No one"
                    iconColor="black"
                    textStyle={{ fontWeight: "bold" }}
                    onChange={() =>
                      updateAccountTypeDetails({ chatPermissions: false })
                    }
                  />
                </ScrollView>
                {/* If there are lots of account types, show a shadow at the bottom to make it more obvious that the user can scroll */}
                {accountTypeCheckboxes?.length > 7 && (
                  <View
                    style={{
                      height: 10,
                      backgroundColor: "white",
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: -5
                      },
                      shadowOpacity: 0.1,
                      shadowRadius: 4,
                      elevation: 5
                    }}
                  />
                )}
              </>
            )}
        </View>
      )}
      {showChangesSaved && (
        <View
          style={{
            position: "absolute",
            left: 16,
            top: 16,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Icon icon="check" size={14} color="#2DD881" />
          <Text
            style={{
              fontSize: 12,
              color: "#2DD881",
              marginLeft: 5,
              fontWeight: "bold"
            }}
          >
            Changes saved
          </Text>
        </View>
      )}
      {!showingIconPicker && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            borderRadius: 100,
            backgroundColor: "white",
            padding: 8
          }}
          onPress={() => deleteAccountType(type)}
        >
          <MaterialIcons
            name="delete"
            size={26}
            color={Glob.get("dangerRed")}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default function EditAccountTypes({ navigation }) {
  const [accountTypes, setAccountTypes] = useState(null);
  const [accountTypePasscodes, setAccountTypePasscodes] = useState(null);
  const [isAddingAccountType, setIsAddingAccountType] = useState(false);
  const [alert, setAlert] = useState(null);
  const allAccountTypes = Glob.getAllAccountTypes()
    .filter((type) => !type.hideOption)
    .sort((a, b) => a.buttonText > b.buttonText);
  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToEditSecurity =
    !!adminPrivileges && Rex.getConfig()?.granularSecurityEnabled;

  useEffect(() => {
    Analytics.logEvent("view_editAccountTypes");
    Database.addTask("viewAccountTypes", "done");
    Database.subscribeToAccountTypes(setAccountTypes);
    Database.setAccountTypesIfLegacyDb();
    if (allowedToEditSecurity)
      Database.fetchAccountTypesSecurityInfo().then((accountTypesSecurity) => {
        setAccountTypePasscodes(accountTypesSecurity);
      });

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromAccountTypes();
    };
  }, []);

  if (!accountTypes)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Account Types" />
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" />
        </View>
      </View>
    );

  const deleteAccountType = (accountTypeID) => {
    Analytics.logEvent("touch_editAccountTypes_pressDeleteAccountType", {
      accountTypeID
    });
    const title = accountTypes[accountTypeID]?.title;
    if (Object.keys(accountTypes).length < 2) {
      setAlert({
        title: "You can't delete all account types",
        message: `Your app needs to have at least one account type, so you would need to make a new account type before you can delete this ${
          title ? title.toLowerCase() : ""
        } type.${"\n\n"}Note that if there's only one account type, we won't actually show it to your users when they sign up; we will just assume they are signing up as ${
          title ? indefinite(title).toLowerCase() : "this type"
        }.`,
        confirm: { text: "Got it" }
      });
    } else {
      setAlert({
        title: "Are you sure?",
        message: `Deleting this account type will mean that every member who's currently ${
          title ? indefinite(title).toLowerCase() : "this type"
        } will be switched to a default type.`,
        confirm: { text: "Cancel" },
        cancel: {
          text: "💥 Delete",
          onPress: () => {
            Analytics.logEvent(
              "touch_editAccountTypes_confirmDeleteAccountType",
              {
                accountTypeID
              }
            );
            Database.setAccountTypeDetails(accountTypeID, null);
          }
        }
      });
    }
  };

  const addAccountType = (type) => {
    const { buttonText, iconName } = type;
    Analytics.logEvent("touch_editAccountTypes_addAccountType", {
      title: buttonText,
      icon: iconName
    });
    const defaultPortals =
      Object.values(accountTypes)[0]?.defaultPortals || null;
    Database.addAccountType({
      title: buttonText,
      icon: iconName,
      defaultPortals
    });
    setIsAddingAccountType(false);
  };

  const updatePasscode = (type, passcode) => {
    const newAccountTypePasscodes = {
      ...accountTypePasscodes,
      [type]: { lockedPasscode: passcode }
    };
    setAccountTypePasscodes(newAccountTypePasscodes);
    Database.setSecurityInfo({ accountTypes: newAccountTypePasscodes });
  };

  const allAccountButtonRows = [];
  let temporaryRow = [];
  allAccountTypes.forEach((type, idx) => {
    const { key } = type;
    if (idx % 4 === 3) {
      temporaryRow.push(
        <UserTypeButton
          key={key}
          backgroundColor="white"
          textColor="gray"
          type={key}
          selected
          onPress={() => addAccountType(type)}
        />
      );
      allAccountButtonRows.push(
        <View style={styles.buttonRowContainer}>{temporaryRow}</View>
      );
      temporaryRow = [];
    } else {
      temporaryRow.push(
        <UserTypeButton
          key={key}
          backgroundColor="white"
          textColor="gray"
          type={key}
          selected
          onPress={() => addAccountType(type)}
        />
      );
    }
  });
  if (temporaryRow.length > 0) {
    allAccountButtonRows.push(
      <View style={styles.buttonRowContainer}>{temporaryRow}</View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Account Types" />

      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
          Edit which account types users can be
        </Text>
        <Text style={[Style.get("subheaderText"), { marginBottom: 20 }]}>
          When new users join your app, they select an account type. This
          affects their default portal configuration and also lets you send
          notifications to specific account types.
        </Text>

        {Object.entries(accountTypes).map(([key, details]) => (
          <AccountType
            type={key}
            details={details}
            passcode={
              accountTypePasscodes && accountTypePasscodes[key]?.lockedPasscode
            }
            deleteAccountType={deleteAccountType}
            allowedToEditSecurity={allowedToEditSecurity}
            updatePasscode={(passcode) => updatePasscode(key, passcode)}
            accountTypes={accountTypes}
          />
        ))}
        {isAddingAccountType ? (
          <View>
            <Text style={Style.get("headerText")}>
              Select an account type template to add
            </Text>
            <Text style={[Style.get("subheaderText"), { marginBottom: 20 }]}>
              You can fully customize it once you've added it.
            </Text>
            {allAccountButtonRows}
          </View>
        ) : (
          <Button
            text="+ New Account Type"
            style={{ marginBottom: 20 }}
            onPress={() => setIsAddingAccountType(true)}
          />
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  buttonRowContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10
  },
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20,
    marginTop: 40
  }
});
